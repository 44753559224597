import {combineReducers} from 'redux';

// Other
import {searchReducer} from './searchReducer';

const defaultState = {
  user: {},
  content_counter: {},
};

const authReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'SAVE_SESSION':
      return {
        ...state,
        user: action.payload,
      };

    case 'REMOVE_SESSION':
      return {
        ...state,
        user: {},
      };

    case 'UPDATE_CONTENT_COUNTER':
      return {
        ...state,
        content_counter: action.payload,
      };

    default:
      return state;
  }
};

export default combineReducers({
  authReducer: authReducer,
  searchReducer: searchReducer,
});
