// Dependencies
import React from 'react';

// Modules
import {withRouter} from 'react-router'
import {connect} from 'react-redux';
import moment from 'moment';

// App
import {getOnlineNode} from '../../core/getNode';
import PostTitle from '../../partials/postTitle';
import UserImage from '../../partials/userImage';
import { alertMessages } from '../../partials/alertMessages';
import Error from '../../partials/error';
import GalleriesViewer from './galleriesViewer';
import GalleriesSkeletonSingleScreen from './galleriesSkeletonSingleScreen';

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import {Clock, Tag, Plus} from 'react-feather';

class GalleriesSingle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isPaginating: false,
      // isGalleryLoading: true,
      post: null,
      images: null,
      pageSize: 10,
      currentPage: 0,
      total_pages: 0,
      modalVisible: false,
      activeIndex: 0,
    };
  }

  componentDidMount() {
    this.checkSession();
  }
  
  checkSession = () => {
    if (Object.keys(this.props.user).length === 0) {
      this.props.history.push('/');
    } else {
      this.loadNode();
    }
  }

  /**
   * @function setData
   * @description Updates the state of the component upon data retrival
   * @param {object} _data
   * @param {object} _realm
   */
  setData = (_data) => {
    this.setState(
      {
        // isLoading: false,
        isRefreshing: false,
        isError: false,
        post: _data,
      },
      function () {
        this.getOnlineImages();
      },
    );
  };

  /**
   * @function setError
   * @description Updates the state of the component upon an error
   * @param {boolean} _isError
   * @param {int} _errorStatus
   * @param {string} _errorMessage
   */
  setError = (_isError, _errorStatus, _errorMessage) => {
    this.setState({
      isLoading: false,
      isRefreshing: false,
      isError: _isError,
      errorStatus: _errorStatus,
      errorMessage: _errorMessage,
    });
  };

  loadNode = () => {
    const nid = this.props.match.params.nid;

    if (nid) {
      this.getContent(nid);
    } else {
      this.setError(true, 0, alertMessages.contentError);
    }
  };

  /**
   * @function getContent
   * @description Retrieves the data from an API / Fallback to local realm object if there is no connection
   */
  getContent = (nid) => {
    let path = 'image_gallery/all/' + nid + '?_format=json&status=1&promote=1';

    getOnlineNode(path, this.props.user.access_token)
      .then((response) => {
        if (response.data.rows.length > 0) {
          this.setData(response.data.rows[0]);
        } else {
          this.setError(true, 200, alertMessages.noData);
        }
      })
      .catch((_error) => {
        console.log('getContent: ', _error);
        if (_error.response) {
          this.setError(
            true,
            _error.response.status,
            _error.response.statusText,
          );
        } else if (_error.request) {
          //console.log('@rest request: ', _error.request);
          this.setError(true, 0, alertMessages.requestError.message);
        } else {
          //console.log('@rest unknown: ', _error);
          this.setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };

  getOnlineImages = () => {
    const {nid} = this.state.post;
    const path = '/files_rest_api/' + nid + '?_format=json';

    getOnlineNode(path, this.props.user.access_token)
      .then((response) => {
        this.setState({images: response.data}, function() {
          this.getPostAuthorData();
        });
      })
      .catch((_error) => {
        console.log('@getOnlineImages _error: ', _error);
      });
  };

  getTempImages = () => {

    const placeholderImages = [
      {
        "fid":"1",
        "uri":"https://via.placeholder.com/800x800.png?text=image%201",
        "alt": "Jane and Jim",
        "username": "companyapp",
      },
      {
        "fid":"2",
        "uri":"https://via.placeholder.com/800x800.png?text=image%202",
        "alt": "Omar and Gill",
        "username": "companyapp",
      },
      {
        "fid":"3",
        "uri":"https://via.placeholder.com/800x800.png?text=image%203",
        "alt": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quam nulla porttitor massa id neque. Quam quisque id diam vel quam elementum pulvinar. Massa tincidunt dui ut ornare lectus sit. Enim ut sem viverra aliquet eget sit amet tellus.",
        "username": "companyapp",
      },
      {
        "fid":"4",
        "uri":"https://via.placeholder.com/800x800.png?text=image%204",
        "alt": "",
        "username": "mark",
      },
      {
        "fid":"5",
        "uri":"https://via.placeholder.com/800x800.png?text=image%205",
        "alt": "Caption 5",
        "username": "companyapp",
      },
      {
        "fid":"6",
        "uri":"https://via.placeholder.com/800x800.png?text=image%206",
        "alt": "Caption 6",
        "username": "companyapp",
      },
      {
        "fid":"7",
        "uri":"https://via.placeholder.com/800x800.png?text=image%207",
        "alt": "Caption 7",
        "username": "companyapp",
      },
      {
        "fid":"8",
        "uri":"https://via.placeholder.com/800x800.png?text=image%208",
        "alt": "Caption 8",
        "username": "companyapp",
      },
      {
        "fid":"9",
        "uri":"https://via.placeholder.com/800x800.png?text=image%209",
        "alt": "Caption 9",
        "username": "companyapp",
      },
      {
        "fid":"10",
        "uri":"https://via.placeholder.com/800x800.png?text=image%2010",
        "alt": "Caption 10",
        "username": "companyapp",
      },
      {
        "fid":"11",
        "uri":"https://via.placeholder.com/800x800.png?text=image%2011",
        "alt": "Caption 11",
        "username": "companyapp",
      },
      {
        "fid":"12",
        "uri":"https://via.placeholder.com/800x800.png?text=image%2012",
        "alt": "Caption 12",
        "username": "companyapp",
      },
    ];

    this.setState({images: placeholderImages}, 
      function() {
        this.getPostAuthorData();
      }
    );
  };

  /**
   * Get the username for each image
   * Get the corresponding user data that matches the username
   * Store all authors in authorData state
   */
  getPostAuthorData = () => {
    let responseData;
    let imageAuthorsData = [];

    if (this.state.images.length > 0) {
      this.state.images.forEach(item => {
        let path = 'custom_user?_format=json&name=' + item.username;
  
        getOnlineNode(path, this.props.user.access_token)
          .then((response) => {
            // console.log('@author data:', response.data.rows[0]);
            responseData = response.data.rows[0];
            imageAuthorsData.push({
              fid: item.fid,
              uid: responseData.uid,
              name: responseData.name,
              field_first_name: responseData.field_first_name,
              field_last_name: responseData.field_last_name,
              user_picture: responseData.user_picture,
            });
            this.setState(
              {
                authorData: imageAuthorsData
              },
              () => {
                this.setState({isLoading: false});
              },
            );
          })
          .catch((_error) => {
            // error
            console.log('error: ', _error)
          });
      });
    } else {
      this.setState({isLoading: false});
    }    
  };

  /**
   * Render the author of the image upload
   * Filters authors by image file id
   * @param {*} imageFid 
   * @returns 
   */
  renderAuthor = (imageFid) => {
    const filteredAuthor = this.state.authorData.filter(item => item.fid === imageFid);
    const imageAuthor = filteredAuthor[0];

    // username
    let author = imageAuthor.name;

    // first name
    if (imageAuthor.field_first_name) {
      author = imageAuthor.field_first_name;
    }

    // Concat first & last name
    if ( imageAuthor.field_first_name && imageAuthor.field_last_name) {
      author =imageAuthor.field_first_name + " " + imageAuthor.field_last_name;
    }

    return (
      <div className={"author"}>
        <UserImage
          className="author__image"
          image={imageAuthor.user_picture}
        />
        <div className={"author__name"}>
          <span>
            posted by <strong>{author}</strong>
          </span>
        </div>
      </div>
    );
  };

  handleImageClick = (item, index) => {
    this.setModalVisible(true);
    this.setState({
      selectedImage: item,
      activeIndex: index,
    });
  }

  setModalVisible = (visible) => {
    this.setState({
      modalVisible: visible,
    });
  };

  setIndex = (selectedIndex) => {
    this.setState({activeIndex: selectedIndex}, () => {
      // console.log('Active index is:', this.state.activeIndex);
    })
  }   

  setDirection = (direction) => {
    this.setState({direction: direction})
  }

  handleSelect = (selectedIndex, e) => {
    this.setIndex(selectedIndex);
    this.setDirection(e.direction);
  };

  renderGallery = (images) => {
    if (images) {
      return (
        <section className="gallery">
          <Row>
            {images.map((item, index) => {
              return (
                <Col
                  xs={6}
                  sm={4}
                  lg={3}
                  key={"gallery-item-" + item.fid}
                  className="gallery-item"
                >
                  <div className="gallery-item__image" onClick={() => this.handleImageClick(item, index)}>
                    <img
                      src={item.uri}
                      alt={"image-" + item.fid}
                      onLoad={() => {
                        // if (index === (images.length - 1)) {
                        //   this.setState({ isGalleryLoading: false });
                        // }
                      }}
                    />
                  </div>
                </Col>
              );
            })}
          </Row>
        </section>
      );
    } else {
      return false;
    }
  };

  render() { 
    if (this.state.isLoading) {
      return <GalleriesSkeletonSingleScreen />;
    } else {
      if (this.state.isError) {
        return (
          <Error 
            status={this.state.errorStatus}
            message={this.state.errorMessage}
          />
        );
      } else {
        const {post, images} = this.state;
        const timestamp = moment.unix(post.created).format("Do MMM YYYY");

        let category = 'Uncategorised';

        if (post.field_category_1) {
          category = post.field_category_1;
        }

        if (post.categoryLabel) {
          category = post.categoryLabel;
        }

        return (
          <main className="galleries  single">
            <article className="article">
              <header className="article__header">
                <PostTitle title={post.title} headingLevel={'h1'} />
                <div className="article__meta">
                  <Row className="align-center">
                    <Col xs={"auto"}>
                      <div className="timestamp align-items-center">
                        <Clock className="timestamp__icon" />
                        <span className="timestamp__label  label">{timestamp}</span> 
                      </div>

                    </Col>
                    <Col xs={"auto"}>
                      <div className="category align-items-center">
                        <Tag className="category__icon" />
                        <span className="category__label  label">{category}</span>
                      </div>
                    </Col>
                    <Col className="gallery-actions">
                      <Row className="justify-content-end">
                        <Col className="col-auto">
                          <Button onClick={() => {
                            this.props.history.push(this.props.location.pathname + '/edit');
                          }}>
                            <Plus />
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </header>
              <div className="article__body">
                <div dangerouslySetInnerHTML={{__html: post.body}} />
              </div>
              {this.renderGallery(images)}
              {images && images.length > 0 && (
                <GalleriesViewer 
                  images={this.state.images}
                  activeIndex={this.state.activeIndex}
                  modalVisible={this.state.modalVisible}
                  modalVisibleCallback={this.setModalVisible}
                  postTitle={this.state.post.title}
                  setIndex={this.setIndex}
                  setDirection={this.setDirection}
                  handleSelect={this.handleSelect}
                  authorData={this.state.authorData}
                />
              )} 
            </article>
          </main>
        );
      }
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default withRouter(connect(mapStateToProps)(GalleriesSingle));
