import React from 'react';

// Modules
import {connect} from 'react-redux';
import {withRouter} from 'react-router';

// App
import {serverUrl} from '../../config';
import {getOnlineNode} from '../../core/getNode';
import {patchNode, getToken} from '../../core/postNode';
import {postBase64} from '../../core/postFile';
import {alertMessages} from '../../partials/alertMessages';
import PageHeader from '../../partials/pageHeader';
import AlertModal from '../../partials/alertModal';
import Crop from '../../partials/cropper/cropper';
import NoticesEditSkeletonScreen from '../notices/skeleton-screens/noticesEditSkeletonScreen';

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner'
import {Plus, ArrowRight} from 'react-feather';

class EditGallery extends React.Component {
  constructor() {
    super();
    this.state = {
      isComponentLoading: true,
      isLoading: false,
      token: null,
      images: [],
      sentImageIDs: [],
      modalVisible: false,
      postModalVisible: false,
    };
  }

  componentDidMount() {
    this.getToken();
    this.getNode();
  }

  getToken = () => {
    getToken().then(_response => {
      this.setState({
        token: _response.data,
      });
    });
  };

  getNode = () => {
    const nid = this.props.match.params.nid;

    getOnlineNode('node/' + nid + '?_format=json')
      .then(response => {
        this.setState({
          existingImages: response.data.field_gallery_image,
          isLoading: false,
          isComponentLoading: false,
        });
      })
      .catch(error => {
        this.setState({
          isLoading: false,
          isComponentLoading: false,
        });

        this.setState({
          modalVisible: true,
          alertType: 'error',
          alertTitle: alertMessages.nodeEndpointFetch.title,
          alertBody: alertMessages.nodeEndpointFetch.message,
          alertConfirmButton: true,
          redirectBack: false,
        });
      });
  };

  setAlt = (event, index) => {
    const newImages = this.state.images.map((image, item) => {
      if (index !== item) {
        return image;
      }

      return {
        ...image,
        alt: event.target.value,
      };
    });

    this.setState({
      images: newImages,
    });
  };

  saveNode = submittedImages => {
    const nid = this.props.match.params.nid;

    const data = {
      _links: {
        type: {
          href: serverUrl + '/rest/type/node/image_gallery',
        },
      },
      type: {
        target_id: 'image_gallery',
      },
      field_gallery_image: submittedImages ? submittedImages : [],
    };

    patchNode(
      'node/' + nid,
      data,
      this.state.token,
      this.props.user.access_token,
    )
      .then(_response => {
        if (_response.status === 200) {
          this.setState({
            isLoading: false,
          });

          this.setState({
            isLoading: false,
            postModalVisible: true,
            alertTitle: alertMessages.imagesSaved.title,
            alertBody: alertMessages.imagesSaved.message,
          });
        } else {
          this.setState({
            isLoading: false,
            modalVisible: true,
            alertType: 'error',
            alertTitle: alertMessages.imagesNotSaved.title,
            alertBody: alertMessages.imagesNotSaved.message,
            alertConfirmButton: true,
          });
        }
      })
      .catch(_error => {
        this.setState({
          isLoading: false,
          modalVisible: true,
          alertType: 'error',
          alertTitle: alertMessages.imagesNotSaved.title,
          alertBody: alertMessages.imagesNotSaved.message,
          alertConfirmButton: true,
        });

        console.log('@patchNode error: ', _error);
      });
  };

  uploadGalleryImages = () => {
    let fileUploadErrors = 0;

    this.setState({
      isLoading: true,
    });

    let imagesSent = 0;

    let images = this.state.images.filter(function (image) {
      if (!image.cropped_image) {
        return false; // skip
      }
      return true;
    });

    if (images && images.length > 0) {
      images.forEach((image, index) => {
        const imageData = {
          _links: {
            type: {
              href: `${serverUrl}/rest/type/file/image`
            }
          },
          filename: [
            {
              value: `gallery_cropped-${Date.now()}.png`
            }
          ],
          filemime: {
            value: 'image/png'
          },
          uri: [
            {
              value: `public://gallery_cropped-${Date.now()}.png`
            }
          ],
          type: {
            target_id: 'image'
          },
          data: [
            {
              value: image.cropped_image
            }
          ],
          uid: [
            {
              target_id: this.props.user.current_user.uid ? this.props.user.current_user.uid : 1
            },
          ]
        };

        postBase64(
          imageData,
          serverUrl +
            '/entity/file?_format=hal_json',
          this.state.token,
          // this.props.user.access_token,
        )
          .then(response => {
            const fid = response.data.fid[0].value;

            this.setState({
              sentImageIDs: this.state.sentImageIDs.concat([fid]),
            });

            imagesSent++;

            if (imagesSent === this.state.images.length) {
              this.imagesSaved();
            }
          })
          .catch(error => {
            // console.log('@postBase64: ', error.request);

            fileUploadErrors = fileUploadErrors + (index + 1);

            imagesSent++;

            if (imagesSent === images.length) {
              this.imagesSaved();
            }
          });
      });
    } else {
      this.saveNode();
    }

    if (fileUploadErrors > 0) {
      this.setState({
        modalVisible: true,
        alertType: 'error',
        alertTitle: 'Error with images',
        alertBody: 'Failed to upload some of the images. Please try uploading them again later.',
        alertConfirmButton: true,
        redirectBack: false,
      });
    }
  };

  imagesSaved = () => {
    let images = this.state.existingImages;

    this.state.sentImageIDs.forEach((fid, index) => {
      const image = {
        target_id: fid,
        alt: this.state.images[index].alt,
      };

      images.push(image);
    });

    this.saveNode(images);
  };

  addImage = () => {
    const {images} = this.state;
    const lastImage = images[images.length - 1];

    this.setState({
      images: this.state.images.concat([
        {image: '', alt: '', index: lastImage.index + 1},
      ]),
    });
  };

  removeImage = _index => {
    const {images} = this.state;

    if (images.length === 1) {
      return false;
    }

    const newImages = images;

    const index = newImages[_index];

    if (index !== -1) {
      newImages.splice(_index, 1);
      this.setState({
        images: newImages,
      });
    }
  };

  errorAlerts = () => {
    return (
      <AlertModal 
        showAlert={this.state.modalVisible} 
        showAlertCallback={() => {
          this.setState({
            modalVisible: !this.state.modalVisible
          });
        }}
        alertType={this.state.alertType}
        alertMessageTitle={this.state.alertTitle}
        alertMessageBody={this.state.alertBody}
        cancelButton={this.state.alertCancelButton}
        confirmButton={this.state.alertConfirmButton}
        cancelButtonLabel={this.state.cancelButtonLabel}
        confirmButtonLabel={this.state.confirmButtonLabel}
      />
    )
  }

  saveAlerts = () => {
    return (
      <AlertModal 
        showAlert={this.state.postModalVisible} 
        showAlertCallback={() => {
          this.setState({
            postModalVisible: !this.state.postModalVisible,
          });
        }}
        alertType={'success'}
        alertMessageTitle={this.state.alertTitle}
        alertMessageBody={this.state.alertBody}
        cancelButton={false}
        confirmButton={true}
        confirmButtonLabel={'OK'}
        onCancel={() => {
          // this.props.history.push('/gallery');
        }}
        onConfirm={() => {
          this.props.history.goBack();
        }}
      />
    );
  };

  renderSelectedImages = () => {
    const {images} = this.state;

    if (images.length > 0) {
      return (
        images.map((image, index) => {
          return (
            <Col key={`gallery-image-${index}`} xs={12} className="mb-3">
              <div className="cropper-wrapper gallery-images-cropper">
              <Row key={`gallery-image-${index}`}>
                <Col>
                  {image && (
                    <Crop
                      file={image.file}
                      ratio={1}
                      minWidth={800}
                      maxWidth={800}
                      minHeight={800}
                      maxHeight={800}
                      onCrop={(data) => {
                        let newImages = images;
                        newImages[index] = {
                          ...image,
                          cropped_image: data,
                        };

                        this.setState({
                          images: newImages
                        });
                      }}
                      onRemoveImage={() => {
                        this.removeImage(index)
                      }}
                    />
                  )}
                </Col>
              </Row>
              <Row>
                <Col className="my-2">
                  <input 
                    type="text"
                    className="form-control"
                    onChange={(event) => {
                      this.setAlt(event, index)}
                    }
                    defaultValue={image.alt}
                    placeholder="Add caption..."
                  />
                </Col>
              </Row>
              </div>
            </Col>
          );
        })
      );
    }
  };

  /**
   * @function render
   * @description Default render method
   */
  render() {
    if (this.state.isComponentLoading) {
      return <NoticesEditSkeletonScreen />;
    } else {
      return (
        <main className="edit-gallery galleries screen">
          <PageHeader 
            pageName="Add images" 
            filters={false}
          />
          
          {this.errorAlerts()}
          {this.saveAlerts()}

          <div className="form-group">
            <Row className="mb-2">
              <Col>
                <label>Gallery Images</label>
              </Col>
            </Row>

            <Row>
              {this.renderSelectedImages()}
            </Row>

            <Row>
              <Col xs={'auto'}>
                <input
                  ref={galleryInput => this.galleryImageInput = galleryInput}
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={(event) => {
                    let {images} = this.state;

                    for(let i = 0; i < event.target.files.length; i++) {
                      const image = {
                        file: event.target.files[i],
                        cropped_image: null,
                        alt: null,
                      }

                      images.push(image);
                    }

                    this.galleryImageInput.value = "";

                    this.setState({
                      images: images,
                    });
                  }}
                  style={{display: 'none'}}
                />

                <Button variant="link" onClick={() => {
                  this.galleryImageInput.click()
                }}>
                  <Plus />
                  <span>
                    {this.state.images.length > 0 ? 'Add images' : 'Select images'}
                  </span>
                </Button>
              </Col>
            </Row>
          </div>

          <hr style={{margin: '3rem 0'}}/>

          <div className="form-group">
            <Row className="align-items-center justify-content-end">
              <Col xs={'auto'}>
                {this.state.isLoading ? (
                  <Spinner animation="border" role="status" variant="primary">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                ) : (
                  <Button
                    className="submit-btn"
                    onClick={() => {
                      this.uploadGalleryImages();
                    }}
                  >
                    <span>Update Gallery</span>
                    <ArrowRight />
                  </Button>
                )}
              </Col>
            </Row>
          </div>
        </main>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default withRouter(connect(mapStateToProps)(EditGallery));
