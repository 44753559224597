// Dependencies
import React from "react";

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { AlertTriangle, CheckCircle, Info } from "react-feather";

class AlertModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: this.props.showAlert,
    };
  }

  /**
   * Set modal visibility
   * @param {*} visible
   */
  setModalVisible = (visible) => {
    this.setState(
      {
        modalVisible: visible,
      },
      () => {
        if (this.props.showAlertCallback) {
          this.props.showAlertCallback(this.state.modalVisible);
        }
      }
    );
  };

  /**
   * Render action buttons (Cancel / OK)
   * @param {*} alertType 
   * @returns 
   */
  renderAlertActions = (alertType) => {
    return (
      <Row noGutters>
        {this.props.cancelButton && (
          <Col className="pr-1">
            <Button
              type="button"
              className="close-modal-btn"
              variant={
                this.props.cancelButtonVariant
                  ? this.props.cancelButtonVariant
                  : "outline-primary"
              }
              onClick={() => {
                this.setModalVisible(false);

                if (this.props.onCancel) {
                  this.props.onCancel();
                }
              }}
            >
              {this.props.cancelButtonLabel
                ? this.props.cancelButtonLabel
                : "Cancel"}
            </Button>
          </Col>
        )}

        {this.props.confirmButton && (
          <Col className="pl-1">
            <Button
              type="button"
              className="close-modal-btn"
              variant={
                this.props.confirmButtonVariant
                  ? this.props.confirmButtonVariant
                  : "primary"
              }
              onClick={() => {
                this.setModalVisible(false);

                if (this.props.onConfirm) {
                  this.props.onConfirm();
                }
              }}
            >
              {this.props.confirmButtonLabel
                ? this.props.confirmButtonLabel
                : "OK"}
            </Button>
          </Col>
        )}
      </Row>
    );
  }

  /**
   * Render the alert modal
   * @returns 
   */
  renderAlert = () => {

    const iconSelector = {
      success: <CheckCircle />,
      info: <Info />,
      error: <AlertTriangle />,
    }
          
    let alertType = "default";
    let icon = iconSelector.info;

    if (this.props.alertType !== "undefined") {
      alertType = this.props.alertType;
    }

    if (this.props.alertType === "success") {
      icon = iconSelector.success;
    }
    if (this.props.alertType === "warning") {
      icon = iconSelector.info;
    }
    if (this.props.alertType === "error") {
      icon = iconSelector.error;
      alertType = "danger"; // set to bootstrap colours
    }

    return (
      <Modal
        show={this.props.showAlert}
        onHide={() => this.setModalVisible(false)}
        centered
        dialogClassName={`alert-modal--${alertType} alert-modal`}
      >
        <Modal.Header
          // className={`d-flex justify-content-center text-${alertType}`}
          className={`d-flex justify-content-center text-primary`}
        >
          <div className="icon">{icon}</div>
          <Modal.Title className="h5">{this.props.alertMessageTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p className="mb-0">{this.props.alertMessageBody}</p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          {this.renderAlertActions(alertType)}
        </Modal.Footer>
      </Modal>
    );
  }

  render() {
    if (this.props.showAlert) {
      return this.renderAlert();
    } else {
      return false;
    }
  }
}

export default AlertModal;
