import React, { Component } from 'react';

// Modules
import ContentLoader from 'react-content-loader';

class SkeletonPageHeader extends Component {  
  constructor() {
    super();
    this.state = {
      backgroundColor: '#f3f3f3',
      foregroundColor: '#ecebeb',
    };
  }

  render() {
    return (
      <div className="page-header">
        <ContentLoader 
          speed={2}
          width={'100%'}
          height={'100%'}
          viewBox="0 0 1140 60"
          backgroundColor={this.state.backgroundColor}
          foregroundColor={this.state.foregroundColor}
        >
          <rect x="0" y="5" width="386.701" height="50" rx="10" ry="10" />
          <rect x="1028.03" y="11" width="111.975" height="38" rx="5" ry="5" />
          <rect x="927.937" y="11" width="77.079" height="38" rx="5" ry="5" />
          <rect x="673.299" y="11" width="231.446" height="38" rx="5" ry="5" />
        </ContentLoader>
      </div>
    );
  }

}

export default SkeletonPageHeader;