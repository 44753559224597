import React from 'react';

// Modules
import {connect} from 'react-redux';

// App
import {serverUrl} from '../../config';
import {getToken} from '../../core/postNode';
import {patchNode} from '../../core/postNode';
import {getOnlineNode} from '../../core/getNode';
import PageHeader from '../../partials/pageHeader';
import {alertMessages} from '../../partials/alertMessages';
import AlertModal from '../../partials/alertModal';
import Error from "../../partials/error";
import AccountSkeletonScreen from './skeleton-screens/accountSkeletonScreen';
import Crop from '../../partials/cropper/cropper';
import {postBase64} from '../../core/postFile';
import UserImage from '../../partials/userImage';

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner'
import Button from 'react-bootstrap/Button';
import FormCheck from 'react-bootstrap/FormCheck';
import {X, Plus, ArrowRight} from 'react-feather';

class EditProfile extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      isSubmitting: false,
      user: '',

      first_name: '',
      last_name: '',
      phone_number: '',
      mail: '',
      password: '',
      newPassword: '',
      show_info: '',
      field_push_notifications: '',
      field_email_notifications: '',
      profile_picture: null,
      isMailChanged: false,
      isPassChanged: false,

      modalVisible: false,
    };


  }

  /**
   * @function componentDidMount
   * @description Default react method. Fired when component is rendered
   */
  componentDidMount() {
    this.getToken();
    this.getAccount();
  }

  getToken = () => {
    getToken().then((_response) => {
      this.setState({
        token: _response.data,
      });
    });
  };

  getAccount = () => {
    if (typeof this.props.user.current_user === 'undefined') {
      // this.setState({
      //   isAccountLoading: false,
      //   isAccountError: true,
      //   accountErrorStatus: 0,
      //   accountErrorMessage: alertMessages.loggedOut,
      // });
      this.props.history.push('/');
    } else {
      getOnlineNode(
        'custom_user/' + this.props.user.current_user.uid,
        this.props.user.access_token,
      )
        .then((response) => {
          this.setState(
            {
              user: response.data.rows[0],
            },
            function () {
              this.getContent();
            },
          );
        })
        .catch((_error) => {
          console.log('error: ', _error);
          if (_error.response) {
            this.setState({
              isAccountLoading: false,
              isAccountError: true,
              accountErrorStatus: 200,
              accountErrorMessage: alertMessages.noData,
            });
            this.setError(
              true,
              _error.response.status,
              _error.response.statusText,
            );
          } else if (_error.request) {
            this.setState({
              isAccountLoading: false,
              isAccountError: true,
              accountErrorStatus: 0,
              accountErrorMessage: alertMessages.requestError.message,
            });
            //console.log('@rest request: ', _error.request);
            this.setError(true, 0, alertMessages.requestError.message);
          } else {
            this.setState({
              isAccountLoading: false,
              isAccountError: true,
              accountErrorStatus: 0,
              accountErrorMessage: alertMessages.unkownError.message,
            });
            //console.log('@rest unknown: ', _error);
            this.setError(true, 0, alertMessages.unkownError.message);
          }
        });
    }
  };

  getContent = () => {
    const {user} = this.state;

    if (user) {
      let show_info = true;
      let field_push_notifications = true;
      let field_email_notifications = true;

      if (user.field_show_info === 'false') {
        show_info = false;
      }

      if (user.field_push_notifications === 'false') {
        field_push_notifications = false;
      }

      if (user.field_email_notifications === 'false') {
        field_email_notifications = false;
      }

      this.setState({
        user: user,
        isLoading: false,
        first_name: user.field_first_name,
        last_name: user.field_last_name,
        phone_number: user.phone_number,
        mail: user.mail,
        show_info: show_info,
        // password: 'herewego',
        field_push_notifications: field_push_notifications,
        field_email_notifications: field_email_notifications,
      });
    }
  };

  setError = (_isError, _status, _message) => {
    this.setState({
      isLoading: false,
      isRefreshing: false,
      isError: _isError,
      errorStatus: _status,
      errorMessage: _message,
    });
  };

  updateUser = () => {
    const access_token = this.props.user.access_token;
    const uid = this.props.user.current_user.uid;

    if (this.state.isPassChanged && this.state.newPassword.length < 5) {
      this.setState({
        modalVisible: true,
        alertType: 'error',
        alertTitle: alertMessages.editProfilePasswordTooShort.title,
        alertBody: alertMessages.editProfilePasswordTooShort.message,
        alertConfirmButton: true,
      });
      return false;
    }

    this.setState({
      isSubmitting: true,
    });

    let password = [{existing: this.state.password}];

    if (this.state.isPassChanged && this.state.newPassword) {
      password = [
        {existing: this.state.password, value: this.state.newPassword},
      ];
    }

    if (this.state.profile_picture_cropped) {
      const imageData = {
        _links: {
          type: {
            href: `${serverUrl}/rest/type/file/image`
          }
        },
        filename: [
          {
            value: `${uid}_pp_cropped-${Date.now()}.png`
          }
        ],
        filemime: {
          value: 'image/png'
        },
        uri: [
          {
            value: `public://${uid}_pp_cropped-${Date.now()}.png`
          }
        ],
        type: {
          target_id: 'image'
        },
        data: [
          {
            value: this.state.profile_picture_cropped
          }
        ],
        uid: [
          {
            target_id: this.props.user.current_user.uid ? this.props.user.current_user.uid : 1
          },
        ]
      };

      postBase64(
        imageData,
        serverUrl +
          '/entity/file?_format=hal_json',
        this.state.token,
        // this.props.user.access_token,
      )
        .then(response => {
          const fid = response.data.fid[0].value;

          const data = {
            _links: {
              type: {
                href: serverUrl + '/rest/type/user/user',
              },
            },
            uid: {
              value: uid,
            },
            field_first_name: {
              value: this.state.first_name,
            },
            field_last_name: {
              value: this.state.last_name,
            },
            field_show_info: {
              value: this.state.show_info ? true : false,
            },
            field_push_notifications: {
              value: this.state.field_push_notifications ? true : false,
            },
            field_email_notifications: {
              value: this.state.field_email_notifications ? true : false,
            },
            phone_number: {
              value: this.state.phone_number,
            },
            mail: {
              value: this.state.mail,
            },
            pass: password,
            user_picture: [
              {
                target_id: fid,
              },
            ],
          };

          patchNode('user/' + uid, data, this.state.token, access_token)
            .then((response) => {
              this.backToAccount.click();
            })
            .catch((error) => {
              console.log('@user error: ', error);
              if (error.response.data.message) {
                this.setState({
                  isSubmitting: false,
                  modalVisible: true,
                  alertType: 'error',
                  alertTitle: alertMessages.unkownError.title,
                  alertBody: error.response.data.message,
                  alertConfirmButton: true,
                });
              } else {
                this.setState({
                  isSubmitting: false,
                  modalVisible: true,
                  alertType: 'error',
                  alertTitle: alertMessages.editProfileUpdateFailed.title,
                  alertBody: alertMessages.editProfileUpdateFailed.message,
                  alertConfirmButton: true,
                });
              }
            });
        })
        .catch(error => {
          // console.log('@postBase64: ', error.request);
          this.setState({
            modalVisible: true,
            alertType: 'error',
            alertTitle: alertMessages.editProfileUpdateFailed.title,
            alertBody: alertMessages.editProfileUpdateFailed.message,
            alertConfirmButton: true,
          });
        });

    } else {
      const data = {
        _links: {
          type: {
            href: serverUrl + '/rest/type/user/user',
          },
        },
        uid: {
          value: uid,
        },
        field_first_name: {
          value: this.state.first_name,
        },
        field_last_name: {
          value: this.state.last_name,
        },
        field_show_info: {
          value: this.state.show_info ? true : false,
        },
        field_push_notifications: {
          value: this.state.field_push_notifications ? true : false,
        },
        field_email_notifications: {
          value: this.state.field_email_notifications ? true : false,
        },
        phone_number: {
          value: this.state.phone_number,
        },
        mail: {
          value: this.state.mail,
        },
        pass: password,
      };

      patchNode('user/' + uid, data, this.state.token, access_token)
        .then((response) => {
          this.backToAccount.click();
        })
        .catch((error) => {
          console.log('@user error: ', error);
          if (error.response.data.message) {
            this.setState({
              isSubmitting: false,
              modalVisible: true,
              alertType: 'error',
              alertTitle: alertMessages.unkownError.title,
              alertBody: error.response.data.message,
              alertConfirmButton: true,
            });
          } else {
            this.setState({
              isSubmitting: false,
              modalVisible: true,
              alertType: 'error',
              alertTitle: alertMessages.editProfileUpdateFailed.title,
              alertBody: alertMessages.editProfileUpdateFailed.message,
              alertConfirmButton: true,
            });
          }
        });
    }
  };

  removePicture = () => {
    const access_token = this.props.user.access_token;
    const uid = this.props.user.current_user.uid;

    this.setState({
      isSubmitting: true,
    });

    const data = {
      _links: {
        type: {
          href: serverUrl + '/rest/type/user/user',
        },
      },
      uid: {
        value: uid,
      },
      user_picture: [],
    };

    patchNode('user/' + uid, data, this.state.token, access_token)
      .then(response => {
        this.setState({
          isSubmitting: false,
        });
      })
      .catch(error => {
        console.log('@user error: ', error);
        this.setState({
          isLoading: false,
          isSubmitting: false,
          modalVisible: true,
          alertType: 'error',
          alertTitle: alertMessages.editProfileUpdateFailed.title,
          alertBody: alertMessages.editProfileUpdateFailed.message,
          alertConfirmButton: true,
          redirectBack: false,
        });
      });
  };

  /**
   * Set modal visibility
   * @param {*} visible 
   */
  setModalVisible = (visible) => {
    this.setState({
      modalVisible: visible,
    });
  };

  passwordComponent = (note, label, placeholder) => {
    const {isMailChanged, isPassChanged} = this.state;

    if (!isMailChanged && !isPassChanged) {
      return null;
    } else {
      return (
        <div className="form-group">
          <Row>
            <Col>
              <p className="text-danger">{note}</p>
            </Col>
          </Row>
          <Row className="mb-1">
            <Col>
              <label>{label}</label>
            </Col>
          </Row>
          <Row>
            <Col>
              <input
                type="password"
                onChange={(event) => {
                  this.setState({
                    password: event.target.value,
                  });
                }}
                placeholder={placeholder}
                className="form-control"
              />
            </Col>
          </Row>
        </div>
      );
    }
  };

  errorAlerts = () => {
    return (
      <AlertModal 
        showAlert={this.state.modalVisible} 
        showAlertCallback={this.setModalVisible}
        alertType={this.state.alertType}
        alertMessageTitle={this.state.alertTitle}
        alertMessageBody={this.state.alertBody}
        cancelButton={this.state.alertCancelButton}
        confirmButton={this.state.alertConfirmButton}
        cancelButtonLabel={this.state.cancelButtonLabel}
        confirmButtonLabel={this.state.confirmButtonLabel}
      />
    )
  }

  saveAlerts = () => {
    return (
      <AlertModal 
        showAlert={this.state.postModalVisible} 
        showAlertCallback={() => {
          this.setState({
            postModalVisible: !this.state.postModalVisible,
          });
        }}
        alertType={'success'}
        alertMessageTitle={this.state.alertTitle}
        alertMessageBody={this.state.alertBody}
        cancelButton={false}
        confirmButton={true}
        confirmButtonLabel={'OK'}
        onConfirm={() => {
          this.props.history.push('/account');
        }}
      />
    );
  };

  /**
   * @function render
   * @description Default render method
   */
   render() {
    if (this.state.isLoading) {
      return <AccountSkeletonScreen />;
    } else {
      if (this.state.isError) {
        return (
          <Error 
            status={this.state.errorStatus}
            message={this.state.errorMessage}
          />
        );
      } else {
        const {isMailChanged, isPassChanged} = this.state;

        let note, label, placeholder;

        if (isMailChanged && isPassChanged) {
          note =
            'Note: To change your email or current password you must confirm your existing password below';
          label = 'Current Password';
          placeholder = 'Enter your current password...';
        } else {
          if (isMailChanged) {
            note =
              'Note: To change your email address you must confirm your password below';
            label = 'Password';
            placeholder = 'Enter your password...';
          }

          if (isPassChanged) {
            note =
              'Note: To change your password you must confirm your existing password below';
            label = 'Current password';
            placeholder = 'Enter your current password...';
          }
        }

        return (
          <main ref={this.containerRef} className="edit-profile  screen">

            <Row>
              <Col xs={12} lg={6} className="mx-lg-auto">

                <PageHeader pageName="Edit Profile" filters={false} />

                {this.errorAlerts()}
                {this.saveAlerts()}

                {/* First name */}
                <div className="form-group">
                  <Row className="mb-1">
                    <Col>
                      <label>First name</label>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <input
                        type="text"
                        onChange={(event) => {
                          this.setState({
                            first_name: event.target.value,
                          });
                        }}
                        // placeholder={
                        //   this.state.user.field_first_name.length > 0
                        //     ? this.state.user.field_first_name
                        //     : "Add your first name..."
                        // }
                        placeholder={"Add your first name..."}
                        value={this.state.first_name}
                        className="form-control"
                      />
                    </Col>
                  </Row>
                </div>

                {/* Last name */}
                <div className="form-group">
                  <Row className="mb-1">
                    <Col>
                      <label>Last name</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <input
                        type="text"
                        onChange={(event) => {
                          this.setState({
                            last_name: event.target.value,
                          });
                        }}
                        // placeholder={
                        //   this.state.user.field_last_name.length > 0
                        //     ? this.state.user.field_last_name
                        //     : "Add your last name..."
                        // }
                        placeholder={"Add your last name..."}
                        value={this.state.last_name}
                        className="form-control"
                      />
                    </Col>
                  </Row>
                </div>
              
                {/* Email and password */}
                {this.props.user.current_user.name !== "demo" && (
                  <>
                    {/* Email */}
                    <div className="form-group">
                      <Row className="mb-1">
                        <Col>
                          <label>Email</label>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <input
                            type="email"
                            onChange={(event) => {
                              let hasMailChanged = false;
                              let mail = event.target.value;

                              if (mail !== this.state.user.mail) {
                                hasMailChanged = true;
                              }

                              if (mail.length < 1) {
                                hasMailChanged = false;
                              }

                              this.setState({
                                mail: mail,
                                isMailChanged: hasMailChanged,
                              });
                            }}
                            // placeholder={
                            //   this.state.user.mail.length > 0
                            //     ? this.state.user.mail
                            //     : "Add your email..."
                            // }
                            placeholder={"Add your email..."}
                            value={this.state.mail}
                            className="form-control"
                          />
                        </Col>
                      </Row>
                    </div>

                    {/* New password */}
                    <div className="form-group">
                      <Row className="mb-1">
                        <Col>
                          <label>New Password</label>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <input
                            type="password"
                            onChange={(event) => {
                              let hasPassChanged = true;
                              let newPassword = event.target.value;

                              if (newPassword.length < 1) {
                                hasPassChanged = false;
                              }

                              this.setState({
                                newPassword: newPassword,
                                isPassChanged: hasPassChanged,
                              });
                            }}
                            placeholder={"New Password..."}
                            className="form-control"
                          />
                        </Col>
                      </Row>
                    </div>

                    {/* Current password */}
                    {this.passwordComponent(note, label, placeholder)}
                  </>
                )}

                {/* Phone number */}
                <div className="form-group">
                  <Row className="mb-1">
                    <Col>
                      <label>Phone number</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <input
                        // type="text"
                        // onChange={(phone_number) => {
                        //   this.setState({
                        //     phone_number: phone_number,
                        //   });
                        // }}
                        // placeholder={
                        //   this.state.user.phone_number.length > 0
                        //     ? this.state.user.phone_number
                        //     : "Add a phone number..."
                        // }
                        type="tel"
                        onChange={(event) => {
                          this.setState({
                            phone_number: event.target.value,
                          });
                        }}
                        placeholder={"Add your phone number..."}
                        value={this.state.phone_number}
                        className="form-control"
                      />
                    </Col>
                  </Row>
                </div>

                {/* Show profile */}
                <div className="form-group">
                  <Row className="mb-1">
                    <Col>
                      <label>Show profile</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="d-flex">
                      <FormCheck
                        id={"show-profile"}
                        type={"checkbox"}
                        checked={this.state.show_info}
                        onChange={() => {
                          if (this.state.show_info) {
                            this.setState({
                              show_info: false,
                            });
                          } else {
                            this.setState({
                              show_info: true,
                            });
                          }
                        }}
                      />
                      <label
                        htmlFor={"show-profile"}
                        className="label-checkbox"
                      >
                        {
                          "Checking this option will make your profile publicly available to other users within the app."
                        }
                      </label>
                    </Col>
                  </Row>
                </div>

                {/* Message alerts */}
                <div className="form-group">
                  <Row className="mb-1">
                    <Col>
                      <label>Message Alerts</label>
                    </Col>
                    <Col xs={12}>
                      <p>Choose how you wish to be notified that you have a new message in the app.</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="d-flex align-items-center">
                      <FormCheck
                        id={"push-notifications"}
                        type={"checkbox"}
                        checked={this.state.field_push_notifications}
                        onChange={() => {
                          if (this.state.field_push_notifications) {
                            this.setState({
                              field_push_notifications: false,
                            });
                          } else {
                            this.setState({
                              field_push_notifications: true,
                            });
                          }
                        }}
                      />
                      <label
                        htmlFor={"push-notifications"}
                        className="label-checkbox"
                      >
                        {"Push notifications"}
                      </label>
                    </Col>

                    <Col className="d-flex align-items-center">
                      <FormCheck
                        id={"email-notifications"}
                        type={"checkbox"}
                        checked={this.state.field_email_notifications}
                        onChange={() => {
                          if (this.state.field_email_notifications) {
                            this.setState({
                              field_email_notifications: false,
                            });
                          } else {
                            this.setState({
                              field_email_notifications: true,
                            });
                          }
                        }}
                      />
                      <label
                        htmlFor={"email-notifications"}
                        className="label-checkbox"
                      >
                        {"Email"}
                      </label>
                    </Col>
                  </Row>
                </div>

                {/* Profile picture */ }
                <div className="form-group">
                  <Row className="mb-2">
                    <Col>
                      <label>Profile Picture</label>
                    </Col>
                  </Row>

                  <input
                    ref={input => this.profile_picture = input}
                    type="file"
                    accept="image/*"
                    onChange={(event) => {
                      this.setState({
                        profile_picture: event.target.files[0],
                      })

                      this.profile_picture.value = "";
                    }}
                    style={{display: 'none'}}
                  />

                  {/* Only show existing picture if NOT cropping */}
                  {!this.state.profile_picture && (
                    this.state.user.user_picture ? (
                      <>
                        <Row className="justify-content-center">
                          <Col xs={4} lg={4}>
                            <UserImage image={this.state.user.user_picture} />
                          </Col>
                        </Row>

                        {!this.state.profile_picture && (
                          <Row className="flex-column align-items-center">
                            <Col xs={'auto'} className="my-2">
                              <Button variant="link" onClick={() => {
                                this.profile_picture.click();
                              }}>
                                <Plus />
                                <span className="button-label">Change picture</span>
                              </Button>
                            </Col>
                            <Col xs={'auto'}>
                              <Button variant="link" onClick={() => {
                                let user = this.state.user;
                                user.user_picture = null;
                                this.setState({
                                  user: user,
                                });

                                this.removePicture();
                              }}>
                                <X/>
                                <span className="button-label">Remove</span>
                              </Button>
                            </Col>
                          </Row>
                        )}
                      </>
                    ) : (
                      <Row>
                        <Col>
                          <Button variant="link" onClick={() => {
                            this.profile_picture.click();
                          }}>
                            <Plus />
                            <span className="button-label">Add picture</span>
                          </Button>
                        </Col>
                      </Row>
                    )
                  )}
                  
                  {this.state.profile_picture && (
                    <>
                      <Row className="my-2">
                        <Col>
                          <Crop
                            file={this.state.profile_picture}
                            ratio={1}
                            minWidth={400}
                            maxWidth={400}
                            minHeight={400}
                            maxHeight={400}
                            onCrop={(data) => {
                              this.setState({
                                profile_picture_cropped: data
                              });
                            }}
                          />
                        </Col>
                      </Row>
                      <Row className="justify-content-center">
                        <Col xs={'auto'}>
                          <Button variant="link" onClick={() => {
                            this.setState({
                              profile_picture: null,
                              profile_picture_cropped: null,
                            });
                          }}>
                            <X />
                            <span className="button-label">Cancel</span>
                          </Button>
                        </Col>
                      </Row>
                    </>
                  )}
                </div>

                <hr style={{margin: '3rem 0'}}/>

                <div className="form-group">
                  <Row className="align-items-center justify-content-end">
                    <Col xs={'auto'}>
                      {this.state.isSubmitting ? (
                        <Spinner animation="border" role="status" variant="primary">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      ) : (
                        <Button className="submit-btn" onClick={this.updateUser}>
                          <span>Save Profile</span>
                          <ArrowRight />
                        </Button>
                      )}
                    </Col>
                  </Row>
                </div>

              </Col>
            </Row>

            <div style={{ display: "none" }}>
              <a
                id="back-to-account"
                href="/account"
                ref={(link) => (this.backToAccount = link)}
              >
                Account
              </a>
            </div>

          </main>
        );
      }
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default connect(mapStateToProps)(EditProfile);
