import React, { Component } from 'react';

// Modules
import ContentLoader from 'react-content-loader';

// App
import SkeletonPageHeader from './skeletonPageHeader';

// UI components
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";

class SkeletonItemScreen extends Component {  
  constructor() {
    super();
    this.state = {
      backgroundColor: '#f3f3f3',
      foregroundColor: '#ecebeb',
    };
  }

  renderDefaultItems = (itemClassList) => {

    let items = [];
    let numberOfItems;

    if (this.props.numberOfItems) {
      numberOfItems = this.props.numberOfItems;
    } else {
      numberOfItems = 20;
    }

    for (let i = 0; i < numberOfItems; i++) {

      items.push(
        <Col xs={12} lg={6} key={'item-' + i}>
          <div className={itemClassList}>
            <ContentLoader 
              speed={2}
              width={'100%'}
              height={'100%'}
              viewBox="0 0 555 207"
              backgroundColor={this.state.backgroundColor}
              foregroundColor={this.state.foregroundColor}
            >
              <circle cx="534.7" cy="186.915" r="10" />
              <rect x="30.037" y="176.915" width="184.848" height="20" rx="10" ry="10" />
              <circle cx="10" cy="186.915" r="10" />
              <rect x="293.553" y="119.601" width="150" height="20" rx="10" ry="10" />
              <rect x="293.553" y="92.603" width="208.058" height="20" rx="10" ry="10" />
              <rect x="293.553" y="65.59" width="261.132" height="20" rx="10" ry="10" />
              <rect x="293.553" y="27.012" width="261.132" height="20" rx="10" ry="10" />
              <rect x="293.553" y="0" width="261.132" height="20" rx="10" ry="10" />
              <rect x="0" y="0" width="277.94" height="156.341" rx="2.5" ry="2.5" />
            </ContentLoader>
          </div>
        </Col>
      );

    }

    return items;
  };

  render() {
    const itemClassList = "item";

    return (
      <main className="screen">
        <SkeletonPageHeader />

        {/* All items */}
        <Row>
          {this.renderDefaultItems(itemClassList)}
        </Row>

      </main>
    );
  }

}

export default SkeletonItemScreen;