import React, { Component } from 'react';

// Modules
import ContentLoader from 'react-content-loader';

// UI components
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";

class GalleriesSkeletonSingleScreen extends Component {  
  constructor() {
    super();
    this.state = {
      backgroundColor: '#f3f3f3',
      foregroundColor: '#ecebeb',
    };
  }

  renderDefaultItems = (itemClassList) => {

    let items = [];
    let numberOfItems;

    if (this.props.numberOfItems) {
      numberOfItems = this.props.numberOfItems;
    } else {
      numberOfItems = 20;
    }

    for (let i = 0; i < numberOfItems; i++) {

      items.push(
        <Col className={itemClassList} xs={6} sm={4} lg={3} key={'item-' + i} >
          <div className="gallery-item__image">
            <ContentLoader 
              speed={2}
              width={'100%'}
              height={'100%'}
              viewBox="0 0 225 225"
              backgroundColor={this.state.backgroundColor}
              foregroundColor={this.state.foregroundColor}
            >
                <rect x="0" y="0" width="224.094" height="224.1" />
            </ContentLoader>
          </div>
        </Col>
      );

    }

    return items;
  };

  render() {
    const itemClass = "gallery-item";
    const itemClassList = `${itemClass}  item`;

    return (
      <main className="galleries single">
        <article className="article">
          
          <div className="article__header">
            <ContentLoader 
              speed={2}
              width={'100%'}
              height={'100%'}
              viewBox="0 0 920 50"
              backgroundColor={this.state.backgroundColor}
              foregroundColor={this.state.foregroundColor}
              style={{marginBottom: '0.75em', fontSize: '2.5rem'}}
            >
              <rect x="0" y="0" width="700" height="50" rx="10" ry="10" />
            </ContentLoader>

            <div className="article__meta">
              <ContentLoader 
                speed={2}
                width={'100%'}
                height={'100%'}
                viewBox="0 0 920 20"
                backgroundColor={this.state.backgroundColor}
                foregroundColor={this.state.foregroundColor}
                style={{width: '100%', height: 20}}
              >
                <rect x="185.594" y="0" width="93.013" height="20" />
                <circle cx="165.557" cy="10" r="10" />
                <rect x="30.037" y="0" width="93.013" height="20" />
                <circle cx="10" cy="10" r="10" />
              </ContentLoader>
            </div>
          </div>

          <div className="article__body">
            <ContentLoader 
              speed={2}
              width={'100%'}
              height={'100%'}
              viewBox="0 0 920 35"
              backgroundColor={this.state.backgroundColor}
              foregroundColor={this.state.foregroundColor}
              style={{marginBottom: '1rem'}}
            >
              <rect x="0" y="7.5" width="822.549" height="20" rx="10" ry="10" />
            </ContentLoader>
          </div>

          {/* All items */}
          <section className="gallery">
            <Row>
              {this.renderDefaultItems(itemClassList)}
            </Row>
          </section>

        </article>
      </main>
    );
  }

}

export default GalleriesSkeletonSingleScreen;