// Dependencies
import React from "react";

// Modules
import {connect} from 'react-redux';
import {withRouter} from 'react-router';

// App
import {getOnlineNode} from '../../core/getNode';
import {postNode, getToken} from '../../core/postNode';
import {alertMessages} from "../../partials/alertMessages";

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner'
import { X, AlertTriangle, Edit2 } from 'react-feather';

class CreateGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isTyping: false,
      isSearching: false,
      isError: false,
      isCollapsed: true,
      errorMessage: alertMessages.noResults.message,

      users: [],
      selectedUsers: [],

      searchTerm: '',
      group_name: '',

      isFocused: false,
    };
  }

  componentDidMount() {
    this.getToken();
    this.checkViewport();
    window.addEventListener("resize", this.checkViewport);
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.checkViewport);
  };

  setSearch = value => {
    this.setState(
      {
        searchTerm: value,
        isTyping: false,
        isSearching: true,
      },
      () => {
        this.getUsers();
      },
    );
  };

  getToken = () => {
    getToken().then(_response => {
      this.setState({
        token: _response.data,
      });
    });
  };

  getUsers = () => {
    const {searchTerm, isTyping} = this.state;

    let path =
      'users?_format=json&first_name=' +
      searchTerm +
      '&last_name=' +
      searchTerm;

    if (!isTyping && searchTerm.length > 0) {
      getOnlineNode(path, this.props.user.access_token)
        .then(response => {
          if (response.data.length > 0) {
            this.setState({
              users: response.data,
              isTyping: false,
              isSearching: false,
              isError: false,
            });
          } else {
            this.setState({
              isTyping: false,
              isSearching: false,
              isError: true,
              errorMessage: alertMessages.noResults.message,
              users: [],
            });
          }
        })
        .catch(_error => {
          // error
          this.setState({
            isTyping: false,
            isSearching: false,
            isError: true,
            errorMessage: alertMessages.fetchError.message,
            users: [],
          });
        });
    } else {
      this.setState({
        isTyping: false,
        isSearching: false,
        isError: false,
        errorMessage: '',
        users: [],
      });
    }
  };

  saveGroup = () => {
    const {group_name, selectedUsers} = this.state;

    if (group_name.length < 1) {
      this.setState({
        inlineError: alertMessages.groupName.message,
      });

      return false;
    }

    if (selectedUsers.length < 1) {
      this.setState({
        inlineError: alertMessages.groupUsers.message,
      });

      return false;
    }

    this.setState({
      isLoading: true,
    });

    const data = {
      group_name: group_name,
      group_type: 'users',
      members: selectedUsers,
    };

    postNode(
      'groups',
      data,
      this.state.token,
      this.props.user.access_token,
    )
      .then(_response => {
        const id = parseInt(_response.data.id[0].value, 10);
        this.props.onCreated(id);
        this.props.setModalVisible();
      })
      .catch(_error => {
        this.setState({
          isLoading: false,
          inlineError: alertMessages.postNodeFailed.message,
        });
      });
  };

  setUser = user => {
    const collapsedState = this.state.isCollapsed;
    const newUsers = this.state.selectedUsers.concat(user);
    this.setState(
      {
        selectedUsers: newUsers,
        isCollapsed: !collapsedState,
      },
      () => {
        this.setState({
          isCollapsed: collapsedState,
        });
      },
    );
  };

  removeUser = user => {
    const collapsedState = this.state.isCollapsed;
    this.setState(
      {
        selectedUsers: this.state.selectedUsers.filter(
          prevUser => prevUser.uid !== user.uid,
        ),
        isCollapsed: !collapsedState,
      },
      () => {
        this.setState({
          isCollapsed: collapsedState,
        });
      },
    );
  };

  renderItem = item => {
    let itemStyle = '';

    const {selectedUsers} = this.state;
    let isSelected = false;

    selectedUsers.forEach(user => {
      if (user.uid === item.uid) {
        itemStyle = 'selected-user';
        isSelected = true;
      }
    });

    let name = '';

    if (item.field_first_name) {
      name = item.field_first_name;
    }

    if (item.field_last_name) {
      name = name + ' ' + item.field_last_name;
    }

    name = name + ' (' + item.name + ')';

    return (
      <Col>
        <button
          className={`user ${itemStyle}`}
          onClick={() => {
            if (isSelected) {
              this.removeUser(item);
            } else {
              this.setUser(item);
            }
          }}
        >
          <span dangerouslySetInnerHTML={{__html: name}} />
        </button>
      </Col>
    );
  };

  renderMember = item => {
    let name = '';

    if (item.field_first_name) {
      name = item.field_first_name;
    }

    if (item.field_last_name) {
      name = name + ' ' + item.field_last_name;
    }

    name = name + ' (' + item.name + ')';

    return (
      <Col>
        <div className="member">
          <Row className="align-items-center">
            <Col>
              <span dangerouslySetInnerHTML={{__html: name}} />
            </Col>
            <Col xs={"auto"}>
              <Button variant="link" onClick={() => {
                this.removeUser(item);
              }}>
                <X className="icon" />
              </Button>
            </Col>
          </Row>
        </div>
      </Col>
    );
  };

  renderModalFooter = (setModalVisible) => {
    return (
      <Modal.Footer>
        <Row noGutters className="align-items-center">
          <Col xs={"auto"}>
            <Button
              variant="outline-primary"
              className="cancel-btn"
              onClick={() => {
                setModalVisible(false);
              }}
            >
              Cancel
            </Button>
          </Col>
          <Col xs={"auto"} className="ml-auto">
            {this.state.isLoading ? (
              <Spinner animation="border" role="status" variant="primary">
                <span className="sr-only">Loading...</span>
              </Spinner>
            ) : (
              <Button
                onClick={() => {
                  this.saveGroup();
                }}
              >
                <span>Create Group</span>
              </Button>
            )}
          </Col>
        </Row>
      </Modal.Footer>
    );
  };

  checkViewport = () => {
    const desktopQuery = window.matchMedia("(min-width: 768px)");

    this.setState({
      isDesktop: desktopQuery.matches,
    });

  };

  handleInputFocus = (isFocused) => {
    this.setState({
      isFocused: isFocused,
    });
  };

  render() {
    const {modalVisible, setModalVisible} = this.props;

    return (
      <Modal
        show={modalVisible}
        onHide={() => setModalVisible(false)}
        className="group-modal"
      >
        <Modal.Header className="d-block">
          {this.state.inlineError && (
            <div className="w-100">
              <Row>
                <Col className="error align-content-center">
                  <AlertTriangle />
                  <span>{this.state.inlineError}</span>
                </Col>
              </Row>
            </div>
          )}

          <Modal.Title className={this.state.isFocused ? "is-focused" : ""}>
            <div className="icon-container">
              <Edit2 />
            </div>
            <input
              type="text"
              value={this.state.group_name}
              placeholder="Group name"
              onInput={(event) => {
                this.setState({
                  group_name: event.target.value,
                });
              }}
              onFocus={() => {
                this.handleInputFocus(true);
              }}
              onBlur={() => {
                this.handleInputFocus(false);
              }}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div className="form-group">
            <Row className="mb-1">
              <Col>
                <label>Group name</label>
              </Col>
            </Row>

            <Row>
              <Col>
                <input
                  type="text"
                  value={this.state.group_name}
                  placeholder="Group name"
                  className="form-control"
                  onInput={(event) => {
                    this.setState({
                      group_name: event.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
          </div>

          <div className="form-group">
            <Row className="mb-1">
              <Col>
                <label>Users</label>
              </Col>
              <Col xs={"auto"} className="d-flex align-items-center mb-1">
                {this.state.isSearching && (
                  <Spinner animation="border" role="status" variant="primary">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                )}
              </Col>
            </Row>

            <Row className="mb-1">
              <Col>
                <input
                  type="text"
                  value={this.state.searchTerm}
                  placeholder="Search users"
                  className="form-control"
                  onInput={(event) => {
                    this.setSearch(event.target.value);
                  }}
                />
              </Col>
            </Row>

            {this.state.users.length > 0 ? (
              <div className="users-container">
                {this.state.users.map((item, index) => {
                  return (
                    <Row key={`user-${index}`}>{this.renderItem(item)}</Row>
                  );
                })}
              </div>
            ) : (
              <>
                {this.state.isError && (
                  <div className="error-message">
                    <span>{this.state.errorMessage}</span>
                  </div>
                )}
              </>
            )}
          </div>

          <div className="form-group">
            <Row className="mb-1">
              <Col>
                <label>Group members</label>
              </Col>
            </Row>

            <Row>
              {this.state.isMembersLoading ? (
                <Col className="d-flex justify-content-center mt-4">
                  <Spinner animation="border" role="status" variant="primary">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </Col>
              ) : (
                <Col>
                  {this.state.selectedUsers.length < 1 ? (
                    <div className="error-message">
                      <span>No members</span>
                    </div>
                  ) : (
                    <div className="members-container">
                      {this.state.selectedUsers.map((user, index) => {
                        return (
                          <Row key={`member-${index}`}>
                            {this.renderMember(user)}
                          </Row>
                        );
                      })}
                    </div>
                  )}
                </Col>
              )}
            </Row>
          </div> */}

          <Row noGutters className={this.state.isDesktop && "h-100"}>
            <Col xs={12} md={6} className="h-100">
              <section className="users-section">
                <div className="form-group">
                  <Row className="mb-1">
                    <Col>
                      <label>Search users</label>
                    </Col>
                    <Col xs={"auto"} className="d-flex align-items-center mb-1">
                      {this.state.isSearching && (
                        <Spinner
                          animation="border"
                          role="status"
                          variant="primary"
                        >
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      )}
                    </Col>
                  </Row>

                  <Row className="mb-1">
                    <Col>
                      <input
                        type="text"
                        value={this.state.searchTerm}
                        placeholder="Type a name..."
                        className="form-control"
                        onInput={(event) => {
                          this.setSearch(event.target.value);
                        }}
                      />
                    </Col>
                  </Row>

                  {this.state.users.length > 0 ? (
                    <div className="users-container">
                      {this.state.users.map((item, index) => {
                        return (
                          <Row key={`user-${index}`}>
                            {this.renderItem(item)}
                          </Row>
                        );
                      })}
                    </div>
                  ) : (
                    <>
                      {this.state.isError && (
                        <div className="error-message">
                          <span>{this.state.errorMessage}</span>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </section>
            </Col>

            <Col xs={12} md={6} className="h-100">
              <section className="members-section">
                <div className="members-section__content">
                  <div className="form-group">
                    <Row className="mb-1">
                      <Col>
                        <label>Group members</label>
                      </Col>
                    </Row>

                    <Row>
                      {this.state.isMembersLoading ? (
                        <Col className="d-flex justify-content-center mt-4">
                          <Spinner
                            animation="border"
                            role="status"
                            variant="primary"
                          >
                            <span className="sr-only">Loading...</span>
                          </Spinner>
                        </Col>
                      ) : (
                        <Col>
                          {this.state.selectedUsers.length < 1 ? (
                            <div className="error-message">
                              <span>No members</span>
                            </div>
                          ) : (
                            <div className="members-container">
                              {this.state.selectedUsers.map((user, index) => {
                                return (
                                  <Row key={`member-${index}`}>
                                    {this.renderMember(user)}
                                  </Row>
                                );
                              })}
                            </div>
                          )}
                        </Col>
                      )}
                    </Row>
                  </div>
                </div>
                {this.state.isDesktop &&
                  this.renderModalFooter(setModalVisible)}
              </section>
            </Col>
          </Row>
        </Modal.Body>
        {/* On small screens render modal footer outside Modal.Body */}
        {!this.state.isDesktop && this.renderModalFooter(setModalVisible)}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default withRouter(connect(mapStateToProps)(CreateGroup));
