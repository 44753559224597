// Dependencies
import React from 'react';

// Modules
import {withRouter} from 'react-router'
import {connect} from 'react-redux';
import moment from 'moment';

// App
import {serverUrl} from '../../config';
import {getOnlineNode} from '../../core/getNode';
import {postNode} from '../../core/postNode';
import {deleteRequest} from '../../core/delete';
import PostTitle from '../../partials/postTitle';
import PostBody from '../../partials/postBody';
import CommentForm from '../../partials/commentForm';
import {alertMessages} from '../../partials/alertMessages';
import Error from '../../partials/error';
import Comment from '../../partials/comment';
import SkeletonSingleScreen from '../../partials/skeleton-screens/skeletonSingleScreen';

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import {MessageSquare, Clock, Tag} from 'react-feather';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faThumbsUp as faThumbsUpSolid, faHeart as faHeartSolid, faSurprise as faSurpriseSurpriseSolid}  from '@fortawesome/free-solid-svg-icons';
import {faThumbsUp as faThumbsUpRegular, faHeart as faHeartRegular, faSurprise as faSurpriseSurpriseRegular} from '@fortawesome/free-regular-svg-icons';

class NewsSingle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isPaginating: false,
      post: null,

      // Comments
      isCommentsLoading: true,
      comments: [],
      pageSize: 10,
      currentPage: 0,
      total_pages: 0,

      // reactions
      reactions: {
        hasReacted: false,
        likes: 0,
        love: 0,
        suprised: 0,
      },
      isReactionsLoading: true,
    };
  }

  componentDidMount() {
    this.loadNode();
  }

  /**
   * @function setData
   * @description Updates the state of the component upon data retrival
   * @param {object} _data
   * @param {object} _realm
   */
  setData = (_data) => {
    this.setState(
      {
        isLoading: false,
        isRefreshing: false,
        isError: false,
        post: _data,
      },
      function () {
        this.getComments();
        this.getReactions();
      },
    );
  };

  /**
   * @function setError
   * @description Updates the state of the component upon an error
   * @param {boolean} _isError
   * @param {int} _errorStatus
   * @param {string} _errorMessage
   */
  setError = (_isError, _errorStatus, _errorMessage) => {
    this.setState({
      isLoading: false,
      isRefreshing: false,
      isError: _isError,
      errorStatus: _errorStatus,
      errorMessage: _errorMessage,
    });
  };

  loadNode = () => {
    const nid = this.props.match.params.nid;

    if (nid) {
      this.getContent(nid);
    } else {
      this.setError(true, 0, alertMessages.contentError);
    }
  };

  /**
   * @function getContent
   * @description Retrieves the data from an API / Fallback to local realm object if there is no connection
   */
  getContent = (nid) => {
    let path = 'news/all/' + nid + '?_format=json&status=1&promote=1';

    getOnlineNode(path, this.props.user.access_token)
      .then((response) => {
        if (response.data.rows.length > 0) {
          this.setData(response.data.rows[0]);
        } else {
          this.setError(true, 200, alertMessages.noData);
        }
      })
      .catch((_error) => {
        if (_error.response) {
          this.setError(
            true,
            _error.response.status,
            _error.response.statusText,
          );
        } else if (_error.request) {
          this.setError(true, 0, alertMessages.requestError.message);
        } else {
          this.setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };

  getComments = (_scrollToBottom) => {
    const {post, comments, currentPage, total_pages} = this.state;

    if (currentPage > total_pages) {
      return;
    }

    let path =
      'comments/' +
      post.nid +
      '?items_per_page=' +
      this.state.pageSize +
      '&page=' +
      this.state.currentPage;

    getOnlineNode(path, this.props.user.access_token)
      .then((response) => {
        this.setState(
          {
            isPaginating: false,
            comments:
              currentPage === 0
                ? response.data.rows
                : [...comments, ...response.data.rows],
            isCommentsLoading: false,
            total_pages: response.data.pager.total_pages - 1,
          },
          function () {
            // if (_scrollToBottom) {
            //   this.setState({
            //     scrollToBottom: true,
            //   });
            // }
          },
        );
      })
      .catch((_error) => {
        // failed to get comments
        this.setState({
          isCommentsLoading: false,
        });
      });
  };

  getReactions = () => {
    const {post} = this.state;

    let nid = false;

    if (post) {
      nid = post.nid;
    } else {
      nid = this.props.route.params?.nid;
    }

    if (!nid) {
      return false;
    }

    if (post.field_allow_reactions === 'false') {
      return false;
    }
    
    const path = 'reactions/' + nid;

    getOnlineNode(path, this.props.user.access_token)
      .then(response => {       
        if (response.data.rows.length > 0) {
          let likes = [];
          let love = [];
          let surprised = [];
          let hasReacted = false;
          let reactionType = 'none';
          let reactionId = 'none';

          response.data.rows.forEach(reaction => {
            if (
              reaction.user_id ===
              this.props.user.current_user.uid
            ) {
              hasReacted = true;
              reactionType = reaction.type;
              reactionId = reaction.id;
            }

            if (reaction.type === 'reaction_like') {
              likes.push(reaction);
            }

            if (reaction.type === 'reaction_love') {
              love.push(reaction);
            }

            if (reaction.type === 'reaction_surprised') {
              surprised.push(reaction);
            }
          });

          this.setState({
            reactions: {
              hasReacted: hasReacted,
              reactionType: reactionType,
              reactionId: reactionId,
              likes: likes.length,
              love: love.length,
              surprised: surprised.length,
            },
            isReactionsLoading: false,
          });
        } else {
          this.setState({
            reactions: {
              hasReacted: false,
              likes: 0,
              love: 0,
              surprised: 0,
            },
            isReactionsLoading: false,
          });
        }
      })
      .catch(_error => {
        this.setState({
          reactions: {
            hasReacted: false,
            likes: 0,
            love: 0,
            surprised: 0,
          },
          isReactionsLoading: false,
        });
      });
  };

  addReaction = type => {
    const data = {
      _links: {
        type: {
          href: serverUrl + '/rest/type/vote/reaction_' + type,
        },
      },
      entity_id: [
        {
          target_id: this.state.post.nid,
        },
      ],
      entity_type: [
        {
          value: 'node',
        },
      ],
    };

    postNode(
      'entity/vote',
      data,
      this.props.user.csrf_token,
      this.props.user.access_token,
    )
      .then(response => {
        this.getReactions();
      })
      .catch(_error => {        
        this.setState({
          isLoading: false,
          modalVisible: true,
          alertType: 'error',
          alertTitle: alertMessages.cannotReact.title,
          alertBody: alertMessages.cannotReact.message,
          alertConfirmButton: true,
        });
      });
  };

  removeReaction = eid => {
    deleteRequest(
      'entity/vote/' + eid,
      this.props.user.csrf_token,
      this.props.user.access_token,
    )
      .then(response => {
        this.getReactions();
      })
      .catch(_error => {
        console.log('@_error: ', _error);
      });
  };

  loadMore = () => {
    this.setState(
      {
        isCommentsLoading: true,
        isPaginating: true,
        currentPage: this.state.currentPage + 1,
      },
      function () {
        this.getComments(true);
      },
    );
  };

  renderLoadMore = () => {
    if (this.state.currentPage < this.state.total_pages) {
      return (
        <div className="d-flex justify-content-center">
          <Button onClick={() => {
            if (!this.state.isPaginating) {
              this.loadMore();
            }
          }}>
            {'Load more'}
          </Button>
        </div>
      );
    } else {
      return null;
    }
  };

  renderPostImage = (post) => {
    let hasImage = post.field_featured_image.length > 0 ? true : false;

    if (hasImage && !this.state.isLandscape) {
      return (
        <div className="article__image">
          <img src={post.field_featured_image} alt={'img-' + post.nid} />
        </div>
      );
    }
  };

  renderReactions = () => {
    const {reactions, post} = this.state;

    if (post.field_allow_reactions === 'false') {
      return false;
    }

    const hasReacted = reactions.hasReacted;
    const reactionType = reactions.reactionType;
    const reactionId = reactions.reactionId;

    const reactionFontSize = 'lg';

    /**
     * Logic here
     * Design and tweak as needed
     * I haven't applied anything as you may apply these styles to other elements
     */
    let likesReactionStyles = 'node_reactions_icon_container node_reactions_icon_container_like'
    let loveReactionStyles = 'node_reactions_icon_container node_reactions_icon_container_love'
    let surprisedReactionStyles = 'node_reactions_icon_container node_reactions_icon_container_surprised'

    /**
     * Reaction counter style logic
     */
    let reactionCounterLikeStyles = 'node_reactions_counter node_reactions_like_counter'
    let reactionCounterLoveStyles = 'node_reactions_counter node_reactions_love_counter'
    let reactionCounterSurprisedStyles = 'node_reactions_counter node_reactions_surprised_counter'

    if (hasReacted && reactionType === 'reaction_like') {
      reactionCounterLikeStyles += ' reacted';
    }

    if (hasReacted && reactionType === 'reaction_love') {
      reactionCounterLoveStyles += ' reacted';
    }

    if (hasReacted && reactionType === 'reaction_surprised') {
      reactionCounterSurprisedStyles += ' reacted';
    }

    return (
      <Row>
        <Col className='text-center'>
        <div className='node_reactions'>
          <Button
            className={hasReacted && reactionType === 'reaction_like' ? 'like reacted' : 'like'} 
            onClick={() => {
              if (hasReacted) {
                if (reactionType === 'reaction_like') {
                  this.removeReaction(reactionId);
                }
              } else {
                this.addReaction('like');
              }
            }}>
            <span className={likesReactionStyles}>
              {hasReacted && reactionType === 'reaction_like' ? (
                <FontAwesomeIcon size={reactionFontSize} icon={faThumbsUpSolid} />
              ) : (
                <FontAwesomeIcon size={reactionFontSize} icon={faThumbsUpRegular} />
              )}
              <span className={reactionCounterLikeStyles}>{this.state.reactions.likes}</span>
            </span>
          </Button>

          <Button
            className={hasReacted && reactionType === 'reaction_love' ? 'love reacted' : 'love'} 
            onClick={() => {
              if (hasReacted) {
                if (reactionType === 'reaction_love') {
                  this.removeReaction(reactionId);
                }
              } else {
                this.addReaction('love');
              }
            }}>
            <span className={loveReactionStyles}>
              {hasReacted && reactionType === 'reaction_love' ? (
                <FontAwesomeIcon size={reactionFontSize} icon={faHeartSolid} />
              ) : (
                <FontAwesomeIcon size={reactionFontSize} icon={faHeartRegular} />
              )}
              <span className={reactionCounterLoveStyles}>{this.state.reactions.love}</span>
            </span>
          </Button>

          <Button
            className={hasReacted && reactionType === 'reaction_surprised' ? 'surprised reacted' : 'surprised'} 
            onClick={() => {
              if (hasReacted) {
                if (reactionType === 'reaction_surprised') {
                  this.removeReaction(reactionId);
                }
              } else {
                this.addReaction('surprised');
              }
            }}>
            <span className={surprisedReactionStyles}>
              {hasReacted && reactionType === 'reaction_surprised' ? (
                <FontAwesomeIcon size={reactionFontSize} icon={faSurpriseSurpriseSolid} />
              ) : (
                <FontAwesomeIcon size={reactionFontSize} icon={faSurpriseSurpriseRegular} />
              )}
              <span className={reactionCounterSurprisedStyles}>{this.state.reactions.surprised}</span>
            </span>
          </Button>
        </div>
        </Col>
      </Row>
    );
  };

  renderComments = () => {
    const {post, comments} = this.state;

    if (post.field_allow_comments === 'false') {
      return false;
    }

    return (
      <section className="comments">
        {comments.length > 0 ? (
          <>
            <div className="comments__header  d-flex align-items-center">
              <MessageSquare size={35} />
              <h3 className="mb-0">Comments</h3>
            </div>
            {this.props.user.current_user && (
              <CommentForm nid={post.nid} />
            )}
            <div className="comments__content">
              <div className="comments__header  d-flex align-items-center">
                <Clock size={25} />
                <h5 className="mb-0">Most Recent</h5>
              </div>
              {comments.map((_comment, __index) => {
                return (
                  <Comment
                    comment={_comment}
                    key={'comment-' + __index}
                    getComments={this.getComments}
                  />
                )
              })}
            </div>

          </>
        ) : (
          <>
            <div className="comments__header  d-flex align-items-center">
              <MessageSquare/>
              <h3 className="mb-0">Comments</h3>
            </div>
            <div className="comments__header  d-flex align-items-center">
              <Clock/>
              <h5 className="mb-0">
                {this.props.user.current_user ? (
                  'Be the first to comment'
                ) : (
                  'Login to comment'
                )}
              </h5>
            </div>
            {this.props.user.current_user && (
              <CommentForm nid={post.nid} />
            )}
          </>
        )}
        {this.renderLoadMore()}
      </section>
    )
  }

  render() {
    if (this.state.isLoading) {
      return <SkeletonSingleScreen />;
    } else {
      if (this.state.isError) {
        return (
          <Error 
            status={this.state.errorStatus}
            message={this.state.errorMessage}
          />
        );
      } else {
        const {post} = this.state;
        const timestamp = moment.unix(post.created).format("Do MMM YYYY");

        let category = 'Uncategorised';

        if (post.field_category_1) {
          category = post.field_category_1;
        }

        if (post.categoryLabel) {
          category = post.categoryLabel;
        }

        return (
          <main className="news  single">
            <article className="article">
              <header className="article__header">
                <PostTitle title={post.title} headingLevel={'h1'} />
                <div className="article__meta">
                  <Row>
                    <Col xs={"auto"}>
                      <div className="timestamp align-items-center">
                        <Clock className="timestamp__icon" />
                        <span className="timestamp__label  label">{timestamp}</span> 
                      </div>

                    </Col>
                    <Col xs={"auto"}>
                      <div className="category align-items-center">
                        <Tag className="category__icon" />
                        <span className="category__label  label">{category}</span>
                      </div>
                    </Col>
                  </Row>
                </div>
              </header>
              {this.renderPostImage(post)}
              <div className="article__body">
                {/* <div dangerouslySetInnerHTML={{__html: post.body}} /> */}
                <PostBody body={post.body} />
              </div>
              {this.renderReactions()}
              {this.renderComments()}
            </article>
          </main>
        );
      }
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default withRouter(connect(mapStateToProps)(NewsSingle));
