// Data
import db from './db';

export const getFavorites = async (table, category, index, pageSize) => {
  let id = '' + category;
  let tid = '' + category;
  switch (table) {
    case 'notices': 
      if (id !== '0') {
        return db.table(table)
          .where({favorite: 'true', groupID: id})
          .offset(index * pageSize)
          .limit(pageSize)
          .toArray()
      } else {
        return db.table(table)
          .where('favorite').equals('true')
          .offset(index * pageSize)
          .limit(pageSize)
          .toArray()
      }

    case 'event':
      if (tid !== '0') {
        return db.table(table)
          .where({favorite: 'true', categoryID: tid})
          .offset(index * pageSize)
          .limit(pageSize)
          .sortBy('eventStartDate')
      } else {
        return db.table(table)
          .where('favorite').equals('true')
          .offset(index * pageSize)
          .limit(pageSize)
          .sortBy('eventStartDate')
      }

    default:
      if (tid !== '0') {
        return db.table(table)
          .where({favorite: 'true', categoryID: tid})
          .offset(index * pageSize)
          .limit(pageSize)
          .toArray()
      } else {
        return db.table(table)
          .where('favorite').equals('true')
          .offset(index * pageSize)
          .limit(pageSize)
          .toArray()
      }
  }
}

export const getFavouritesLength = async (table, category) => {
  if (table === 'notices') {
    const id = '' + category;

    if (id !== '0') {
      return await db[table].where({favorite: 'true', groupID: id}).count();
    } else {
      return await db[table].where('favorite').equals('true').count();
    }
  } else {
    const tid = '' + category;

    if (tid !== '0') {
      return await db[table].where({favorite: 'true', categoryID: tid}).count();
    } else {
      return await db[table].where('favorite').equals('true').count();
    }
  }
}