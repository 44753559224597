import {api, serverUrl} from '../config';

export const deleteRequest = (_endpoint, _xcsrf_token, _access_token) => {
  let headers = {
    'Content-Type': 'application/hal+json',
    'X-CSRF-Token': _xcsrf_token,
    Authorization: 'Bearer ' + _access_token,
  };

  if (!_access_token) {
    headers = {
      Accept: 'application/hal+json',
      'Content-Type': 'application/hal+json',
      'X-CSRF-Token': _xcsrf_token,
    };
  }

  let uri = serverUrl + '/' + _endpoint + '?_format=hal_json';
  return api.delete(uri, {headers});
};
