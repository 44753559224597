import React from "react";

// Modules
import { connect } from "react-redux";

// App
import PostTitle from "../../partials/postTitle";
import PostExcerpt from "../../partials/postExcerpt";
import LoadingSpinner from "../../partials/loadingSpinner";

// Data
// import db from "../../core/db";

// UI components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Bookmark, Info } from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import Accordion from "react-bootstrap/Accordion";

class DocumentsItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      favorite: "false",
    };
  }

  componentDidMount() {
    this.checkState();
  }

  checkState = () => {
    this.setState({
      favorite: this.props.item.favorite,
    });
  };

  /**
   * Favourite a document item and update the db
   *
   * @param {The node id} _nid
   */
  favorite = (_nid) => {
    this.setState({
      favorite: "true",
    });

    this.props.favorite(_nid);
  };

  /**
   * Unfavourite a document item and update the db
   *
   * @param {*} _nid
   */
  unfavorite = (_nid) => {
    this.setState({
      favorite: "false",
    });

    this.props.unfavorite(_nid);
  };

  formatBytes = (a, b) => {
    if (a === 0) {
      return "0 Bytes";
    }
    var c = 1024,
      d = b || 2,
      e = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
      f = Math.floor(Math.log(a) / Math.log(c));
    return parseFloat((a / Math.pow(c, f)).toFixed(d)) + " " + e[f];
  };

  /**
   * Handle item hover state
   * @returns
   */
  handleItemHover = (hover) => {
    this.setState({ isHovered: hover });
  };

  renderDocumentItem = () => {
    const itemClass = "document-item";
    const itemClassList = `${itemClass}  item`;

    let itemStyle = itemClassList;
    let hoveredStyle = this.state.isHovered ? "hovered" : "";

    return (
      <Col
        key={"document" + this.props.item.nid}
        className="d-flex gutter-lg"
        xs={12}
        lg={6}
      >
        <div className={`${itemStyle} ${hoveredStyle}`}>
          <Accordion defaultActiveKey="0">
            <Row noGutters className="align-items-center">
              <Col xs={"auto"}>
                <a
                  href={this.props.item.uri}
                  rel="noreferrer"
                  target="_blank"
                  className={`${itemClass}__icon`}
                  onMouseEnter={() => this.handleItemHover(true)}
                  onMouseLeave={() => this.handleItemHover(false)}
                >
                  <FontAwesomeIcon icon={faFilePdf} />
                </a>
              </Col>
              <Col>
                <a
                  href={this.props.item.uri}
                  rel="noreferrer"
                  target="_blank"
                  className={`${itemClass}__content  item__content`}
                  onMouseEnter={() => this.handleItemHover(true)}
                  onMouseLeave={() => this.handleItemHover(false)}
                >
                  <PostTitle
                    title={this.props.item.title}
                    headingLevel={"h6"}
                  />
                </a>
              </Col>
              <Col xs={"auto"} className="d-flex  align-items-center">
                <Accordion.Toggle
                  eventKey={this.props.item.nid}
                  className="info  px-2"
                  disabled={!this.props.item.body ? true : false}
                >
                  <Info />
                </Accordion.Toggle>
                <div className="px-2">
                  {this.state.favorite === "true" ? (
                    <button
                      className="favourite-btn filled"
                      onClick={() => {
                        this.unfavorite(this.props.item.nid);
                      }}
                    >
                      <Bookmark />
                    </button>
                  ) : (
                    <button
                      className="favourite-btn"
                      onClick={() => {
                        this.favorite(this.props.item.nid);
                      }}
                    >
                      <Bookmark />
                    </button>
                  )}
                </div>
              </Col>
            </Row>
            <Accordion.Collapse eventKey={this.props.item.nid}>
              <PostExcerpt body={this.props.item.body} />
            </Accordion.Collapse>
          </Accordion>
        </div>
      </Col>
    );
  };

  /**
   * @function render
   * @description Default render method
   */
  render() {
    if (this.state.isLoading) {
      return <LoadingSpinner />;
    } else {
      return this.renderDocumentItem();
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default connect(mapStateToProps)(DocumentsItem);
