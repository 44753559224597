import React from "react";

// Modules
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// App
import PostTitle from "../../partials/postTitle";
import PostExcerpt from "../../partials/postExcerpt";
import LoadingSpinner from "../../partials/loadingSpinner";
import UserImage from "../../partials/userImage";

// Data
// import db from "../../core/db";

// UI components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Bookmark, Tag } from "react-feather";
import placeholderImage from "../../assets/galleries-placeholder.svg";

class GalleriesItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // isLoading: true,
      isLoading: false,
      favorite: "false",
    };
  }

  componentDidMount() {
    this.checkState();
    // this.getPostAuthorData(this.props.item);
  }

  checkState = () => {
    this.setState({
      favorite: this.props.item.favorite,
    });
  };

  /**
   * Favourite a gallery item and update the db
   *
   * @param {The node id} _nid
   */
  favorite = (_nid) => {
    this.setState({
      favorite: "true",
    });

    this.props.favorite(_nid);
  };

  /**
   * Unfavourite a gallery item and update the db
   *
   * @param {*} _nid
   */
  unfavorite = (_nid) => {
    this.setState({
      favorite: "false",
    });

    this.props.unfavorite(_nid);
  };

  /**
   * @function setData
   * @description Updates the state of the component upon data retrival
   * @param {object} _data
   */
  setData = (_data) => {
    this.setState({
      isLoading: false,
      authorData: _data,
    });
  };

  // getPostAuthorData = (_data) => {
  //   let path = "custom_user?_format=json&name=" + _data.name;

  //   getOnlineNode(path, this.props.user.access_token)
  //     .then((response) => {
  //       // console.log('@author data:', response.data.rows[0]);
  //       this.setData(response.data.rows[0]);
  //     })
  //     .catch((_error) => {
  //       // error
  //     });
  // };

  renderAuthor = () => {
    let author;
    let picture;

    if (this.props.author) {
      author = this.props.author.username;
      picture = this.props.author.user_picture;

      // first name
      if (this.props.author.field_first_name) {
        author = this.props.author.field_first_name;
      }

      // Concat first & last name
      if (
        this.props.author.field_first_name &&
        this.props.author.field_last_name
      ) {
        author =
          this.props.author.field_first_name +
          " " +
          this.props.author.field_last_name;
      }

      if (!author) {
        author = this.props.author.username;
      }
    }

    return (
      <div className={"author"}>
        <UserImage
          className="author__image"
          image={picture}
        />
        <div className={"author__name"}>
          <span>
            posted by <strong>{author}</strong>
          </span>
        </div>
      </div>
    );
  };

  /**
   * Handle item hover state
   * @returns
   */
  handleItemHover = (hover) => {
    this.setState({ isHovered: hover });
  };

  renderGalleriesItem = () => {
    const itemClass = "galleries-item";
    const itemClassList = `${itemClass}  item`;

    let category = "Uncategorised";
    if (this.props.item.categoryLabel) {
      category = this.props.item.categoryLabel;
    }

    let hasImage = this.props.item.field_featured_image.length > 0 ? true : false;
    hasImage = this.props.item.field_featured_image === "null" ? false : true;

    let imageSrc = placeholderImage;
    if (hasImage) {
      imageSrc = this.props.item.field_featured_image;
    }

    let itemStyle = hasImage ? itemClassList : `no-image ${itemClassList}`;
    let hoveredStyle = this.state.isHovered ? "hovered" : "";

    return (
      <Col key={"galleries" + this.props.item.nid} xs={12} lg={6}>
        <div className={`${itemStyle} ${hoveredStyle}`}>
          <div className="d-block position-relative">
            <Link
              to={"/galleries/" + this.props.item.nid}
              className={`${itemClass}__image  item__image`}
              onMouseEnter={() => this.handleItemHover(true)}
              onMouseLeave={() => this.handleItemHover(false)}
            >
              <img
                src={imageSrc}
                alt={"img-" + this.props.item.nid}
              />
            </Link>
            <Link
              to={"/galleries/" + this.props.item.nid}
              className={`${itemClass}__content  item__content`}
              onMouseEnter={() => this.handleItemHover(true)}
              onMouseLeave={() => this.handleItemHover(false)}
            >
              <PostTitle title={this.props.item.title} headingLevel={"h6"} />
              <PostExcerpt body={this.props.item.body} />
            </Link>
            {this.state.favorite === "true" ? (
              <button
                className="favourite-btn filled"
                onClick={() => {
                  this.unfavorite(this.props.item.nid);
                }}
              >
                <Bookmark />
              </button>
            ) : (
              <button
                className="favourite-btn"
                onClick={() => {
                  this.favorite(this.props.item.nid);
                }}
              >
                <Bookmark />
              </button>
            )}
          </div>

          <footer className={`${itemClass}__footer  item__footer`}>
            <Row>
              <Col>{this.renderAuthor()}</Col>
              <Col xs={"auto"} className="category">
                <Tag className="category__icon" />
                <span className="category__text">{category}</span>
              </Col>
            </Row>
          </footer>
        </div>
      </Col>
    );
  };

  /**
   * @function render
   * @description Default render method
   */
  render() {
    if (this.state.isLoading) {
      return <LoadingSpinner />;
    } else {
      return this.renderGalleriesItem();
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default connect(mapStateToProps)(GalleriesItem);
