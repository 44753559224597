import React from 'react';

// Modules
import {withRouter} from 'react-router'
import {connect} from 'react-redux';

// App
import UserImage from '../../partials/userImage';
import PostTitle from "../../partials/postTitle";

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import {ChevronLeft, ChevronRight, X } from 'react-feather';

class GalleriesViewer extends React.Component {
 
  /**
   * Render the author of the image upload
   * Filters authors by image file id
   * @param {*} imageFid 
   * @returns 
   */
  renderAuthor = (imageFid) => {
    const filteredAuthor = this.props.authorData.filter(item => item.fid === imageFid);
    const imageAuthor = filteredAuthor[0];


    // username
    let author = imageAuthor.name;

    // first name
    if (imageAuthor.field_first_name) {
      author = imageAuthor.field_first_name;
    }

    // Concat first & last name
    if ( imageAuthor.field_first_name && imageAuthor.field_last_name) {
      author =imageAuthor.field_first_name + " " + imageAuthor.field_last_name;
    }

    return (
      <div className={"author"}>
        <UserImage
          className="author__image"
          image={imageAuthor.user_picture}
        />
        <div className={"author__name"}>
          <span>
            posted by <strong>{author}</strong>
          </span>
        </div>
      </div>
    );
  };
  
  setIndex = (selectedIndex) => {
    this.props.setIndex(selectedIndex);
  }   

  setDirection = (direction) => {
    this.props.setDirection(direction);
    // this.setState({direction: direction})
  }

  handleSelect = (selectedIndex, e) => {
    this.props.setIndex(selectedIndex);
    this.props.setDirection(e.direction);

    // this.setIndex(selectedIndex);
    // this.setDirection(e.direction);
  };

  render() {
    const {images, modalVisible} = this.props;


    if (modalVisible) {
      return (
        <Modal 
          show={this.props.modalVisible} 
          onHide={() => this.props.modalVisibleCallback(false)} 
          centered
          dialogClassName="image-gallery-modal"
          backdropClassName="image-gallery-backdrop"
        >
          <Modal.Body>
            <Carousel 
              defaultActiveIndex={this.props.activeIndex}
              interval={null}
              indicators={false}
              prevIcon={<ChevronLeft></ChevronLeft>}
              nextIcon={<ChevronRight></ChevronRight>}
              onSelect={this.handleSelect}
            >
              {images.map((item) => {
                return (
                  <Carousel.Item key={'carousel-item-' + item.fid}>
                    <div className="content">
                      <div className="content__text">
                        <PostTitle title={this.props.postTitle} headingLevel={"h2"} />

                        <div className="text my-auto">
                          {item.alt && (
                            <p>{item.alt}</p>
                          )}
                        </div>

                        <footer className="content__footer">
                          <Row className="align-items-center">
                            <Col>
                              {this.renderAuthor(item.fid)}
                            </Col>
                            <Col xs={'auto'}>
                              <div className="carousel-pagination">
                                {this.props.activeIndex + 1 + '/' + this.props.images.length}
                              </div>
                            </Col>
                          </Row>
                        </footer>

                      </div>

                      <div className="content__image">
                        <img src={item.uri} alt={'image-' + item.fid} />
                      </div>
                    </div>
                  </Carousel.Item>
                );
              })}
            </Carousel>

            <button 
              type="button" 
              className="close-modal-btn"
              onClick={() => this.props.modalVisibleCallback(false)}
            >
              <X />
            </button>

          </Modal.Body>
        </Modal>
      );
    } else {
      return null;
    }
  }
}

// export default GalleriesViewer;
const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default withRouter(connect(mapStateToProps)(GalleriesViewer));