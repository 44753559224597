import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';
import ServiceWorkerUpdateNotification from './partials/serviceWorkerUpdate';

// App
import store from './redux/store';

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

console.log(`v0.1.7`);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener("statechange", (event) => {
        if (event.target.state === "activated") {
          const alert = <ServiceWorkerUpdateNotification />
          ReactDOM.render(alert, document.getElementById('alert'));
          // document.getElementById('alert').style.display = 'block';

          // document.getElementById('update-app').addEventListener('click', () => {
          //   window.location.reload();
          // });

          // document.getElementById('close-notification').addEventListener('click', () => {
          //   document.getElementById('alert').style.display = 'none';
          // });
        }
      });
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
    }
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
