import React, { Component } from 'react';

// Modules
import ContentLoader from 'react-content-loader';

class ContactsSkeletonSingleScreen extends Component {  
  constructor() {
    super();
    this.state = {
      backgroundColor: '#f3f3f3',
      foregroundColor: '#ecebeb',
    };
  }

  render() {
    const itemClass = "contact";

    return (
      <main className="contact single">
        <article className="article">
          
          <div className={`${itemClass}__header article__header`}>
            <ContentLoader 
              speed={2}
              width={'100%'}
              height={'100%'}
              viewBox="0 0 920 150"
              backgroundColor={this.state.backgroundColor}
              foregroundColor={this.state.foregroundColor}

            >
              <rect x="180.295" y="86.036" width="356.618" height="35" rx="17.5" ry="17.5" />
              <rect x="180.295" y="25.864" width="289.496" height="50" rx="25" ry="25" />
              <circle cx="74.864" cy="74.864" r="74.864" />
            </ContentLoader>
          </div>

          {/* Contact buttons */}
          <ContentLoader 
            speed={2}
            width={'100%'}
            height={'100%'}
            viewBox="0 0 920 136"
            backgroundColor={this.state.backgroundColor}
            foregroundColor={this.state.foregroundColor}
          >
            <circle cx="661.732" cy="113.18" r="22.012" />
            <circle cx="586.739" cy="113.18" r="22.012" />
            <rect x="564.727" y="43.957" width="123.984" height="20" rx="10" ry="10" />
            <rect x="564.727" y="0" width="57.689" height="20" rx="10" ry="10" />
            <circle cx="202.307" cy="113.18" r="22.012" />
            <rect x="180.295" y="43.957" width="226.738" height="20" rx="10" ry="10" />
            <rect x="180.295" y="0" width="49.249" height="20" rx="10" ry="10" />
          </ContentLoader>

          {/* Contact message form */}
          <div className={`${itemClass}__message`}>
            <ContentLoader 
              speed={2}
              width={'100%'}
              height={'100%'}
              viewBox="0 0 920 50"
              backgroundColor={this.state.backgroundColor}
              foregroundColor={this.state.foregroundColor}
            >
              <rect x="180.295" y="-0" width="739.705" height="50" rx="5" ry="5"/>
            </ContentLoader>
          </div>

        </article>
      </main>
    );
  }

}

export default ContactsSkeletonSingleScreen;