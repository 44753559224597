import React from "react";

// Modules
import { connect } from "react-redux";
import moment from 'moment';
import { Link } from "react-router-dom";
import { Link as ScrollLink } from 'react-scroll';

// App
import {getOnlineNode} from '../../core/getNode';
import {paginator} from '../../core/paginator';
import Pagination from '../../core/pagination';
import PageHeader from '../../partials/pageHeader';
import PostExcerpt from "../../partials/postExcerpt";
import {alertMessages} from "../../partials/alertMessages";
import Error from "../../partials/error";
import ForumComment from "../../partials/forumComment";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import AlertModal from '../../partials/alertModal';
import UserImage from "../../partials/userImage";
import ForumsSkeletonTopicCommentsScreen from "./skeleton-screens/forumsSkeletonTopicCommentsScreen";

// UI components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { MessageSquare, Share2 } from "react-feather";

class ForumTopicComments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isError: false,

      currentPage: 1,
      pageSize: 10,
      totalItems: 0,

      comment: {
        cid: null,
        username: null,
        comment_body: null,
      },
      focus: false,
    };

    this.onPaginate = this.onPaginate.bind(this);
    // this.commentsArray = [];
  }

  componentDidMount() {
    this.checkSession();
  }
  
  checkSession = () => {
    if (Object.keys(this.props.user).length === 0) {
      this.props.history.push('/');
    } else {
      this.loadNode();
    }
  }

  /**
   * @function setData
   * @description Updates the state of the component upon data retrival
   * @param {object} _data
   * @param {object} _realm
   */
  setData = (_data) => {
    this.setState(
      {
        isLoading: false,
        isRefreshing: false,
        isError: false,
        forumTopicComments: _data.rows,
        totalItems: _data.pager.total_items,
      }
    );
  };

  /**
   * @function setError
   * @description Updates the state of the component upon an error
   * @param {boolean} _isError
   * @param {int} _errorStatus
   * @param {string} _errorMessage
   */
  setError = (_isError, _errorStatus, _errorMessage) => {
    this.setState({
      isLoading: false,
      isRefreshing: false,
      isError: _isError,
      errorStatus: _errorStatus,
      errorMessage: _errorMessage,
    });
  };

  loadNode = () => {
    const tid = this.props.match.params.tid;
    const nid = this.props.match.params.nid;

    if (nid && tid) {
      this.getForumBoard(tid, nid);
    } else {
      this.setError(true, 0, alertMessages.contentError);
    }
  };

  /**
   * @function getForumBoard
   * @description Retrieves the data from an API
   */
  getForumBoard = (tid, nid) => {
    let path = 'all_categories/forums/' + tid;

    getOnlineNode(path, this.props.user.access_token)
      .then((response) => {
        this.setState({
          forumBoard: response.data[0],
        }, () => {
          this.getForumTopic(tid, nid);
        });
      })
      .catch((_error) => {
        if (_error.response) {
          this.setError(
            true,
            _error.response.status,
            _error.response.statusText,
          );
        } else if (_error.request) {
          this.setError(true, 0, alertMessages.requestError.message);
        } else {
          this.setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };

  /**
   * @function getForumTopic
   * @description Retrieves the data from an API
   */
  getForumTopic = (tid, nid) => {

    let path = "forum_topic/" + tid + '/' + nid;

    getOnlineNode(path, this.props.user.access_token)
      .then((response) => {
        if (response.data.rows.length > 0) {
          this.setState({
            forumTopic: response.data.rows[0],
          }, () => {
            this.getForumTopicComments(nid);
          });
        } else {
          this.setError(true, 200, alertMessages.noData);
        }
      })
      .catch((_error) => {
        if (_error.response) {
          this.setError(
            true,
            _error.response.status,
            _error.response.statusText
          );
        } else if (_error.request) {
          this.setError(true, 0, alertMessages.requestError.message);
        } else {
          this.setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };

  /**
   * @function getForumTopicComments
   * @description Retrieves the data from an API
   */
  getForumTopicComments = (nid) => {

    let path =
      "forum_topic_comments/" +
      nid +
      "/all?_format=json&status=1&promote=1&" +
      "items_per_page=" +
      this.state.pageSize +
      "&page=" +
      (this.state.currentPage - 1);

    getOnlineNode(path, this.props.user.access_token)
      .then((response) => {
        if (response.data.rows.length > 0) {
          this.setData(response.data);
        } else {
          this.setState({
            isLoading: false,
            forumTopicComments: [],
            totalItems: 0,
          })
        }
      })
      .catch((_error) => {
        if (_error.response) {
          this.setError(
            true,
            _error.response.status,
            _error.response.statusText
          );
        } else if (_error.request) {
          this.setError(true, 0, alertMessages.requestError.message);
        } else {
          this.setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };

  /**
   * @function onPaginate
   * @description Pagination callback
   * @param {int} _index - Page number
   */
  onPaginate = (_index) => {
    this.setState(
      {
        isLoading: true,
        isPaginating: true,
        currentPage: _index,
      },
      function() {
        // Call only getForumTopicComments() as it's quicker than loadNode() = 3x api calls
        this.getForumTopicComments(this.props.match.params.nid);
      },
    );
  };

  // Pagination component
  renderPagination = () => {

    if (this.state.totalItems <= this.state.pageSize) {
      return null;
    } else {
      let pagination = paginator(
        this.state.totalItems,
        this.state.currentPage,
        this.state.pageSize,
        3,
      );

      return (
        <Pagination
          currentPage={pagination.currentPage}
          endIndex={pagination.endIndex}
          endPage={pagination.endPage}
          pageSize={pagination.pageSize}
          pages={pagination.pages}
          startIndex={pagination.startIndex}
          startPage={pagination.startPage}
          totalItems={pagination.totalItems}
          totalPages={pagination.totalPages}
          paginationCallback={this.onPaginate}
        />
      );
    }
  };

  /**
   * @function renderAuthor returns the concatenated name and fallsback to username
   * @param {*} userData 
   * @returns 
   */
  renderAuthor = (userData) => {
    // username
    let author = userData.name;

    // first name
    if (userData.field_first_name) {
      author = userData.field_first_name;
    }

    // Concat first & last name
    if (
      userData.field_first_name &&
      userData.field_last_name
    ) {
      author =
        userData.field_first_name +
        " " +
        userData.field_last_name;
    }

    if (!author) {
      author = userData.name;
    }

    return author;
  };

  /**
   * Render the authors opening comment
   */
  renderAuthorComment = () => {
    const itemClass = "topic-comment";
    const { forumTopic } = this.state;
    const userMemberFor = moment.unix(forumTopic.user_created).format('MMMM YYYY');
    const commentCreated = moment.unix(forumTopic.created).format("DD.MM.YY (HH:mm)");

    return (
      <div className={`${itemClass}-${forumTopic.nid} ${itemClass}`}>
        <div className={`${itemClass}__author`}>
          <Link
            to={"/contacts/" + forumTopic.author_uid}
            className="mb-2 d-flex flex-column align-items-center"
          >
            <UserImage image={forumTopic.user_picture} />
            <span className="username">
              {/* {forumTopic.author_username} */}
              {this.renderAuthor(forumTopic)}
              <span className="author-flag">(Author)</span>
            </span>
          </Link>
          <span className="user-member-date">Joined: {userMemberFor}</span>
        </div>
        <div className={`${itemClass}__content`}>
          <header className={`${itemClass}__header`}>
            <span className="comment-created">{commentCreated}</span>
          </header>
          <div
            className={`${itemClass}__body`}
            dangerouslySetInnerHTML={{
              __html: forumTopic.body,
            }}
          />
        </div>
      </div>
    );
  };

  /**
   * Render each comment within the topic
   */
  renderTopicComments = () => {
    const itemClass = "topic-comment";
    const { forumTopic } = this.state;

    return (
      this.state.forumTopicComments.map((comment, index) => {

        let commentIndex = index + 1;
        const isTopicAuthor = comment.author_username === forumTopic.author_username ? true : false;

        // Filter topic comments 
        // Return the comment that is the parent to another comment (the comment replied to)
        let parentComment = this.state.forumTopicComments.filter((item) => item.cid === comment.pid);
        parentComment = parentComment[0];

        const userMemberFor = moment.unix(comment.user_created).format('MMMM YYYY');
        const commentCreated = moment.unix(comment.created).format("DD.MM.YY (HH:mm)");

        return (
          <div
            key={"topic-comment-" + comment.cid}
            id={"topic-comment-" + comment.cid}
            className={`${itemClass}`}
            // ref={(ref) => (this.commentsArray[comment.cid] = ref)}
          >
            <div className={`${itemClass}__author`}>
              <Link
                to={"/contacts/" + comment.author_uid}
                className="mb-2 d-flex flex-column align-items-center"
              >
                <UserImage image={comment.user_picture} />
                <span className="username">
                  {this.renderAuthor(comment)}
                  {isTopicAuthor && (
                    <span className="author-flag">(Author)</span>
                  )}
                </span>
              </Link>
              <span className="user-member-date">Joined: {userMemberFor}</span>
            </div>
            <div className={`${itemClass}__content`}>
              <header className={`${itemClass}__header`}>
                <span className="comment-created">{commentCreated}</span>
                <span className="comment-url">
                  {/* <span className="comment-clipboard">Copied!</span> */}
                  <CopyToClipboard
                    text={`${window.location.href}#topic-comment-${comment.cid}`}
                    onCopy={() => {
                      this.setState({ copied: true });
                    }}
                  >
                    <Share2 />
                  </CopyToClipboard>
                </span>
                <span className="comment-index">{"#" + commentIndex}</span>
              </header>
              {parentComment && (
                <div className="replied-comment" data-pid={comment.pid}>
                  <p className="d-flex  mb-1">
                    <Link
                      to={"/contacts/" + parentComment.author_uid}
                      className="username"
                    >
                      {this.renderAuthor(parentComment)}
                    </Link>
                    <span className="ml-1">said:</span>
                    {/* <a
                      href={`#topic-comment-${comment.pid}`}
                      className="parent-comment-link"
                      onClick={(event) => this.handleSrollToComment(comment.pid, event)}
                    >
                      Read comment
                    </a> */}
                    <ScrollLink
                      className="parent-comment-link"
                      to={`topic-comment-${comment.pid}`}
                      smooth={true}
                      duration={500}
                      hashSpy={true}
                      offset={-74}
                    >
                      Read comment
                    </ScrollLink>
                  </p>
                  <PostExcerpt
                    body={parentComment.comment_body}
                    maxLength={150}
                  />
                </div>
              )}
              <div
                className={`${itemClass}__body`}
                dangerouslySetInnerHTML={{
                  __html: comment.comment_body,
                }}
              />
              <footer className={`${itemClass}__footer`}>
                <button
                  onClick={() => {
                    this.setState({
                      focus: true,
                      comment: {
                        cid: comment.cid,
                        username: comment.author_username,
                        comment_body: comment.comment_body,
                      },
                    });
                  }}
                  className="reply-btn"
                >
                  Reply
                </button>
              </footer>
            </div>
          </div>
        );
      })
    );
  };

  onCommentPosted = () => {
    this.getForumTopicComments(this.props.match.params.nid);
  };

  /**
   * @function render
   * @description Default render method
   */
  render() {
    if (this.state.isLoading) {
      return <ForumsSkeletonTopicCommentsScreen />;
    } else {
      if (this.state.isError) {
        return (
          <Error 
            status={this.state.errorStatus}
            message={this.state.errorMessage}
          />
        );
      } else {

        const { forumBoard, forumTopic, forumTopicComments } = this.state;
        const forumTopicTimestamp = moment.unix(forumTopic.created).format("dddd Do MMMM YYYY (HH:mm)");

        return (
          <main className="forum-topic-comments  screen">

            {this.state.copied && (
              <AlertModal 
                showAlert={true} 
                alertType={'success'}
                alertMessageTitle={'Success'}
                alertMessageBody={'Copied to clipboard!'}
                confirmButton={true}
                onConfirm={() => {
                  if (this.state.copied) {
                    this.setState({
                      copied: false,
                    })
                  }
                }}
              />
            )}
            
            <section className="comments-header">
              <Link to={`/forums/${forumBoard.tid}`} className="comments-header__board  d-inline-flex align-items-center">
                <MessageSquare />
                <h6 className="mb-0  ml-2">{forumBoard.title}</h6>
              </Link>
              <PageHeader
                pageName={forumTopic.title}
                filters={false}
              />
              <Row noGutters className="align-items-center  mb-5">
                <Col xs={'auto'} className="d-flex align-items-center">
                  <UserImage className="comments-header__author-image" image={forumTopic.user_picture} />
                  <Link
                    to={"/contacts/" + forumTopic.author_uid}
                    className="comments-header__author"
                  >
                    {this.renderAuthor(forumTopic)}
                  </Link>
                </Col>
                <Col>
                  <span className="comments-header__timestamp">{forumTopicTimestamp}</span>
                </Col>
              </Row>
            </section>

            {this.state.currentPage <= 1 && (
              this.renderAuthorComment()
            )}

            <section className="comments-container">
              {forumTopicComments.length > 0 && (
                this.renderTopicComments()
              )}
            </section>

            <section className="comments-post-container">
              <div className="add-comment-header">
                <div className="add-comment-header__icon">
                  <MessageSquare/>
                </div>
                <h3 className="add-comment-header__title">Join the discussion</h3>
              </div>
              
              <ForumComment
                comment={this.state.comment}
                focus={this.state.focus}
                nid={this.props.match.params.nid}
                onCommentPosted={this.onCommentPosted}
                clearReply={() => {
                  this.setState({
                    comment: {
                      cid: null,
                      username: null,
                      comment_body: null,
                    },
                    focus: false,
                  })
                }}
              />
            </section>
            {this.renderPagination()}
          </main>
        );
      }
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default connect(mapStateToProps)(ForumTopicComments);
