import React, { Component } from 'react';

// Modules
import ContentLoader from 'react-content-loader';

// App
import SkeletonPageHeader from '../../../partials/skeleton-screens/skeletonPageHeader';

// UI components
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";

class ForumsSkeletonScreen extends Component {  
  constructor() {
    super();
    this.state = {
      backgroundColor: '#f3f3f3',
      foregroundColor: '#ecebeb',
    };
  }

  renderDefaultItems = (itemClassList) => {

    let items = [];
    let numberOfItems;

    if (this.props.numberOfItems) {
      numberOfItems = this.props.numberOfItems;
    } else {
      numberOfItems = 20;
    }

    for (let i = 0; i < numberOfItems; i++) {

      items.push(
        <Col xs={12} key={'item-' + i}>
          <div className={itemClassList} style={{paddingBottom: 0, border: 'none'}}>
            <ContentLoader 
              speed={2}
              width={'100%'}
              height={'100%'}
              viewBox="0 0 1140 90"
              backgroundColor={this.state.backgroundColor}
              foregroundColor={this.state.foregroundColor}
            >
              <circle cx="1116.21" cy="10.529" r="10" />
              <circle cx="1084.94" cy="10.529" r="10" />
              <rect x="1012.74" y="0.529" width="49.775" height="20" rx="10" ry="10" />
              <circle cx="998.531" cy="10.529" r="10" />
              <rect x="39.467" y="58.509" width="367.626" height="20" rx="10" ry="10" />
              <rect x="39.467" y="31.519" width="530.533" height="20" rx="10" ry="10" />
              <rect x="39.467" y="0.529" width="320.074" height="20" rx="10" ry="10" />
              <rect x="0" y="0.529" width="23.59" height="20" rx="2.5" ry="2.5" />
            </ContentLoader>
          </div>
        </Col>
      );

    }

    return items;
  };

  render() {
    const itemClass = "forum-item";
    const itemClassList = `${itemClass}  item`;

    return (
      <main className="forums  screen">
        <SkeletonPageHeader />

        <Row>
          {this.renderDefaultItems(itemClassList)}
        </Row>
      </main>
    );
  }

}

export default ForumsSkeletonScreen;