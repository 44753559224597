import React from 'react';

// Modules
import {connect} from 'react-redux';

// App
import GalleriesItem from './galleriesItem';
import {getOnlineNode} from '../../core/getNode';
import {paginator} from '../../core/paginator';
import Pagination from '../../core/pagination';
import PageHeader from '../../partials/pageHeader';
import CategorySelector from '../../partials/categorySelector';
import {alertMessages} from '../../partials/alertMessages';
import Error from '../../partials/error';
import {_checkContent} from '../../core/checkContent';
import GalleriesSkeletonScreen from './galleriesSkeletonScreen';
import NoData from '../../partials/noData';


// Data
import db from './../../core/db';
import {getContentCount} from '../../core/getRecordCount';
import {updateContentCounterPreviousCount} from '../../core/updateData';
import {getFavorites, getFavouritesLength} from './../../core/getFavorites';

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button'
import {Plus} from 'react-feather';

class Galleries extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      isRefreshing: false,
      isError: false,

      errorStatus: '',
      errorMessage: '',

      currentPage: 1,
      pageSize: 20,
      index: 0,
      totalItems: 0,

      selectedCategory: 0,
      categories: [],
      token: '',

      data: [],
      favorites: [],

      activeIndex: 0,
    };

    this.onPaginate = this.onPaginate.bind(this);
  }

  /**
   * @function componentDidMount
   * @description Default react method. Fired when component is rendered
   */
  componentDidMount() {
    this.checkSession();
  }

  checkSession = () => {
    if (Object.keys(this.props.user).length === 0) {
      this.props.history.push('/');
    } else {
      this.getCategories();
      this.getContent();
    }
  }

  /**
   * @function setData
   * @description Updates the state of the component upon data retrival
   * @param {object} _data
   * @param {object} _realm
   */
  setData = async (_data) => {
    this.setState(
      {
        // isLoading: false,
        isRefreshing: false,
        isPaginating: false,
        data: _data.rows,
        totalItems: _data.pager.total_items,
      }, () => {
        this.getPostAuthorData();
      }
    );

    const serverCount = await getContentCount('image_gallery')
    
    if (serverCount && serverCount[0]) {
      const count = serverCount[0] ? serverCount[0].serverCount : 0;
      updateContentCounterPreviousCount('image_gallery', count)
    }

    _checkContent();
  };

  /**
   * @function setError
   * @description Updates the state of the component upon an error
   * @param {boolean} _isError
   * @param {int} _errorStatus
   * @param {string} _errorMessage
   */
  setError = (_isError, _errorStatus, _errorMessage) => {
    this.setState({
      isLoading: false,
      isRefreshing: false,
      isPaginating: false,
      isError: _isError,
      errorStatus: _errorStatus,
      errorMessage: _errorMessage,
    });
  };

  favorite = async (_nid) => {
    const {activeIndex} = this.state;

    let gallery = await db.gallery.get(_nid);
    gallery.favorite = 'true';
    db.gallery.put(gallery, _nid);

    if (activeIndex === 0) {
      this.getContent();
    }

    if (activeIndex === 1) {
      this.getFavourites();
    }
  }

  unfavorite = async (_nid) => {
    const {activeIndex} = this.state;
  
    let gallery = await db.gallery.get(_nid);
    gallery.favorite = 'false';
    db.gallery.put(gallery, _nid);

    if (activeIndex === 0) {
      this.getContent();
    }

    if (activeIndex === 1) {
      this.getFavourites();
    }
  }

  dataHandler = async (_data) => {
    let galleries = [];

    for (const data of _data.rows) {
      let existingGallery = await db.gallery.get(data.nid);
      let favorite = 'false';

      if (existingGallery) {
        favorite = existingGallery.favorite;
      }
      
      const gallery = {
        nid: data.nid,
        title: data.title,
        body: data.body,
        created: data.created,
        categoryID: data.field_category,
        categoryLabel: data.field_category_1,
        sticky: 'false',
        favorite: favorite,
        field_featured_image: data.field_featured_image.length > 0 ? data.field_featured_image : 'null',
        name: data.name,
      };

      db.gallery.put(gallery, data.nid);

      galleries.push(gallery);
    }

    let data = _data;

    data.rows = galleries;

    this.setData(data);
  }

  /**
   * @function getContent
   * @description Retrieves the data from an API / Fallback to local realm object if there is no connection
   */
  getContent = () => {
    let path =
      'image_gallery/all/all?status=1&promote=1&' +
      'items_per_page=' +
      this.state.pageSize +
      '&page=' +
      (this.state.currentPage - 1);

    const tid = this.state.selectedCategory;

    if (tid !== 0) {
      path =
        'image_gallery/' +
        tid +
        '/all' +
        '?status=1&promote=1&' +
        'items_per_page=' +
        this.state.pageSize +
        '&page=' +
        (this.state.currentPage - 1);
    }

    getOnlineNode(path, this.props.user.access_token)
      .then((response) => {
        this.dataHandler(response.data);
      })
      .catch((_error) => {
        console.log('error: ', _error);
        if (_error.response) {
          //console.log('@rest response: ', _error.response);
          this.setError(
            true,
            _error.response.status,
            _error.response.statusText,
          );
        } else if (_error.request) {
          //console.log('@rest request: ', _error.request);
          this.setError(true, 0, alertMessages.requestError.message);
        } else {
          //console.log('@rest unknown: ', _error);
          this.setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };

  /**
   * @function getPostAuthorData
   * @description Retrieves the data from an API
   */
  getPostAuthorData = () => {
    let data;
    let authorData = [];

    this.state.data.forEach(item => {
      let path = 'custom_user?_format=json&name=' + item.name;

      getOnlineNode(path, this.props.user.access_token)
        .then((response) => {
          data = response.data.rows[0];
          // console.log('@getPostAuthorData:', data);
          authorData.push({
            uid: data.uid,
            username: data.name,
            field_first_name: data.field_first_name,
            field_last_name: data.field_last_name,
            user_picture: data.user_picture,
          });
          this.setState(
            {
              authorData: authorData
            },
            () => {
              this.setState({isLoading: false});
            },
          );
        })
        .catch((_error) => {
          // error
        });
    });
  };

  /**
   * @function getFavourites
   * @description Retrieves the favourited data from the database
   */
  getFavourites = async () => {
    const favorites = await getFavorites('gallery', this.state.selectedCategory, this.state.index, this.state.pageSize);
    const favoritesLength = await getFavouritesLength('gallery', this.state.selectedCategory);

    this.setState({
      data: favorites,
      totalItems: favoritesLength,
      isLoading: false,
      isRefreshing: false,
      isPaginating: false,
    });
  }

  /**
   * @function getCategories
   * @description Retrives the category data from an API / Fallback to local realm object if there is no connection
   */
  getCategories = () => {
    getOnlineNode(
      'all_categories/image_gallery_',
      this.props.user.access_token,
    )
      .then((response) => {
        this.renderCategories(response.data);
      })
      .catch((_error) => {
        console.log('error: ', _error);
        if (_error.response) {
          this.setError(
            true,
            _error.response.status,
            _error.response.statusText,
          );
        } else if (_error.request) {
          //console.log('@rest request: ', _error.request);
          this.setError(true, 0, alertMessages.requestError.message);
        } else {
          //console.log('@rest unknown: ', _error);
          this.setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };

  /**
   * @function onPaginate
   * @description Pagination callback
   * @param {int} _index - Page number
   */
  onPaginate = (_index) => {
    this.setState(
      {
        isLoading: true,
        isPaginating: true,
        currentPage: _index,
      },
      function() {
        this.getContent();
      },
    );
  };

  /**
   * @function renderCategories
   * @description Updates the state with the retrived categories
   * @param {object} _categories
   */
  renderCategories = (_data) => {
    let categories = [
      {
        value: 0,
        label: 'All Galleries',
      },
    ];

    _data.forEach(item => {
      categories.push({
        value: item.tid,
        label: item.title,
      });
    });

    this.setState({
      categories: categories,
    });
  };

  /**
   * @function handleCategorySelection
   * @description Modal Picker callback / Updates the header title and sets the state to the selected category
   * @param {string} _id - Category ID
   */
  handleCategorySelection = (event) => {
    const {activeIndex} = this.state;
    this.setState(
      {
        // experimental
        isLoading: true,
        isPaginating: true,
        isError: false,
        currentPage: 1,
        selectedCategory: parseInt(event.value, 10),
        selectedCategoryItem: event,
      },
      function () {
        if (activeIndex === 0) {
          this.getContent();
        }

        if (activeIndex === 1) {
          this.getFavourites()
        }
      },
    );
  };
  
  // Pagination component
  renderPagination = () => {
    if (this.state.totalItems <= this.state.pageSize) {
      return null;
    } else {
      let pagination = paginator(
        this.state.totalItems,
        this.state.currentPage,
        this.state.pageSize,
        3,
      );

      return (
        <Pagination
          currentPage={pagination.currentPage}
          endIndex={pagination.endIndex}
          endPage={pagination.endPage}
          pageSize={pagination.pageSize}
          pages={pagination.pages}
          startIndex={pagination.startIndex}
          startPage={pagination.startPage}
          totalItems={pagination.totalItems}
          totalPages={pagination.totalPages}
          paginationCallback={this.onPaginate}
        />
      );
    }
  };

  // Data component
  dataComponent = () => {
    const {data} = this.state;

    if (typeof data !== 'undefined' && data.length > 0) {
      return (
        <Row>
          {data.map(item => {

            let authorObject;

            // Filter author data for author that matches gallery author 
            let filteredAuthor = this.state.authorData.filter(author => author.username === item.name);

            if (filteredAuthor) {
              authorObject = filteredAuthor[0];
            }

            return (
              <GalleriesItem
                key={'galleries-' + item.nid}
                item={item}
                nid={item.nid}
                favorite={this.favorite}
                unfavorite={this.unfavorite}
                author={authorObject}
              />
            );
          })}
        </Row>
      );
    } else {
      return (
        <NoData activeIndex={this.state.activeIndex} />
      );
    }
  };

  /**
   * @function render
   * @description Default render method
   */
  render() {
    if (this.state.isLoading) {
      return <GalleriesSkeletonScreen />;
    } else {
      if (this.state.isError) {
        return (
          <Error 
            status={this.state.errorStatus}
            message={this.state.errorMessage}
          />
        );
      } else {
        return (
          <main className="galleries  screen">
            <PageHeader 
              pageName="Image Galleries" 
              filters={true}
              categorySelector={
                <CategorySelector 
                  name="galleries"
                  categories={this.state.categories}
                  selectedCategory={this.state.selectedCategoryItem}
                  handleCategorySelection={this.handleCategorySelection}
                />
              }
              getContent={() => {
                this.setState(
                  {
                    activeIndex: 0,
                    isLoading: true,
                  },
                  function () {
                    this.getContent();
                  },
                );
              }}
              getFavourites={() => {
                this.setState(
                  {
                    activeIndex: 1,
                    isLoading: true,
                  },
                  function () {
                    this.getFavourites();
                  },
                );
              }}
              activeTabIndex={this.state.activeIndex}
            />
            
            <div className="gallery-actions">
              <Row className="justify-content-end">
                <Col className="col-auto">
                  <Button onClick={() => {
                    this.props.history.push('/add-gallery');
                  }}>
                    <Plus />
                  </Button>
                </Col>
              </Row>
            </div>

            {this.dataComponent()}
            {this.renderPagination()}
          </main>
        );
      }
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default connect(mapStateToProps)(Galleries);
