import React, { Component } from 'react';

// Modules
import ContentLoader from 'react-content-loader';

// App
import SkeletonPageHeader from '../../partials/skeleton-screens/skeletonPageHeader';

// UI components
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";

class NewsSkeletonScreen extends Component {  
  constructor() {
    super();
    this.state = {
      backgroundColor: '#f3f3f3',
      foregroundColor: '#ecebeb',
    };
  }

  renderDefaultItems = (itemClassList) => {

    let items = [];
    let numberOfItems;

    if (this.props.numberOfItems) {
      numberOfItems = this.props.numberOfItems;
    } else {
      numberOfItems = 20;
    }

    for (let i = 0; i < numberOfItems; i++) {

      items.push(
        <Col sm={6} lg={3} key={'item-' + i}>
          <div className={itemClassList}>
            <ContentLoader 
              speed={2}
              width={'100%'}
              height={'100%'}
              viewBox="0 0 263 319"
              backgroundColor={this.state.backgroundColor}
              foregroundColor={this.state.foregroundColor}
            >
              <rect x="232" y="298.367" width="20" height="20" rx="10" ry="10" />
              <rect x="30.037" y="298.367" width="55.042" height="20" rx="10" ry="10" />
              <circle cx="10" cy="308.367" r="10" />
              <rect x="0" y="260.961" width="208.851" height="20" rx="10" ry="10" />
              <rect x="0" y="233.948" width="262.127" height="20" rx="10" ry="10" />
              <rect x="0" y="193.999" width="262.127" height="20" rx="10" ry="10" />
              <rect x="0" y="166.987" width="262.127" height="20" rx="10" ry="10" />
              <rect x="0" y="0" width="262.044" height="147.4" rx="2.5" ry="2.5"/>
            </ContentLoader>
          </div>
        </Col>
      );

    }

    return items;
  };

  render() {
    const itemClass = "news-item";
    const itemClassList = `${itemClass}  item`;

    return (
      <main className="news  screen">
        <SkeletonPageHeader />

        {/* Sticky item */}
        <div className={itemClassList} >
          <ContentLoader 
            speed={2}
            width={'100%'}
            height={'100%'}
            viewBox="0 0 1140 313"
            backgroundColor={this.state.backgroundColor}
            foregroundColor={this.state.foregroundColor}
          >
            <rect x="1120" y="282.354" width="20" height="20" rx="10" ry="10" />
            <rect x="617.3" y="282.354" width="55.042" height="20" rx="10" ry="10" />
            <circle cx="597.263" cy="292.354" r="10" />
            <rect x="587.263" y="177.717" width="375.193" height="20" rx="10" ry="10" />
            <rect x="587.263" y="150.717" width="466.417" height="20" rx="10" ry="10" />
            <rect x="587.263" y="123.705" width="501.555" height="20" rx="10" ry="10" />
            <rect x="587.263" y="50.017" width="351.584" height="38" rx="19" ry="19" />
            <rect x="587.263" y="0" width="552.737" height="38" rx="19" ry="19" />
            <rect x="0" y="0" width="554.173" height="312.354" rx="5" ry="5" />
          </ContentLoader>
        </div>

        {/* All items */}
        <Row>
          {this.renderDefaultItems(itemClassList)}
        </Row>

      </main>
    );
  }

}

export default NewsSkeletonScreen;