// Dependencies
import React from 'react';

// Modules
import {connect} from 'react-redux';
import { Link } from "react-router-dom";
import TextareaAutosize from 'react-textarea-autosize';

// App
import {serverUrl} from '../../config'
import {getOnlineNode} from '../../core/getNode';
import {postNode} from '../../core/postNode';
import {alertMessages} from '../../partials/alertMessages';
import Error from '../../partials/error';
import AlertModal from '../../partials/alertModal';
import {get_xcsrf_token} from '../../core/auth';
import LoadingSpinner from '../../partials/loadingSpinner';
import ForumsSkeletonAddTopicScreen from './skeleton-screens/forumsSkeletonAddTopicScreen';

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import {MessageSquare, Send} from 'react-feather';

class ForumsAddTopic extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      isTopicCreated: false,
      subject: '',
      tid: '',
      body: '',
      modalVisible: false,
      forumBoard: '',
      userData: '',
    };
  }

  componentDidMount() {
    this.getToken();
    this.getTaxonomy();
    this.getUserData();
  }

  getToken = () => {
    get_xcsrf_token().then(_response => {
      this.setState({
        token: _response.data,
      });
    });
  };

  getTaxonomy = () => {
    if (this.props.match.params.tid) {
      this.setState({
        tid: this.props.match.params.tid,
      })
    }
  }

  getUserData = () => {
    const user = this.props.user.current_user.name;
    const path = 'custom_user?_format=json&name=' + user;

    getOnlineNode(path, this.props.user.access_token)
      .then((response) => {
        if (response.data.rows.length > 0) {
          this.setState(
            {
              userData: response.data.rows[0],
            },
            () => {
              this.getForumBoard();
            },
          );
        }
      })
      .catch((_error) => {
        // error
      });
  };

  getForumBoard = () => {
    let path = 'all_categories/forums/' + this.state.tid;

    getOnlineNode(path, this.props.user.access_token)
      .then((response) => {
        this.setState(
          {
            forumBoard: response.data[0],
          },
          () => {
            this.setState({isLoading: false});
          },
        );
      })
      .catch((_error) => {
        if (_error.response) {
          this.setError(
            true,
            _error.response.status,
            _error.response.statusText,
          );
        } else if (_error.request) {
          this.setError(true, 0, alertMessages.requestError.message);
        } else {
          this.setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };

  postTopic = () => {
    const {subject, body, tid} = this.state;

    if (subject.length < 1) {
      this.setState({
        modalVisible: true,
        alertType: 'error',
        alertTitle: alertMessages.subjectEmpty.title,
        alertBody: alertMessages.subjectEmpty.message,
        alertConfirmButton: true,
      });
      return false;
    }

    if (body.length < 1) {
      this.setState({
        modalVisible: true,
        alertType: 'error',
        alertTitle: alertMessages.bodyEmpty.title,
        alertBody: alertMessages.bodyEmpty.message,
        alertConfirmButton: true,
      });
      return false;
    }

    if (tid === '') {
      this.setState({
        modalVisible: true,
        alertType: 'error',
        alertTitle: alertMessages.tidError.title,
        alertBody: alertMessages.tidError.message,
        alertConfirmButton: true,
      });
      return false;
    }

    this.setState({
      isLoading: true,
      isTopicCreated: false,
    });

    const data = {
      _links: {
        type: {
          href: serverUrl + '/rest/type/node/forum',
        },
      },
      type: {
        target_id: 'forum',
      },
      title: {
        value: subject,
      },
      body: {
        value: body,
      },
      taxonomy_forums: [
        {
          target_id: tid,
        },
      ],
    };

    postNode(
      'node',
      data,
      this.state.token,
      this.props.user.access_token,
    )
      .then((response) => {
        this.setState({
          isLoading: false,
          isTopicCreated: true,
          subject: '',
          body: '',
          modalVisible: true,
          alertType: 'success',
          alertTitle: alertMessages.topicPosted.title,
          alertBody: alertMessages.topicPosted.message,
          alertConfirmButton: true,
        });
      })
      .catch((_error) => {
        this.setState({
          modalVisible: true,
          alertType: 'error',
          alertTitle: alertMessages.topicPostedFailure.title,
          alertBody: alertMessages.topicPostedFailure.message,
          alertConfirmButton: true,
          isLoading: false,
          isTopicCreated: false,
        });
      });
  };

  /**
   * Set modal visibility
   * @param {*} visible 
   */
  setModalVisible = (visible) => {
    this.setState({
      modalVisible: visible,
    });
  };

  renderAuthor = () => {
    // username
    let author = this.state.userData.name;

    // first name
    if (this.state.userData.field_first_name) {
      author = this.state.userData.field_first_name;
    }

    // Concat first & last name
    if (
      this.state.userData.field_first_name &&
      this.state.userData.field_last_name
    ) {
      author =
        this.state.userData.field_first_name +
        " " +
        this.state.userData.field_last_name;
    }

    if (!author) {
      author = this.state.userData.name;
    }

    return (
      <Col>
        <p className="user mb-0">
          <span>Posting as:</span>              
          <Link to={"/contacts/" + this.state.userData.uid} className="mb-2">
            {author}
          </Link>
        </p>
      </Col>
    );
  };

  render() {
    if (this.state.isLoading) {
      return <ForumsSkeletonAddTopicScreen />;
    } else {
      if (this.state.isError) {
        return (
          <Error 
            status={this.state.errorStatus}
            message={this.state.errorMessage}
          />
        );
      } else {
          if (this.props.user.current_user) {
            return (
              <main className="add-forum-topic  screen">
                <AlertModal 
                  showAlert={this.state.modalVisible} 
                  showAlertCallback={this.setModalVisible}
                  alertType={this.state.alertType}
                  alertMessageTitle={this.state.alertTitle}
                  alertMessageBody={this.state.alertBody}
                  cancelButton={this.state.alertCancelButton}
                  confirmButton={this.state.alertConfirmButton}
                  onConfirm={() => {
                    if (this.state.isTopicCreated) {
                      this.props.history.push(`/forums/${this.state.tid}/`)
                    }
                  }}
                />
                <header className="add-forum-topic__header">
                  <h3 className="title">Create a new topic</h3>
      
                  <Link to={`/forums/${this.state.forumBoard.tid}`} className="add-topic-board  d-inline-flex align-items-center">
                    <MessageSquare />
                    <h6 className="mb-0  ml-2">{this.state.forumBoard.title}</h6>
                  </Link>
      
                  {this.state.forumBoard.description__value && (
                    <div
                      className="add-topic-board-description"
                      dangerouslySetInnerHTML={{
                        __html: this.state.forumBoard.description__value,
                      }}
                    />
                  )}
                </header>
      
                <section className="topic-form-container">
                  <div className="topic-form">
                    <input
                      className="topic-form__input"
                      placeholder="Topic title..."
                      value={this.state.subject}
                      onChange={(event) => {
                        this.setState({
                          subject: event.target.value,
                        });
                      }}
                    />
                    <TextareaAutosize 
                      className="topic-form__input"
                      onChange={(event) => {
                        this.setState({
                          body: event.target.value,
                        });
                      }}
                      placeholder="Write a description..."
                      value={this.state.body}
                      minRows={4}
                    />
                    <div className="topic-form__footer">
                      <Row className="align-items-center">
                        {this.renderAuthor()}
                        <Col xs={'auto'}>
                          {this.state.isLoading ? (
                            <LoadingSpinner />
                          ) : (
                            <Button
                              className="submit"
                              type="submit"
                              onClick={this.postTopic}>
                              <span className="mr-2">Submit</span>
                              <Send/>
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </div>
                </section>
              </main>
            );
          } else {
            return null;
          }
      }
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default connect(mapStateToProps)(ForumsAddTopic);