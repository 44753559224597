import React, { Component } from 'react';

// Modules
import {decode} from 'html-entities';

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Bookmark, Grid, FileText } from 'react-feather';

class PageHeader extends Component {

  renderFilters = () => {
    
    if (this.props.filters) {
      return (
        <Col
          xs={12}
          xl={this.props.getArchives ? 9 : 6}
          className="mt-4 mt-xl-0"
        >
          <Row
            noGutters
            className="page-header__filters justify-content-xl-end"
          >
            <Col xs={12} sm={6} md={4} xl={this.props.getArchives ? 4 : 5}>
              {this.props.categorySelector}
            </Col>
            <Col
              xs={12}
              sm={"auto"}
              className={
                this.props.getArchives
                  ? "mt-4 mt-md-0 ml-md-auto ml-xl-0 d-flex"
                  : "mt-4 mt-sm-0 ml-sm-auto ml-xl-0 d-flex"
              }
            >
              <button
                className={
                  this.props.activeTabIndex === 0
                    ? "filter-btn active"
                    : "filter-btn"
                }
                onClick={() => {
                  if (this.props.getContent) {
                    this.props.getContent();
                  }
                }}
              >
                <Grid />
                <span>All</span>
              </button>
              <button
                className={
                  this.props.activeTabIndex === 1
                    ? "filter-btn active"
                    : "filter-btn"
                }
                onClick={() => {
                  if (this.props.getFavourites) {
                    this.props.getFavourites();
                  }
                }}
              >
                <Bookmark />
                <span>Favourites</span>
              </button>
              {this.props.getArchives && (
                <button
                  className={
                    this.props.activeTabIndex === 2
                      ? "filter-btn active"
                      : "filter-btn"
                  }
                  onClick={() => {
                    if (this.props.getArchives) {
                      this.props.getArchives();
                    }
                  }}
                >
                  <FileText />
                  <span>Archive</span>
                </button>
              )}
            </Col>
          </Row>
        </Col>
      );
    }
  };

  render() {
    let pageName = this.props.pageName;
    try {
      pageName = decode(pageName)
    } catch (error) {
      // console.log('cannot decode')
    }

    return (
      <div className="page-header">
        <Row className="align-items-center">
          <Col>
            <h1 className="mb-0">{pageName}</h1>
          </Col>
          {this.renderFilters()}
        </Row>
      </div>
    );
  }
}

export default PageHeader;
