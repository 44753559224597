/* eslint-disable array-callback-return */
// Dependencies
import React from 'react';

// Modules
import {connect} from 'react-redux';
import moment from 'moment';

// App
import {serverUrl} from '../../config';
import {getOnlineNode} from '../../core/getNode';
import {postNode, getToken} from '../../core/postNode';
import PostTitle from '../../partials/postTitle';
import {alertMessages} from '../../partials/alertMessages';
import AlertModal from '../../partials/alertModal';
import Error from "../../partials/error";
import SkeletonSingleScreen from '../../partials/skeleton-screens/skeletonSingleScreen';

// Data
import db from './../../core/db';

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import {Clock, Tag, ArrowRight, Info, CheckCircle, CheckSquare, Square } from 'react-feather';

class CoursesSingle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isError: false,
      errorStatus: '',
      errorMessage: '',
      item: null,
      sections: [],
      courseComplete: false,
      signoff: false,
      modalVisible: false,
    };
  }

  componentDidMount() {
    this.checkSession();
  }
  
  checkSession = () => {
    if (Object.keys(this.props.user).length === 0) {
      this.props.history.push('/');
    } else {
      this.getToken();
      this.loadNode();
    }
  }

  getToken = () => {
      getToken()
        .then((_response) => {
          this.setState({
            token: _response.data,
          });
        })
        .catch((_error) => {
          this.setState({
            modalVisible: true,
            alertType: 'error',
            alertTitle: alertMessages.tokenError.title,
            alertBody: alertMessages.tokenError.message,
            alertConfirmButton: true,
          });
        });
  };

  /**
   * Set modal visibility
   * @param {*} visible 
   */
   setModalVisible = (visible) => {
    this.setState({
      modalVisible: visible,
    });
  };

  /**
   * @function setError
   * @description Updates the state of the component upon an error
   * @param {boolean} _isError
   * @param {int} _errorStatus
   * @param {string} _errorMessage
   */
  setError = (_isError, _errorStatus, _errorMessage) => {
    this.setState({
      isLoading: false,
      isRefreshing: false,
      isError: _isError,
      errorStatus: _errorStatus,
      errorMessage: _errorMessage,
    });
  };

  loadNode = () => {
    const nid = this.props.match.params.nid;

    if (nid) {
      this.getContent(nid);
    } else {
      this.setError(true, 0, alertMessages.contentError);
    }
  };

  /**
   * @function getContent
   * @description Retrieves the data from an API / Fallback to local realm object if there is no connection
   */
  getContent = (nid) => {
    let path = 'course/all/' + nid + '?_format=json&status=1&promote=1';

    getOnlineNode(path, this.props.user.access_token)
      .then((response) => {
        if (response.data.rows.length > 0) {
          this.setState(
            {
              item: response.data.rows[0],
            },
            function () {
              this.getSections();
            },
          );
        } else {
          this.setError(true, 200, alertMessages.noData);
        }
      })
      .catch((_error) => {
        if (_error.response) {
          this.setError(
            true,
            _error.response.status,
            _error.response.statusText,
          );
        } else if (_error.request) {
          this.setError(true, 0, alertMessages.requestError.message);
        } else {
          this.setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };

  getSections = () => {
    const {item} = this.state;
    const path = '/course_sections/' + item.nid + '?_format=json';

    getOnlineNode(path, this.props.user.access_token)
      .then((response) => {
        this.asyncMapSections(response.data);
      })
      .catch((_error) => {
        if (_error.response) {
          this.setError(
            true,
            _error.response.status,
            _error.response.statusText,
          );
        } else if (_error.request) {
          this.setError(true, 0, alertMessages.requestError.message);
        } else {
          this.setError(true, 0, alertMessages.unkownError.message);
        }
      });
  }

  async asyncMapSections(_array) {
    const that = this;
    const userId = this.props.user.current_user.uid;

    if (_array && _array.length > 0) {
      const iteration = _array[0];
      let entity_references = _array[0].field_entity_reference.split(',');
      let entities = [];

      async function fetchNodeAsync(_resources) {
        if (_resources && _resources.length > 0 && _resources[0]) {
          const nid = _resources[0].trim() + '';
          const path = '/node/' + nid + '?_format=json';

          const courseProgressData = await db.courseProgress.get(nid);
          const quizSubmissionData = await db.quizSubmissions.get(nid);

          let opened = 'false';
          let complete = 'false';

          getOnlineNode(path, that.props.user.access_token)
            .then((response) => {
              let fileUrl;
              let type;
              let typeId;

              if (response.data.type[0].target_id === 'quiz') {
                fileUrl = response.data.nid[0].value;
                type = 'quiz';

                const _path = 'quiz_submission_check/' + userId + '/' + response.data.nid[0].value;

                getOnlineNode(_path + '?_format=json', that.props.user.access_token)
                .then((_response) => {
                  if (_response.data && _response.data.length >= 1) {
                    opened = 'true';

                    if (courseProgressData) {
                      complete = courseProgressData.complete;
                    }

                  } else {
                    if (quizSubmissionData) {
                      db.quizSubmissions.where('id').equals(this.state.item.nid).delete();  
                    }
                    opened = 'false';
                  }

                  const entity = {
                    nid: response.data.nid[0].value,
                    item: response.data,
                    title: response.data.title[0].value,
                    url: fileUrl,
                    type: type,
                    opened: opened,
                    complete: complete,
                  };

                  entities.push(entity);
                  _resources.shift();
                  fetchNodeAsync(_resources);
                })
                .catch((_error) => {
                  opened = 'false';

                  const entity = {
                    nid: response.data.nid[0].value,
                    item: response.data,
                    title: response.data.title[0].value,
                    url: fileUrl,
                    type: type,
                    opened: opened,
                    complete: complete,
                  };

                  entities.push(entity);
                  _resources.shift();
                  fetchNodeAsync(_resources);
                });

              } else {
                if (courseProgressData) {
                  complete = courseProgressData.complete;
                  opened = courseProgressData.opened;
                }

                if (response.data.type[0].target_id === 'documents') {
                  fileUrl = response.data.field_document[0].url;
                  type = 'document';
                }

                if (response.data.type[0].target_id === 'videos') {
                  fileUrl = response.data.field_video[0].url;
                  type = 'video';
                }

                if (response.data.type[0].target_id === 'webform') {
                  fileUrl = serverUrl + '/node/' + response.data.nid[0].value;
                  type = 'webform';
                  typeId = response.data.webform[0].target_id;
                }

                if (response.data.type[0].target_id === 'external_resource') {
                  fileUrl = response.data.field_url[0].uri;
                  type = 'external_resource';
                }

                const entity = {
                  nid: response.data.nid[0].value,
                  item: response.data,
                  title: response.data.title[0].value,
                  url: fileUrl,
                  type: type,
                  typeId: typeId,
                  opened: opened,
                  complete: complete,
                };

                entities.push(entity);
                _resources.shift();
                fetchNodeAsync(_resources);
              }
            })
            .catch((_error) => {
              _resources.shift();
              fetchNodeAsync(_resources);
            });
        } else {
          const sectionData = {
            title: iteration.field_section_title,
            body: iteration.field_section_body,
            entities: entities,
          };

          that.setState({
            sections: that.state.sections.concat(sectionData),
          });

          proceedToNextIteration();
        }
      }

      function proceedToNextIteration() {
        _array.shift();
        that.asyncMapSections(_array);
      }

      fetchNodeAsync(entity_references);
    } else {
      this.setState({
        submitted: this.props.location.submitted,
        isLoading: false,
      });

      this.checkProgress();
    }
  };

  openFile = async (_entity) => {
    let complete = 'false';
    const nid = _entity.nid + '';

    const courseProgressData = await db.courseProgress.get(nid);

    if (courseProgressData) {
      complete = courseProgressData.complete;
    }

    if (_entity.url) {
      if (_entity.type === 'webform' || _entity.type === 'external_resource') {
        let url = _entity.url;

        if (this.props.user.access_token) {
          url = _entity.url + '?access_token=' + this.props.user.access_token;
        }

        db.courseProgress.put({
          nid: nid,
          opened: 'true',
          complete: complete,
        }, nid);
        
        window.open(url, '_blank');
      } else if (_entity.type === 'document') {
        getOnlineNode(
          'documents/all/' + _entity.item.nid[0].value + '?_format=json',
          this.props.user.access_token,
        )
          .then((response) => {
            if (response.status === 200 && response.data) {
              db.courseProgress.put({
                nid: nid,
                opened: 'true',
                complete: complete,
              }, nid);

              window.open(response.data.rows[0].uri, '_blank');
            }
          })
          .catch((_error) => {
            this.setState({
              modalVisible: true,
              alertType: 'error',
              alertTitle: alertMessages.fetchdata.title,
              alertBody: alertMessages.fetchdata.message,
              alertConfirmButton: true,
            });
          });
      } else {
        let url;

        if (_entity.type === 'video') {
          url = '/videos/' + nid;
        }

        if (_entity.type === 'quiz') {
          url = '/quizzes/' + nid;
        }

        db.courseProgress.put({
          nid: nid,
          opened: 'true',
          complete: complete,
        }, nid);

        // this.props.history.push(url)
        window.open(window.location.origin + url);
      }
    } else {
      this.setState({
        modalVisible: true,
        alertType: 'error',
        alertTitle: alertMessages.courseNoEntityError.title,
        alertBody: alertMessages.courseNoEntityError.message,
        alertConfirmButton: true,
      });
    }
  }

  async fileComplete (_entity) {
    let didComplete;
    let opened;

    const nid = _entity.nid + '';

    if (_entity.type === 'quiz') {
      const uid = this.props.user.current_user.uid;
      const path = 'quiz_submission_check/' + uid + '/' + _entity.item.nid[0].value;

      const quizSubmission = await db.quizSubmissions.get(nid);

      getOnlineNode(
        path + '?_format=json',
        this.props.user.access_token,
      )
        .then((response) => {
          if (response.data && response.data.length > 0) {
            opened = 'true';
          } else {
            if (quizSubmission && quizSubmission.length > 0) {
              db.quizSubmissions.where('id').equals(this.state.item.nid).delete();  
            }

            opened = 'false';
          }

          this.fileCompletePromise(_entity, opened, didComplete);
        })
        .catch((_error) => {
          this.fileCompletePromise(_entity, false, didComplete);
        });
    } else if (_entity.type === 'webform') {
      const uid = this.props.user.current_user.uid;
      const path = 'webform_submission_check/' + uid + '/' + _entity.typeId;

      getOnlineNode(
        path + '?_format=json',
        this.props.user.access_token,
      )
        .then((response) => {
          if (response.data && response.data.length > 0) {
            opened = 'true';
          } else {
            opened = 'false';
          }

          this.fileCompletePromise(_entity, opened, didComplete);
        })
        .catch((_error) => {
          this.fileCompletePromise(_entity, 'false', didComplete);
        });
    } else {
      const courseProgres = await db.courseProgress.get(nid);

      if (courseProgres && courseProgres.opened === 'true') {
        opened = 'true';
      } else {
        opened = 'false';
      }

      this.fileCompletePromise(_entity, opened, didComplete);
    }
  }

  async fileCompletePromise(_entity, opened, didComplete) {
    let updatedSections = [];
    const nid = _entity.nid + '';
    const courseProgresData = await db.courseProgress.get(nid);

    if (opened === 'false') {
      if (_entity.type === 'quiz') {
        this.setState({
          modalVisible: true,
          alertType: 'error',
          alertTitle: alertMessages.courseQuizSubmission.title,
          alertBody: alertMessages.courseQuizSubmission.message,
          alertConfirmButton: true,
        });

      } else if (_entity.type === 'webform') {
        this.setState({
          modalVisible: true,
          alertType: 'error',
          alertTitle: alertMessages.courseFormSubmission.title,
          alertBody: alertMessages.courseFormSubmission.message,
          alertConfirmButton: true,
        });
      } else {
        this.setState({
          modalVisible: true,
          alertType: 'error',
          alertTitle: alertMessages.courseViewItemFirst.title,
          alertBody: alertMessages.courseViewItemFirst.message,
          alertConfirmButton: true,
        });
      }

      return false;
    }

    if (courseProgresData) {
      didComplete = courseProgresData.complete;
    } else {
      didComplete = 'false';
    }

    if (didComplete === 'true') {
      didComplete = 'false';
    } else {
      didComplete = 'true';
    }

    db.courseProgress.put({
      nid: nid,
      opened: opened,
      complete: didComplete,
    }, nid);

    this.state.sections.map((section) => {
      let updatedEntity = [];

      section.entities.map((entity) => {
        let didOpen;
        const entity_nid = entity.nid + '';

        if (nid === entity_nid) {
          didOpen = didComplete;
        } else {
          didOpen = entity.complete;
        }

        let entityData = entity;
        entityData.complete = didOpen;

        updatedEntity.push(entityData);
      });

      const sectionData = {
        title: section.title,
        body: section.body,
        entities: updatedEntity,
      };

      updatedSections.push(sectionData);
    });

    this.setState({
      sections: updatedSections,
    });

    this.checkProgress();
  }

  signoffConfirmation = () => {
    if (!this.state.courseComplete) {
      this.setState({
        modalVisible: true,
        alertType: 'error',
        alertTitle: alertMessages.courseIncomplete.title,
        alertBody: alertMessages.courseIncomplete.message,
        alertConfirmButton: true,
      });
      return false;
    }

    this.setState({
      signoff: this.state.signoff ? false : true,
    });
  };

  checkSubmission = (shouldSave) => {
    const uid = this.props.user.current_user.uid;
    const path = 'course_submission_check/' + uid + '/' + this.state.item.nid;

    /**
     * 
     * shouldSave
     * 
     * Needed to check if course is submitted when hitting the screen directly / on refresh tab/window
     */
    if (shouldSave === false) {
      getOnlineNode(path, this.props.user.access_token)
      .then((response) => {
        if (response.data && response.data.length >= 1) {
          this.setState({
            submitted: true,
          });
        }
      })
      .catch((_error) => {
        // cannot check
      });
    } else {
      getOnlineNode(path, this.props.user.access_token)
        .then((response) => {
          if (response.data && response.data.length >= 1) {
            this.setState({
              modalVisible: true,
              alertType: 'error',
              alertTitle: alertMessages.courseSubmittedAnotherDevice.title,
              alertBody: alertMessages.courseSubmittedAnotherDevice.message,
              alertConfirmButton: true,
              submitted: true,
            });
          } else {
            this.saveNode();
          }
        })
        .catch((_error) => {
          // cannot check
          this.setState({
            modalVisible: true,
            alertType: 'error',
            alertTitle: alertMessages.nodeEndpointFetch.title,
            alertBody: alertMessages.nodeEndpointFetch.message,
            alertConfirmButton: true,
          });
        });
    }
  };

  checkProgress = async () => {
    const data = this.state.sections;

    let IDS = [];
    let completedIDS = [];

    data.map(item => {
      item.entities.map(entity => {
        IDS.push(entity.nid);
      });
    });

    const completedItems = IDS.map(async id => {
      const courseProgresData = await db.courseProgress.get('' + id);
      
      if (courseProgresData && courseProgresData.complete === 'true') {
        completedIDS.push(id)
      }
    });

    const allItems = await Promise.all(completedItems);

    if (completedIDS.length >= allItems.length) {
      this.setState({
        courseComplete: true,
      });
    } else {
      this.setState({
        courseComplete: false,
      });
    }

    this.checkSubmission(false)
  };

  saveNode = () => {
    let entities = [];

    this.state.sections.map((section) => {
      section.entities.map((_entity) => {
        const nid = _entity.nid + '';
        const entity = {
          target_id: nid,
        };

        entities.push(entity);
      });
    });

    const data = {
      _links: {
        type: {
          href: serverUrl + '/rest/type/node/course_submission',
        },
      },
      type: {
        target_id: 'course_submission',
      },
      title: {
        value: this.state.item.title + ' Submission',
      },
      field_entity_reference: entities,
      field_course: {
        0: {
          target_id: this.props.match.params.nid,
        },
      },
    };

    postNode(
      'node',
      data,
      this.state.token,
      this.props.user.access_token,
    )
      .then((response) => {
        if (response.status === 201) {
          this.setState({
            modalVisible: true,
            alertType: 'success',
            alertTitle: alertMessages.courseSubmitted.title,
            alertBody: alertMessages.courseSubmitted.message,
            alertConfirmButton: true,
            submitted: true,
            isLoading: false,
          });
        } else {
          this.setState({
            submitted: false,
            isLoading: false,
          });
        }
      })
      .catch((_error) => {
        this.setState({
          modalVisible: true,
          alertType: 'error',
          alertTitle: alertMessages.courseSubmissionFailed.title,
          alertBody: alertMessages.courseSubmissionFailed.message,
          alertConfirmButton: true,
          submitted: false,
          isLoading: false,
        });
      });

  };

  renderPostImage = (post) => {
    let hasImage = post.field_featured_image.length > 0 ? true : false;

    if (hasImage && !this.state.isLandscape) {
      return (
        <div className="article__image">
          <img src={post.field_featured_image} alt={'img-' + post.nid} />
        </div>
      );
    }
  };

  renderComponent = () => {
    if (this.state.submitted) {
      return this.renderSubmittedView();
    } else {
      return this.renderNormalView();
    }
  };

  renderSubmission = () => {
    const post = this.state.item;
    const signTitle = post.field_signature_title
      ? post.field_signature_title
      : alertMessages.courseSignOff;
    const promptText = post.field_confirmation_prompt
      ? post.field_confirmation_prompt
      : alertMessages.courseSignOffPrompt;
    const confirmationText = post.field_confirmation_text
      ? post.field_confirmation_text
      : alertMessages.courseSignOffConfirmation;

    return (
      <div className="signoff__wrapper">
        <div className="signoff__block__title__wrapper">
          <h5 className="signoff__block__title" dangerouslySetInnerHTML={{__html: signTitle}} />
        </div>

        <div className="signoff__block__body__wrapper">
          <p className="signoff__block__body" dangerouslySetInnerHTML={{__html: promptText}} />
        </div>

        <div className="signoff__block__unit">
          <Row noGutters className="align-items-start">
            <Col xs={'auto'} className="pr-2">
              <Button onClick={() => this.signoffConfirmation()} className="checkbox">
                {this.state.signoff ? (
                  <CheckSquare className="selected__icon"/>
                ) : (
                  <Square className="unselected__icon"/>
                )}
              </Button>
            </Col>
            <Col>
              <label className="signoff__unit__title" dangerouslySetInnerHTML={{__html: confirmationText}} htmlFor="sign-off-confirmation" />
            </Col>
          </Row>
        </div>
      
        <div className="signoff__button__wrapper">
          <Button
            disabled={this.state.signoff ? false : true}
            onClick={() => this.checkSubmission(true)}>
              <span className="signoff__button__text">Submit</span>
              <ArrowRight className="signoff__arrow__icon" />
          </Button>
        </div>
      </div>
    )
  }

  renderNormalView = () => {
    const post = this.state.item;
    const hideSubmission = post.field_hide_submission === 'true' ? true : false;
    const timestamp = moment.unix(post.created).format("Do MMM YYYY");
    
    let category = 'Uncategorised';

    if (post.field_category_1) {
      category = post.field_category_1;
    }

    if (post.categoryLabel) {
      category = post.categoryLabel;
    }

    return (
      <article className="article">
        <header className="article__header">
          <PostTitle title={post.title} headingLevel={'h1'} />
          <div className="article__meta">
            <Row>
              <Col xs={"auto"}>
                <div className="timestamp align-items-center">
                  <Clock className="timestamp__icon" />
                  <span className="timestamp__label  label">{timestamp}</span> 
                </div>
              </Col>
              <Col xs={"auto"}>
                <div className="category align-items-center">
                  <Tag className="category__icon" />
                  <span className="category__label  label">{category}</span>
                </div>
              </Col>
            </Row>
          </div>
        </header>
        {this.renderPostImage(post)}
        <div className="article__body">
          <div dangerouslySetInnerHTML={{__html: post.body}} />
        </div>
        <div className="section__container">
          {this.state.sections.map((section, _index) => {
            return (
              <div key={'section-' + _index} className="section__wrapper">
                {!!section.title && (
                  <div className="section__title__wrapper">
                    <h5 className="section__title">{section.title}</h5>
                  </div>
                )}
                {section.body ? (
                  <div className="section__body__wrapper">
                    <div dangerouslySetInnerHTML={{__html: section.body}} className="section__body" />
                  </div>
                ) : (
                  <div className="section__body__wrapper" />
                )}
                {section.entities.map((_entity, __index) => {
                  let iconStyle;
                  if (_entity.complete === 'true') {
                    iconStyle = 'selected__icon';
                  } else {
                    iconStyle = 'unselected__icon';
                  }
                  
                  return (
                    <div key={_entity.nid + '-' + __index} className="section__entity__unit">
                      <Row noGutters className="align-items-start">
                        <Col xs={'auto'} className="pr-2">
                          <Button onClick={() => {
                            this.fileComplete(_entity, _entity.complete)
                          }} className="checkbox">
                            {_entity.complete === 'true' ? (
                              <CheckSquare className={iconStyle} />
                            ) : (
                              <Square className={iconStyle} />
                            )}
                          </Button>
                        </Col>
                        <Col>
                          <div className="section__entity__title__wrapper">
                            <Button onClick={() => {
                              this.openFile(_entity)
                            }}>
                              <span className="section__entity__title">{_entity.title}</span>
                              <ArrowRight className="arrow__icon" />
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  )
                })}
              </div>
            )
          })}
          {!hideSubmission ? (
            this.renderSubmission()
          ) : (
            <div className="submission__saved">
              <Info className="info__icon" />
              <p className="submission__info">{'This course is for infomation only and does not require a submission'}</p>
            </div>
          )}
        </div>
      </article>
    );
  }

  renderSubmittedView = () => {
    const post = this.state.item;
    const timestamp = moment.unix(post.created).format("Do MMM YYYY");
    
    let category = 'Uncategorised';

    if (post.field_category_1) {
      category = post.field_category_1;
    }

    if (post.categoryLabel) {
      category = post.categoryLabel;
    }

    return (
      <article className="article">
        <header className="article__header">
          <PostTitle title={post.title} headingLevel={'h1'} />
          <div className="article__meta">
            <Row>
              <Col xs={"auto"}>
                <div className="timestamp align-items-center">
                  <Clock className="timestamp__icon" />
                  <span className="timestamp__label  label">{timestamp}</span> 
                </div>

              </Col>
              <Col xs={"auto"}>
                <div className="category align-items-center">
                  <Tag className="category__icon" />
                  <span className="category__label  label">{category}</span>
                </div>
              </Col>
            </Row>
          </div>
        </header>
        {this.renderPostImage(post)}
        <div className="article__body">
          <div dangerouslySetInnerHTML={{__html: post.body}} />
        </div>
        <div className="submitted__container alert alert-success" role="alert">
          <CheckCircle/>
          <span dangerouslySetInnerHTML={{__html: alertMessages.courseSubmissionSavedText}} className="ml-2"/>
        </div>
        <div className="section__container">
          {this.state.sections.map((section, _index) => {
            return (
              <div key={'section-' + _index} className="section__wrapper">
                {!!section.title && (
                  <div className="section__title__wrapper">
                    <h5 className="section__title">{section.title}</h5>
                  </div>
                )}
                {section.body ? (
                  <div className="section__body__wrapper">
                    <div dangerouslySetInnerHTML={{__html: section.body}} className="section__body" />
                  </div>
                ) : (
                  <div className="section__body__wrapper" />
                )}
                {section.entities.map((_entity, __index) => {
                  return (
                    <div key={_entity.nid + '-' + __index} className="section__entity__unit">
                      <Row noGutters className="align-items-start">
                        <Col xs={'auto'} className="pr-2">
                          <div className="section__entity__checkbox">
                            <Button disabled={true} className="checkbox">
                              <CheckSquare className="selected__icon" />
                            </Button>
                          </div>
                        </Col>
                        <Col>
                          <div className="section__entity__title__wrapper">
                            <Button onClick={() => {
                              this.openFile(_entity)
                            }}>
                              <span className="section__entity__title">{_entity.title}</span>
                              <ArrowRight className="arrow__icon" />
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
      </article>
    );
  }

  render() {
    if (this.state.isLoading) {
      return <SkeletonSingleScreen />;
    } else {
      if (this.state.isError) {
        return (
          <Error 
            status={this.state.errorStatus}
            message={this.state.errorMessage}
          />
        );
      } else {
        return (
          <main className="course single">
            <AlertModal 
              showAlert={this.state.modalVisible} 
              showAlertCallback={this.setModalVisible}
              alertType={this.state.alertType}
              alertMessageTitle={this.state.alertTitle}
              alertMessageBody={this.state.alertBody}
              cancelButton={this.state.alertCancelButton}
              confirmButton={this.state.alertConfirmButton}
            />
            {this.renderComponent()}
          </main>
        )
      }
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default connect(mapStateToProps)(CoursesSingle);
