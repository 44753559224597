import React, { Component } from 'react';
import {
  Switch,
  Route,
} from "react-router-dom";
import {withRouter, Redirect} from 'react-router'
import {connect} from 'react-redux';

// Components
import Home from '../screens/home/home';
import PublicHome from '../screens/home/publicHome';
import News from '../screens/news/news';
import NewsSingle from '../screens/news/newsSingle';
import Events from '../screens/events/events';
import EventsSingle from '../screens/events/eventsSingle';
import Courses from '../screens/courses/courses';
import CoursesSingle from '../screens/courses/coursesSingle';
import Quizzes from '../screens/quizzes/quizzes';
import QuizzesSingle from '../screens/quizzes/quizzesSingle';
import Documents from '../screens/documents/documents';
import DocumentSingle from '../screens/documents/documentSingle';
import Videos from '../screens/videos/videos';
import VideosSingle from '../screens/videos/videosSingle';
import Galleries from '../screens/galleries/galleries';
import GalleriesSingle from '../screens/galleries/galleriesSingle';
import AddGallery from '../screens/galleries/addGallery';
import EditGallery from '../screens/galleries/editGallery';
import Contacts from '../screens/contacts/contacts';
import ContactsSingle from '../screens/contacts/contactsSingle';
import CompanyDirectory from '../screens/company_directory/companyDirectory';
import CompanyDirectorySingle from '../screens/company_directory/companyDirectorySingle';
import Login from '../screens/auth/auth';
import Register from '../screens/auth/register';
import Account from '../screens/account/account';
import Profile from '../screens/account/profile';
import Search from '../screens/search/search';
import Blog from '../screens/blog/blog';
import BlogSingle from '../screens/blog/blogSingle';
import Noticeboard from '../screens/noticeboard/noticeboard';
import NoticeboardSingle from '../screens/noticeboard/noticeboardSingle';
import AddNoticeboard from '../screens/noticeboard/addNoticeboard';
import Forms from '../screens/forms/forms';
import FormsSingle from '../screens/forms/formsSingle';
import Polls from '../screens/polls/polls';
import PollsSingle from '../screens/polls/pollsSingle';
import Links from '../screens/links/links';
import Presentations from '../screens/presentations/presentations';
import Forums from '../screens/forums/forums';
import ForumsTopics from '../screens/forums/forumsTopics';
import ForumsAddTopic from '../screens/forums/addTopic';
import ForumsTopicComments from '../screens/forums/forumsTopicComments';
import Podcasts from '../screens/podcasts/podcasts';
import PodcastsSingle from '../screens/podcasts/podcastsSingle';
import Messages from '../screens/account/messages';
import Conversation from '../screens/account/conversation';
import ResetPassword from '../screens/auth/resetPassword';
import Notices from '../screens/notices/notices';
import NoticesSingle from '../screens/notices/noticesSingle';
import AddNotice from '../screens/notices/addNotice';
import EditNotice from '../screens/notices/editNotice';
import Groups from '../screens/notices/groups';
import Submissions from '../screens/submissions/submissions';


class Routes extends Component {
  render() {
    if (this.props.user.current_user) {
      return(
        /**
         * A <Switch> looks through its children <Route>s and
         * renders the first one that matches the current URL.
         */
        <Switch>
          <Route exact path="/" component={Home} />

          <Route exact path="/news" component={News} />
          <Route path="/news/:nid" component={NewsSingle} />

          <Route exact path="/events" component={Events} />
          <Route path="/events/:nid" component={EventsSingle} />

          <Route exact path="/courses" component={Courses} />
          <Route path="/courses/:nid" component={CoursesSingle} />

          <Route exact path="/quizzes" component={Quizzes} />
          <Route path="/quizzes/:nid" component={QuizzesSingle} />

          <Route exact path="/polls" component={Polls} />
          <Route path="/polls/:nid" component={PollsSingle} />

          <Route exact path="/forms" component={Forms} />
          <Route path="/forms/:nid" component={FormsSingle} />

          <Route exact path="/documents" component={Documents} />
          <Route path="/documents/:nid" component={DocumentSingle} />

          <Route exact path="/videos" component={Videos} />
          <Route path="/videos/:nid" component={VideosSingle} />

          <Route exact path="/galleries" component={Galleries} />
          <Route exact path="/galleries/:nid" component={GalleriesSingle} />
          <Route path="/add-gallery" component={AddGallery} /> 
          <Route path="/galleries/:nid/edit" component={EditGallery} />

          <Route exact path="/company-directory" component={CompanyDirectory} />
          <Route path="/company-directory/:nid" component={CompanyDirectorySingle} />

          <Route exact path="/contacts" component={Contacts} />
          <Route path="/contacts/:uid" component={ContactsSingle} />

          <Route exact path="/links" component={Links} />

          <Route exact path="/noticeboard" component={Noticeboard} />
          <Route path="/noticeboard/:nid" component={NoticeboardSingle} />
          <Route path="/add-noticeboard" component={AddNoticeboard} />

          <Route exact path="/blog" component={Blog} />
          <Route path="/blog/:id" component={BlogSingle} />

          <Route exact path="/login" component={Login} />

          <Route exact path="/account" component={Account} />
          <Route exact path="/account/edit-profile" component={Profile} />
          <Route exact path="/account/messages" component={Messages} />
          <Route exact path="/account/messages/:uid" component={Conversation} />
          <Route exact path="/account/notices" component={Notices} />
          <Route path="/account/notices/:nid" component={NoticesSingle} />
          <Route path="/account/add-notice" component={AddNotice} />
          <Route path="/account-edit-notice/:nid" component={EditNotice} />
          <Route path="/account/groups" component={Groups} />

          <Route exact path="/search" component={Search} />
          <Route exact path='/presentations' component={Presentations} />

          <Route exact path="/forums" component={Forums} />
          <Route exact path="/forums/:tid" component={ForumsTopics} />
          <Route exact path="/forums/:tid/create" component={ForumsAddTopic} />
          <Route exact path="/forums/:tid/:nid" component={ForumsTopicComments} />

          <Route exact path="/podcasts" component={Podcasts} />
          <Route path="/podcasts/:nid" component={PodcastsSingle} />

          <Route exact path="/submissions" component={Submissions} />

          {/* 404 */}
          <Redirect to="/" />
        </Switch>
      )
    } else {
      return (
        <Switch>
          <Route exact path="/" component={PublicHome} />
          <Route exact path="/news" component={News} />
          <Route path="/news/:nid" component={NewsSingle} />
          <Route exact path="/blog" component={Blog} />
          <Route path="/blog/:id" component={BlogSingle} />
          <Route exact path="/videos" component={Videos} />
          <Route path="/videos/:nid" component={VideosSingle} />
          <Route exact path='/presentations' component={Presentations} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/reset-pass" component={ResetPassword} />
          {/* 404 */}
          <Redirect to="/" />
        </Switch>
      )
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default withRouter(connect(mapStateToProps)(Routes));
