import React from 'react';

// Modules
import {connect} from 'react-redux';
import {withRouter} from 'react-router';

// App
import {serverUrl} from '../../config';
import {getOnlineNode} from '../../core/getNode';
import {postNode, getToken} from '../../core/postNode';
import {postBase64} from '../../core/postFile';
import {alertMessages} from '../../partials/alertMessages';
import PageHeader from '../../partials/pageHeader';
import CategorySelector from '../../partials/categorySelector';
import AlertModal from '../../partials/alertModal';
import Crop from '../../partials/cropper/cropper';
import NoticesEditSkeletonScreen from '../notices/skeleton-screens/noticesEditSkeletonScreen';

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner'
import {Plus, ArrowRight} from 'react-feather';


class AddGallery extends React.Component {
  constructor() {
    super();
    this.state = {
      isComponentLoading: true,
      isLoading: false,
      title: '',
      body: '',
      token: null,
      isConnected: true,
      featured_image: null,
      featured_image_cropped: null,
      images: [],
      sentImageIDs: [],
      modalVisible: false,
      postModalVisible: false,

      categories: [{tid: 0, title: 'Uncategorised'}],
      selectedCategory: 0,
    };
  }

  componentDidMount() {
    this.getCategories();
    this.getToken();
  }

  getToken = () => {
    getToken().then(_response => {
      this.setState({
        token: _response.data,
      });
    });
  };

  getCategories = () => {
    getOnlineNode('all_categories/image_gallery_')
      .then(response => {
        let categories = [{tid: 0, title: 'Uncategorised'}];

        response.data.forEach(item => {
          categories.push(item);
        });

        this.setState({
          isComponentLoading: false,
          categories: categories,
        });
      })
      .catch(_error => {
        this.setState({
          isComponentLoading: false,
        });
      });
  };

  setTitle = _title => {
    this.setState({
      title: _title,
    });
  };

  setBody = _body => {
    this.setState({
      body: _body,
    });
  };

  setAlt = (event, index) => {
    const newImages = this.state.images.map((image, item) => {
      if (index !== item) {
        return image;
      }

      return {
        ...image,
        alt: event.target.value,
      };
    });

    this.setState({
      images: newImages,
    });
  };

  uploadGalleryImages = () => {
    const {title, body} = this.state;
    let fileUploadErrors = 0;

    if (title.length < 5) {
      this.setState({
        modalVisible: true,
        alertType: 'error',
        alertTitle: alertMessages.galleryTitle.title,
        alertBody: alertMessages.galleryTitle.message,
        alertConfirmButton: true,
      });

      this.setState({
        isLoading: false,
      });

      return false;
    }

    if (body.length < 5) {
      this.setState({
        modalVisible: true,
        alertType: 'error',
        alertTitle: alertMessages.galleryMessage.title,
        alertBody: alertMessages.galleryMessage.message,
        alertConfirmButton: true,
      });

      this.setState({
        isLoading: false,
      });

      return false;
    }

    this.setState({
      isLoading: true,
    });

    let imagesSent = 0;

    let images = this.state.images.filter(function (image) {
      if (!image.cropped_image) {
        return false; // skip
      }
      return true;
    });

    if (images && images.length > 0) {
      images.forEach((image, index) => {
        const imageData = {
          _links: {
            type: {
              href: `${serverUrl}/rest/type/file/image`
            }
          },
          filename: [
            {
              value: `gallery_cropped-${Date.now()}.png`
            }
          ],
          filemime: {
            value: 'image/png'
          },
          uri: [
            {
              value: `public://gallery_cropped-${Date.now()}.png`
            }
          ],
          type: {
            target_id: 'image'
          },
          data: [
            {
              value: image.cropped_image
            }
          ],
          uid: [
            {
              target_id: this.props.user.current_user.uid ? this.props.user.current_user.uid : 1
            },
          ]
        };

        postBase64(
          imageData,
          serverUrl +
            '/entity/file?_format=hal_json',
          this.state.token,
          // this.props.user.access_token,
        )
          .then(response => {
            const fid = response.data.fid[0].value;

            this.setState({
              sentImageIDs: this.state.sentImageIDs.concat([fid]),
            });

            imagesSent++;

            if (imagesSent === this.state.images.length) {
              this.imagesSaved();
            }
          })
          .catch(error => {
            // console.log('@postBase64: ', error.request);

            fileUploadErrors = fileUploadErrors + (index + 1);

            imagesSent++;

            if (imagesSent === images.length) {
              this.imagesSaved();
            }
          });
      });
    } else {
      this.saveNode();
    }

    if (fileUploadErrors > 0) {
      this.setState({
        modalVisible: true,
        alertType: 'error',
        alertTitle: 'Error with images',
        alertBody: 'Failed to upload some of the images. Please try uploading them again later.',
        alertConfirmButton: true,
        redirectBack: false,
      });
    }
  };

  imagesSaved = () => {
    let images = [];

    this.state.sentImageIDs.forEach((fid, index) => {
      const image = {
        target_id: fid,
        alt: this.state.images[index].alt,
      };

      images.push(image);
    });

    this.saveNode(images);
  };

  saveNode = submittedImages => {
    const {title, body, selectedCategory, featured_image_cropped} = this.state;

    this.setState({
      isLoading: true,
    });

    let category;
    if (selectedCategory && selectedCategory !== 0) {
      category = selectedCategory;
    }

    if (featured_image_cropped) {
      const imageData = {
        _links: {
          type: {
            href: `${serverUrl}/rest/type/file/image`
          }
        },
        filename: [
          {
            value: `gallery_cropped-${Date.now()}.png`
          }
        ],
        filemime: {
          value: 'image/png'
        },
        uri: [
          {
            value: `public://gallery_cropped-${Date.now()}.png`
          }
        ],
        type: {
          target_id: 'image'
        },
        data: [
          {
            value: featured_image_cropped
          }
        ],
        uid: [
          {
            target_id: this.props.user.current_user.uid ? this.props.user.current_user.uid : 1
          },
        ]
      };

      postBase64(
        imageData,
        serverUrl +
          '/entity/file?_format=hal_json',
        this.state.token,
        // this.props.user.access_token,
      )
        .then(response => {
          const fid = response.data.fid[0].value;

          const data = {
            _links: {
              type: {
                href: serverUrl + '/rest/type/node/image_gallery',
              },
            },
            type: {
              target_id: 'image_gallery',
            },
            title: {
              value: title,
            },
            body: {
              value: body,
            },
            field_featured_image: [
              {
                target_id: fid,
              },
            ],
            field_gallery_image: submittedImages ? submittedImages : [],
            field_category: [
              {
                target_id: category.value,
              },
            ],
          };

          postNode(
            'node',
            data,
            this.state.token,
            this.props.user.access_token,
          )
            .then(_response => {
              if (_response.status === 201) {
                this.setState({
                  isLoading: false,
                  postModalVisible: true,
                  alertTitle: alertMessages.gallerySubmission.title,
                  alertBody: alertMessages.gallerySubmission.message,
                });
              } else {
                this.setState({
                  isLoading: false,
                  modalVisible: true,
                  alertType: 'error',
                  alertTitle: alertMessages.postNodeFailed.title,
                  alertBody: alertMessages.postNodeFailed.message,
                  alertConfirmButton: true,
                });
              }
            })
            .catch(_error => {
              console.log('post node error: ', _error);

              this.setState({
                isLoading: false,
                modalVisible: true,
                alertType: 'error',
                alertTitle: alertMessages.postNodeFailed.title,
                alertBody: alertMessages.postNodeFailed.message,
                alertConfirmButton: true,
                redirectBack: false,
              });
            });
        })
        .catch(_error => {
          console.log('post node featured image error: ', _error);

          this.setState({
            isLoading: false,
            modalVisible: true,
            alertType: 'error',
            alertTitle: alertMessages.postNodeFailed.title,
            alertBody: alertMessages.postNodeFailed.message,
            alertConfirmButton: true,
            redirectBack: false,
          });
        });
    } else {
      const data = {
        _links: {
          type: {
            href: serverUrl + '/rest/type/node/image_gallery',
          },
        },
        type: {
          target_id: 'image_gallery',
        },
        title: {
          value: title,
        },
        body: {
          value: body,
        },
        field_gallery_image: submittedImages ? submittedImages : [],
        field_category: [
          {
            target_id: category.value,
          },
        ],
      };

      postNode(
        'node',
        data,
        this.state.token,
        this.props.user.access_token,
      )
        .then(_response => {
          if (_response.status === 201) {
            this.setState({
              isLoading: false,
              postModalVisible: true,
              alertTitle: alertMessages.gallerySubmission.title,
              alertBody: alertMessages.gallerySubmission.message,
            });
          } else {
            this.setState({
              isLoading: false,
              modalVisible: true,
              alertType: 'error',
              alertTitle: alertMessages.postNodeFailed.title,
              alertBody: alertMessages.postNodeFailed.message,
              alertConfirmButton: true,
              redirectBack: false,
            });
          }
        })
        .catch(_error => {
          this.setState({
            isLoading: false,
            modalVisible: true,
            alertType: 'error',
            alertTitle: alertMessages.postNodeFailed.title,
            alertBody: alertMessages.postNodeFailed.message,
            alertConfirmButton: true,
            redirectBack: false,
          });
        });
    }
  };

  /**
   * @function handleCategorySelection
   * @description Modal Picker callback / Updates the header title and sets the state to the selected category
   * @param {string} _id - Category ID
   */
  handleCategorySelection = _id => {
    this.setState(
      {
        selectedCategory: _id,
      },
      function () {
        this.setModalVisible(false);
      },
    );
  };

  setModalVisible = _visible => {
    this.setState({
      modalVisible: _visible,
    });
  };

  getCategoryTitle = () => {
    return this.state.categories.find(
      x => x.tid === this.state.selectedCategory,
    ).title;
  };

  addImage = () => {
    const {images} = this.state;
    const lastImage = images[images.length - 1];

    this.setState({
      images: this.state.images.concat([
        {image: '', alt: '', index: lastImage.index + 1},
      ]),
    });
  };

  removeImage = _index => {
    const {images} = this.state;

    if (images.length === 1) {
      return false;
    }

    const newImages = images;

    const index = newImages[_index];

    if (index !== -1) {
      newImages.splice(_index, 1);
      this.setState({
        images: newImages,
      });
    }
  };

  errorAlerts = () => {
    return (
      <AlertModal 
        showAlert={this.state.modalVisible} 
        showAlertCallback={this.setModalVisible}
        alertType={this.state.alertType}
        alertMessageTitle={this.state.alertTitle}
        alertMessageBody={this.state.alertBody}
        cancelButton={this.state.alertCancelButton}
        confirmButton={this.state.alertConfirmButton}
        cancelButtonLabel={this.state.cancelButtonLabel}
        confirmButtonLabel={this.state.confirmButtonLabel}
      />
    )
  }

  saveAlerts = () => {
    return (
      <AlertModal 
        showAlert={this.state.postModalVisible} 
        showAlertCallback={() => {
          this.setState({
            postModalVisible: !this.state.postModalVisible,
          });
        }}
        alertType={'success'}
        alertMessageTitle={this.state.alertTitle}
        alertMessageBody={this.state.alertBody}
        cancelButton={false}
        confirmButton={true}
        confirmButtonLabel={'OK'}
        onConfirm={() => {
          this.props.history.push('/galleries');
        }}
      />
    );
  };

  removeImage = _index => {
    const {images} = this.state;

    let newImages = [];

    images.forEach((image, index) => {
      if (_index !== index) {
        newImages.push(image);
      }
    });

    this.setState({
      images: newImages,
    });
  };

  renderSelectedImages = () => {
    const {images} = this.state;

    if (images.length > 0) {
      return (
        images.map((image, index) => {
          return (
            <Col key={`gallery-image-${index}`} xs={12} className="mb-3">
              <div className="cropper-wrapper gallery-images-cropper">
                <Row>
                  <Col>
                    {image && (
                      <Crop
                        file={image.file}
                        ratio={1}
                        minWidth={800}
                        maxWidth={800}
                        minHeight={800}
                        maxHeight={800}
                        onCrop={(data) => {
                          let newImages = images;
                          newImages[index] = {
                            ...image,
                            cropped_image: data,
                          };

                          this.setState({
                            images: newImages
                          });
                        }}
                        onRemoveImage={() => {
                          this.removeImage(index)
                        }}
                      />
                    )}
                  </Col>
                </Row>
                <Row className="my-2">
                  <Col>
                    <input 
                      type="text"
                      className="form-control"
                      onChange={(event) => {
                        this.setAlt(event, index)}
                      }
                      defaultValue={image.alt}
                      placeholder="Add caption..."
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          );
        })
      );
    }
  };

  /**
   * @function render
   * @description Default render method
   */
  render() {
    if (this.state.isComponentLoading) {
      return <NoticesEditSkeletonScreen />;
    } else {
      return (
        <main className="add-gallery galleries screen">
          <PageHeader 
            pageName="Add a Gallery" 
            filters={false}
          />
          
          {this.errorAlerts()}
          {this.saveAlerts()}

          <div className="form-group">
            <Row className="mb-1">
              <Col>
                <label>Title</label>
              </Col>
            </Row>

            <Row>
              <Col>
                <input
                  type="text"
                  value={this.state.title}
                  placeholder="Add title"
                  className="form-control"
                  onInput={(event) => {
                    this.setTitle(event.target.value);
                  }}
                />
              </Col>
            </Row>
          </div>

          <div className="form-group">
            <Row className="mb-1">
              <Col>
                <label>Body</label>
              </Col>
            </Row>

            <Row>
              <Col>
                <textarea className="form-control" onChange={(event) => {
                  this.setBody(event.target.value);
                }} />
              </Col>
            </Row>
          </div>  

          <div className="form-group">
            <Row className="mb-1">
              <Col>
                <label>Select Category</label>
              </Col>
            </Row>

            <Row>
              <Col>
                <CategorySelector 
                  name="addnoticeboard"
                  categories={this.state.categories}
                  selectedCategory={this.state.selectedCategoryItem}
                  handleCategorySelection={this.handleCategorySelection}
                />
              </Col>
            </Row>
          </div>

          <div className="form-group">
            <Row className="mb-2">
              <Col>
                <label>Featured Image</label>
              </Col>
            </Row>

            <Row>
              <Col xs={'auto'}>
                <input
                  ref={input => this.featuredInput = input}
                  type="file"
                  accept="image/*"
                  onChange={(event) => {
                    this.setState({
                      featured_image: event.target.files[0],
                    })

                    this.featuredInput.value = "";
                  }}
                  style={{display: 'none'}}
                />

                {!this.state.featured_image && (
                  <Button variant="link" onClick={() => {
                    this.featuredInput.click()
                  }}>
                    <Plus />
                    <span>Add image</span>
                  </Button>
                )}
              </Col>
            </Row>
          </div>

          {this.state.featured_image && (
            <div className="form-group cropper-wrapper">
              <Row>
                <Col>
                  <Crop
                    file={this.state.featured_image}
                    ratio={16 / 9}
                    minWidth={960}
                    maxWidth={960}
                    minHeight={540}
                    maxHeight={540}
                    onCrop={(data) => {
                      this.setState({
                        featured_image_cropped: data
                      });
                    }}
                    onRemoveImage={() => {
                      this.setState({
                        featured_image: null,
                      });
    
                      this.featuredInput.value = "";
                    }}
                  />
                </Col>
              </Row>
            </div>
          )}

          <div className="form-group">
            <Row className="mb-2">
              <Col>
                <label>Gallery Images</label>
              </Col>
            </Row>

            <Row>
              {this.renderSelectedImages()}
            </Row>

            <Row>
              <Col xs={'auto'}>
                <input
                  ref={galleryInput => this.galleryImageInput = galleryInput}
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={(event) => {
                    let {images} = this.state;

                    for(let i = 0; i < event.target.files.length; i++) {
                      const image = {
                        file: event.target.files[i],
                        cropped_file: null,
                        alt: null,
                      }

                      images.push(image);
                    }

                    this.galleryImageInput.value = "";

                    this.setState({
                      images: images,
                    });
                  }}
                  style={{display: 'none'}}
                />

                <Button variant="link" onClick={() => {
                  this.galleryImageInput.click()
                }}>
                  <Plus />
                  <span>
                    {this.state.images.length > 0 ? 'Add images' : 'Select images'}
                  </span>
                </Button>
              </Col>
            </Row>
          </div>

          <hr style={{margin: '3rem 0'}}/>

          <div className="form-group">
            <Row className="align-items-center justify-content-end">
              <Col xs={'auto'}>
                {this.state.isLoading ? (
                  <Spinner animation="border" role="status" variant="primary">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                ) : (
                  <Button
                    className="submit-btn"
                    onClick={() => {
                      this.uploadGalleryImages();
                    }}
                  >
                    <span>Create Gallery</span>
                    <ArrowRight />
                  </Button>
                )}
              </Col>
            </Row>
          </div>

        </main>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default withRouter(connect(mapStateToProps)(AddGallery));
