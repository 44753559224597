import React, { Component } from 'react';

// Modules
import ContentLoader from 'react-content-loader';

// App
import SkeletonPageHeader from '../../partials/skeleton-screens/skeletonPageHeader';

// UI components
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";

class EventsSkeletonScreen extends Component {  
  constructor() {
    super();
    this.state = {
      backgroundColor: '#f3f3f3',
      foregroundColor: '#ecebeb',
    };
  }

  renderDefaultItems = (itemClassList) => {

    let items = [];
    let numberOfItems;

    if (this.props.numberOfItems) {
      numberOfItems = this.props.numberOfItems;
    } else {
      numberOfItems = 4;
    }

    for (let i = 0; i < numberOfItems; i++) {

      items.push(
        <Col sm={12} lg={6} key={'item-' + i}>
          <div className={itemClassList}>
            <ContentLoader 
              speed={2}
              width={'100%'}
              height={'100%'}
              viewBox="0 0 529 91"
              backgroundColor={this.state.backgroundColor}
              foregroundColor={this.state.foregroundColor}
            >
              <circle cx="508.712" cy="69.958" r="10" />
              <rect x="311.528" y="59.958" width="82.836" height="20" rx="10" ry="10" />
              <circle cx="291.491" cy="69.958" r="10" />
              <rect x="281.491" y="27.012" width="200" height="20" rx="10" ry="10" />
              <rect x="281.491" y="0" width="247.234" height="20" rx="10" ry="10" />
              <rect x="103.551" y="0" width="161.313" height="90.738" rx="2.5" ry="2.5" />
              <rect x="0" y="-0" width="90.273" height="90.738" rx="20" ry="20"/>
            </ContentLoader>
          </div>
        </Col>
      );

    }

    return items;
  };


  renderMonthBlock = (itemClassList) => {

    let months = [];
    for (let i = 0; i < 12; i++) {

      months.push(
        <div key={'month-' + i} className="month">
          <header className="month__header" style={{borderBottom: 'none', paddingBottom: 0}}>
            <ContentLoader 
              speed={2}
              width={'100%'}
              height={'100%'}
              viewBox="0 0 1140 33"
              backgroundColor={this.state.backgroundColor}
              foregroundColor={this.state.foregroundColor}
            >
              <rect x="34.388" y="0" width="229.7" height="20" rx="10" ry="10" />
              <circle cx="11" cy="11" r="11" />
            </ContentLoader>
          </header>
          <Row className="ml-lg-3">
            {this.renderDefaultItems(itemClassList)}
          </Row>
        </div>
      );

    }

    return months;
  };

  render() {
    const itemClass = "event-item";
    const itemClassList = `${itemClass}  item`;

    return (
      <main className="events  screen">
        <SkeletonPageHeader />

        <div className="events__today">
          <ContentLoader 
            speed={2}
            width={'100%'}
            height={'100%'}
            viewBox="0 0 1140 28"
            backgroundColor={this.state.backgroundColor}
            foregroundColor={this.state.foregroundColor}
          >
            <rect x="34.388" y="3.825" width="367.297" height="20" rx="10" ry="10" />
            <circle cx="11" cy="13.825" r="11" />
          </ContentLoader>
        </div>

        {this.renderMonthBlock(itemClassList)}
      </main>
    );
  }

}

export default EventsSkeletonScreen;