import React, { Component } from 'react';

// App

// UI components
import { User } from 'react-feather';

class UserImage extends Component {

  render() {
    const hasImage = this.props.image ? true : false;
    let imageUrl = this.props.image
    let style = this.props.className ? `${this.props.className} user-image` : 'user-image';
  
    return (
      <>
        <div className={style}>
          {hasImage ? (
            <img 
              src={imageUrl}
              alt="user"
            />
          ) : (
            <User />
          )}
        </div>
        {this.props.content_counter > 0 && (
          <span className="dropdown-main__indicator">
            {this.props.content_counter}
          </span>
        )}
      </>
    );
  }

}

export default UserImage;