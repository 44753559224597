// Dependencies
import React from 'react';

// Modules
import {connect} from 'react-redux';
import TextareaAutosize from 'react-textarea-autosize';

// App
import {serverUrl} from '../config'
import {postNode} from '../core/postNode';
import {alertMessages} from '../partials/alertMessages';
import AlertModal from '../partials/alertModal';
import {get_xcsrf_token} from '../core/auth';

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Send} from 'react-feather';

class MessageForm extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      isPosted: false,
      body: '',
      modalVisible: false,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.getToken();
  }

  getToken = () => {
    get_xcsrf_token().then(_response => {
      this.setState({
        token: _response.data,
      });
    });
  };

  postMessage = () => {
    if (this.state.body < 1) {
      this.setState({
        modalVisible: true,
        alertType: 'error',
        alertTitle: alertMessages.messageEmpty.title,
        alertBody: alertMessages.messageEmpty.message,
        alertConfirmButton: true,
      });
      return false;
    }

    this.setState({
      isLoading: true,
      isPosted: false,
    });

    const data = {
      _links: {
        type: {
          href: serverUrl + '/rest/type/node/message',
        },
      },
      type: {
        target_id: 'message',
      },
      title: {
        value: 'Message from ' + this.props.uid + ' to ' + this.props.sendto,
      },
      body: {
        value: this.state.body,
      },
      field_read: {
        value: 0,
      },
      field_recipient: [
        {
          target_id: this.props.sendto,
        },
      ],
    };

    postNode(
      'node',
      data,
      this.state.token,
      this.props.user.access_token,
    )
      .then((response) => {
        this.setState({
          isLoading: false,
          isPosted: true,
          body: '',
          modalVisible: true,
          alertType: 'success',
          alertTitle: alertMessages.messagePosted.title,
          alertBody: alertMessages.messagePosted.message,
          alertConfirmButton: true,
        });

        
      })
      .catch((_error) => {
        this.setState({
          modalVisible: true,
          alertType: 'error',
          alertTitle: alertMessages.messagePostFailure.title,
          alertBody: alertMessages.messagePostFailure.message,
          alertConfirmButton: true,
          isLoading: false,
          isCommentPosted: false,
        });
      });
  };

  /**
   * Handle textarea input onChange
   * @param {*} event 
   */
  handleChange(event) {
    this.setState({body: event.target.value});
  }

  /**
   * Set modal visibility
   * @param {*} visible 
   */
  setModalVisible = (visible) => {
    this.setState({
      modalVisible: visible,
    });
  };

  handleOnBlur = (event) => {
    if (this.props.onMessageBlur) {
      this.props.onMessageBlur();
    }
  };

  render() {
    if (this.props.user.current_user) {
      return (
        <>
          <AlertModal 
            showAlert={this.state.modalVisible} 
            showAlertCallback={this.setModalVisible}
            alertType={this.state.alertType}
            alertMessageTitle={this.state.alertTitle}
            alertMessageBody={this.state.alertBody}
            cancelButton={this.state.alertCancelButton}
            confirmButton={this.state.alertConfirmButton}
            onConfirm={() => {
              if (this.state.isPosted) {
                if (this.props.onMessageSent) {
                  this.props.onMessageSent();
                }
              }
            }}
          />
          <section className="message-form-container">
            <div className="message-form">
              <Row>
                <Col className="d-flex align-items-center">
                  <TextareaAutosize 
                    className="message-form__input  form-control"
                    onChange={this.handleChange}
                    placeholder="Write a message..."
                    value={this.state.body}
                    minRows={1}
                    maxRows={8}
                    onBlur={() => {
                      this.handleOnBlur();
                    }}
                  />
                </Col>
                <Col xs={'auto'} className="d-flex align-items-start">
                  <button
                    className="submit"
                    type="submit"
                    onClick={this.postMessage}>
                    <Send/>
                  </button>
                </Col>
              </Row>
            </div>
          </section>
        </>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default connect(mapStateToProps)(MessageForm);