import React from "react";

// Modules
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// App
import PostTitle from "../../partials/postTitle";
import LoadingSpinner from "../../partials/loadingSpinner";
import UserImage from "../../partials/userImage";

// Data
// import db from "../../core/db";

// UI components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Bookmark, Tag, Mail, Phone, MessageSquare, Send } from "react-feather";

class ContactsItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      favorite: "false",
    };
  }

  componentDidMount() {
    this.checkState();
  }

  checkState = () => {
    this.setState({
      favorite: this.props.item.favorite,
    });
  };

  /**
   * Favourite a contact item and update the db
   *
   * @param {The node id} _nid
   */
  favorite = (_nid) => {
    this.setState({
      favorite: "true",
    });

    this.props.favorite(_nid);
  };

  /**
   * Unfavourite a contact item and update the db
   *
   * @param {*} _nid
   */
  unfavorite = (_nid) => {
    this.setState({
      favorite: "false",
    });

    this.props.unfavorite(_nid);
  };

  /**
   * Handle item hover state
   * @returns
   */
  handleItemHover = (hover) => {
    this.setState({ isHovered: hover });
  };

  renderActionButtons = (itemClass) => {
    return (
      <Col xs={"auto"} className="d-none  d-xl-flex ml-auto">
        {this.props.item.phoneNumber && (
          <>
            <a
              href={`tel: ${this.props.item.phoneNumber}`}
              className={`${itemClass}__btn--phone  ${itemClass}__btn`}
            >
              <Button>
                <Phone />
              </Button>
            </a>
            <a
              href={`sms: ${this.props.item.phoneNumber}`}
              className={`${itemClass}__btn--sms  ${itemClass}__btn`}
            >
              <Button>
                <MessageSquare />
              </Button>
            </a>
          </>
        )}
        <a
          href={`mailto: ${this.props.item.email}`}
          className={`${itemClass}__btn--email  ${itemClass}__btn`}
        >
          <Button>
            <Mail />
          </Button>
        </a>
        <Link
          to={"/contacts/" + this.props.item.uid}
          className={`${itemClass}__btn--message  ${itemClass}__btn`}
        >
          <Button>
            <Send />
          </Button>
        </Link>
      </Col>
    );
  };

  renderContactsItem = () => {
    const itemClass = "contacts-item";
    const itemClassList = `${itemClass}  item`;

    // username
    let contactName = this.props.item.name;

    // first name
    if (this.props.item.firstName) {
      contactName = this.props.item.firstName;
    }

    // Concat first & last name
    if (this.props.item.firstName && this.props.item.lastName) {
      contactName = this.props.item.firstName + " " + this.props.item.lastName;
    }

    let itemStyle = itemClassList;
    let hoveredStyle = this.state.isHovered ? "hovered" : "";

    return (
      <Col
        key={"contacts" + this.props.item.uid}
        className="d-flex gutter-lg"
        md={6}
      >
        <div className={`${itemStyle} ${hoveredStyle}`}>
          <Row noGutters className="h-100">
            <Col xs={"auto"}>
              <Link
                to={"/contacts/" + this.props.item.uid}
                className={`${itemClass}__image  item__image`}
                onMouseEnter={() => this.handleItemHover(true)}
                onMouseLeave={() => this.handleItemHover(false)}
              >
                <UserImage image={this.props.item.userPicture} />
              </Link>
            </Col>
            <Col className="d-flex flex-column">
              <Link
                to={"/contacts/" + this.props.item.uid}
                className={`${itemClass}__content  item__content`}
                onMouseEnter={() => this.handleItemHover(true)}
                onMouseLeave={() => this.handleItemHover(false)}
              >
                <PostTitle
                  title={contactName}
                  headingLevel={"h6"}
                />
                <h6 className={`${itemClass}__role`}>
                  {this.props.item.jobTitle}
                </h6>
              </Link>
              <footer className={`${itemClass}__footer  item__footer`}>
                <Row noGutters className="align-items-center">
                  {this.props.item.department && (
                    <Col>
                      <div className="category">
                        <Tag className="category__icon" />
                        <span className="category__text">
                          {this.props.item.department}
                        </span>
                      </div>
                    </Col>
                  )}
                  {this.renderActionButtons(itemClass)}
                  <Col xs={"auto"} className="ml-auto ml-xl-1 px-2">
                    {this.state.favorite === "true" ? (
                      <button
                        className="favourite-btn filled"
                        onClick={() => {
                          this.unfavorite(this.props.item.uid);
                        }}
                      >
                        <Bookmark />
                      </button>
                    ) : (
                      <button
                        className="favourite-btn"
                        onClick={() => {
                          this.favorite(this.props.item.uid);
                        }}
                      >
                        <Bookmark />
                      </button>
                    )}
                  </Col>
                </Row>
              </footer>
            </Col>
          </Row>
        </div>
      </Col>
    );
  };

  /**
   * @function render
   * @description Default render method
   */
  render() {
    if (this.state.isLoading) {
      return <LoadingSpinner />;
    } else {
      return this.renderContactsItem();
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default connect(mapStateToProps)(ContactsItem);
