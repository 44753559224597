import React, { Component } from 'react';

// Modules
import ContentLoader from 'react-content-loader';

class ForumsSkeletonAddTopicScreen extends Component {  
  constructor() {
    super();
    this.state = {
      backgroundColor: '#f3f3f3',
      foregroundColor: '#ecebeb',
    };
  }

  render() {
    return (
      <main className="add-forum-topic  screen">

        <div className="add-forum-topic__header">
          <div className="title">
            <ContentLoader 
              speed={2}
              width={'100%'}
              height={'100%'}
              viewBox="0 0 840 50"
              backgroundColor={this.state.backgroundColor}
              foregroundColor={this.state.foregroundColor}
            >
              <rect x="0" y="0" width="298.102" height="50" rx="10" ry="10" />
            </ContentLoader>
          </div>

          <div className="add-topic-board">
            <ContentLoader 
              speed={2}
              width={'100%'}
              height={'100%'}
              viewBox="0 0 840 20"
              backgroundColor={this.state.backgroundColor}
              foregroundColor={this.state.foregroundColor}
            >
              <rect x="35.58" y="0" width="227.087" height="20" rx="10" ry="10" />
              <rect x="0" y="0" width="21.953" height="20" rx="5" ry="5" />
            </ContentLoader>
          </div>

          {/* desc */}
          <ContentLoader 
            speed={2}
            width={'100%'}
            height={'100%'}
            viewBox="0 0 840 47"
            backgroundColor={this.state.backgroundColor}
            foregroundColor={this.state.foregroundColor}
          >
            <rect x="0" y="26.99" width="211.7" height="20" rx="10" ry="10" />
            <rect x="0" y="0" width="815.374" height="20" rx="10" ry="10" />
          </ContentLoader>
        </div>

        {/* Form */}
        <ContentLoader 
          speed={2}
          width={'100%'}
          height={'100%'}
          viewBox="0 0 840 254"
          backgroundColor={this.state.backgroundColor}
          foregroundColor={this.state.foregroundColor}
        >
          <rect id="form" x="-0" y="0" width="839.984" height="253.119" rx="10" ry="10" />
        </ContentLoader>
      </main>
    );
  }

}

export default ForumsSkeletonAddTopicScreen;