import React from "react";

// Modules
import TimeAgo from "react-timeago";
import moment from "moment";

// App
import PostTitle from "../../partials/postTitle";
import PostExcerpt from "../../partials/postExcerpt";
import LoadingSpinner from "../../partials/loadingSpinner";

// UI components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Bookmark, Clock } from "react-feather";

class PresentationItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  /**
   * Handle item hover state
   * @returns
   */
  handleItemHover = (hover) => {
    this.setState({ isHovered: hover });
  };

  renderPresentationItem = () => {
    const itemClass = "presentation-item";
    const itemClassList = `${itemClass}  item`;
    const timestamp = moment.unix(this.props.item.date_created);

    let itemStyle = itemClassList;
    let hoveredStyle = this.state.isHovered ? "hovered" : "";

    return (
      <Col
        key={"presentation" + this.props.item.id}
        className="d-flex"
        sm={6}
        lg={3}
      >
        <div className={`${itemStyle} ${hoveredStyle}`}>
          <Row noGutters>
            <Col xs={4} sm={12} lg={12}>
              <a
                href={this.props.item.share_link}
                rel="noreferrer"
                target="_blank"
                className={`${itemClass}__image  item__image`}
                onMouseEnter={() => this.handleItemHover(true)}
                onMouseLeave={() => this.handleItemHover(false)}
              >
                <img
                  src={this.props.item.thumbnail}
                  alt={"img-" + this.props.item.id}
                />
              </a>
            </Col>
            <Col>
              <a
                href={this.props.item.share_link}
                rel="noreferrer"
                target="_blank"
                className={`${itemClass}__content  item__content`}
                onMouseEnter={() => this.handleItemHover(true)}
                onMouseLeave={() => this.handleItemHover(false)}
              >
                <PostTitle
                  title={this.props.item.title}
                  headingLevel={"h6"}
                />
                <PostExcerpt
                  body={this.props.item.description}
                />
              </a>
            </Col>
          </Row>
          <Row noGutters className="mt-auto">
            <Col>
              <footer className={`${itemClass}__footer  item__footer`}>
                <Row noGutters>
                  <Col className="timestamp">
                    <Clock className="timestamp__icon" />
                    <TimeAgo date={timestamp} className="timestamp__text" />
                  </Col>
                  <Col xs={"auto"} className="px-2">
                    <button className="favourite-btn">
                      <Bookmark />
                    </button>
                  </Col>
                </Row>
              </footer>
            </Col>
          </Row>
        </div>
      </Col>
    );
  };

  /**
   * @function render
   * @description Default render method
   */
  render() {
    if (this.state.isLoading) {
      return <LoadingSpinner />;
    } else {
      return this.renderPresentationItem();
    }
  }
}

export default PresentationItem;
