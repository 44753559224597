import React, { Component } from 'react';

// Modules
import ContentLoader from 'react-content-loader';

// App
import SkeletonPageHeader from '../../partials/skeleton-screens/skeletonPageHeader';

// UI components
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";

class VideosSkeletonScreen extends Component {  
  constructor() {
    super();
    this.state = {
      backgroundColor: '#f3f3f3',
      foregroundColor: '#ecebeb',
    };
  }

  renderDefaultItems = (itemClassList) => {

    let items = [];
    let numberOfItems;

    if (this.props.numberOfItems) {
      numberOfItems = this.props.numberOfItems;
    } else {
      numberOfItems = 20;
    }

    for (let i = 0; i < numberOfItems; i++) {

      items.push(
        <Col sm={12} lg={4} xl={3} key={'item-' + i}>
          <div className={itemClassList}>
            <ContentLoader 
              speed={2}
              width={'100%'}
              height={'100%'}
              viewBox="0 0 263 272"
              backgroundColor={this.state.backgroundColor}
              foregroundColor={this.state.foregroundColor}
            >
              <circle cx="242.9" cy="248.142" r="10" />
              <rect x="30.037" y="238.142" width="82.836" height="20" rx="10" ry="10" />
              <circle cx="10" cy="248.142" r="10" />
              <rect x="36.168" y="187" width="183.453" height="20" rx="10" ry="10" />
              <rect x="36.168" y="159.988" width="226.779" height="20" rx="10" ry="10" />
              <circle cx="12.5" cy="169.988" r="12.5" />
              <rect x="0" y="0.152" width="262.947" height="147.908" rx="2.5" ry="2.5"/>
            </ContentLoader>
          </div>
        </Col>
      );

    }

    return items;
  };

  render() {
    const itemClass = "videos-item";
    const itemClassList = `${itemClass}  item`;

    return (
      <main className="videos  screen">
        <SkeletonPageHeader />

        <Row>
          {this.renderDefaultItems(itemClassList)}
        </Row>
      </main>
    );
  }

}

export default VideosSkeletonScreen;