import React from 'react';

// Modules
import {connect} from 'react-redux';
import { Link } from 'react-router-dom';

// App
import {getOnlineNode} from '../../core/getNode';
import {getToken} from '../../core/postNode';
import AccountSkeletonScreen from './skeleton-screens/accountSkeletonScreen';

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import UserImage from '../../partials/userImage';
import {Edit} from 'react-feather';

class Account extends React.Component {
  constructor() {
    super();
    this.state = {
      // account
      isAccountLoading: true,
      isAccountError: false,
      accountErrorStatus: '',
      accountErrorMessage: '',
      user: '',
    };
  }

  /**
   * @function componentDidMount
   * @description Default react method. Fired when component is rendered
   */
  componentDidMount() {
    this.getToken();
    this.getAccount();
  }

  getToken = () => {
    getToken().then((_response) => {
      this.setState({
        token: _response.data,
      });
    });
  };

  setAccount = (_data) => {
    this.setState({
      isAccountLoading: false,
      isRefreshing: false,
      user: _data,
    });
  };

  getAccount = () => {
    if (typeof this.props.user.current_user === 'undefined') {
      // this.setState({
      //   isAccountLoading: false,
      //   isAccountError: true,
      //   accountErrorStatus: 0,
      //   accountErrorMessage: alertMessages.loggedOut,
      // });
      this.props.history.push('/');
    } else {
      getOnlineNode(
        'custom_user/' + this.props.user.current_user.uid,
        this.props.user.access_token,
      )
        .then((response) => {
          this.setAccount(response.data.rows[0]);
        })
        .catch((_error) => {
          if (_error.response) {
            this.setState({
              isAccountLoading: false,
              isAccountError: true,
              accountErrorStatus: 200,
              accountErrorMessage: 'No data found.',
            });
          } else if (_error.request) {
            this.setState({
              isAccountLoading: false,
              isAccountError: true,
              accountErrorStatus: 0,
              accountErrorMessage: 'Could not make the request.',
            });
          } else {
            this.setState({
              isAccountLoading: false,
              isAccountError: true,
              accountErrorStatus: 0,
              accountErrorMessage: 'Unknown error.',
            });
          }
        });
    }
  };

  renderProfilePic = () => {
    let imageURI = undefined;

    if (this.state.user.user_picture) {
      imageURI = this.state.user.user_picture;
    }
    
    return (
      <Row>
        <Col className="d-flex justify-content-center align-content-center" xs={12} lg={12}>
          <UserImage className="user__image" image={imageURI}/>
        </Col>
      </Row>
    )
  };

  renderUserGreeting = () => {
    if (this.state.user.field_first_name) {
      return (
        <Row className={"greeting"}>
          <Col
            className="d-flex justify-content-center align-content-center"
            xs={12}
            lg={12}
          >
            <h4>
              <span className="font-weight-light">Hi, </span>
              {this.state.user.field_first_name}
            </h4>
          </Col>
        </Row>
      );
    }
  };

  renderUsername = () => {
    if (this.state.user.name) {
      return (
        <Row className={'username'}>
          <Col className="d-flex justify-content-center align-content-center" xs={12} lg={12}>
            <p style={{marginBottom: 0}}>Username: <strong>{this.state.user.name}</strong></p>
          </Col>
        </Row>
      );
    }
  };

  renderUserMail = () => {
    if (this.state.user.mail) {
      return (
        <div>
          <p>{this.state.user.mail}</p>
        </div>
      );
    }
  };

  renderUserRoles = () => {
    if (this.state.user.roles_target_id) {
      return (
        <Row className={'username'}>
          <Col className="d-flex justify-content-center align-content-center" xs={12} lg={12}>
            <p>Role: <strong>{this.state.user.roles_target_id}</strong></p>
          </Col>
        </Row>
      );
    }
  };

  /**
   * @function render
   * @description Default render method
   */
  render() {
    if (this.state.isAccountLoading) {
      return <AccountSkeletonScreen />;
    } else {
      if (this.state.isAccountError) {
        return (
          <div>
            <h3>{this.state.accountErrorStatus}</h3>
            <p>{this.state.accountErrorMessage}</p>
          </div>
        );
      } else {
        return (
          <main className={"account screen"}>
            {this.renderProfilePic()}
            {this.renderUserGreeting()}
            {this.renderUsername()}
            {this.renderUserRoles()}

            <Row className={"actions"}>
              <Col
                className="d-flex justify-content-center align-content-center"
                xs={12}
                lg={12}
              >
                <Link
                  className={"edit-profile-link d-flex align-items-center"}
                  to={"/account/edit-profile"}
                  params={{ user: this.state.user }}
                >
                  <Edit color={"#b6519a"} className={"icon"} />
                  {"Edit profile"}
                </Link>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="creators d-flex justify-content-center">
                  <p>
                    Designed &amp; developed by{" "}
                    <a
                      href="https://companyapp.co.uk/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      companyapp.co.uk
                    </a>
                  </p>
                </div>
              </Col>
            </Row>
          </main>
        );
      }
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  removeUserSession: () => dispatch({type: 'REMOVE_SESSION'}),
});

export default connect(mapStateToProps, mapDispatchToProps)(Account);
