import React from 'react';

// Modules
import {connect} from 'react-redux';
import {withRouter} from 'react-router';

// App
import {getOnlineNode} from '../../core/getNode';
import PageHeader from '../../partials/pageHeader';
import MessageItem from './messageItem';
import {alertMessages} from '../../partials/alertMessages';
import Error from '../../partials/error';
import Conversation from './conversation';
import MessagesSkeletonScreen from './skeleton-screens/messagesSkeletonScreen';
import {_checkContent} from '../../core/checkContent';

// Data
// import db from './../../core/db';
// import {getContentCount} from '../../core/getRecordCount';
// import {updateContentCounterPreviousCount} from '../../core/updateData';

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class Messages extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      isRefreshing: false,
      isError: false,

      errorStatus: '',
      errorMessage: '',

      uid: null,

      data: [],
      desktopMediaQuery: window.matchMedia("(min-width: 992px)"),
    };
  }

  /**
   * @function componentDidMount
   * @description Default react method. Fired when component is rendered
   */
  componentDidMount() {
    this.checkSession();
    window.addEventListener('resize', this.checkDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkDimensions);
  }

  checkSession = () => {
    if (Object.keys(this.props.user).length === 0) {
      this.props.history.push('/');
    } else {
      this.getContent();

      if (
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.uid
      ) {
        this.setState({
          uid: this.props.location.state.uid,
        });
      }
    }
  }

  checkDimensions = () => {
    const {innerWidth} = window;
    const {uid} = this.state;
  
    const breakpoint = 992;

    /** breakpoint 992 */
    if (innerWidth < breakpoint && uid !== null) {
      this.props.history.push(`/account/messages/${uid}`);
    }
  }

  /**
   * @function setData
   * @description Updates the state of the component upon data retrival
   * @param {object} _data
   * @param {object} _realm
   */
  setData = async (_data) => {
    this.setState(
      {
        isLoading: false,
        isRefreshing: false,
        data: _data,
      }
    );

    // const serverCount = await getContentCount('news')
    
    // if (serverCount && serverCount[0]) {
    //   const count = serverCount[0] ? serverCount[0].serverCount : 0;
    //   updateContentCounterPreviousCount('news', count)
    // }

    // _checkContent();
  };

  /**
   * @function setError
   * @description Updates the state of the component upon an error
   * @param {boolean} _isError
   * @param {int} _errorStatus
   * @param {string} _errorMessage
   */
  setError = (_isError, _errorStatus, _errorMessage) => {
    this.setState({
      isLoading: false,
      isRefreshing: false,
      isPaginating: false,
      isError: _isError,
      errorStatus: _errorStatus,
      errorMessage: _errorMessage,
    });
  };

  /**
   * @function getContent
   * @description Retrieves the data from an API / Fallback to local realm object if there is no connection
   */
  getContent = () => {
    let path = 'messages';

    getOnlineNode(path, this.props.user.access_token)
      .then((response) => {
        this.setData(response.data);
      })
      .catch((_error) => {
        if (_error.response) {
          this.setError(
            true,
            _error.response.status,
            _error.response.statusText,
          );
        } else if (_error.request) {
          this.setError(true, 0, alertMessages.requestError.message);
        } else {
          this.setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };

  // Data component
  dataComponent = () => {
    const {data} = this.state;

    if (typeof data !== 'undefined' && data.length > 0) {
      return (
          data.map(item => {
            return (
              <MessageItem
                key={item.user.uid}
                item={item}
                onMessageOpen={this.onMessageOpen}
                isDesktopViewport={this.state.desktopMediaQuery.matches}
                activeItem={this.state.uid}
              />
            );
          })
      );
    } else {
      return (
        <div>
          <p>{alertMessages.noMessages2}</p>
        </div>
      );
    }
  };

  onMessageOpen = (uid) => {
    this.setState(
      {
        uid: null,
      },
      () => {
        this.setState({
          uid: uid,
        });
      });
  };

  onMessageRead = () => {
    this.getContent();
    _checkContent();
  }

  /**
   * @function render
   * @description Default render method
   */
  render() {
    if (this.state.isLoading) {
      return <MessagesSkeletonScreen />;
    } else {
      if (this.state.isError) {
        return (
          <Error 
            status={this.state.errorStatus}
            message={this.state.errorMessage}
          />
        );
      } else {
        return (
          <main className="messages screen">
            {this.state.desktopMediaQuery.matches ? (
              // DESKTOP
              <div className="content-wrapper">
                <Row noGutters className="h-100">
                  <Col xs={12} lg={5} xl={4} className="h-100">
                    <section className="message-list-container">
                      <PageHeader
                        pageName="Messages"
                        filters={false}
                        getContent={() => {
                          this.getContent();
                        }}
                      />
                      <div className="message-list">
                        {this.dataComponent()}
                      </div>
                    </section>
                  </Col>
                  {this.state.desktopMediaQuery.matches && (
                    <Col xs={12} lg={7} xl={8} className="h-100">
                      <section className="message-thread-container">
                        {this.state.uid ? (
                          <Conversation
                            uid={this.state.uid}
                            onMessageReadStatusUpdate={this.onMessageRead}
                          />
                        ) : (
                          <div className="h-100 d-flex justify-content-center align-items-center">
                            No message selected
                          </div>
                        )}
                      </section>
                    </Col>
                  )}
                </Row>
              </div>
            ) : (
              // MOBILE
              <section className="message-list-container">
                <PageHeader
                  pageName="Messages"
                  filters={false}
                  getContent={() => {
                    this.getContent();
                  }}
                />
                <div className="message-list">
                  {this.dataComponent()}
                </div>
              </section>
            )}
          </main>
        );
      }
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default withRouter(connect(mapStateToProps)(Messages));
