import React from 'react';

// Modules
import {connect} from 'react-redux';

// App
import ContactsItem from './contactsItem';
import {getOnlineNode} from '../../core/getNode';
import {paginator} from '../../core/paginator';
import Pagination from '../../core/pagination';
import PageHeader from '../../partials/pageHeader';
import {alertMessages} from '../../partials/alertMessages';
import Error from '../../partials/error';
import {_checkContent} from '../../core/checkContent';
import ContactsSkeletonScreen from './contactsSkeletonScreen';
import NoData from '../../partials/noData';

// Data
import db from './../../core/db';
import {getContentCount} from '../../core/getRecordCount';
import {updateContentCounterPreviousCount} from '../../core/updateData';
import {getFavorites, getFavouritesLength} from './../../core/getFavorites';

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Search, X, AlertCircle } from 'react-feather';

class Contacts extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      isRefreshing: false,
      isError: false,

      errorStatus: '',
      errorMessage: '',

      currentPage: 1,
      pageSize: 20,
      index: 0,
      totalItems: 0,

      selectedCategory: 0,
      categories: [],
      token: '',

      data: [],
      favorites: [],

      search: '',
      activeIndex: 0,
    };

    this.onPaginate = this.onPaginate.bind(this);
    this.submitSearchForm = this.submitSearchForm.bind(this);
  }

  /**
   * @function componentDidMount
   * @description Default react method. Fired when component is rendered
   */
  componentDidMount() {
    this.checkSession();
  }

  checkSession = () => {
    if (Object.keys(this.props.user).length === 0) {
      this.props.history.push('/');
    } else {
      this.getContent();
    }
  }

  /**
   * @function setData
   * @description Updates the state of the component upon data retrival
   * @param {object} _data
   * @param {object} _realm
   */
  setData = async (_data) => {
    this.setState(
      {
        isLoading: false,
        isRefreshing: false,
        isPaginating: false,
        data: _data.rows,
        totalItems: _data.pager.total_items,
      }
    );

    const serverCount = await getContentCount('users')
    
    if (serverCount && serverCount[0]) {
      const count = serverCount[0] ? serverCount[0].serverCount : 0;
      updateContentCounterPreviousCount('users', count)
    }

    _checkContent();
  };

  /**
   * @function setError
   * @description Updates the state of the component upon an error
   * @param {boolean} _isError
   * @param {int} _errorStatus
   * @param {string} _errorMessage
   */
  setError = (_isError, _errorStatus, _errorMessage) => {
    this.setState({
      isLoading: false,
      isRefreshing: false,
      isPaginating: false,
      isError: _isError,
      errorStatus: _errorStatus,
      errorMessage: _errorMessage,
    });
  };

  favorite = async (_nid) => {
    const {activeIndex} = this.state;

    let contact = await db.contact.get(_nid);
    contact.favorite = 'true';
    db.contact.put(contact, _nid);

    if (activeIndex === 0) {
      this.getContent();
    }

    if (activeIndex === 1) {
      this.getFavourites();
    }
  }

  unfavorite = async (_nid) => {
    const {activeIndex} = this.state;
  
    let contact = await db.contact.get(_nid);
    contact.favorite = 'false';
    db.contact.put(contact, _nid);

    if (activeIndex === 0) {
      this.getContent();
    }

    if (activeIndex === 1) {
      this.getFavourites();
    }
  }

  dataHandler = async (_data) => {
    let contacts = [];

    for (const data of _data.rows) {
      let existingContact = await db.contact.get(data.uid);
      let favorite = 'false';

      if (existingContact) {
        favorite = existingContact.favorite;
      }
      
      const contact = {
        uid: data.uid,
        name: data.name,
        firstName: data.field_first_name,
        lastName: data.field_last_name,
        email: data.mail,
        phoneNumber: data.phone_number,
        userPicture: data.user_picture,
        jobTitle: data.field_job_title,
        department: data.field_department,
        location: data.field_location,
        role: data.roles_target_id,
        emailNotifications: data.field_email_notifications,
        pushNotifications: data.field_push_notifications,
        showInfo: data.field_show_info,
        favorite: favorite,
      };

      db.contact.put(contact, data.uid);

      contacts.push(contact);
    }

    let data = _data;

    data.rows = contacts;

    this.setData(data);
  }

  /**
   * @function getContent
   * @description Retrieves the data from an API / Fallback to local realm object if there is no connection
   */
  getContent = () => {
    let path =
    'custom_user/all/?_format=json&' +
    'items_per_page=' +
    this.state.pageSize +
    '&page=' +
    (this.state.currentPage - 1);

    const tid = this.state.selectedCategory;

    if (tid !== 0) {
      path =
      'custom_user/' +
      tid +
      '/all' +
      '?_format=json&' +
      'items_per_page=' +
      this.state.pageSize +
      '&page=' +
      (this.state.currentPage - 1);
    }

    if (this.state.search && this.state.search.length > 0) {
      path =
      'custom_user/all/?_format=json&' +
      'first_name=' +
      this.state.search +
      '&last_name' +
      this.state.search +
      '&items_per_page=' +
      this.state.pageSize +
      '&page=' +
      (this.state.currentPage - 1);
    }

    getOnlineNode(path, this.props.user.access_token)
      .then((response) => {
        this.dataHandler(response.data);
      })
      .catch((_error) => {
        console.log('error: ', _error);
        if (_error.response) {
          //console.log('@rest response: ', _error.response);
          this.setError(
            true,
            _error.response.status,
            _error.response.statusText,
          );
        } else if (_error.request) {
          //console.log('@rest request: ', _error.request);
          this.setError(true, 0, alertMessages.requestError.message);
        } else {
          //console.log('@rest unknown: ', _error);
          this.setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };

  /**
   * @function getFavourites
   * @description Retrieves the favourited data from the database
   */
  getFavourites = async () => {
    const favorites = await getFavorites('contact', this.state.selectedCategory, this.state.index, this.state.pageSize);
    const favoritesLength = await getFavouritesLength('contact', this.state.selectedCategory);

    this.setState({
      data: favorites,
      totalItems: favoritesLength,
      isLoading: false,
      isRefreshing: false,
      isPaginating: false,
    });
  }

  /**
   * @function onPaginate
   * @description Pagination callback
   * @param {int} _index - Page number
   */
  onPaginate = (_index) => {
    this.setState(
      {
        isLoading: true,
        isPaginating: true,
        currentPage: _index,
      },
      function() {
        this.getContent();
      },
    );
  };
  
  // Pagination component
  renderPagination = () => {
    if (this.state.totalItems <= this.state.pageSize) {
      return null;
    } else {
      let pagination = paginator(
        this.state.totalItems,
        this.state.currentPage,
        this.state.pageSize,
        3,
      );

      return (
        <Pagination
          currentPage={pagination.currentPage}
          endIndex={pagination.endIndex}
          endPage={pagination.endPage}
          pageSize={pagination.pageSize}
          pages={pagination.pages}
          startIndex={pagination.startIndex}
          startPage={pagination.startPage}
          totalItems={pagination.totalItems}
          totalPages={pagination.totalPages}
          paginationCallback={this.onPaginate}
        />
      );
    }
  };

  /**
   * Submit contact search query
   * @param {*} event 
   */
  submitSearchForm = async (event) => {
    event.preventDefault();
    this.setState(
      {
        currentPage: 1,
      },
      function () {
        this.getContent();
      }
    );
  };

  /**
   * Reset contact search
   */
  clearSearchForm = async () => {
    this.setState(
      {
        search: '',
      }, 
      function() {
        if (this.state.activeIndex === 0) {
          this.getContent();
        }

        if (this.state.activeIndex === 1) {
          this.getFavourites();
        }
      }
    );
  };

  // Data component
  dataComponent = () => {
    const {data} = this.state;

    if (typeof data !== 'undefined' && data.length > 0) {
      return (
        <>
          <Row>
            <Col xs={12} md={6} lg={4}>
              <form className="search" onSubmit={this.submitSearchForm}>
                <div className="flex-grow-1">
                  <input
                    type="text"
                    onChange={(event) => {
                      this.setState({
                        search: event.target.value,
                      });
                    }}
                    placeholder="Search"
                    value={this.state.search}
                    className="form-control"
                  />
                </div>
                {this.state.search.length > 0 && (
                  <button
                    type="button"
                    onClick={() => this.clearSearchForm()}
                  >
                    <X />
                  </button>
                )}
                <button type="submit">
                  <Search />
                </button>
              </form>

            </Col>
          </Row>
          <Row>
            {data.map(item => {
              return (
                <ContactsItem
                  key={'contact-' + item.uid}
                  item={item}
                  uid={item.uid}
                  favorite={this.favorite}
                  unfavorite={this.unfavorite}
                />
              );
            })}
          </Row>
        </>
      );
    } else {
      if (this.state.search.length > 0) {
        return (
          <>
            <Row>
                <Col xs={12} md={6} lg={4}>
                  <form className="search" onSubmit={this.submitSearchForm}>
                    <div className="flex-grow-1">
                      <input
                        type="text"
                        placeholder="Search"
                        value={this.state.search}
                        onChange={(event) => {
                          this.setState({
                            search: event.target.value,
                          });
                        }}
                      />
                    </div>
                    {this.state.search.length > 0 && (
                      <button
                        type="button"
                        onClick={() => this.clearSearchForm()}
                      >
                        <X />
                      </button>
                    )}
                    <button type="submit">
                      <Search />
                    </button>
                  </form>
                </Col>
            </Row>
            <Row noGutters>
              <Col>
                <div className='alert alert-secondary  d-flex align-items-center'>
                  <AlertCircle />
                  <p className='ml-2 mb-0'>Nothing found matching your search...</p>
                </div>
              </Col>
            </Row>
          </>
        );
      } else {
        return (
          <NoData activeIndex={this.state.activeIndex} />
        );
      }
    }
  };

  /**
   * @function render
   * @description Default render method
   */
  render() {
    if (this.state.isLoading) {
      return <ContactsSkeletonScreen />;
    } else {
      if (this.state.isError) {
        return (
          <Error 
            status={this.state.errorStatus}
            message={this.state.errorMessage}
          />
        );
      } else {
        return (
          <main className="contacts  screen">
            <PageHeader 
              pageName="User Directory" 
              filters={true} 
              getContent={() => {
                this.setState(
                  {
                    activeIndex: 0,
                    isLoading: true,
                  },
                  function () {
                    this.getContent();
                  },
                );
              }}
              getFavourites={() => {
                this.setState(
                  {
                    activeIndex: 1,
                    isLoading: true,
                  },
                  function () {
                    this.getFavourites();
                  },
                );
              }}
              activeTabIndex={this.state.activeIndex}
            />
            {this.dataComponent()}
            {this.renderPagination()}
          </main>
        );
      }
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default connect(mapStateToProps)(Contacts);
