import React from 'react';

// Modules
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {encode, decode} from "html-entities";

// App
import {serverUrl} from '../../config';
import {getOnlineNode} from '../../core/getNode';
import {postNode, patchNode, getToken} from '../../core/postNode';
import {deleteRequest} from '../../core/delete';
import {postFile} from '../../core/postFile';
import {alertMessages} from '../../partials/alertMessages';
import PageHeader from '../../partials/pageHeader';
import CategorySelector from '../../partials/categorySelector';
import EditGroup from './editGroup';
import CreateGroup from './createGroup';
import AlertModal from '../../partials/alertModal';
import NoticesEditSkeletonScreen from './skeleton-screens/noticesEditSkeletonScreen';


// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner'
import { Edit2, File, Plus,X, ArrowRight, Trash2, FileText } from 'react-feather';

class EditNotice extends React.Component {
  constructor() {
    super();
    this.state = {
      isComponentLoading: true,
      isLoading: false,
      isMembersLoading: false,
      title: '',
      body: '',
      token: null,
      featured_image: null,
      modalVisible: false,
      isCollapsed: true,

      editGroupModalVisible: false,
      addGroupModalVisible: false,
      deleteModalConfirmVisible: false,
      deleteModalVisible: false,
      patchModalVisible: false,

      groups: [
        {
          label: 'Please choose an option',
          id: 0,
        },
      ],
      selectedGroup: 0,
      group_members: [],
      existingFiles: [],
      files: [],
      sentFileIDs: [],
    };
  }

  componentDidMount() {
    this.getFileIDs();
    this.loadNode();
    this.getToken();
  }

  getToken = () => {
    getToken().then(_response => {
      this.setState({
        token: _response.data,
      });
    });
  };

  loadNode = () => {
    const nid = this.props.match.params.nid;

    if (nid) {
      this.getContent(nid);
    } else {
      // this.setError(true, 0, alertMessages.contentError);
    }
  };

  /**
   * @function getContent
   * @description Retrieves the data from an API / Fallback to local realm object if there is no connection
   */
  getContent = (nid) => {
    let path = 'group_notices/all/' + nid + '?_format=json&status=1&promote=1';

    getOnlineNode(path, this.props.user.access_token)
      .then((response) => {
        if (response.data.rows.length > 0) {
          this.setState(
            {
              title: response.data.rows[0].title,
              body: response.data.rows[0].field_body,
              node: response.data.rows[0],
            },
            function () {
              this.getGroups();
            },
          );
        } else {
          this.setError(true, 200, alertMessages.noData);
        }
      })
      .catch((_error) => {
        if (_error.response) {
          this.setError(
            true,
            _error.response.status,
            _error.response.statusText,
          );
        } else if (_error.request) {
          this.setError(true, 0, alertMessages.requestError.message);
        } else {
          this.setError(true, 0, alertMessages.unkownError.message);
        }
      });
  }

  getFileIDs = () => {
    const nid = this.props.match.params.nid;

    if (nid) {
      const path = '/files/' + nid;

      getOnlineNode(path, this.props.user.access_token)
        .then(response => {
          let files = [];
          let sentFileIDs = [];

          response.data.forEach(_file => {
            sentFileIDs.push(_file.fid);
            files.push({
              fid: _file.fid,
              name: _file.uri.split('/').pop(),
            });
          });

          this.setState({
            existingFiles: files,
            sentFileIDs: sentFileIDs,
          });
        })
        .catch(error => {
          console.log('err: ', error);
        });
    }
  };

  getGroups = id => {
    let path =
        'groups_list_manager/' +
        this.props.user.current_user.uid;

      let selectedID = id;

      const {node} = this.state;

      if (!selectedID && node.groupID) {
        selectedID = node.groupID;
      }

    getOnlineNode(path, this.props.user.access_token)
      .then(response => {
        let groups = [
          {
            label: 'Please choose an option',
            id: 0,
          },
        ];

        response.data.forEach(group => {
          groups.push(group);
        });

        this.setState(
          {
            isComponentLoading: false,
            groups: groups,
          },
          () => {
            this.setState(
              {
                selectedGroup: selectedID ? parseInt(selectedID, 10) : 0,
                selectedGroupItem: selectedID ? {
                  value: parseInt(selectedID, 10),
                  label: node.groupLabel,
                } : {
                  value: 0,
                  label: 'Please choose an option',
                }
              },
              () => {
                this.getGroupMembers();
              },
            );
          },
        );
      })
      .catch(_error => {
        this.setState({
          isComponentLoading: false,
        });
      });
  };

  getGroupMembers = () => {
    const {selectedGroup} = this.state;

    if (selectedGroup !== 0) {
      getOnlineNode(
        'group_members/' + this.state.selectedGroup,
        this.props.user.access_token,
      )
        .then(response => {
          this.setState({
            group_members: response.data,
            isMembersLoading: false,
          });
        })
        .catch(_error => {
          this.setState({
            group_members: [],
            isMembersLoading: false,
          });
        });
    } else {
      this.setState({
        group_members: [],
        isMembersLoading: false,
      });
    }
  };

  setTitle = _title => {
    this.setState({
      title: _title,
    });
  };

  setBody = _body => {
   let body = encode(_body);

    this.setState({
      body: body,
    });
  };

  uploadFiles = () => {
    const {title, body, files} = this.state;
    let fileUploadErrors = 0;

    if (title.length < 5) {
      this.setState({
        modalVisible: true,
        alertType: 'error',
        alertTitle: alertMessages.noticeTitle.title,
        alertBody: alertMessages.noticeTitle.message,
      });

      this.setState({
        isLoading: false,
      });

      return false;
    }

    if (body.length < 5) {
      this.setState({
        modalVisible: true,
        alertType: 'error',
        alertTitle: alertMessages.noticeBody.title,
        alertBody: alertMessages.noticeBody.message,
      });

      this.setState({
        isLoading: false,
      });

      return false;
    }

    this.setState({
      isLoading: true,
    });

    let filesSent = 0;

    if (files.length > 0) {
      files.forEach((file, index) => {
        postFile(
          file,
          serverUrl +
            '/file/upload/node/group_notices/field_notice_document?_format=hal_json',
          this.state.token,
          // this.props.user.access_token,
        )
          .then(response => response.json())
          .then(result => {
            const fid = result.fid[0].value;

            this.setState({
              sentFileIDs: this.state.sentFileIDs.concat([fid]),
            });

            filesSent++;

            if (filesSent === files.length) {
              this.patchNode();
            }
          })
          .catch(error => {
            fileUploadErrors = fileUploadErrors + (index + 1);
            filesSent++;

            if (filesSent === files.length) {
              this.patchNode();
            }
          });
      });
    } else {
      this.patchNode();
    }

    if (fileUploadErrors > 0) {
      this.setState({
        modalVisible: true,
        alertType: 'error',
        alertTitle: 'Error with files',
        alertBody: 'Failed to upload some of the documents. Please try uploading them again.',
      });
    }
  };

  filesSaved = () => {
    this.patchNode();
  };

  archive = () => {
    const {title, body, sentFileIDs, node} = this.state;

    let files = [];

    sentFileIDs.forEach((fid, index) => {
      const file = {
        target_id: fid,
      };

      files.push(file);
    });

    this.setState({
      isLoading: true,
    });

    const data = {
      _links: {
        type: {
          href: serverUrl + '/rest/type/node/group_notices',
        },
      },
      type: {
        target_id: 'group_notices',
      },
      title: {
        value: title,
      },
      field_body: {
        value: body,
      },
      field_group: [],
      field_notice_document: files ? files : [],
    };

    patchNode(
      'node/' + node.nid,
      data,
      this.state.token,
      // this.props.user.access_token,
    )
      .then(_response => {
        this.setState({
          isLoading: false,
        });

        this.setState({
          archiveModalVisible: true,
          alertTitle: alertMessages.noticeArchived.title,
          alertBody: alertMessages.noticeArchived.message,
        });
      })
      .catch(_error => {
        this.setState({
          isLoading: false,
        });

        this.setState({
          modalVisible: true,
          alertType: 'error',
          alertTitle: alertMessages.postNodeFailed.title,
          alertBody: alertMessages.postNodeFailed.message,
          alertCancelButton: true,
          alertConfirmButton: false,
        });
      });
  };
  
  patchNode = () => {
    const {title, body, selectedGroup, sentFileIDs, node} = this.state;

    let group = [];
    let field_notice_document = [];

    sentFileIDs.forEach(fid => {
      const file = {
        target_id: fid,
      };

      field_notice_document.push(file);
    });

    if (selectedGroup && selectedGroup !== 0) {
      group = [
        {
          target_id: selectedGroup,
        },
      ];
    }

    this.setState({
      isLoading: true,
    });

    const data = {
      _links: {
        type: {
          href: serverUrl + '/rest/type/node/group_notices',
        },
      },
      type: {
        target_id: 'group_notices',
      },
      title: {
        value: title,
      },
      field_body: {
        value: body,
      },
      field_group: group,
      field_notice_document:
        field_notice_document.length > 0 ? field_notice_document : [],
    };

    patchNode(
      'node/' + node.nid,
      data,
      this.state.token,
      // this.props.user.access_token,
    )
      .then(_response => {
        this.setState({
          isLoading: false,
        });

        this.setState({
          patchModalVisible: true,
          alertTitle: alertMessages.noticeUpdate.title,
          alertBody: alertMessages.noticeUpdate.message,
        });
      })
      .catch(_error => {
        this.setState({
          isLoading: false,
        });

        this.setState({
          modalVisible: true,
          alertType: 'error',
          alertTitle: alertMessages.postNodeFailed.title,
          alertBody: alertMessages.postNodeFailed.message,
          alertCancelButton: true,
          alertConfirmButton: false,
        });
      });
  };

  deleteConfirm = () => {
    this.setState({
      deleteModalConfirmVisible: true,
      alertType: 'warning',
      alertTitle: alertMessages.noticeDeleteConfirm.title,
      alertBody: alertMessages.noticeDeleteConfirm.message,
      alertCancelButton: true,
      alertConfirmButton: true,
      confirmButtonLabel: 'Delete',
    });
  };
  
  deleteNode = () => {
    deleteRequest(
      'node/' + this.state.node.nid,
      this.state.token,
      // this.props.user.access_token,
    )
      .then(response => {
        this.setState({
          deleteModalVisible: true,
          alertType: 'success',
          alertTitle: 'Success!',
          alertBody: 'Notice has been deleted.',
          alertConfirmButton: true,
        });
      })
      .catch(_error => {
        this.setState({
          modalVisible: true,
          alertType: 'error',
          alertTitle: 'Error',
          alertBody: "Sorry, we couldn't delete this notice. Please try again.",
          alertConfirmButton: true,
        });
      });
  };

  notifyGroup = () => {
    const data = {
      nid: this.props.match.params.nid,
      type: 'update',
    };

    postNode(
      'notice',
      data,
      this.state.token,
      this.props.user.access_token,
    )
      .then(() => {
        console.log('notified');
      })
      .catch(error => {
        console.log('failed to nofity');
      });
  };

  uploadMultipleFiles = e => {
    let {files} = this.state;

    for(let i = 0; i < e.target.files.length; i++) {
      files.push(e.target.files[i]);
    }

    this.setState({
      files: files,
    });
  }

  /**
   * @function handleCategorySelection
   * @description Modal Picker callback / Updates the header title and sets the state to the selected category
   * @param {object} event
   */
  handleGroupSelection = (event) => {
    this.setState(
      {
        selectedGroup: parseInt(event.value, 10),
        selectedGroupItem: event,
      },
      function () {
        this.getGroupMembers();
      },
    );
  };

  setModalVisible = _visible => {
    this.setState({
      modalVisible: _visible,
    });
  };

  getGroupTitle = () => {
    const {groups, selectedGroup} = this.state;

    if (groups.length > 0) {
      return groups.find(x => parseInt(x.id, 10) === selectedGroup).label;
    }
  };

  removeExistingFile = _fid => {
    const {existingFiles} = this.state;

    let newFiles = [];
    let newIDs = [];

    existingFiles.forEach(file => {
      if (file.fid !== _fid) {
        newFiles.push(file);
        newIDs.push(file.fid);
      }
    });

    this.setState({
      existingFiles: newFiles,
      sentFileIDs: newIDs,
    });
  };

  removeFile = _index => {
    const {files} = this.state;

    let newFiles = [];

    files.forEach((file, index) => {
      if (_index !== index) {
        newFiles.push(file);
      }
    });

    this.setState({
      files: newFiles,
    });
  };

  renderMember = item => {
    let name = '';

    if (item.field_first_name) {
      name = item.field_first_name;
    }

    if (item.field_last_name) {
      name = name + ' ' + item.field_last_name;
    }

    name = name + ' (' + item.name + ')';

    return (
      <Col>
        <div className="member">
          <span dangerouslySetInnerHTML={{__html: name}} />
        </div>
      </Col>
    );
  };

  renderExistingFiles = () => {
    const {existingFiles} = this.state;

    if (existingFiles.length > 0) {
      return (
        <>
          {existingFiles.map((file, index) => {
            return (
              <div key={`doc-${index}`} className="document">
                <Row noGutters>
                  <Col className="col-auto">
                    <File />
                  </Col>
                  <Col className="d-flex">
                    <span className="file-name">{file.name}</span>
                    <Button variant="link" className="ml-auto" onClick={() => {
                      this.removeExistingFile(file.fid);
                    }}>
                      <X />
                      <span>Remove</span>
                    </Button>
                  </Col>
                </Row>
              </div>
            );
          })}
        </>
      );
    }
  };

  renderSelectedFiles = () => {
    const {files} = this.state;

    if (files.length > 0) {
      return (
        <>
          {files.map((file, index) => {
            return (
              <div key={`doc-${index}`} className="document">
                <Row noGutters>
                  <Col className="col-auto">
                    <File />
                  </Col>
                  <Col className="d-flex">
                    <span className="file-name">{file.name}</span>
                    <Button variant="link" className="ml-auto" onClick={() => {
                      this.removeFile(index)
                    }}>
                      <X />
                      <span>Remove</span>
                    </Button>
                  </Col>
                </Row>
              </div>
            );
          })}
        </>
      );
    }
  };

  editGroup = group => {
    if (this.state.editGroupModalVisible) {
      const {selectedGroup, group_members} = this.state;
      return (
        <EditGroup
          modalVisible={this.state.editGroupModalVisible}
          setModalVisible={() => {
            this.setState({
              editGroupModalVisible: !this.state.editGroupModalVisible,
            });
          }}
          selectedGroup={selectedGroup.id}
          group_members={group_members}
          group_id={selectedGroup}
          group_label={this.getGroupTitle()}
          onEdit={id => {
            this.getGroups(id);
          }}
        />
      );
    }
  };

  createGroup = () => {
    if (this.state.addGroupModalVisible) {
      return (
        <CreateGroup
          modalVisible={this.state.addGroupModalVisible}
          setModalVisible={() => {
            this.setState({
              addGroupModalVisible: !this.state.addGroupModalVisible,
            });
          }}
          onCreated={id => {
            this.getGroups(id);
          }}
        />
      );
    }
  };

  errorAlerts = () => {
    return (
      <AlertModal 
        showAlert={this.state.modalVisible} 
        showAlertCallback={() => {
          this.setState({
            modalVisible: !this.state.modalVisible,
          });
        }}
        alertType={this.state.alertType}
        alertMessageTitle={this.state.alertTitle}
        alertMessageBody={this.state.alertBody}
        cancelButton={this.state.alertCancelButton}
        confirmButton={this.state.alertConfirmButton}
        cancelButtonLabel={this.state.cancelButtonLabel}
        confirmButtonLabel={this.state.confirmButtonLabel}
      />
    );
  }

  deleteConfirmAlert = () => {
    return (
      <AlertModal 
        showAlert={this.state.deleteModalConfirmVisible} 
        showAlertCallback={() => {
          this.setState({
            deleteModalConfirmVisible: !this.state.deleteModalConfirmVisible,
          });
        }}
        alertType={'error'}
        alertMessageTitle={this.state.alertTitle}
        alertMessageBody={this.state.alertBody}
        cancelButton={this.state.alertCancelButton}
        confirmButton={this.state.alertConfirmButton}
        cancelButtonLabel={this.state.cancelButtonLabel}
        confirmButtonLabel={this.state.confirmButtonLabel}
        onConfirm={() => {
          this.deleteNode();
        }}
      />
    );
  }

  deleteAlert = () => {
    return (
      <AlertModal 
        showAlert={this.state.deleteModalVisible} 
        showAlertCallback={() => {
          this.setState({
            deleteModalVisible: !this.state.deleteModalVisible,
          });
        }}
        alertType={'success'}
        alertMessageTitle={this.state.alertTitle}
        alertMessageBody={this.state.alertBody}
        confirmButton={true}
        confirmButtonLabel={'OK'}
        onConfirm={() => {
          this.props.history.push('/account/notices');
        }}
      />
    );
  }

  archiveAlert = () => {
    return (
      <AlertModal 
        showAlert={this.state.archiveModalVisible} 
        showAlertCallback={() => {
          this.setState({
            archiveModalVisible: !this.state.archiveModalVisible,
          });
        }}
        alertType={'success'}
        alertMessageTitle={this.state.alertTitle}
        alertMessageBody={this.state.alertBody}
        confirmButton={true}
        confirmButtonLabel={'OK'}
        onConfirm={() => {
          this.props.history.push('/account/notices');
        }}
      />
    );
  }

  patchAlert = () => {
    return (
      <AlertModal 
        showAlert={this.state.patchModalVisible} 
        showAlertCallback={() => {
          this.setState({
            patchModalVisible: !this.state.patchModalVisible,
          });
        }}
        alertType={'success'}
        alertMessageTitle={this.state.alertTitle}
        alertMessageBody={this.state.alertBody}
        cancelButton={true}
        confirmButton={true}
        cancelButtonLabel={'No'}
        confirmButtonLabel={'Yes'}
        onCancel={() => {
          this.props.history.push('/account/notices');
        }}
        onConfirm={() => {
          this.notifyGroup();
          this.props.history.push('/account/notices');
        }}
      />
    );
  }

  render() {
    if (this.state.isComponentLoading) {
      return <NoticesEditSkeletonScreen />;
    } else {
      return (
        <main className="edit-notice notices screen">
          <PageHeader pageName="Edit Notice" filters={false} />

          {this.errorAlerts()}
          {this.deleteConfirmAlert()}
          {this.deleteAlert()}
          {this.archiveAlert()}
          {this.patchAlert()}

          {this.editGroup()}
          {this.createGroup()}

          <div className="form-group">
            <Row className="mb-1">
              <Col>
                <label>Title</label>
              </Col>
            </Row>

            <Row>
              <Col>
                <input
                  type="text"
                  value={this.state.title}
                  placeholder="Add title"
                  className="form-control"
                  onInput={(event) => {
                    this.setTitle(event.target.value);
                  }}
                />
              </Col>
            </Row>
          </div>

          <div className="form-group">
            <Row className="mb-1">
              <Col>
                <label>Body</label>
              </Col>
            </Row>

            <Row>
              <Col>
                <CKEditor
                  editor={ClassicEditor}
                  config={{
                    toolbar: [
                      "bold",
                      "italic",
                      "link",
                      "bulletedList",
                      "undo",
                      "redo",
                    ],
                  }}
                  data={decode(this.state.body)}
                  placeholder="Add body..."
                  onReady={(editor) => {}}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    this.setBody(data);
                  }}
                />
              </Col>
            </Row>
          </div>

          <div className="form-group">
            <Row className="mb-2">
              <Col>
                <label>Documents</label>
              </Col>
            </Row>

            <div className="documents-container  mb-2">
              {this.renderExistingFiles(this.state.existingFiles)}
              {this.renderSelectedFiles()}
            </div>

            <Row>
              <Col>
                <input
                  ref={(input) => (this.fileInput = input)}
                  type="file"
                  accept="application/pdf"
                  onChange={this.uploadMultipleFiles}
                  multiple
                  style={{ display: "none" }}
                />

                <Button
                  variant="link"
                  onClick={() => {
                    this.fileInput.click();
                  }}
                >
                  <Plus />
                  <span>
                    {this.state.files.length > 0
                      ? "Add Documents"
                      : "Select Documents"}
                  </span>
                </Button>
              </Col>
            </Row>
          </div>

          <div className="form-group">
            <Row className="mb-1">
              <Col>
                <label>Select Group</label>
              </Col>
            </Row>

            <Row className="align-items-center">
              <Col md={8}>
                <CategorySelector
                  name="notices"
                  categories={this.state.groups}
                  selectedCategory={this.state.selectedGroupItem}
                  handleCategorySelection={this.handleGroupSelection}
                />
              </Col>

              <Col xs={'auto'} className="mt-2 mt-md-0">
                <Button
                  variant="link"
                  onClick={() => {
                    this.setState({
                      addGroupModalVisible: true,
                    });
                  }}
                >
                  <Plus />
                  <span>Create Group</span>
                </Button>
              </Col>
            </Row>

            <Row className="mt-2">
              <Col xs={'auto'}>
                {this.state.selectedGroup !== 0 && (
                  <Button
                    variant="link"
                    onClick={() => {
                      this.setState({
                        editGroupModalVisible: true,
                      });
                    }}
                  >
                    <Edit2 />
                    <span>Edit Group</span>
                  </Button>
                )}
              </Col>
            </Row>
          </div>

          <hr style={{margin: '3rem 0'}}/>

          <div className="form-group">
            <Row className="align-items-center justify-content-between">
              <Col xs={'auto'}>
                <Button
                  variant="link"
                  className="delete-btn"
                  onClick={() => {
                    this.deleteConfirm();
                  }}
                >
                  <Trash2 />
                  <span>Delete Notice</span>
                </Button>
              </Col>
              <Col xs={'auto'}>
                <Button
                  variant="link"
                  className="archive-btn"
                  onClick={() => {
                    this.archive();
                  }}
                >
                  <FileText />
                  <span>Archive Notice</span>
                </Button>
              </Col>
              <Col xs={'auto'}>
                {this.state.isLoading ? (
                  <Spinner animation="border" role="status" variant="primary">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                ) : (
                  <Button
                    className="submit-btn"
                    onClick={() => {
                      this.uploadFiles();
                    }}
                  >
                    <span>Update Notice</span>
                    <ArrowRight />
                  </Button>
                )}
              </Col>
            </Row>
          </div>

        </main>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default withRouter(connect(mapStateToProps)(EditNotice));
