import React from 'react';


// Modules
import {connect} from 'react-redux';
import {withRouter} from 'react-router';

// App
import {getOnlineNode} from '../../core/getNode';
import {getToken} from '../../core/postNode';
import {deleteRequest} from '../../core/delete';
import PageHeader from '../../partials/pageHeader';
import Error from '../../partials/error';
import NoData from '../../partials/noData';
import PostTitle from '../../partials/postTitle';
import EditGroup from './editGroup';
import CreateGroup from './createGroup';
import AlertModal from '../../partials/alertModal';
import NoticesGroupsSkeletonScreen from './skeleton-screens/noticesGroupsSkeletonScreen';

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Trash, Edit, Plus } from "react-feather";

class Groups extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      isRefreshing: false,
      editGroupModalVisible: false,
      addGroupModalVisible: false,
      deleteGroupModalVisible: false,
      isError: false,
      errorStatus: '',
      errorMessage: '',
      groups: '',
      token: '',
    };
  }

  /**
   * @function componentDidMount
   * @description Default react method. Fired when component is rendered
   */
  componentDidMount() {
    this.getToken();
    this.getContent();
  }

  getToken = () => {
    getToken().then(_response => {
      this.setState({
        token: _response.data,
      });
    });
  };

  /**
   * @function setData
   * @description Updates the state of the component upon data retrival
   * @param {object} _data
   * @param {object} _realm
   */
  setData = _data => {
    this.setState({
      isLoading: false,
      isRefreshing: false,
      isPaginating: false,
      modalVisible: false,
      groups: _data,
    });
  };

  /**
   * @function setError
   * @description Updates the state of the component upon an error
   * @param {boolean} _isError
   * @param {int} _errorStatus
   * @param {string} _errorMessage
   */
  setError = (_isError, _errorStatus, _errorMessage) => {
    this.setState({
      isLoading: false,
      isRefreshing: false,
      isPaginating: false,
      isError: _isError,
      errorStatus: _errorStatus,
      errorMessage: _errorMessage,
      modalVisible: false,
    });
  };

  getContent = () => {
    let path =
      'groups_list_manager/' +
      this.props.user.current_user.uid;

    getOnlineNode(path, this.props.user.access_token)
      .then(response => {
        this.setData(response.data);
      })
      .catch(_error => {
        if (_error.response) {
          this.setError(
            true,
            _error.response.status,
            _error.response.data.message,
          );
        } else if (_error.request) {
          this.setError(true, 0, 'Could not make the request.');
        } else {
          this.setError(true, 0, 'Unknown error.');
        }
      });
  };

  deleteGroup = () => {
    const {selectedGroup} = this.state
    deleteRequest(
      '/group/' + selectedGroup.id,
      this.state.token,
    )
      .then(response => {
        this.getContent();
      })
      .catch(error => {
        console.log('error: ', error);
        this.setState({
          deleteGroupModalVisible: true,
          alertTitle: 'Error',
          alertType: 'error',
          alertBody: 'Failed to delete group. Please try again.',
          alertConfirmButton: true,
        });
      });
  };

  // delete group
  deleteConfirm = group => {
    this.setState({
      selectedGroup: group,
      deleteGroupModalVisible: true,
      alertType: 'error',
      alertTitle: 'Delete "' + group.label + '"?',
      alertBody: 'Are you sure you want to delete this group?',
      alertCancelButton: true,
      alertConfirmButton: true,
      confirmButtonLabel: 'Delete',
    });
  };

  // Data component
  dataComponent = () => {
    const {groups} = this.state;

    if (typeof groups !== 'undefined' && groups.length > 0) {
      return (
        <Row>
          {groups.map(item => {
            return this.renderGroupItem(item);
          })}
        </Row>
      );
    } else {
      return (
        <NoData activeIndex={0} />
      );
    }
  };

  renderGroupItem = item => {
    const itemClass = "group-item";
    const itemClassList = `${itemClass}  item`;

    let itemStyle = itemClassList;

    return (
      <Col
        key={"group" + item.id}
        className="d-flex gutter-lg"
        xs={12}
        lg={6}
      >
        <div className={`${itemStyle}`}>
          <Row noGutters className="align-items-center">
            <Col>
              <div className={`${itemClass}__content  item__content`}>
                <PostTitle
                  title={item.label}
                  headingLevel={"h6"}
                />
              </div>
            </Col>
            <Col xs={"auto"} className="d-flex">
              <div className="px-2">
                <Button className="delete" onClick={() => {
                  this.deleteConfirm(item);
                }}>
                  <Trash />
                  <span>Delete</span>
                </Button>
              </div>
              <div className="px-2">
                <Button className="edit" onClick={() => {
                  this.setState({
                    selectedGroup: item,
                    editGroupModalVisible: true,
                  });
                }}>
                  <Edit />
                  <span>Edit</span>
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Col>
    );
  };

  editGroup = group => {
    if (this.state.editGroupModalVisible) {
      const {selectedGroup} = this.state;
      return (
        <EditGroup
          modalVisible={this.state.editGroupModalVisible}
          setModalVisible={() => {
            this.setState({
              editGroupModalVisible: !this.state.editGroupModalVisible,
            });
          }}
          selectedGroup={selectedGroup.id}
          group_members={selectedGroup.members}
          group_id={selectedGroup.id}
          group_label={selectedGroup.label}
          onEdit={id => {
            this.getContent();
          }}
        />
      );
    }
  };

  createGroup = () => {
    if (this.state.addGroupModalVisible) {
      return (
        <CreateGroup
          modalVisible={this.state.addGroupModalVisible}
          setModalVisible={() => {
            this.setState({
              addGroupModalVisible: !this.state.addGroupModalVisible,
            });
          }}
          onCreated={() => {
            this.getContent();
          }}
        />
      );
    }
  };

  /**
   * @function render
   * @description Default render method
   */
  render() {
    if (this.state.isLoading) {
      return <NoticesGroupsSkeletonScreen />;
    } else {
      if (this.state.isError) {
        return (
          <Error 
            status={this.state.errorStatus}
            message={this.state.errorMessage}
          />
        );
      } else {
        return (
          <main className="groups screen">
            <PageHeader 
              pageName="My Groups" 
              filters={false}
            />
            <div className="notices-actions">
              <Row className="justify-content-end">
                <Col className="col-auto">
                  <Button onClick={() => {
                    this.setState({
                      addGroupModalVisible: true,
                    })
                  }}>
                    <Plus />
                  </Button>
                </Col>
              </Row>
            </div>
            {this.dataComponent()}
            {this.editGroup()}
            {this.createGroup()}
            <AlertModal 
              showAlert={this.state.deleteGroupModalVisible} 
              showAlertCallback={() => {
                this.setState({
                  deleteGroupModalVisible: false,
                });
              }}
              alertType={this.state.alertType}
              alertMessageTitle={this.state.alertTitle}
              alertMessageBody={this.state.alertBody}
              cancelButton={this.state.alertCancelButton}
              cancelButtonLabel={this.state.cancelButtonLabel}
              confirmButton={this.state.alertConfirmButton}
              confirmButtonLabel={this.state.confirmButtonLabel}
              onCancel={() => {
                this.setState({
                  deleteGroupModalVisible: false,
                  selectedGroup: '',
                });
              }}
              onConfirm={() => {
                this.deleteGroup(this.state.selectedGroup);
              }}
            />
          </main>
        );
      }
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default withRouter(connect(mapStateToProps)(Groups));
