import React from 'react';

// Modules
import {connect} from 'react-redux';

// App
import ForumsBoard from './forumsBoard';
import {getOnlineNode} from '../../core/getNode';
import PageHeader from '../../partials/pageHeader';
import {alertMessages} from '../../partials/alertMessages';
import Error from '../../partials/error';
import {_checkContent} from '../../core/checkContent';
import ForumsSkeletonScreen from './skeleton-screens/forumsSkeletonScreen';

// Data
import db from './../../core/db';
import {getContentCount} from '../../core/getRecordCount';
import {updateContentCounterPreviousCount} from '../../core/updateData';
import {getFavorites, getFavouritesLength} from './../../core/getFavorites';
import NoData from '../../partials/noData';


// UI components
import Row from 'react-bootstrap/Row';

class Forums extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      isRefreshing: false,
      isError: false,

      errorStatus: '',
      errorMessage: '',

      currentPage: 1,
      pageSize: 20,
      index: 0,
      totalItems: 0,

      selectedCategory: 0,
      categories: [],
      token: '',

      data: [],
      favorites: [],

      activeIndex: 0,

      counter: 0,
    };

  }

  /**
   * @function componentDidMount
   * @description Default react method. Fired when component is rendered
   */
  componentDidMount() {
    this.checkSession();
  }
  
  checkSession = () => {
    if (Object.keys(this.props.user).length === 0) {
      this.props.history.push('/');
    } else {
      this.getContent();
    }
  }

  /**
   * @function setData
   * @description Updates the state of the component upon data retrival
   * @param {object} _data
   * @param {object} _realm
   */
  setData = async (_data) => {
    this.setState(
      {
        // isLoading: false,
        isRefreshing: false,
        isPaginating: false,
        data: _data,
      }, () => {
        this.getForumTopics();
      }
    );

    const serverCount = await getContentCount('forums')
    
    if (serverCount && serverCount[0]) {
      const count = serverCount[0] ? serverCount[0].serverCount : 0;
      updateContentCounterPreviousCount('forums', count)
    }

    _checkContent();
  };

  /**
   * @function setError
   * @description Updates the state of the component upon an error
   * @param {boolean} _isError
   * @param {int} _errorStatus
   * @param {string} _errorMessage
   */
  setError = (_isError, _errorStatus, _errorMessage) => {
    this.setState({
      isLoading: false,
      isRefreshing: false,
      isPaginating: false,
      isError: _isError,
      errorStatus: _errorStatus,
      errorMessage: _errorMessage,
    });
  };

  favorite = async (_tid) => {
    const {activeIndex} = this.state;

    let forum = await db.forum.get(_tid);
    forum.favorite = 'true';
    db.forum.put(forum, _tid);

    if (activeIndex === 0) {
      this.getContent();
    }

    if (activeIndex === 1) {
      this.getFavourites();
    }
  }

  unfavorite = async (_tid) => {
    const {activeIndex} = this.state;
  
    let forum = await db.forum.get(_tid);
    forum.favorite = 'false';
    db.forum.put(forum, _tid);

    if (activeIndex === 0) {
      this.getContent();
    }

    if (activeIndex === 1) {
      this.getFavourites();
    }
  }

  dataHandler = async (_data) => {
    let forums = [];

    for (const data of _data) {
      let existingForum = await db.forum.get(data.tid);
      let favorite = 'false';

      if (existingForum) {
        favorite = existingForum.favorite;
      }
      
      const forum = {
        tid: data.tid,
        title: data.title,
        description: data.description__value,
        favorite: favorite,
      };

      db.forum.put(forum, data.tid);

      forums.push(forum);
    }

    let data = _data;

    data = forums;

    this.setData(data);
  }

  /**
   * @function getContent
   * @description Retrieves the data from an API
   */
  getContent = () => {
    let path = 'all_categories/forums';

    getOnlineNode(path, this.props.user.access_token)
      .then((response) => {
        this.dataHandler(response.data);
      })
      .catch((_error) => {
        console.log('error: ', _error);
        if (_error.response) {
          //console.log('@rest response: ', _error.response);
          this.setError(
            true,
            _error.response.status,
            _error.response.statusText,
          );
        } else if (_error.request) {
          //console.log('@rest request: ', _error.request);
          this.setError(true, 0, alertMessages.requestError.message);
        } else {
          //console.log('@rest unknown: ', _error);
          this.setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };

  /**
   * @function getForumTopics
   * @description Retrieves the data from an API
   */
  getForumTopics = () => {
    let topics;
    let topicsData = [];

    this.state.data.forEach(item => {
      let path = "forum_topic/" + item.tid + "/all?_format=json&status=1&promote=1";

      getOnlineNode(path, this.props.user.access_token)
        .then((response) => {
          topics = response.data.rows;
          topics.forEach(topic => {
            topicsData.push({
              nid: topic.nid,
              taxonomy_forums: topic.taxonomy_forums,
              title: topic.title,
            });
          });
          this.setState(
            {
              topicsData: topicsData
            },
            () => {
              this.setState({isLoading: false});
            },
          );
        })
        .catch((_error) => {
          // error
        });
    });
  };

  /**
   * @function getFavourites
   * @description Retrieves the favourited data from the database
   */
  getFavourites = async () => {
    const favorites = await getFavorites('forum', this.state.selectedCategory, this.state.index, this.state.pageSize);
    const favoritesLength = await getFavouritesLength('forum', this.state.selectedCategory);

    this.setState({
      data: favorites,
      totalItems: favoritesLength,
      isLoading: false,
      isRefreshing: false,
      isPaginating: false,
    });
  }

  // Data component
  dataComponent = () => {
    const {data} = this.state;

    if (typeof data !== 'undefined' && data.length > 0) {
      return (
        <Row>
          {data.map(item => {

            let topics;

            // Filter topics data for topics that match forum tid 
            let filteredTopics = this.state.topicsData.filter(topic => topic.taxonomy_forums === item.tid);

            if (filteredTopics) {
              topics = filteredTopics;
            }

            return (
              <ForumsBoard
                key={'forum-board-' + item.tid}
                item={item}
                tid={item.tid}
                favorite={this.favorite}
                unfavorite={this.unfavorite}
                topics={topics}
              />
            );
          })}
        </Row>
      );
    } else {
      return (
        <NoData activeIndex={this.state.activeIndex} />
      );
    }
  };

  /**
   * @function render
   * @description Default render method
   */
  render() {
    if (this.state.isLoading) {
      return <ForumsSkeletonScreen />
    } else {
      if (this.state.isError) {
        return (
          <Error 
            status={this.state.errorStatus}
            message={this.state.errorMessage}
          />
        );
      } else {
        return (
          <main className="forums  screen">
            <PageHeader 
              pageName="Discussion Boards" 
              filters={true}
              getContent={() => {
                this.setState(
                  {
                    activeIndex: 0,
                    isLoading: true,
                  },
                  function () {
                    this.getContent();
                  },
                );
              }}
              getFavourites={() => {
                this.setState(
                  {
                    activeIndex: 1,
                    isLoading: true,
                  },
                  function () {
                    this.getFavourites();
                  },
                );
              }}
              activeTabIndex={this.state.activeIndex}
            />
            {this.dataComponent()}
          </main>
        );
      }
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default connect(mapStateToProps)(Forums);
