// Dependencies
import React from 'react';

// Modules
import {withRouter} from 'react-router'
import {connect} from 'react-redux';
import moment from 'moment';

// App
import {getOnlineNode} from '../../core/getNode';
import Button from 'react-bootstrap/Button';
import LoadingSpinner from '../../partials/loadingSpinner';
import PostTitle from '../../partials/postTitle';
import {alertMessages} from "../../partials/alertMessages";
import Error from "../../partials/error";

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Clock, Tag } from 'react-feather';

class PollsSingle extends React.Component {
  constructor(props) {
    super(props);
    this.scrollViewRef = React.createRef();
    this.state = {
      isLoading: true,
      isPaginating: false,
      post: null,
      pageSize: 10,
      currentPage: 0,
      total_pages: 0,
    };
  }

  componentDidMount() {
    this.loadNode();
  }

  /**
   * @function setData
   * @description Updates the state of the component upon data retrival
   * @param {object} _data
   * @param {object} _realm
   */
  setData = (_data) => {
    this.setState(
      {
        isLoading: false,
        isRefreshing: false,
        isError: false,
        post: _data,
      },
    );
  };

  /**
   * @function setError
   * @description Updates the state of the component upon an error
   * @param {boolean} _isError
   * @param {int} _errorStatus
   * @param {string} _errorMessage
   */
  setError = (_isError, _errorStatus, _errorMessage) => {
    this.setState({
      isLoading: false,
      isRefreshing: false,
      isError: _isError,
      errorStatus: _errorStatus,
      errorMessage: _errorMessage,
    });
  };


  loadNode = () => {
    console.log('props: ', this.props);

    const nid = this.props.match.params.nid;

    if (nid) {
      this.getContent(nid);
    } else {
      this.setError(true, 0, alertMessages.contentError);
    }
  };

  /**
   * @function getContent
   * @description Retrieves the data from an API / Fallback to local realm object if there is no connection
   */
  getContent = (nid) => {
    let path = 'polls/all/' + nid + '?_format=json&status=1&promote=1';

    getOnlineNode(path, this.props.user.access_token)
      .then((response) => {
        if (response.data.rows.length > 0) {
          this.setData(response.data.rows[0]);
        } else {
          this.setError(true, 200, alertMessages.noData);
        }
      })
      .catch((_error) => {
        console.log('error: ', _error);
        if (_error.response) {
          this.setError(
            true,
            _error.response.status,
            _error.response.statusText,
          );
        } else if (_error.request) {
          //console.log('@rest request: ', _error.request);
          this.setError(true, 0, alertMessages.requestError.message);
        } else {
          //console.log('@rest unknown: ', _error);
          this.setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };

  loadMore = () => {
    this.setState(
      {
        isPaginating: true,
        currentPage: this.state.currentPage + 1,
      },
    );
  };

  renderLoadMore = () => {
    if (this.state.currentPage < this.state.total_pages) {
      return (
        <div>
          <Button onClick={() => {
            if (!this.state.isPaginating) {
              this.loadMore();
            }
          }}>
            {'Load more'}
          </Button>
        </div>
      );
    } else {
      return null;
    }
  };

  renderPostImage = (post) => {
    let hasImage = post.uri.length > 0 ? true : false;

    if (hasImage && !this.state.isLandscape) {
      return (
        <div className="article__image">
          <img src={post.uri} alt={'img-' + post.nid} />
        </div>
      );
    }
  };

  render() {
    if (this.state.isLoading) {
      return <LoadingSpinner />;
    } else {
      if (this.state.isError) {
        return (
          <Error 
            status={this.state.errorStatus}
            message={this.state.errorMessage}
          />
        );
      } else {
        const {post} = this.state;
        const timestamp = moment.unix(post.created).format("Do MMM YYYY");

        let category = 'Uncategorised';

        if (post.field_category_1) {
          category = post.field_category_1;
        }

        if (post.categoryLabel) {
          category = post.categoryLabel;
        }

        return (
          <main ref={this.containerRef} className="poll  single">
            <article className="article">
              <header className="article__header">
                <PostTitle title={post.title} headingLevel={'h1'} />
                <div className="article__meta">
                  <Row>
                    <Col xs={"auto"}>
                      <div className="timestamp align-items-center">
                        <Clock className="timestamp__icon" />
                        <span className="timestamp__label  label">{timestamp}</span> 
                      </div>

                    </Col>
                    <Col xs={"auto"}>
                      <div className="category align-items-center">
                        <Tag className="category__icon" />
                        <span className="category__label  label">{category}</span>
                      </div>
                    </Col>
                  </Row>
                </div>
              </header>
              {this.renderPostImage(post)}
              <div className="article__body">
              <div>
                <div dangerouslySetInnerHTML={{__html: post.body}} />
              </div>
              </div>
            </article>
          </main>
        );
      }
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default withRouter(connect(mapStateToProps)(PollsSingle));
