import React, { Component } from 'react';

// Modules
import ContentLoader from 'react-content-loader';

class NoticesEditSkeletonScreen extends Component {  
  constructor() {
    super();
    this.state = {
      backgroundColor: '#f3f3f3',
      foregroundColor: '#ecebeb',
    };
  }

  render() {

    return (
      <main className="edit-notice notices screen">
        <div className="page-header">
          <ContentLoader 
            speed={2}
            width={'100%'}
            height={'100%'}
            viewBox="0 0 600 60"
            backgroundColor={this.state.backgroundColor}
            foregroundColor={this.state.foregroundColor}
          >
            <rect x="0" y="0" width="247.968" height="60" rx="10" ry="10"/>
          </ContentLoader>
        </div>

        {/* Title */}
        <div className="form-group">
          <ContentLoader 
            speed={2}
            width={'100%'}
            height={'100%'}
            viewBox="0 0 600 68"
            backgroundColor={this.state.backgroundColor}
            foregroundColor={this.state.foregroundColor}
          >
            <rect x="0" y="26.202" width="600" height="41.053" rx="10" ry="10"/>
            <rect x="29.759" y="0" width="69.508" height="20" rx="10" ry="10" />
            <circle cx="10" cy="10" r="10" />
          </ContentLoader>
        </div>

        {/* Body */}
        <div className="form-group">
          <ContentLoader 
            speed={2}
            width={'100%'}
            height={'100%'}
            viewBox="0 0 600 144"
            backgroundColor={this.state.backgroundColor}
            foregroundColor={this.state.foregroundColor}
          >
            <rect x="0" y="26.202" width="600" height="116.926" rx="10" ry="10"/>
            <rect x="29.759" y="0" width="69.508" height="20" rx="10" ry="10"/>
            <circle cx="10" cy="10" r="10" />
          </ContentLoader>
        </div>

        {/* Documents */}
        <div className="form-group">
          <ContentLoader 
            speed={2}
            width={'100%'}
            height={'100%'}
            viewBox="0 0 600 74"
            backgroundColor={this.state.backgroundColor}
            foregroundColor={this.state.foregroundColor}
          >
            <rect x="0" y="31.997" width="222.164" height="41.334" rx="10" ry="10"/>
            <rect x="29.759" y="0" width="83.507" height="20" rx="10" ry="10"/>
            <circle cx="10" cy="10" r="10" />
          </ContentLoader>
        </div>

        {/* Select group */}
        <div className="form-group">
          <ContentLoader 
            speed={2}
            width={'100%'}
            height={'100%'}
            viewBox="0 0 600 66"
            backgroundColor={this.state.backgroundColor}
            foregroundColor={this.state.foregroundColor}
          >
            <rect x="0" y="26.202" width="300" height="39.378" rx="10" ry="10"/>
            <rect x="29.759" y="0" width="91.209" height="20" rx="10" ry="10"/>
            <circle cx="10" cy="10" r="10" />
          </ContentLoader>
        </div>

        {/* Members */}
        <div className="form-group">
          <ContentLoader 
            speed={2}
            width={'100%'}
            height={'100%'}
            viewBox="0 0 600 89"
            backgroundColor={this.state.backgroundColor}
            foregroundColor={this.state.foregroundColor}
          >
            <rect x="-0" y="46.903" width="165.635" height="41.158" rx="10" ry="10"/>
            <rect x="195.62" y="46.903" width="186.949" height="41.158" rx="10" ry="10"/>
            <rect x="29.759" y="0" width="114.085" height="20" rx="10" ry="10"/>
            <circle cx="10" cy="10" r="10" />
          </ContentLoader>
        </div>
        
        <hr style={{margin: '3rem 0'}}/>

        {/* Action buttons */}
        <div className="form-group">
          <ContentLoader 
            speed={2}
            width={'100%'}
            height={'100%'}
            viewBox="0 0 600 42"
            backgroundColor={this.state.backgroundColor}
            foregroundColor={this.state.foregroundColor}
          >
            <rect x="481.221" y="0" width="118.779" height="41.158" rx="10" ry="10"/>
            <rect x="240.61" y="0" width="118.779" height="41.158" rx="10" ry="10"/>
            <rect x="0" y="0" width="118.779" height="41.158" rx="10" ry="10"/>
          </ContentLoader>
        </div>

      </main>
    );
  }

}

export default NoticesEditSkeletonScreen;