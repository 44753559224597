import React, { Component } from 'react';

// Modules
import { BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";

// App
import store from './redux/store';
import {getOnlineNode} from '../src/core/getNode';
import LoadingSpinner from './partials/loadingSpinner';
import Header from './partials/header';
import Routes from './partials/routes';
import ScrollToTop from './partials/scrollToTop';
import { _checkContent } from './core/checkContent';

// UI components
import Container from 'react-bootstrap/Container';

// Style 
import './styles/main.scss';

class App extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isLoading: true,
    };

    this.unsubscribe = null;
  }

  componentDidMount() {
    this._bootstrapAsync();

    this.unsubscribe = store.subscribe(() => {
      const storeState = store.getState();
      const data = {
        user: storeState.authReducer.user,
        content_counter: storeState.authReducer.content_counter,
      };

      localStorage.setItem('userSession', JSON.stringify(data));
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  checkSession = (_user) => {
    getOnlineNode('user/' + _user.current_user.uid, _user.access_token)
      .then((response) => {
        store.dispatch({type: 'SAVE_SESSION', payload: _user});

        this.setState({
          isLoading: false,
        });

        _checkContent();
      })
      .catch((_error) => {
        store.dispatch({type: 'SAVE_SESSION', payload: _user});

        this.setState({
          isLoading: false,
        });
      });
  };

  // Fetch the token from storage then navigate to our appropriate place
  async _bootstrapAsync() {
    let session = localStorage.getItem('userSession');
    session = JSON.parse(session);

    if (session && session.user && session.user.current_user) {
      this.checkSession(session.user);
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  handleHeaderCallback = (visible) => {
    this.setState({
      showNavigation: visible,
    });
  };
  
  render() {
    if (this.state.isLoading) {
      return <LoadingSpinner className="spinner-container--app"/>;
    } else {

      let navbarState = "nav-collapsed";
      if (this.state.showNavigation === true || this.state.showNavigation === 'true') {
        navbarState = "nav-expanded";
      }

      return (
        <Router>
          <ScrollToTop />
          <Header headerCallback={this.handleHeaderCallback} />
          <div className={`page-container ${navbarState}`}>
            <Container className="content-container">
              <Routes />
            </Container>
          </div>
        </Router>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default connect(mapStateToProps)(App)