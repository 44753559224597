import React, {useState, useRef} from 'react';

// Modules
import Cropper from 'react-cropper';

// App
import {resizeFile} from '../../partials/cropper/resizer';

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import { Check, X } from 'react-feather';
import 'cropperjs/dist/cropper.css';

const resizeCroppedImage = (base64Str, maxWidth, maxHeight) => {
  return new Promise((resolve) => {
    let img = new Image()
    img.src = base64Str;
    img.onload = () => {
      let canvas = document.createElement('canvas');
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }
      canvas.width = width;
      canvas.height = height;

      let ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, width, height);
      resolve(canvas.toDataURL('image/png', 1));
    }
  })
}

const Crop = ({
  file,
  ratio,
  minWidth,
  maxWidth,
  minHeight,
  maxHeight,
  onCrop,
  removeImageButton,
  onRemoveImage,
}) => {
  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(null);

  const [image, setImage] = useState(null);
  const [rawImage, setRawImage] = useState(null);

  const containerHeight = useState(400);

  const [cropApplied, setCropApplied] = useState(false);
  const [cropData, setCropData] = useState();
  const [cropper, setCropper] = useState();

  const imageRef = useRef(null);

  const readRawImage = async (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      setRawImage(reader.result);
    };
    reader.readAsDataURL(file);
  }

  const resizeImage = async (file) => {
    try {
      const image = await resizeFile(
        file,
        960, // minWidth
        3000, // maxWidth
        540, // minHeight
        3000, // maxHeight
        'JPEG', // format
        100, // quality
        0, // rotation
        'file', // outputFormat
      );

      readImage(image);
    } catch (err) {
      console.log(err);
    }
  }

  const readImage = async (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
      setLoading(false);
    };
    reader.readAsDataURL(file);
  }

  if (file) {
    readRawImage(file);
  }

  const checkImageResolution = (image) => {
    if (image.width < minWidth || image.height < minHeight) {
      setError(
        `Image is too small. Please upload an image that has a minimum width of ${minWidth} and a minimum height of ${minHeight} pixels.`
      );
      setLoading(false);
    } else {
      resizeImage(file);
    }
  }

  if (isLoading) {
    return (
      <Row>
        <Col className='text-center'>
          <Spinner animation="border" role="status" variant="primary">
            <span className="sr-only">Loading...</span>
          </Spinner>

          <div style={{display: 'none'}}>
            <img
              onLoad={(data) => {
                checkImageResolution(data.target);
              }}
              src={rawImage} alt='uploaded' />
          </div>
        </Col>
      </Row>
    );
  }

  if (isError) {
    return (
      <Row>
        <Col>
          <Alert
            variant="danger"
            onClose={() => {
              onRemoveImage();
            }}
            dismissible
          >
            <p>{isError}</p>
          </Alert>
        </Col>
      </Row>
    );
  }

  const getCropData = async () => {
    if (typeof cropper !== 'undefined') {
      // const canvasData = cropper.getCanvasData();
      const croppedImage = cropper.getCroppedCanvas({
        // width: canvasData.naturalWidth,
        // height: canvasData.naturalHeight,
        minWidth: minWidth,
        minHeight: minHeight,
        // maxWidth: maxWidth,
        // maxHeight: maxHeight,
        imageSmoothingEnabled: true,
        imageSmoothingQuality: 'high',
      });

      const image = croppedImage.toDataURL('image/jpeg', 1);

      setLoading(true);

      const newImage = await resizeCroppedImage(image, maxWidth, maxWidth);
      const newImageBase64 = newImage.split(',')[1];
      onCrop(newImageBase64)
      setCropData(newImage);
      setLoading(false);
    }
  };

  return (
    <>
    <div>
      <div>
        <Cropper
          style={{
            height: containerHeight,
            width: '100%',
            display: cropApplied ? 'none' : ''
          }}
          zoomTo={0}
          zoomOnWheel={false}
          zoomOnTouch={false}
          initialAspectRatio={ratio}
          aspectRatio={ratio}
          preview=".img-preview"
          src={image}
          viewMode={1}
          minCropBoxHeight={100}
          minCropBoxWidth={100}
          background={false}
          responsive={true}
          autoCropArea={1}
          checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
          onInitialized={(instance) => {
            setCropper(instance);
          }}
          precisePreview={true}
          guides={true}
        />

        <div
          style={{display: cropApplied ? '' : 'none'}}
          >
          <div className='box'>
            <img id='cropped-img' ref={imageRef} style={{ width: "100%" }} src={cropData} alt='cropped' />
          </div>
        </div>

        <Row className='mt-2'>
          {cropApplied ? (
            <>
              {onRemoveImage && (
                <Col xs={'auto'}>
                  <Button variant="link" onClick={() => {
                    onRemoveImage();
                  }}>
                    <X />
                    <span>Remove image</span>
                  </Button>
                </Col>
              )}
            </>
          ) : (
            <>
              {onRemoveImage && (
                <Col xs={'auto'}>
                  <Button variant="link" onClick={() => {
                    onRemoveImage();
                  }}>
                    <X />
                    <span>Remove image</span>
                  </Button>
                </Col>
              )}
              <Col xs={'auto'} className='ml-auto'>
                <Button
                  variant="link"
                  type="button"
                  onClick={() => {
                    setCropApplied(true)
                    getCropData()
                  }}>
                  <Check />
                  <span>Apply crop</span>
                </Button>
              </Col>
            </>
          )}

          {/* Optional prop to render a 'remove' button */}
          {removeImageButton && (
            removeImageButton
          )}
        </Row>

      </div>
    </div>
      
    </>
  );
};

export default Crop;
