import React from 'react';

// Modules
import {connect} from 'react-redux';

// App
import PresentationItem from './presentationItem';
import {paginator} from '../../core/paginator';
import Pagination from '../../core/pagination';
import PageHeader from '../../partials/pageHeader';
import LoadingSpinner from '../../partials/loadingSpinner';
import Error from "../../partials/error";

// UI components
import Row from 'react-bootstrap/Row';

class Presentations extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      isRefreshing: false,
      isError: false,
      errorStatus: '',
      errorMessage: '',
      currentPage: 1,
      pageSize: 20,
      index: 0,
      totalItems: 0,
      token: '',
      data: [
        {
          "id": "71186",
          "title": "What is Presenter: IE11 version",
          "description": "Examples of slides built with the Companyapp Presenter system.",
          "category": {
            "id": "74",
            "name": "Website Presentations",
            "weight": "0"
          },
          "author": "companyapp",
          "group": "library",
          "thumbnail": "https://presenter.companyapp.co.uk/system/files/styles/app_thumbnails/private/presenter/ca_presenter/presentations/71186/__thumbnails/71187.jpg?itok=yAVEyBzP",
          "date_created": "1585221169",
          "date_created_formatted": "26/03/20 11:12",
          "date_modified": "1601388492",
          "date_modified_formatted": "29/09/20 15:08",
          "download_size": 143101087,
          "download_url": "https://presenter.companyapp.co.uk/presentations/71186/download",
          "archive_hash": "f70ca606e7820ce653c153f9a6ccb685",
          "primary_status": {
            "tid": "4",
            "vid": "4",
            "name": "Published (Full)",
            "description": "PUBLISHED_1",
            "format": null,
            "weight": "0",
            "uuid": "2b494fdc-a779-49c9-a054-faa23c924905",
            "vocabulary_machine_name": "primary_statuses"
          },
          "secondary_status": {
            "tid": "8",
            "vid": "5",
            "name": "None",
            "description": "NONE",
            "format": null,
            "weight": "0",
            "uuid": "7c4f3827-c69b-4d69-a3b4-387a3b5726ac",
            "vocabulary_machine_name": "secondary_statuses"
          },
          "view_url": "domain=presenter&username=companyapp&session=q-jXnsSxt1-VeTN6ZMoXW7vEXrmReIr3oaIDYOR102Y&presentation_id=71186",
          "viewer_size": "1",
          "onlyweb": false,
          "slide_length": 33,
          "share_link": "https://presenter.companyapp.co.uk/viewer?presentation_id=71186&ref=e93e76c44d767f630d0c4d153c382e2c0d70cc16"
        },
        {
          "id": "57299",
          "title": "Logica Presentation",
          "description": "An overview of the Logica company's logistics service.",
          "category": {
            "id": "74",
            "name": "Website Presentations",
            "weight": "0"
          },
          "author": "companyapp",
          "group": "personal",
          "thumbnail": "https://presenter.companyapp.co.uk/system/files/styles/app_thumbnails/private/presenter/ca_presenter/presentations/57299/__thumbnails/57300.jpg?itok=Ub-IkduT",
          "date_created": "1578390117",
          "date_created_formatted": "07/01/20 9:41",
          "date_modified": "1586166388",
          "date_modified_formatted": "06/04/20 10:46",
          "download_size": false,
          "download_url": "https://presenter.companyapp.co.uk/presentations/57299/download",
          "archive_hash": null,
          "primary_status": {
            "tid": "5",
            "vid": "4",
            "name": "Published (Web)",
            "description": "PUBLISHED_2",
            "format": null,
            "weight": "1",
            "uuid": "31e3c935-567b-4d61-9485-4a7df8f71e58",
            "vocabulary_machine_name": "primary_statuses"
          },
          "secondary_status": {
            "tid": "9",
            "vid": "5",
            "name": "Updated",
            "description": "UPDATED",
            "format": null,
            "weight": "1",
            "uuid": "622e883e-9b59-4bcc-ba82-a64a8eeb59a8",
            "vocabulary_machine_name": "secondary_statuses"
          },
          "view_url": "domain=presenter&username=companyapp&session=q-jXnsSxt1-VeTN6ZMoXW7vEXrmReIr3oaIDYOR102Y&presentation_id=57299",
          "viewer_size": "1",
          "onlyweb": true,
          "slide_length": 21,
          "share_link": "https://presenter.companyapp.co.uk/viewer?presentation_id=57299&ref=24cbc448e61e1aa8b59ff56e9b88e5be444647f6"
        },
        {
          "id": "56964",
          "title": "Fashion Presentation",
          "description": "The latest fashion collections from the UK and Europe.",
          "category": {
            "id": "74",
            "name": "Website Presentations",
            "weight": "0"
          },
          "author": "companyapp",
          "group": "personal",
          "thumbnail": "https://presenter.companyapp.co.uk/system/files/styles/app_thumbnails/private/presenter/ca_presenter/presentations/56964/__thumbnails/56986.jpg?itok=4xx3NHDE",
          "date_created": "1578313197",
          "date_created_formatted": "06/01/20 12:19",
          "date_modified": "1584032507",
          "date_modified_formatted": "12/03/20 17:01",
          "download_size": false,
          "download_url": "https://presenter.companyapp.co.uk/presentations/56964/download",
          "archive_hash": null,
          "primary_status": {
            "tid": "5",
            "vid": "4",
            "name": "Published (Web)",
            "description": "PUBLISHED_2",
            "format": null,
            "weight": "1",
            "uuid": "31e3c935-567b-4d61-9485-4a7df8f71e58",
            "vocabulary_machine_name": "primary_statuses"
          },
          "secondary_status": {
            "tid": "8",
            "vid": "5",
            "name": "None",
            "description": "NONE",
            "format": null,
            "weight": "0",
            "uuid": "7c4f3827-c69b-4d69-a3b4-387a3b5726ac",
            "vocabulary_machine_name": "secondary_statuses"
          },
          "view_url": "domain=presenter&username=companyapp&session=q-jXnsSxt1-VeTN6ZMoXW7vEXrmReIr3oaIDYOR102Y&presentation_id=56964",
          "viewer_size": "1",
          "onlyweb": true,
          "slide_length": 10,
          "share_link": "https://presenter.companyapp.co.uk/viewer?presentation_id=56964&ref=0f084980dc35d98fcc82fce056435df243ccf0ac"
        },
        {
          "id": "55359",
          "title": "Manufacturing furniture",
          "description": "A selection of contemporary furniture to add to your home.",
          "category": {
            "id": "74",
            "name": "Website Presentations",
            "weight": "0"
          },
          "author": "companyapp",
          "group": "personal",
          "thumbnail": "https://presenter.companyapp.co.uk/system/files/styles/app_thumbnails/private/presenter/ca_presenter/presentations/55359/__thumbnails/55360.jpg?itok=ukYQ_CPs",
          "date_created": "1575905546",
          "date_created_formatted": "09/12/19 15:32",
          "date_modified": "1585564473",
          "date_modified_formatted": "30/03/20 11:34",
          "download_size": false,
          "download_url": "https://presenter.companyapp.co.uk/presentations/55359/download",
          "archive_hash": "09bce9e29c69ce00c60fb3db78e8dabb",
          "primary_status": {
            "tid": "5",
            "vid": "4",
            "name": "Published (Web)",
            "description": "PUBLISHED_2",
            "format": null,
            "weight": "1",
            "uuid": "31e3c935-567b-4d61-9485-4a7df8f71e58",
            "vocabulary_machine_name": "primary_statuses"
          },
          "secondary_status": {
            "tid": "9",
            "vid": "5",
            "name": "Updated",
            "description": "UPDATED",
            "format": null,
            "weight": "1",
            "uuid": "622e883e-9b59-4bcc-ba82-a64a8eeb59a8",
            "vocabulary_machine_name": "secondary_statuses"
          },
          "view_url": "domain=presenter&username=companyapp&session=q-jXnsSxt1-VeTN6ZMoXW7vEXrmReIr3oaIDYOR102Y&presentation_id=55359",
          "viewer_size": "1",
          "onlyweb": true,
          "slide_length": 16,
          "share_link": "https://presenter.companyapp.co.uk/viewer?presentation_id=55359&ref=25b6dfe1b55a3df133745f363e53fe1e956b1479"
        },
        {
          "id": "44583",
          "title": "Agency presentation example",
          "description": "Learn how the Zeyda Agency can bring your brand to life.",
          "category": {
            "id": "74",
            "name": "Website Presentations",
            "weight": "0"
          },
          "author": "companyapp",
          "group": "personal",
          "thumbnail": "https://presenter.companyapp.co.uk/system/files/styles/app_thumbnails/private/presenter/ca_presenter/presentations/44583/__thumbnails/44584.jpg?itok=9fkrJWG9",
          "date_created": "1571997896",
          "date_created_formatted": "25/10/19 11:04",
          "date_modified": "1583762465",
          "date_modified_formatted": "09/03/20 14:01",
          "download_size": false,
          "download_url": "https://presenter.companyapp.co.uk/presentations/44583/download",
          "archive_hash": null,
          "primary_status": {
            "tid": "5",
            "vid": "4",
            "name": "Published (Web)",
            "description": "PUBLISHED_2",
            "format": null,
            "weight": "1",
            "uuid": "31e3c935-567b-4d61-9485-4a7df8f71e58",
            "vocabulary_machine_name": "primary_statuses"
          },
          "secondary_status": {
            "tid": "9",
            "vid": "5",
            "name": "Updated",
            "description": "UPDATED",
            "format": null,
            "weight": "1",
            "uuid": "622e883e-9b59-4bcc-ba82-a64a8eeb59a8",
            "vocabulary_machine_name": "secondary_statuses"
          },
          "view_url": "domain=presenter&username=companyapp&session=q-jXnsSxt1-VeTN6ZMoXW7vEXrmReIr3oaIDYOR102Y&presentation_id=44583",
          "viewer_size": "1",
          "onlyweb": true,
          "slide_length": 9,
          "share_link": "https://presenter.companyapp.co.uk/viewer?presentation_id=44583&ref=cd41f37e85a93e5df88cb29c0ed38cf8724e1d94"
        },
        {
          "id": "44489",
          "title": "What is Presenter: Modern browser version (Master)",
          "description": "Examples of slides built with the Companyapp Presenter system.",
          "category": {
            "id": "74",
            "name": "Website Presentations",
            "weight": "0"
          },
          "author": "companyapp",
          "group": "library",
          "thumbnail": "https://presenter.companyapp.co.uk/system/files/styles/app_thumbnails/private/presenter/ca_presenter/presentations/44489/__thumbnails/70580.jpg?itok=p9Gq7Kt7",
          "date_created": "1571935428",
          "date_created_formatted": "24/10/19 17:43",
          "date_modified": "1603099700",
          "date_modified_formatted": "19/10/20 10:28",
          "download_size": 145022231,
          "download_url": "https://presenter.companyapp.co.uk/presentations/44489/download",
          "archive_hash": "833badc2194d87d6e59c27057c576f15",
          "primary_status": {
            "tid": "4",
            "vid": "4",
            "name": "Published (Full)",
            "description": "PUBLISHED_1",
            "format": null,
            "weight": "0",
            "uuid": "2b494fdc-a779-49c9-a054-faa23c924905",
            "vocabulary_machine_name": "primary_statuses"
          },
          "secondary_status": {
            "tid": "9",
            "vid": "5",
            "name": "Updated",
            "description": "UPDATED",
            "format": null,
            "weight": "1",
            "uuid": "622e883e-9b59-4bcc-ba82-a64a8eeb59a8",
            "vocabulary_machine_name": "secondary_statuses"
          },
          "view_url": "domain=presenter&username=companyapp&session=q-jXnsSxt1-VeTN6ZMoXW7vEXrmReIr3oaIDYOR102Y&presentation_id=44489",
          "viewer_size": "1",
          "onlyweb": false,
          "slide_length": 33,
          "share_link": "https://presenter.companyapp.co.uk/viewer?presentation_id=44489&ref=2a7f3d20a44c255104ef8eac994a76093cd1fbdd"
        },
        {
          "id": "9816",
          "title": "Rosso Group Presentation",
          "description": "An example presentation created for a fictional hotel chain.",
          "category": {
            "id": "74",
            "name": "Website Presentations",
            "weight": "0"
          },
          "author": "companyapp",
          "group": "library",
          "thumbnail": "https://presenter.companyapp.co.uk/system/files/styles/app_thumbnails/private/presenter/ca_presenter/presentations/9816/__thumbnails/10214.jpg?itok=eehSiD5h",
          "date_created": "1540979998",
          "date_created_formatted": "31/10/18 9:59",
          "date_modified": "1575373904",
          "date_modified_formatted": "03/12/19 11:51",
          "download_size": 60190294,
          "download_url": "https://presenter.companyapp.co.uk/presentations/9816/download",
          "archive_hash": "fdfe64e253631f3f4e1bd9214037f465",
          "primary_status": {
            "tid": "4",
            "vid": "4",
            "name": "Published (Full)",
            "description": "PUBLISHED_1",
            "format": null,
            "weight": "0",
            "uuid": "2b494fdc-a779-49c9-a054-faa23c924905",
            "vocabulary_machine_name": "primary_statuses"
          },
          "secondary_status": {
            "tid": "8",
            "vid": "5",
            "name": "None",
            "description": "NONE",
            "format": null,
            "weight": "0",
            "uuid": "7c4f3827-c69b-4d69-a3b4-387a3b5726ac",
            "vocabulary_machine_name": "secondary_statuses"
          },
          "view_url": "domain=presenter&username=companyapp&session=q-jXnsSxt1-VeTN6ZMoXW7vEXrmReIr3oaIDYOR102Y&presentation_id=9816",
          "viewer_size": "1",
          "onlyweb": false,
          "slide_length": 19,
          "share_link": "https://presenter.companyapp.co.uk/viewer?presentation_id=9816&ref=b215ddbb85479df5da096725983e23c02d46c494"
        },
        {
          "id": "2150",
          "title": "Emerging Trends in Real Estate",
          "description": "A demo presentation built within the Presenter system.",
          "category": {
            "id": "74",
            "name": "Website Presentations",
            "weight": "0"
          },
          "author": "companyapp",
          "group": "library",
          "thumbnail": "https://presenter.companyapp.co.uk/system/files/styles/app_thumbnails/private/presenter/ca_presenter/presentations/2150/__thumbnails/2250.jpg?itok=dZhq-iEh",
          "date_created": "1497622274",
          "date_created_formatted": "16/06/17 15:11",
          "date_modified": "1572962837",
          "date_modified_formatted": "05/11/19 14:07",
          "download_size": 64370551,
          "download_url": "https://presenter.companyapp.co.uk/presentations/2150/download",
          "archive_hash": "77f234fe84a07183a00cdcd1eb72d0d4",
          "primary_status": {
            "tid": "4",
            "vid": "4",
            "name": "Published (Full)",
            "description": "PUBLISHED_1",
            "format": null,
            "weight": "0",
            "uuid": "2b494fdc-a779-49c9-a054-faa23c924905",
            "vocabulary_machine_name": "primary_statuses"
          },
          "secondary_status": {
            "tid": "9",
            "vid": "5",
            "name": "Updated",
            "description": "UPDATED",
            "format": null,
            "weight": "1",
            "uuid": "622e883e-9b59-4bcc-ba82-a64a8eeb59a8",
            "vocabulary_machine_name": "secondary_statuses"
          },
          "view_url": "domain=presenter&username=companyapp&session=q-jXnsSxt1-VeTN6ZMoXW7vEXrmReIr3oaIDYOR102Y&presentation_id=2150",
          "viewer_size": "1",
          "onlyweb": false,
          "slide_length": 17,
          "share_link": "https://presenter.companyapp.co.uk/viewer?presentation_id=2150&ref=fca856d0ba141b73f59f5ff1ca095f436f156e76"
        },
        {
          "id": "2112",
          "title": "Company Annual Report",
          "description": "Despite global challenges, 2020 was a productive year.",
          "category": {
            "id": "74",
            "name": "Website Presentations",
            "weight": "0"
          },
          "author": "companyapp",
          "group": "library",
          "thumbnail": "https://presenter.companyapp.co.uk/system/files/styles/app_thumbnails/private/presenter/ca_presenter/presentations/2112/__thumbnails/2114.jpg?itok=Rgr9i3rc",
          "date_created": "1497264335",
          "date_created_formatted": "12/06/17 11:45",
          "date_modified": "1585244728",
          "date_modified_formatted": "26/03/20 17:45",
          "download_size": 13857649,
          "download_url": "https://presenter.companyapp.co.uk/presentations/2112/download",
          "archive_hash": "f480c967a8ed571ac50c15c886187027",
          "primary_status": {
            "tid": "4",
            "vid": "4",
            "name": "Published (Full)",
            "description": "PUBLISHED_1",
            "format": null,
            "weight": "0",
            "uuid": "2b494fdc-a779-49c9-a054-faa23c924905",
            "vocabulary_machine_name": "primary_statuses"
          },
          "secondary_status": {
            "tid": "9",
            "vid": "5",
            "name": "Updated",
            "description": "UPDATED",
            "format": null,
            "weight": "1",
            "uuid": "622e883e-9b59-4bcc-ba82-a64a8eeb59a8",
            "vocabulary_machine_name": "secondary_statuses"
          },
          "view_url": "domain=presenter&username=companyapp&session=q-jXnsSxt1-VeTN6ZMoXW7vEXrmReIr3oaIDYOR102Y&presentation_id=2112",
          "viewer_size": "1",
          "onlyweb": false,
          "slide_length": 17,
          "share_link": "https://presenter.companyapp.co.uk/viewer?presentation_id=2112&ref=60a4d4270d34a666cff9e9f8778df96d9dbbc0c7"
        },
        {
          "id": "318",
          "title": "Companyapp Training Example",
          "description": "An example deck using both presentation and training slides. ",
          "category": {
            "id": "74",
            "name": "Website Presentations",
            "weight": "0"
          },
          "author": "companyapp",
          "group": "library",
          "thumbnail": "https://presenter.companyapp.co.uk/system/files/styles/app_thumbnails/private/presenter/presentations/318/training-thumbnail.jpg?itok=snKceuhW",
          "date_created": "1468236318",
          "date_created_formatted": "11/07/16 12:25",
          "date_modified": "1584014776",
          "date_modified_formatted": "12/03/20 12:06",
          "download_size": 55414748,
          "download_url": "https://presenter.companyapp.co.uk/presentations/318/download",
          "archive_hash": "938f2926ad20c0751d4386a00c450ef6",
          "primary_status": {
            "tid": "4",
            "vid": "4",
            "name": "Published (Full)",
            "description": "PUBLISHED_1",
            "format": null,
            "weight": "0",
            "uuid": "2b494fdc-a779-49c9-a054-faa23c924905",
            "vocabulary_machine_name": "primary_statuses"
          },
          "secondary_status": {
            "tid": "9",
            "vid": "5",
            "name": "Updated",
            "description": "UPDATED",
            "format": null,
            "weight": "1",
            "uuid": "622e883e-9b59-4bcc-ba82-a64a8eeb59a8",
            "vocabulary_machine_name": "secondary_statuses"
          },
          "view_url": "domain=presenter&username=companyapp&session=q-jXnsSxt1-VeTN6ZMoXW7vEXrmReIr3oaIDYOR102Y&presentation_id=318",
          "viewer_size": "1",
          "onlyweb": false,
          "slide_length": 19,
          "share_link": "https://presenter.companyapp.co.uk/viewer?presentation_id=318&ref=7b6fad4f638f82f82a24d4b842f0e6b2544f6d1a"
        },
        {
          "id": "143",
          "title": "Financial Services: Example Presentation",
          "description": "A short example of data capture and manipulation within the Presenter system.",
          "category": {
            "id": "74",
            "name": "Website Presentations",
            "weight": "0"
          },
          "author": "companyapp",
          "group": "library",
          "thumbnail": "https://presenter.companyapp.co.uk/system/files/styles/app_thumbnails/private/presenter/ca_presenter/presentations/143/__thumbnails/147.jpg?itok=CaORmsPx",
          "date_created": "1468235314",
          "date_created_formatted": "11/07/16 12:08",
          "date_modified": "1575374302",
          "date_modified_formatted": "03/12/19 11:58",
          "download_size": false,
          "download_url": "https://presenter.companyapp.co.uk/presentations/143/download",
          "archive_hash": "a71ff6e1fc875d47cf92211f420a9d99",
          "primary_status": {
            "tid": "5",
            "vid": "4",
            "name": "Published (Web)",
            "description": "PUBLISHED_2",
            "format": null,
            "weight": "1",
            "uuid": "31e3c935-567b-4d61-9485-4a7df8f71e58",
            "vocabulary_machine_name": "primary_statuses"
          },
          "secondary_status": {
            "tid": "9",
            "vid": "5",
            "name": "Updated",
            "description": "UPDATED",
            "format": null,
            "weight": "1",
            "uuid": "622e883e-9b59-4bcc-ba82-a64a8eeb59a8",
            "vocabulary_machine_name": "secondary_statuses"
          },
          "view_url": "domain=presenter&username=companyapp&session=q-jXnsSxt1-VeTN6ZMoXW7vEXrmReIr3oaIDYOR102Y&presentation_id=143",
          "viewer_size": "1",
          "onlyweb": true,
          "slide_length": 9,
          "share_link": "https://presenter.companyapp.co.uk/viewer?presentation_id=143&ref=8e2bc80edbf3c7ae8d22f0fa28dd11250929d452"
        }
      ]
    };

    this.onPaginate = this.onPaginate.bind(this);
  }

  /**
   * @function componentDidMount
   * @description Default react method. Fired when component is rendered
   */
  componentDidMount() {
    //this.getContent();
    //this.checkSession();
  }

  checkSession = () => {
    if (Object.keys(this.props.user).length === 0) {
      this.props.history.push('/');
    }
  }

  /**
   * @function setData
   * @description Updates the state of the component upon data retrival
   * @param {object} _data
   * @param {object} _realm
   */
  setData = (_data) => {
    this.setState(
      {
        isLoading: false,
        isRefreshing: false,
        isPaginating: false,
        data: _data.rows,
        totalItems: _data.pager.total_items,
      }
    );
  };

  /**
   * @function setError
   * @description Updates the state of the component upon an error
   * @param {boolean} _isError
   * @param {int} _errorStatus
   * @param {string} _errorMessage
   */
  setError = (_isError, _errorStatus, _errorMessage) => {
    this.setState({
      isLoading: false,
      isRefreshing: false,
      isPaginating: false,
      isError: _isError,
      errorStatus: _errorStatus,
      errorMessage: _errorMessage,
    });
  };

  login = () => {
    // const params = {
    //   'username': 'demo',
    //   'password': 'demo'
    // };

    // axios.post(
    //   'https://presenter.companyapp.co.uk/v3/user/login.json',
    //   params,
    //   {
    //     withCredentials: true,
    //     headers: { 
    //       "Content-Type": "application/x-www-form-urlencoded",
    //     }
    //   }
    // ).then((response) => {
    //   this.getContent();
    // })
    // .catch((_error) => {
    //   console.log('error: ', _error);
    //   this.getContent();
    // });
  }

  /**
   * @function getContent
   * @description Retrieves the data from an API / Fallback to local realm object if there is no connection
   */
  getContent = () => {
    // getExternal('https://presenter.companyapp.co.uk/v3/presentations/count')
    // .then((data) => {
    //   console.log('data: ', data)
    // })
    // .catch((error) => {
    //   console.log('error: ', error)
    // })
  };

  /**
   * @function onPaginate
   * @description Pagination callback
   * @param {int} _index - Page number
   */
  onPaginate = (_index) => {
    this.setState({
      //experimental
      isLoading: true,
      isPaginating: true,
      currentPage: _index,
    });
  };

  // Pagination component
  renderPagination = () => {
    if (this.state.totalItems <= this.state.pageSize) {
      return null;
    } else {
      let pagination = paginator(
        this.state.totalItems,
        this.state.currentPage,
        this.state.pageSize,
        3,
      );

      return (
        <Pagination
          currentPage={pagination.currentPage}
          endIndex={pagination.endIndex}
          endPage={pagination.endPage}
          pageSize={pagination.pageSize}
          pages={pagination.pages}
          startIndex={pagination.startIndex}
          startPage={pagination.startPage}
          totalItems={pagination.totalItems}
          totalPages={pagination.totalPages}
          paginationCallback={this.onPaginate}
        />
      );
    }
  };

  // Data component
  dataComponent = () => {
    const {data} = this.state;

    if (typeof data !== 'undefined' && data.length > 0) {
      return (
        <Row>
          {this.state.data.map(item => {
            return (
              <PresentationItem
                key={'presentation' + item.id}
                item={item}
                id={item.id}
              />
            );
          })}
        </Row>
      );
    } else {
      return (
        <div>
          <p>{'No data found'}</p>
        </div>
      );
    }
  };

  /**
   * @function render
   * @description Default render method
   */
  render() {
    if (this.state.isLoading) {
      return <LoadingSpinner />;
    } else {
      if (this.state.isError) {
        return (
          <Error 
            status={this.state.errorStatus}
            message={this.state.errorMessage}
          />
        );
      } else {
        return (
          <main className="presentations screen">
            <PageHeader 
              pageName="Presentations" 
              filters={true}
            />
            {this.dataComponent()}
            {this.renderPagination()}
          </main>
        );
      }
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default connect(mapStateToProps)(Presentations);
