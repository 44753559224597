import React from 'react';

// Modules
import {connect} from 'react-redux';
import {Redirect} from 'react-router'
import {Link} from "react-router-dom";

// App
import {serverUrl} from '../../config';
import {get_xcsrf_token} from '../../core/auth';
import {postNode} from '../../core/postNode';

import PageHeader from '../../partials/pageHeader';
import {alertMessages} from '../../partials/alertMessages';
import AlertModal from '../../partials/alertModal';

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { User, Lock, Phone, Mail, AtSign } from 'react-feather';
import Spinner from 'react-bootstrap/Spinner'

class Register extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isLoading: false,

      errorStatus: '',
      errorMessage: '',

      first_name: '',
      last_name: '',
      phone_number: '',
      username: '',
      email: '',
      password: '',
      confirm_password: '',

      modalVisible: false,
      isConfirmCallback: false,
    };
  }

  componentDidMount() {
    this._getXCSRFToken();
  }

  _getXCSRFToken = () => {
    get_xcsrf_token()
      .then(response => {
        this.setState({
          isLoading: false,
          token: response.data,
        });
      })
      .catch(_error => {
        this.setState({
          modalVisible: true,
          alertType: 'error',
          alertTitle: 'Error',
          alertBody: 'Failed to get token. Please try again. If the problem persists, restart the app or contact an administrator.',
          alertConfirmButton: true,
        });
        return false;
      });
  };

  /**
   * Set modal visibility
   * @param {*} visible 
   */
   setModalVisible = (visible) => {
    this.setState({
      modalVisible: visible,
    });
  };

  register = (event) => {
    if (event) {
      event.preventDefault();
    }

    const {
      first_name,
      last_name,
      phone_number,
      username,
      email,
      password,
      confirm_password,
      token,
    } = this.state;

    if (first_name.length < 2) {
      this.setState({
        modalVisible: true,
        alertType: 'error',
        alertTitle: alertMessages.requiredField,
        alertBody: alertMessages.firstNameRequired,
        alertConfirmButton: true,
      });
      return false;
    }

    if (last_name.length < 2) {
      this.setState({
        modalVisible: true,
        alertType: 'error',
        alertTitle: alertMessages.requiredField,
        alertBody: alertMessages.lastNameRequired,
        alertConfirmButton: true,
      });
      return false;
    }

    if (username.length < 2) {
      this.setState({
        modalVisible: true,
        alertType: 'error',
        alertTitle: alertMessages.requiredField,
        alertBody: alertMessages.usernameRequired,
        alertConfirmButton: true,
      });
      return false;
    }

    if (email.length < 2) {
      this.setState({
        modalVisible: true,
        alertType: 'error',
        alertTitle: alertMessages.requiredField,
        alertBody: alertMessages.emailRequired,
        alertConfirmButton: true,
      });
      return false;
    }

    if (password.length < 2) {
      this.setState({
        modalVisible: true,
        alertType: 'error',
        alertTitle: alertMessages.requiredField,
        alertBody: alertMessages.passwordRequired,
        alertConfirmButton: true,
      });
      return false;
    }

    if (confirm_password.length < 2) {
      this.setState({
        modalVisible: true,
        alertType: 'error',
        alertTitle: alertMessages.confirmPasswordRequired.title,
        alertBody: alertMessages.confirmPasswordRequired.message,
        alertConfirmButton: true,
      });
      return false;
    }

    if (password !== confirm_password) {
      this.setState({
        modalVisible: true,
        alertType: 'error',
        alertTitle: alertMessages.passwordMismatch.title,
        alertBody: alertMessages.passwordMismatch.message,
        alertConfirmButton: true,
      });
      return false;
    }

    this.setState(
      {
        isLoading: true,
      },
      function () {
        const data = {
          _links: {
            type: {
              href: serverUrl + '/rest/type/user/user',
            },
          },
          name: [
            {
              value: username,
            },
          ],
          mail: [
            {
              value: email,
            },
          ],
          pass: [
            {
              value: password,
            },
          ],
          field_first_name: [
            {
              value: first_name,
            },
          ],
          field_last_name: [
            {
              value: last_name,
            },
          ],
          phone_number: [
            {
              value: phone_number,
            },
          ],
        };

        postNode('user/register', data, token)
          .then(response => {
            this.setState({
              isLoading: false,
              first_name: '',
              last_name: '',
              phone_number: '',
              username: '',
              email: '',
              password: '',
              confirm_password: '',
            });

            this.setState({
              modalVisible: true,
              alertType: 'success',
              alertTitle: alertMessages.success.title,
              alertBody: alertMessages.success.message,
              alertConfirmButton: true,
              isConfirmCallback: true,
            });
          })
          .catch(error => {
            const status = error.status ? error.status : 0;
            const message = error.response.data.message
              ? error.response.data.message
              : alertMessages.unknownError + ': ' + status;

            this.setState({
              isLoading: false,
            });

            this.setState({
              modalVisible: true,
              alertType: 'error',
              alertTitle: alertMessages.error,
              alertBody: message,
              alertConfirmButton: true,
            });
            return false;
          });
      },
    );
  };

  render() {
    return (
      <main ref={this.containerRef} className="register  screen">
        <Row noGutters>
          <PageHeader pageName="Register" filters={false} />
        </Row>
        <Row>
          <Col xs={12} lg={4}>
            <form onSubmit={this.register}>
              <div className="form-group">
                <Row>
                  <Col className="d-flex  align-items-center mb-1">
                    <User className={"icon"} />
                    <label>{"First name"}</label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <input
                      type={"text"}
                      id={"first_name"}
                      placeholder={"Enter your first name"}
                      value={this.state.first_name}
                      onInput={(e) => {
                        this.setState({
                          first_name: e.target.value,
                        });
                      }}
                      className="form-control"
                    />
                  </Col>
                </Row>
              </div>

              <div className="form-group">
                <Row>
                  <Col className="d-flex  align-items-center mb-1">
                    <User className={"icon"} />
                    <label>{"Last name"}</label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <input
                      type={"text"}
                      id={"last_name"}
                      placeholder={"Enter your last name"}
                      value={this.state.last_name}
                      onInput={(e) => {
                        this.setState({
                          last_name: e.target.value,
                        });
                      }}
                      className="form-control"
                    />
                  </Col>
                </Row>
              </div>

              <div className="form-group">
                <Row>
                  <Col className="d-flex  align-items-center mb-1">
                    <Phone className={"icon"} />
                    <label>{"Phoner number"}</label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <input
                      type={"tel"}
                      id={"phone_number"}
                      placeholder={"Enter your phone number"}
                      value={this.state.phone_number}
                      onInput={(e) => {
                        this.setState({
                          phone_number: e.target.value,
                        });
                      }}
                      className="form-control"
                    />
                  </Col>
                </Row>
              </div>

              <div className="form-group">
                <Row>
                  <Col className="d-flex  align-items-center mb-1">
                    <AtSign className={"icon"} />
                    <label>{"Username"}</label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <input
                      type={"text"}
                      id={"username"}
                      placeholder={"Enter your username"}
                      value={this.state.username}
                      onInput={(e) => {
                        this.setState({
                          username: e.target.value,
                        });
                      }}
                      className="form-control"
                    />
                  </Col>
                </Row>
              </div>

              <div className="form-group">
                <Row>
                  <Col className="d-flex  align-items-center mb-1">
                    <Mail className={"icon"} />
                    <label>{"Email"}</label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <input
                      type={"email"}
                      id={"email"}
                      placeholder={"Enter your email"}
                      value={this.state.email}
                      onInput={(e) => {
                        this.setState({
                          email: e.target.value,
                        });
                      }}
                      className="form-control"
                    />
                  </Col>
                </Row>
              </div>

              <div className="form-group">
                <Row>
                  <Col className="d-flex  align-items-center mb-1">
                    <Lock className={"icon"} />
                    <label>{"Password"}</label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <input
                      type={"password"}
                      id={"password"}
                      placeholder={"Enter your password"}
                      value={this.state.password}
                      onInput={(e) => {
                        this.setState({
                          password: e.target.value,
                        });
                      }}
                      className="form-control"
                    />
                  </Col>
                </Row>
              </div>

              <div className="form-group">
                <Row>
                  <Col className="d-flex  align-items-center mb-1">
                    <Lock className={"icon"} />
                    <label>{"Confirm Password"}</label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <input
                      type={"password"}
                      id={"confirm_password"}
                      placeholder={"Confirm your password"}
                      value={this.state.confirm_password}
                      onInput={(e) => {
                        this.setState({
                          confirm_password: e.target.value,
                        });
                      }}
                      className="form-control"
                    />
                  </Col>
                </Row>
              </div>

              <div className="form-group">
                <Row>
                  <Col>
                    {this.state.isLoading ? (
                      <Spinner animation="border" role="status" variant="primary">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    ) : (
                      <Button type="submit">Register</Button>
                    )}
                  </Col>
                </Row>
              </div>
            </form>
          </Col>
        </Row>

        {/* Alert error modal */}
        <AlertModal 
          showAlert={this.state.modalVisible} 
          showAlertCallback={this.setModalVisible}
          alertType={this.state.alertType}
          alertMessageTitle={this.state.alertTitle}
          alertMessageBody={this.state.alertBody}
          confirmButton={this.state.alertConfirmButton}
          onConfirm={() => {
            if(this.state.isConfirmCallback) {
              this.props.history.push('/');
            }
          }}
        />
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  saveUserSession: (data) => dispatch({type: 'SAVE_SESSION', payload: data}),
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);
