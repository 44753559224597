import React, { Component } from 'react';

// Modules
import {connect} from 'react-redux';
import { NavLink } from 'react-router-dom';

// UI components
import { Home, FileText, Calendar, Edit, UserCheck, BookOpen, PieChart, Edit2, File, Video, Camera, Users, Phone, Link2, Info, MessageSquare, User, Mic } from 'react-feather';

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navItems: {
        noAuth: [
          {
            label: 'Home',
            path: '/',
            icon: <Home size={20} />,
          },
          {
            label: 'Latest News',
            path: '/news',
            icon: <FileText size={20} />,
            key: 'news',
          },
          {
            label: 'Company Blogs',
            path: '/blog',
            icon: <Edit size={20} />,
            key: 'blog',
          },
          // {
          //   label: 'Presentations',
          //   path: '/presentations',
          //   icon: <BookOpen size={20} />,
          //   key: 'presentations',
          // },
          {
            label: 'Video Library',
            path: '/videos',
            icon: <Video size={20} />,
            key: 'videos',
          },
        ],
        auth: [
          {
            label: 'Home',
            path: '/',
            icon: <Home size={20} />,
          },
          {
            label: 'Latest News',
            path: '/news',
            icon: <FileText size={20} />,
            key: 'news',
          },
          {
            label: 'Company Blogs',
            path: '/blog',
            icon: <Edit size={20} />,
            key: 'blog',
          },
          {
            label: 'Upcoming Events',
            path: '/events',
            icon: <Calendar size={20} />,
            key: 'events',
          },
          {
            label: 'Training Courses',
            path: '/courses',
            icon: <UserCheck size={20} />,
            key: 'course',
          },
          {
            label: 'Tests & Quizzes',
            path: '/quizzes',
            icon: <BookOpen size={20} />,
            key: 'quiz',
          },
          {
            label: 'Presentations',
            path: '/presentations',
            icon: <BookOpen size={20} />,
            key: 'presentations',
          },
          {
            label: 'Surveys & Forms',
            path: '/forms',
            icon: <Edit2 size={20} />,
            key: 'webform',
          },
          {
            label: 'Discussion Boards',
            path: '/forums',
            icon: <MessageSquare size={20} />,
            key: 'forums',
          },
          {
            label: 'Opinion Polls',
            path: '/polls',
            icon: <PieChart size={20} />,
            key: 'polls',
          },
          {
            label: 'Document Library',
            path: '/documents',
            icon: <File size={20} />,
            key: 'documents',
          },
          {
            label: 'Video Library',
            path: '/videos',
            icon: <Video size={20} />,
            key: 'videos',
          },
          {
            label: 'Image Galleries',
            path: '/galleries',
            icon: <Camera size={20} />,
            key: 'image_gallery',
          },
          {
            label: 'Podcasts',
            path: '/podcasts',
            icon: <Mic size={20} />,
            key: 'podcasts',
          },
          {
            label: 'Company Directory',
            path: '/company-directory',
            icon: <Users size={20} />,
            key: 'company_directory',
          },
          {
            label: 'User Directory',
            path: '/contacts',
            icon: <Phone size={20} />,
            key: 'contacts',
          },
          {
            label: 'Noticeboard',
            path: '/noticeboard',
            icon: <Info size={20} />,
            key: 'noticeboard',
          },
          {
            label: 'Useful Links',
            path: '/links',
            icon: <Link2 size={20} />,
            key: 'external_resource',
          },
          {
            label: 'Submissions',
            path: '/submissions',
            icon: <Edit size={20} />,
            key: 'submissions',
          },
        ],
      },
      desktopQuery: '',
      windowHeight: window.innerHeight + 'px',
    };
  }

  componentDidMount = () => {
    window.addEventListener("resize", this.handleResize);
    this.setMediaQuery();
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.handleResize);
  };

  setNavHeight = () => {
    this.setState({
      windowHeight: window.innerHeight + 'px',
    });
  };

  setMediaQuery = () => {
    this.setState({
      desktopQuery: window.matchMedia("(min-width: 992px)"),
    });
  };

  handleResize = () => {
    this.setNavHeight();
    this.setMediaQuery();
  };

  handleClick = () => {
    // If mobile menu, collapse on click
    if (!this.state.desktopQuery.matches) {
      this.props.handleNavCallback();
    } 
  };

  render() {
    const isMobile = !this.state.desktopQuery.matches;
    let showNavigation = "collapsed";
    if (this.props.showNavigation === true || this.props.showNavigation === 'true') {
      showNavigation = "expanded";
      // If mobile add class to body
      // if (!this.state.desktopQuery.matches) {
      //   document.body.classList.add('navbar-expanded');
      // }
    } else {
      // document.body.classList.remove('navbar-expanded');
    }

    return (
      <div
        className={`navbar-container ${showNavigation}`}
        style={{ height: this.state.windowHeight }}
      >
        {isMobile && (
          <div className="mobile-overlay" onClick={this.handleClick}></div>
        )}
        <nav className={`navbar ${showNavigation}`}>
          <ul>
            {typeof this.props.currentUser !== 'undefined' ? (
              <>
                {this.state.navItems.auth.map((item, index) => {
                  return (
                    <li key={index} className="nav-item">
                      <NavLink
                        exact
                        to={item.path}
                        className="nav-link"
                        activeClassName="active"
                        onClick={this.handleClick}
                        isActive={(match, location) => {
                          if (match) {
                            return true;
                          }

                          if (location.pathname.indexOf(item.path) > -1) {
                            if (item.path !== '/') {
                              return true;
                            }
                          }
                        }}
                      >
                        <div className="nav-item__icon">{item.icon}</div>
                        <span className="nav-item__label">{item.label}</span>
                        {!!this.props.content_counter[item.key] && (
                          <span className="nav-item__indicator">
                            {this.props.content_counter[item.key]}
                          </span>
                        )}
                      </NavLink>
                    </li>
                  );
                })}
              </>
            ) : (
              <>
                {this.state.navItems.noAuth.map((item, index) => {
                  return (
                    <li key={index} className="nav-item">
                      <NavLink
                        exact
                        to={item.path}
                        className="nav-link"
                        activeClassName="active"
                        onClick={this.handleClick}
                      >
                        <div className="nav-item__icon">{item.icon}</div>
                        <span className="nav-item__label">{item.label}</span>
                        {!!this.props.content_counter[item.key] && (
                          <span className="nav-item__indicator">
                            {this.props.content_counter[item.key]}
                          </span>
                        )}
                      </NavLink>
                    </li>
                  )
                })}
                <li className="nav-item">
                  <NavLink
                    exact
                    to="/login"
                    className="nav-link"
                    activeClassName="active"
                    onClick={this.handleClick}
                  >
                    <div className="nav-item__icon">
                      <User size={20} />
                    </div>
                    <span className="nav-item__label">Login</span>
                  </NavLink>
                </li>
              </>
            )}
          </ul>
        </nav>
      </div>
    );
  }

}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
  content_counter: state.authReducer.content_counter,
});

export default connect(mapStateToProps)(Navigation);
