import React from 'react';

// Modules
import {connect} from 'react-redux';
import {Redirect} from 'react-router'
import { Link } from "react-router-dom";

// App
import {
  auth_login,
  auth_logout,
  get_oauth_token,
  get_logout_token,
  get_xcsrf_token
} from '../../core/auth';

import {getOnlineNode} from '../../core/getNode';
import PageHeader from '../../partials/pageHeader';
import {alertMessages} from '../../partials/alertMessages';
import AlertModal from '../../partials/alertModal';

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { User, Lock } from 'react-feather';
import Spinner from 'react-bootstrap/Spinner'

class Login extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isLoading: false,
      isError: false,

      errorStatus: '',
      errorMessage: '',

      username: 'demo',
      password: 'demo',

      modalVisible: false,
    };
  }

  componentDidMount() {
    this.checkSession();
  }

  setError = (_isError, _errorStatus, _errorMessage) => {
    this.setState({
      isLoading: false,
      isRefreshing: false,
      isError: _isError,
      errorStatus: _errorStatus,
      errorMessage: _errorMessage,
    });
  };

  _doLogin = (event) => {
    if (event) {
      event.preventDefault();
    }

    if (
      typeof this.state.username === 'undefined' ||
      this.state.username === ''
    ) {
      this.setState({
        modalVisible: true,
        alertType: 'error',
        alertTitle: alertMessages.invalidUsername.title,
        alertBody: alertMessages.invalidUsername.message,
        alertConfirmButton: true,
      });
      return false;
    }

    if (
      typeof this.state.password === 'undefined' ||
      this.state.password === ''
    ) {
      this.setState({
        modalVisible: true,
        alertType: 'error',
        alertTitle: alertMessages.invalidPassword.title,
        alertBody: alertMessages.invalidPassword.message,
        alertConfirmButton: true,
      });
      return false;
    }

    this.setState({
      isLoading: true,
    });

    auth_login(this.state.username, this.state.password)
      .then((response) => {
        let sessionData = response.data;

        get_oauth_token(this.state.username, this.state.password)
          .then((_oauth_response) => {
            sessionData.access_token = _oauth_response.data.access_token;
            sessionData.refresh_token = _oauth_response.data.refresh_token;
            sessionData.expires_in = _oauth_response.data.expires_in;
            sessionData.token_type = _oauth_response.data.token_type;

            this.props.saveUserSession(sessionData);
            this._bootstrapAsync();
          })
          .catch((_oauth_error) => {
            this.props.saveUserSession(sessionData);
            this._bootstrapAsync();
          });
      })
      .catch((loginError) => {
        if (loginError.response && loginError.response.data && loginError.response.data.message === 'This route can only be accessed by anonymous users.') {
          this._doLogout();
        } else {
          this.setState({
            isLoading: false,
            modalVisible: true,
            alertType: 'error',
            alertTitle: loginError.response ? loginError.response.statusText : 'Error',
            alertBody: loginError.response ? loginError.response.data.message : 'Unknown error.',
            alertConfirmButton: true,
          });
        }
      });
  };

  _doLogout = () => {
    get_logout_token().then(get_logout_token_response => {
      get_xcsrf_token().then(get_xcsrf_token_response => {
        auth_logout(
          get_logout_token_response.data.logout_token,
          get_xcsrf_token_response.data,
        )
        .then(response => {
          this._doLogin();
        })
        .catch(error => {
          this.setState({
            isLoading: false,
            modalVisible: true,
            alertType: 'error',
            alertTitle: error.response.statusText,
            alertBody: error.response.data.message,
            alertConfirmButton: true,
          });
        })

      })
      .catch(error => {
        this.setState({
          modalVisible: true,
          alertType: 'error',
          alertTitle: alertMessages.noTokens.title,
          alertBody: alertMessages.noTokens.message,
          alertConfirmButton: true,
        });
      })
    })
    .catch(error => {
      this.setState({
        modalVisible: true,
        alertType: 'error',
        alertTitle: alertMessages.noTokens.title,
        alertBody: alertMessages.noTokens.message,
        alertConfirmButton: true,
      });
    })

  };

  async _bootstrapAsync() {
    if (this.props.user === 'undefined' || this.props.user == null) {
      this.setState({
        redirectHome: true,
      })
    } else {
      this.checkSession();
    }
  };

  checkSession = () => {
    if (typeof this.props.user.current_user !== 'undefined') {
      getOnlineNode(
        'user/' + this.props.user.current_user.uid,
        this.props.user.access_token,
      )
        .then((response) => {
          this.setState({
            redirectHome: true,
          });
        })
        .catch((_error) => {
          this.setState({
            redirectHome: true,
          });
        });
    }
  };

  /**
   * Set modal visibility
   * @param {*} visible 
   */
   setModalVisible = (visible) => {
    this.setState({
      modalVisible: visible,
    });
  };

  render() {
    if (this.state.redirectHome) {
      return (
        <Redirect to="/" />
      )
    }

    let isInModal = false;

    if (this.props.type && this.props.type === 'modal') {
      isInModal = true;
    }

    return (
      <main ref={this.containerRef} className="login  screen">
        <Row noGutters>
          <PageHeader pageName="Login" filters={false} />
          {isInModal && (
            <p className="text-left">
              {'To see all the content in this demo site - please login below (Username: demo, Password: demo)'}
            </p>
          )}
        </Row>
        <Row>
          <Col xs={12} lg={isInModal ? 12 : 4}>
            <form onSubmit={this._doLogin}>
              <div className="form-group">
                <Row>
                  <Col className="d-flex  align-items-center mb-1">
                    <User className={"icon"} />
                    <label>{"Username"}</label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <input
                      type={"text"}
                      id={"username"}
                      placeholder={"Enter your username"}
                      value={this.state.username}
                      onInput={(e) => {
                        this.setState({
                          username: e.target.value,
                        });
                      }}
                      className="form-control"
                    />
                  </Col>
                </Row>
              </div>
              <div className="form-group">
                <Row>
                  <Col className="d-flex  align-items-center mb-1">
                    <Lock className={"icon"} />
                    <label>{"Password"}</label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <input
                      type={"password"}
                      id={"password"}
                      placeholder={"Enter your password"}
                      value={this.state.password}
                      onInput={(e) => {
                        this.setState({
                          password: e.target.value,
                        });
                      }}
                      className="form-control"
                    />
                  </Col>
                </Row>
              </div>

              <div className="form-group">
                <Row>
                  <Col>
                    {this.state.isLoading ? (
                      <Spinner animation="border" role="status" variant="primary">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    ) : (
                      <Button type="submit">Login</Button>
                    )}
                    
                  </Col>
                </Row>
              </div>

              <div className="form-group">
                <Row>
                  <Col>
                    <Link
                      to={'/reset-pass'}>
                        {'Forgotten Password?'}
                    </Link>
                  </Col>

                  <Col>
                    <Link
                      to={'/register'}>
                        {'Register'}
                    </Link>
                  </Col>
                </Row>
              </div>

            </form>
          </Col>
        </Row>

        {/* Alert error modal */}
        <AlertModal 
          showAlert={this.state.modalVisible} 
          showAlertCallback={this.setModalVisible}
          alertType={this.state.alertType}
          alertMessageTitle={this.state.alertTitle}
          alertMessageBody={this.state.alertBody}
          confirmButton={this.state.alertConfirmButton}
        />
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  saveUserSession: (data) => dispatch({type: 'SAVE_SESSION', payload: data}),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
