import React from "react";

import Button from "react-bootstrap/Button";
import {
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
} from "react-feather";

class Pagination extends React.Component {
  render() {
    let hideFirst =
      this.props.currentPage === this.props.startPage ? true : false;

    let hideNext = this.props.currentPage < this.props.endPage ? false : true;

    let hidePrevious =
      this.props.currentPage !== this.props.startPage ? false : true;

    let hideLast =
      this.props.currentPage === this.props.totalPages ? true : false;

    return (
      <div className="pagination-container">
        <Button
          onClick={() => this.props.paginationCallback(1)}
          disabled={hideFirst}
          variant="outline-border-colour"
          className="pagination-start"
        >
          <ChevronsLeft size={18} />
          <span className="d-none d-lg-block">{"First"}</span>
        </Button>

        <Button
          onClick={() =>
            this.props.paginationCallback(this.props.currentPage - 1)
          }
          disabled={hidePrevious}
          variant="outline-border-colour"
        >
          <ChevronLeft size={18} />
        </Button>

        {this.props.pages.map((item, index) => {
          let disableCurrent = false;
          let buttonStyle = "";
          if (this.props.currentPage === item) {
            disableCurrent = true;
            buttonStyle = "active";
          }
          return (
            <Button
              key={"pagination-" + index}
              onClick={() => this.props.paginationCallback(item)}
              disabled={disableCurrent}
              variant="outline-border-colour"
              className={buttonStyle}
            >
              {item}
            </Button>
          );
        })}

        <Button
          onClick={() =>
            this.props.paginationCallback(this.props.currentPage + 1)
          }
          disabled={hideNext}
          variant="outline-border-colour"
        >
          <ChevronRight size={18} />
        </Button>

        <Button
          onClick={() => this.props.paginationCallback(this.props.totalPages)}
          disabled={hideLast}
          variant="outline-border-colour"
          className="pagination-end"
        >
          <span className="d-none d-lg-block">{"Last"}</span>
          <ChevronsRight size={18} />
        </Button>
      </div>
    );
  }
}

export default Pagination;
