import {api} from '../../src/config';
import qs from 'qs';

export const get_xcsrf_token = () => {
  return api({
    method: 'get',
    url: '/session/token?_format=json',
  });
};

export const get_logout_token = () => {
  return api({
    method: 'get',
    url: '/session/token/logout?_format=json',
  });
};

export const auth_login = (username, password) => {
  return api({
    method: 'post',
    url: '/user/login?_format=json',
    // headers: {
    //   'X-CSRF-Token': xscrf_token,
    // },
    data: {name: username, pass: password},
  });
};

export const auth_logout = (logout_token, csrf_token) => {
  return api({
    method: 'post',
    url: `/user/logout?_format=json&token=${logout_token}`,
    headers: {
      'X-CSRF-Token': csrf_token,
    },
  });
};

export const get_oauth_token = (username, password) => {
  const client_id = 'd272a4fe-b71e-4656-8ea3-8eb312cb8f0e'; //'f17208ff-72e7-47bf-8782-c3119a2afe54';
  const client_secret = 'bsen_TKOv_xl8ZZx7UhL'; // 'bsen_TKOv_xl8ZZx7Uh';
  const grant_type = 'password';

  const data = {
    client_id: client_id,
    client_secret: client_secret,
    grant_type: grant_type,
    username: username,
    password: password,
  };

  return api({
    method: 'post',
    url: '/oauth/token',
    data: qs.stringify(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};
