import React, { Component } from 'react';

// Modules
import ContentLoader from 'react-content-loader';

class CompanyDirectorySkeletonSingleScreen extends Component {  
  constructor() {
    super();
    this.state = {
      backgroundColor: '#f3f3f3',
      foregroundColor: '#ecebeb',
    };
  }

  renderDefaultItems = (itemClassList) => {

    let items = [];
    let numberOfItems;

    if (this.props.numberOfItems) {
      numberOfItems = this.props.numberOfItems;
    } else {
      numberOfItems = 6;
    }

    for (let i = 0; i < numberOfItems; i++) {

      items.push(
        <div className={itemClassList} key={'item-' + i} style={{border: 'none'}}>
          <ContentLoader 
            speed={2}
            width={'100%'}
            height={'100%'}
            viewBox="0 0 920 181"
            backgroundColor={this.state.backgroundColor}
            foregroundColor={this.state.foregroundColor}
          >
            <rect x="120.244" y="140.47" width="243.044" height="20" rx="10" ry="10" />
            <circle cx="59.319" cy="150.47" r="30.341" />
            <rect x="120.244" y="70.405" width="129.728" height="20" rx="10" ry="10" />
            <circle cx="59.319" cy="80.405" r="30.341" />
            <rect x="0" y="-0" width="192.931" height="30" rx="15" ry="15" />
          </ContentLoader>
        </div>
      );

    }

    return items;
  };

  render() {
    const itemClass = "company-directory";
    const itemClassList = "contact-group-item";

    return (
      <main className="company-directory single">
        <article className="article">
          
          <div className={`${itemClass}__header article__header`}>
            <ContentLoader 
              speed={2}
              width={'100%'}
              height={'100%'}
              viewBox="0 0 920 150"
              backgroundColor={this.state.backgroundColor}
              foregroundColor={this.state.foregroundColor}

            >
              <rect x="182.669" y="91.376" width="208.811" height="35" rx="17.5" ry="17.5" />
              <rect x="182.669" y="28.327" width="349.73" height="50" rx="25" ry="25" />
              <circle cx="74.864" cy="74.864" r="74.864" />
            </ContentLoader>
          </div>

          <div className="article__body">
            <ContentLoader 
              speed={2}
              width={'100%'}
              height={'100%'}
              viewBox="0 0 920 50"
              backgroundColor={this.state.backgroundColor}
              foregroundColor={this.state.foregroundColor}
            >
              <rect x="0" y="29.99" width="712.089" height="20" rx="10" ry="10" />
              <rect x="0" y="0" width="870.468" height="20" rx="10" ry="10" />
            </ContentLoader>
          </div>

          <div className={`${itemClass}__details`}>
            <ContentLoader 
              speed={2}
              width={'100%'}
              height={'100%'}
              viewBox="0 0 920 61"
              backgroundColor={this.state.backgroundColor}
              foregroundColor={this.state.foregroundColor}
            >
              <rect x="270.261" y="40.969" width="287.073" height="20" rx="10" ry="10" />
              <circle cx="250.224" cy="50.969" r="10" />
              <rect x="0" y="40.969" width="82.018" height="20" rx="10" ry="10" />
              <rect x="270.261" y="0" width="109.727" height="20" rx="10" ry="10" />
              <circle cx="250.224" cy="10" r="10" />
              <rect x="0" y="0" width="115.106" height="20" rx="10" ry="10" />
            </ContentLoader>
          </div>

          {/* All items */}
          {this.renderDefaultItems(itemClassList)}

        </article>
      </main>
    );
  }

}

export default CompanyDirectorySkeletonSingleScreen;