import axios from 'axios';
axios.defaults.withCredentials = true

// export const serverUrl = 'https://d9.companyapp.co.uk';
// export const serverUrl = 'https://comms-staging.companyapp.net';
export const serverUrl = 'https://communicator.companyapp.net';

export const api = axios.create({
  baseURL: serverUrl,
  withCredentials: true,
  timeout: 30000, // 30s
  headers: {
    'Content-Type': 'application/json',
  },
});
