import React from "react";
import {Link} from "react-router-dom";

// Modules
import parse, {domToReact, attributesToProps} from 'html-react-parser';
import { serverUrl } from "../config";

export default class PostBody extends React.Component {
  render() {
    const options = {
      replace: domNode => {
        // links
        if (domNode.name === 'a') {
          const href = domNode.attribs.href;
  
          if (href.indexOf('communicator://') > -1) {
            let newHref = href.replace('communicator://', '');
            newHref = newHref.replace('node/', '');

            if (newHref.indexOf('documents/') > -1) {
              return (
                <a href={window.location.origin + '/' + newHref} target='_blank' rel="noreferrer">
                  {domToReact(domNode.children)}
                </a>
              );
            } else {
              return (
                <Link
                  to={'/' + newHref}
                >
                  {domToReact(domNode.children)}
                </Link>
              );
            }
          }
        }
  
        if (domNode.name === 'img') {
          const src = serverUrl + domNode.attribs.src;
          let attribs = domNode.attribs;
          attribs.src = src;
  
          const props = attributesToProps(attribs);
  
          // eslint-disable-next-line jsx-a11y/alt-text
          return <img {...props} />
        }
      }  
    }

    if (this.props.type && this.props.type === 'raw') {
      return (
        <div dangerouslySetInnerHTML={{__html: parse(this.props.body, options)}} />
      );
    } else {
      return (
        <div>
          {parse(this.props.body, options)}
        </div>
      );
    }
  }
}
