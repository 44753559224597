// Data
import db from './db';

export const updateContentCounterTable = async (type, server_count) => {
  const data = await db.contentCounter.where('content_type').equals(type).toArray();

  if (data && data.length > 0) {
    db.contentCounter.update(type, {
      serverCount: server_count,
    }).then(function (updated) {
      if (updated) {
        // console.log(`updated ${type} ${updated}`);
      } else {
        // console.log(`not ${type} updated`);
      }
    });
  } else {
    db.contentCounter.put({
      content_type: type,
      serverCount: server_count,
      previousCount: 0
    }, type);
  }
}

export const updateContentCounterPreviousCount = async (type, previous_count) => {
  const data = await db.contentCounter.where('content_type').equals(type).toArray();

  if (data && data.length > 0) {
    db.contentCounter.update(type, {
      previousCount: previous_count,
    }).then(function (updated) {
      if (updated) {
        // console.log(`updated ${type} ${updated}`);
      } else {
       //  console.log(`not ${type} updated`);
      }
    });
  }
}