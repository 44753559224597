import React from 'react';

// Modules
import {connect} from 'react-redux';

// App
import BlogItem from './blogItem';
import {getExternal} from '../../core/getNode';
import {paginator} from '../../core/paginator';
import Pagination from '../../core/pagination';
import PageHeader from '../../partials/pageHeader';
import CategorySelector from '../../partials/categorySelector';
import { alertMessages } from "../../partials/alertMessages";
import Error from "../../partials/error";
import { _checkContent } from '../../core/checkContent';
import SkeletonItemScreen from "../../partials/skeleton-screens/skeletonItemScreen";
import NoData from '../../partials/noData';

// Data
import db from './../../core/db';
import {getContentCount} from '../../core/getRecordCount';
import {updateContentCounterPreviousCount} from '../../core/updateData'
import {getFavorites, getFavouritesLength} from './../../core/getFavorites';

// UI components
import Row from 'react-bootstrap/Row';

class Blog extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      isRefreshing: false,
      isError: false,

      errorStatus: '',
      errorMessage: '',

      currentPage: 1,
      pageSize: 20,
      index: 0,
      totalItems: 0,

      selectedCategory: 0,
      categories: [],
      token: '',

      data: [],
      favorites: [],

      activeIndex: 0,
    };

    this.onPaginate = this.onPaginate.bind(this);
  }

  /**
   * @function componentDidMount
   * @description Default react method. Fired when component is rendered
   */
  componentDidMount() {
    this.checkSession();
  }

  checkSession = () => {
    this.getCategories();
    this.getContent();
    // if (Object.keys(this.props.user).length === 0) {
    //   this.props.history.push('/');
    // } else {
    //   this.getCategories();
    //   this.getContent();
    // }
  }

  /**
   * @function setData
   * @description Updates the state of the component upon data retrival
   * @param {object} _data
   * @param {object} _realm
   */
  setData = async (_data) => {
    this.setState(
      {
        isLoading: false,
        isRefreshing: false,
        isPaginating: false,
        data: _data.rows,
        totalItems: _data.pager.total_items,
      }
    );

    const serverCount = await getContentCount('blog')
    
    if (serverCount && serverCount[0]) {
      const count = serverCount[0] ? serverCount[0].serverCount : 0;
      updateContentCounterPreviousCount('blog', count)
    }

    _checkContent();
  };

  /**
   * @function setError
   * @description Updates the state of the component upon an error
   * @param {boolean} _isError
   * @param {int} _errorStatus
   * @param {string} _errorMessage
   */
  setError = (_isError, _errorStatus, _errorMessage) => {
    this.setState({
      isLoading: false,
      isRefreshing: false,
      isPaginating: false,
      isError: _isError,
      errorStatus: _errorStatus,
      errorMessage: _errorMessage,
    });
  };

  favorite = async (_nid) => {
    const {activeIndex} = this.state;

    let blog = await db.blog.get(_nid);
    blog.favorite = 'true';
    db.blog.put(blog, _nid);

    if (activeIndex === 0) {
      this.getContent();
    }

    if (activeIndex === 1) {
      this.getFavourites();
    }
  }

  unfavorite = async (_nid) => {
    const {activeIndex} = this.state;
  
    let blog = await db.blog.get(_nid);
    blog.favorite = 'false';
    db.blog.put(blog, _nid);

    if (activeIndex === 0) {
      this.getContent();
    }

    if (activeIndex === 1) {
      this.getFavourites();
    }
  }

  dataHandler = async (_data) => {
    let blogs = [];

    for (const data of _data.rows) {
      let existingBlog = await db.blog.get(data.id);
      let favorite = 'false';

      if (existingBlog) {
        favorite = existingBlog.favorite;
      }
      
      const blog = {
        id: data.id,
        title: data.title,
        content: data.content,
        date: data.date,
        categoryLabel: data._embedded['wp:term'],
        sticky: "false",
        favorite: favorite,
        uri:
          data._embedded["wp:featuredmedia"][0].source_url.length > 0
            ? data._embedded["wp:featuredmedia"][0].source_url
            : "null",
      };

      db.blog.put(blog, data.id);

      blogs.push(blog);
    }

    let data = _data;

    data.rows = blogs;

    this.setData(data);
  }

  /**
   * @function getContent
   * @description Retrieves the data from an API / Fallback to local realm object if there is no connection
   */
  getContent = () => {
    let path =
      'wp-json/wp/v2/posts?per_page=' +
      this.state.pageSize +
      '&page=' +
      this.state.currentPage;

    const tid = this.state.selectedCategory;

    if (tid !== 0) {
      path =
        'wp-json/wp/v2/posts' +
        '?categories=' +
        tid +
        '&per_page=' +
        this.state.pageSize +
        '&page=' +
        this.state.currentPage;
    }

    getExternal(path + '&_embed=1')
      .then((response) => {
        let data = {
          rows: response.data,
          pager: {
            total_items: response.headers['x-wp-total'],
          },
        };
        this.dataHandler(data);
      })
      .catch((_error) => {
        console.log('error: ', _error);
        if (_error.response) {
          //console.log('@rest response: ', _error.response);
          this.setError(
            true,
            _error.response.status,
            _error.response.statusText,
          );
        } else if (_error.request) {
          //console.log('@rest request: ', _error.request);
          this.setError(true, 0, alertMessages.requestError.message);
        } else {
          //console.log('@rest unknown: ', _error);
          this.setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };

  /**
   * @function getFavourites
   * @description Retrieves the favourited data from the database
   */
  getFavourites = async () => {
    const favorites = await getFavorites('blog', this.state.selectedCategory, this.state.index, this.state.pageSize);
    const favoritesLength = await getFavouritesLength('blog', this.state.selectedCategory);

    this.setState({
      data: favorites,
      totalItems: favoritesLength,
      isLoading: false,
      isRefreshing: false,
      isPaginating: false,
    });
  }

  /**
   * @function getCategories
   * @description Retrives the category data from an API / Fallback to local realm object if there is no connection
   */
  getCategories = () => {
    getExternal('wp-json/wp/v2/categories')
      .then((response) => {
        this.renderCategories(response.data)
      })
      .catch((_error) => {
        if (_error.response) {
          this.setError(
            true,
            _error.response.status,
            _error.response.statusText,
          );
        } else if (_error.request) {
          //console.log('@rest request: ', _error.request);
          this.setError(true, 0, alertMessages.requestError.message);
        } else {
          //console.log('@rest unknown: ', _error);
          this.setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };

  /**
   * @function onPaginate
   * @description Pagination callback
   * @param {int} _index - Page number
   */
  onPaginate = (_index) => {
    this.setState(
      {
        isLoading: true,
        isPaginating: true,
        currentPage: _index,
      },
      function() {
        this.getContent();
      },
    );
  };

  /**
   * @function renderCategories
   * @description Updates the state with the retrived categories
   * @param {object} _categories
   */
  renderCategories = (_data) => {
    let categories = [
      {
        value: 0,
        label: 'All Blogs',
      },
    ];

    _data.forEach(item => {
      categories.push({
        value: item.id,
        label: item.name,
      });
    });

    this.setState({
      categories: categories,
    });
  };

  /**
   * @function handleCategorySelection
   * @description Modal Picker callback / Updates the header title and sets the state to the selected category
   * @param {string} _id - Category ID
   */
  handleCategorySelection = (event) => {
    const {activeIndex} = this.state;
    this.setState(
      {
        // experimental
        isLoading: true,
        isPaginating: true,
        isError: false,
        currentPage: 1,
        selectedCategory: parseInt(event.value, 10),
        selectedCategoryItem: event,
      },
      function () {
        if (activeIndex === 0) {
          this.getContent();
        }

        if (activeIndex === 1) {
          this.getFavourites()
        }
      },
    );
  };
  
  // Pagination component
  renderPagination = () => {
    if (this.state.totalItems <= this.state.pageSize) {
      return null;
    } else {
      let pagination = paginator(
        this.state.totalItems,
        this.state.currentPage,
        this.state.pageSize,
        3,
      );

      return (
        <Pagination
          currentPage={pagination.currentPage}
          endIndex={pagination.endIndex}
          endPage={pagination.endPage}
          pageSize={pagination.pageSize}
          pages={pagination.pages}
          startIndex={pagination.startIndex}
          startPage={pagination.startPage}
          totalItems={pagination.totalItems}
          totalPages={pagination.totalPages}
          paginationCallback={this.onPaginate}
        />
      );
    }
  };

  // Data component
  dataComponent = () => {
    const {data} = this.state;

    if (typeof data !== 'undefined' && data.length > 0) {
      return (
        <Row>
          {data.map(item => {
            return (
              <BlogItem
                key={'blog-' + item.id}
                item={item}
                id={item.id}
                favorite={this.favorite}
                unfavorite={this.unfavorite}
              />
            );
          })}
        </Row>
      );
    } else {
      return (
        <NoData activeIndex={this.state.activeIndex} />
      );
    }
  };

  
  /**
   * @function render
   * @description Default render method
   */
  render() {
    if (this.state.isLoading) {
      return <SkeletonItemScreen />
    } else {
      if (this.state.isError) {
        return (
          <Error 
            status={this.state.errorStatus}
            message={this.state.errorMessage}
          />
        );
      } else {
        return (
          <main className="blog  screen">
            <PageHeader 
              pageName="Blogs" 
              filters={true}
              categorySelector={
                <CategorySelector 
                  name="blog"
                  categories={this.state.categories}
                  selectedCategory={this.state.selectedCategoryItem}
                  handleCategorySelection={this.handleCategorySelection}
                />
              }
              getContent={() => {
                this.setState(
                  {
                    activeIndex: 0,
                    isLoading: true,
                  },
                  function () {
                    this.getContent();
                  },
                );
              }}
              getFavourites={() => {
                this.setState(
                  {
                    activeIndex: 1,
                    isLoading: true,
                  },
                  function () {
                    this.getFavourites();
                  },
                );
              }}
              activeTabIndex={this.state.activeIndex}
            />
            {this.dataComponent()}
            {this.renderPagination()}
          </main>
        );
      }
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default connect(mapStateToProps)(Blog);
