// Dependencies
import React from 'react';

// Modules
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import moment from 'moment';
import {decode} from 'html-entities';

// App
import {getOnlineNode} from '../../core/getNode';
import PostTitle from '../../partials/postTitle';
import PostBody from '../../partials/postBody'
import { alertMessages } from "../../partials/alertMessages";
import Error from "../../partials/error";
import SkeletonSingleScreen from '../../partials/skeleton-screens/skeletonSingleScreen';

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Calendar, Clock, Mail, Phone, Tag, Link2 } from 'react-feather';

class EventsSingle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isPaginating: false,
      post: null,
      pageSize: 10,
      currentPage: 0,
      total_pages: 0,
    };
  }

  componentDidMount() {
    this.checkSession();
  }
  
  checkSession = () => {
    if (Object.keys(this.props.user).length === 0) {
      this.props.history.push('/');
    } else {
      this.loadNode();
    }
  }


  /**
   * @function setData
   * @description Updates the state of the component upon data retrival
   * @param {object} _data
   * @param {object} _realm
   */
  setData = (_data) => {
    this.setState(
      {
        isLoading: false,
        isRefreshing: false,
        isError: false,
        post: _data,
      },
    );
  };

  /**
   * @function setError
   * @description Updates the state of the component upon an error
   * @param {boolean} _isError
   * @param {int} _errorStatus
   * @param {string} _errorMessage
   */
  setError = (_isError, _errorStatus, _errorMessage) => {
    this.setState({
      isLoading: false,
      isRefreshing: false,
      isError: _isError,
      errorStatus: _errorStatus,
      errorMessage: _errorMessage,
    });
  };


  loadNode = () => {
    const nid = this.props.match.params.nid;

    if (nid) {
      this.getContent(nid);
    } else {
      this.setError(true, 0, alertMessages.contentError);
    }
  };

  /**
   * @function getContent
   * @description Retrieves the data from an API / Fallback to local realm object if there is no connection
   */
  getContent = (nid) => {
    let path = 'events/all/' + nid + '?_format=json&status=1&promote=1';

    getOnlineNode(path, this.props.user.access_token)
      .then((response) => {
        if (response.data.rows.length > 0) {
          this.setData(response.data.rows[0]);
        } else {
          this.setError(true, 200, alertMessages.noData);
        }
      })
      .catch((_error) => {
        if (_error.response) {
          this.setError(
            true,
            _error.response.status,
            _error.response.statusText,
          );
        } else if (_error.request) {
          this.setError(true, 0, alertMessages.requestError.message);
        } else {
          this.setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };

  renderPostImage = (post) => {
    let hasImage = post.field_featured_image.length > 0 ? true : false;

    if (hasImage && !this.state.isLandscape) {
      return (
        <div className="article__image">
          <img src={post.field_featured_image} alt={'img-' + post.nid} />
        </div>
      );
    }
  };

  timeRenderer = (post) => {
    const startTime = post.field_start_time;
    let endTime = null;

    if (post.field_end_time) {
      endTime = post.field_end_time;
    }

    let time = startTime;

    if (endTime && endTime !== startTime) {
      time += ' - ' + endTime;
    }

    return (
      <div className="event-details__item">
        <Row noGutters className="align-items-center">
          <Col xs={'auto'}>
            <Clock />
          </Col>
          <Col>
            <p>{time}</p>
          </Col>
        </Row>
      </div>
    );
  };

  dateRenderer = (post) => {
    const startDate = moment
      .unix(post.field_start_date)
      .format('DD MMMM YYYY');

    let endDate = null;

    if (post.field_end_date) {
      endDate = moment.unix(post.field_end_date).format('DD MMMM YYYY');
    }

    let date = startDate;

    if (endDate && endDate !== startDate) {
      date += ' - ' + endDate;
    }

    return (
      <div className="event-details__item">
        <Row noGutters className="align-items-center">
          <Col xs={'auto'}>
            <Calendar />
          </Col>
          <Col>
            <p>{date}</p>
          </Col>
        </Row>
      </div>
    );
  };

  contactInfoRenderer = (post) => {
    const email = post.field_email ? post.field_email : null;
    const number = post.field_contact_number
      ? post.field_contact_number
      : null;
    const website = post.field_website ? post.field_website : null;

    const decodedEmail = decode(email);
    const decodedNumber = decode(number);

    return (
      <>
        {number && (
          <div className="event-details__item">
            <Row noGutters className="align-items-center">
              <Col xs={'auto'}>
                <Phone />
              </Col>
              <Col>
                <a href={`tel:${decodedNumber}`}>{decodedNumber}</a>
              </Col>
            </Row>
          </div>
        )}
      
        {email && (
          <div className="event-details__item">
            <Row noGutters className="align-items-center">
              <Col xs={'auto'}>
                <Mail />
              </Col>
              <Col>
                <a href={`mailto:${decodedEmail}`}>{decodedEmail}</a>
              </Col>
            </Row>
          </div>
        )}

        {website && (
          <div className="event-details__item">
            <Row noGutters className="align-items-center">
              <Col xs={'auto'}>
                <Link2 />
              </Col>
              <Col>
                <a href={website} rel="noreferrer" target="_blank">{website}</a>
              </Col>
            </Row>
          </div>
        )}
      </>
    );
  };

  addressRenderer = (post) => {
    const address = post.field_address;
    const decodedAddress = decode(address);

    if (address) {
      return (
        <div className="event-details__item">
          <Row noGutters className="align-items-center">
            <Col xs={"auto"}>
              <Calendar />
            </Col>
            <Col>
              <a
                href={"https://maps.google.com/?q=" + address}
                rel="noreferrer"
                target="_blank"
              >
                {decodedAddress}
              </a>
            </Col>
          </Row>
        </div>
      );
    } else {
      return null;
    }
  };

  render() {
    if (this.state.isLoading) {
      return <SkeletonSingleScreen />;
    } else {
      if (this.state.isError) {
        return (
          <Error 
            status={this.state.errorStatus}
            message={this.state.errorMessage}
          />
        );
      } else {
        const {post} = this.state;
        const timestamp = moment.unix(post.created).format("Do MMM YYYY");

        let category = 'Uncategorised';

        if (post.field_category_1) {
          category = post.field_category_1;
        }

        if (post.categoryLabel) {
          category = post.categoryLabel;
        }

        return (
          <main className="event  single">
            <article className="article">
              <header className="article__header">
                <PostTitle title={post.title} headingLevel={'h1'} />
                <div className="article__meta">
                  <Row>
                    <Col xs={"auto"}>
                      <div className="timestamp align-items-center">
                        <Clock className="timestamp__icon" />
                        <span className="timestamp__label  label">{timestamp}</span> 
                      </div>

                    </Col>
                    <Col xs={"auto"}>
                      <div className="category align-items-center">
                        <Tag className="category__icon" />
                        <span className="category__label  label">{category}</span>
                      </div>
                    </Col>
                  </Row>
                </div>
              </header>
              {this.renderPostImage(post)}
              <div className="event-details">
                <h3 className="event-details__header">Event details</h3>
                {this.timeRenderer(post)}
                {this.dateRenderer(post)}
                {this.contactInfoRenderer(post)}
                {this.addressRenderer(post)}
              </div>
              <div className="article__body">
                <PostBody body={post.body} />
              </div>
            </article>
          </main>
        );
      }
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default withRouter(connect(mapStateToProps)(EventsSingle));
