/* eslint-disable array-callback-return */
// Dependencies
import React from 'react';

// Modules
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import moment from 'moment';

// App
import {getOnlineNode} from '../../core/getNode';
import {postNode, getToken} from '../../core/postNode';
import PostTitle from '../../partials/postTitle';
import {alertMessages} from '../../partials/alertMessages';
import AlertModal from '../../partials/alertModal';
import Error from "../../partials/error";
import SkeletonSingleScreen from '../../partials/skeleton-screens/skeletonSingleScreen';

// Data
import db from './../../core/db';

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import {Clock, Tag, ArrowRight, CheckSquare, Square, CheckCircle } from 'react-feather';

class QuizsSingle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isError: false,
      errorStatus: '',
      errorMessage: '',
      item: null,
      questions: [],
      answers: [],
      complete: false,
      submitted: false,
    };
  }

  componentDidMount() {
    this.checkSession();
  }
  
  checkSession = () => {
    if (Object.keys(this.props.user).length === 0) {
      this.props.history.push('/');
    } else {
      this.getToken();
      this.loadNode();
    }
  }

  getToken = () => {
    getToken()
      .then((_response) => {
        this.setState({
          token: _response.data,
        });
      })
      .catch((_error) => {
        this.setState({
          modalVisible: true,
          alertType: 'error',
          alertTitle: alertMessages.tokenError.title,
          alertBody: alertMessages.tokenError.message,
          alertConfirmButton: true,
        });
      });
  };

  /**
   * Set modal visibility
   * @param {*} visible 
   */
  setModalVisible = (visible) => {
    this.setState({
      modalVisible: visible,
    });
  };

  /**
   * @function setError
   * @description Updates the state of the component upon an error
   * @param {boolean} _isError
   * @param {int} _errorStatus
   * @param {string} _errorMessage
   */
  setError = (_isError, _errorStatus, _errorMessage) => {
    this.setState({
      isLoading: false,
      isRefreshing: false,
      isError: _isError,
      errorStatus: _errorStatus,
      errorMessage: _errorMessage,
    });
  };

  loadNode = () => {
    const nid = this.props.match.params.nid;

    if (nid) {
      this.getContent(nid);
    } else {
      this.setError(true, 0, alertMessages.contentError);
    }
  };

  /**
   * @function getContent
   * @description Retrieves the data from an API / Fallback to local realm object if there is no connection
   */
  getContent = (nid) => {
    let path = 'quiz/all/' + nid + '?_format=json&status=1&promote=1';

    getOnlineNode(path, this.props.user.access_token)
      .then((response) => {
        if (response.data.rows.length > 0) {
          this.setState(
            {
              item: response.data.rows[0],
            },
            function () {
              this.getQuestions();
            },
          )
        } else {
          this.setError(true, 200, alertMessages.noData);
        }
      })
      .catch((_error) => {
        console.log('error: ', _error);
        if (_error.response) {
          this.setError(
            true,
            _error.response.status,
            _error.response.statusText,
          );
        } else if (_error.request) {
          //console.log('@rest request: ', _error.request);
          this.setError(true, 0, alertMessages.requestError.message);
        } else {
          //console.log('@rest unknown: ', _error);
          this.setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };

  getQuestions = () => {
    let path = 'quiz_questions/' + this.state.item.nid;

    getOnlineNode(
      path + '?_format=json',
      this.props.user.access_token,
    )
      .then((response) => {
        response.data.rows.map((question) => {
          this.setState({
            questions: this.state.questions.concat(question),
          });
        });

        this.getAnswersAsync(response.data.rows);
      })
      .catch((_error) => {
        console.log('error: ', _error);
        if (_error.response) {
          this.setError(
            true,
            _error.response.status,
            _error.response.statusText,
          );
        } else if (_error.request) {
          //console.log('@rest request: ', _error.request);
          this.setError(true, 0, alertMessages.requestError.message);
        } else {
          //console.log('@rest unknown: ', _error);
          this.setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };

  getAnswersAsync = (_array) => {
    const that = this;
    if (_array && _array.length > 0) {
      const question = _array[0];
      const path = 'quiz_answers/' + question.id;

      getOnlineNode(
        path + '?_format=json',
        this.props.user.access_token,
      )
        .then((response) => {
          that.setState(
            {
              answers: that.state.answers.concat(response.data),
            },
            function () {
              _array.shift();
              that.getAnswersAsync(_array);
            },
          );
        })
        .catch((_error) => {
          //console.log('@error: ', _error);
          _array.shift();
          that.getAnswersAsync(_array);
        });
    } else {
      this.manipulateAnswersArray();
    }
  };

  manipulateAnswersArray = () => {
    const answerArray = this.state.answers;
    let updatedArray = [];

    answerArray.map((_array) => {
      let numberOfCorrectAnswers = [];
      let updatedAnswers = [];
      _array.rows.map((_answer) => {
        if (_answer.field_correct === 'Yes') {
          numberOfCorrectAnswers++;
        }

        const updatedAnswer = {
          id: _answer.id,
          parent_id: _answer.parent_id,
          field_answer: _answer.field_answer,
          field_correct: _answer.field_correct,
          selected: false,
          limitReached: false,
        };

        updatedAnswers.push(updatedAnswer);
      });

      const updatedAnswer = {
        rows: updatedAnswers,
        correctLength: numberOfCorrectAnswers,
      };

      updatedArray.push(updatedAnswer);
    });

    this.setState({
      answers: updatedArray,
    });

    this.checkSubmission();
  };

  checkSubmission = async () => {
    this.saveLocal();
    const uid = this.props.user.current_user.uid;
    const path = 'quiz_submission_check/' + uid + '/' + this.state.item.nid;

    const nid = this.state.item.nid + '';

    const submissionData = await db.quizSubmissions.get(nid)

    getOnlineNode(
      path + '?_format=json',
      this.props.user.access_token,
    )
      .then((response) => {
        if (response.data && response.data.length > 0) {
          this.setState({
            isLoading: false,
            submitted: true,
          });
        } else {

          if (submissionData) {
            db.quizSubmissions.where('id').equals(nid).delete();  
          }

          this.setState({
            isLoading: false,
            submitted: false,
          });
        }
      })
      .catch((_error) => {
        // cannot check
        this.setState({
          modalVisible: true,
          alertType: 'error',
          alertTitle: alertMessages.nodeEndpointFetch.title,
          alertBody: alertMessages.nodeEndpointFetch.message,
          alertConfirmButton: true,
        });

        if (_error.response) {
          this.setError(
            true,
            _error.response.status,
            _error.response.statusText,
          );
        } else if (_error.request) {
          //console.log('@rest request: ', _error.request);
          this.setError(true, 0, alertMessages.requestError.message);
        } else {
          //console.log('@rest unknown: ', _error);
          this.setError(true, 0, alertMessages.unkownError.message);
        }
      });
  }

  saveLocal = () => {
    this.state.questions.map((_question, _index) => {
      const question = {
        id: _question.id,
        field_question: _question.field_question,
        parent_id: _question.parent_id,
      };

      db.quizQuestions.put(question, question.id);

      const answer = {
        id: _question.id,
        parent_id: _question.id,
        rows: JSON.stringify(this.state.answers[_index].rows),
      }

      db.quizAnswers.put(answer, answer.id);
    });
  };

  selectAnswer = (_questionIndex, _answerIndex) => {
    const selectedAnswerArray = this.state.answers[_questionIndex];
    const selectedAnswer = selectedAnswerArray.rows[_answerIndex];

    let selectedLength = 0;

    /**
     * count the length of choices
     * if max is reached return false
     */
    selectedAnswerArray.rows.map((_answer) => {
      if (_answer.selected) {
        selectedLength++;
      }
    });

    /**
     * update answer state: select/unselect
     */
    if (selectedAnswer.selected) {
      selectedAnswer.selected = false;
      selectedAnswer.limitReached = false;
    } else {
      if (selectedLength < selectedAnswerArray.correctLength) {
        selectedAnswer.selected = true;
        selectedLength++;
      } else {
        return false;
      }
    }

    const answerArray = this.state.answers;
    let updatedArray = [];

    /**
     * obdate the whole object
     */
    answerArray.map((_array, _index) => {
      let numberOfCorrectAnswers = [];
      let numberOfSelectedAnswers = 0;
      let updatedAnswers = [];
      let limitReached = false;

      _array.rows.map((_answer, __index) => {
        if (_answer.field_correct === 'Yes') {
          numberOfCorrectAnswers++;
        }

        let updatedAnswer;

        if (_index === _questionIndex && __index === _answerIndex) {
          updatedAnswer = selectedAnswer;
        } else {
          updatedAnswer = {
            id: _answer.id,
            parent_id: _answer.parent_id,
            field_answer: _answer.field_answer,
            field_correct: _answer.field_correct,
            selected: _answer.selected,
          };
        }

        if (updatedAnswer.selected) {
          numberOfSelectedAnswers++;
        }

        updatedAnswers.push(updatedAnswer);
      });

      if (numberOfSelectedAnswers === numberOfCorrectAnswers) {
        limitReached = true;
      }

      const updatedAnswer = {
        rows: updatedAnswers,
        correctLength: numberOfCorrectAnswers,
        numberOfChoices: numberOfSelectedAnswers,
        limitReached: limitReached,
      };

      updatedArray.push(updatedAnswer);
    });

    let complete = true;

    updatedArray.map((_updatedArray) => {
      if (_updatedArray.numberOfChoices < _updatedArray.correctLength) {
        complete = false;
      }
    });

    this.setState({
      answers: updatedArray,
      complete: complete,
    });
  };

  saveConfirm = () => {
    this.setState({
      modalVisible: true,
      alertType: 'primary',
      alertTitle: alertMessages.quizSubmissionWarning.title,
      alertBody: alertMessages.quizSubmissionWarning.message,
      alertCancelButton: true,
      alertConfirmButton: true,
      alertConfirmButtonLabel: 'Submit',
      alertOnConfirm: this.saveNode,
    });
  };

  saveNode = () => {
    let questions = [];

    // unset previous state of this.saveNode()
    this.setState({
      alertOnConfirm: false, 
    });

    this.state.questions.map((_question, _index) => {
      let questionAnswers = [];
      this.state.answers[_index].rows.map((_answer) => {
        if (_answer.selected) {
          const answerData = {
            field_answer: _answer.field_answer,
            field_correct: _answer.field_correct,
          };

          questionAnswers.push(answerData);
        }
      });

      let questionData = {
        field_question: _question.field_question,
        answers: questionAnswers,
      };

      questions.push(questionData);
    });

    const data = {
      quizID: this.state.item.nid,
      questions: questions,
    };

    const nid = this.state.item.nid + '';

    postNode(
      'quiz_rest_api/quiz_submission_resource',
      data,
      this.state.token,
      this.props.user.access_token,
    )
      .then((response) => {
        if (response.data && response.data.nid) {
          const quizSubmission = {
            id: nid,
          }

          db.quizSubmissions.put(quizSubmission, nid);

          this.checkSubmission();

          this.setState({
            modalVisible: true,
            alertType: 'success',
            alertTitle: alertMessages.quizSubmitted.title,
            alertBody: alertMessages.quizSubmitted.message,
            alertCancelButton: false,
            alertConfirmButton: true,
            alertConfirmButtonLabel: 'OK',
            submitted: true,
            isLoading: false,
          });
        } else {
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((_error) => {
        this.setState({
          modalVisible: true,
          alertType: 'error',
          alertTitle: alertMessages.quizSubmissionFailed.title,
          alertBody: alertMessages.quizSubmissionFailed.message,
          alertCancelButton: false,
          alertConfirmButton: true,
          alertConfirmButtonLabel: 'OK',
          submitted: false,
          isLoading: false,
        });
      });
  };

  renderComponent = () => {
    if (this.state.submitted) {
      return this.renderSubmittedView();
    } else {
      return this.renderNormalView();
    }
  };

  renderNormalView = () => {
    const post = this.state.item;
    const timestamp = moment.unix(post.created).format("Do MMM YYYYY");

    let category = 'Uncategorised';

    if (post.field_category_1) {
      category = post.field_category_1;
    }

    if (post.categoryLabel) {
      category = post.categoryLabel;
    }

    return (
      <article className="article">
        <header className="article__header">
          <PostTitle title={post.title} headingLevel={'h1'} />
          <div className="article__meta">
            <Row>
              <Col xs={"auto"}>
                <div className="timestamp align-items-center">
                  <Clock className="timestamp__icon" />
                  <span className="timestamp__label  label">{timestamp}</span> 
                </div>

              </Col>
              <Col xs={"auto"}>
                <div className="category align-items-center">
                  <Tag className="category__icon" />
                  <span className="category__label  label">{category}</span>
                </div>
              </Col>
            </Row>
          </div>
        </header>
        {this.renderPostImage(post)}
        <div className="article__body">
          <div dangerouslySetInnerHTML={{__html: post.body}} />
        </div>
        <div className="section__container">
          {this.state.questions.map((_question, _index) => {
            const answers = this.state.answers[_index].rows;
            const limitReached = this.state.answers[_index].limitReached;
            const answerLength = this.state.answers[_index].correctLength;
            const noticeString = answerLength > 1 ? ' answers' : ' answer';

            return (
              <div key={'section-' + _index} className="section__wrapper">
                <div className="section__question__wrapper">
                  <div className="section__question">
                    <h5 className="section__title" dangerouslySetInnerHTML={{__html: _question.field_question}} />
                    <span className="section__label">{'*Please choose ' + answerLength + noticeString + ':'}</span>
                  </div>

                  {answers.map((_answer, __index) => {
                    let answerStyle;
                    let iconStyle;

                    if (_answer.selected) {
                      answerStyle = 'selected__answer';
                      iconStyle = 'selected__icon';
                    } else {
                      if (limitReached) {
                        answerStyle = 'unselectable__answer';
                        iconStyle = 'unselectable__icon';
                      } else {
                        answerStyle = '__answer';
                        iconStyle = '__icon';
                      }
                    }

                    return (
                      <div key={_answer.id + '-' + __index} className="section__answer">
                        <Row noGutters className="align-items-start">
                          <Col xs={'auto'} className="pr-2">
                            <Button onClick={() => {
                              this.selectAnswer(_index, __index)
                            }}>
                              {_answer.selected ? (
                                <CheckSquare className={iconStyle} />
                              ) : (
                                <Square className={iconStyle} />
                              )}
                            </Button>
                          </Col>
                          <Col>
                            <label className={answerStyle} dangerouslySetInnerHTML={{__html: _answer.field_answer}} htmlFor={_answer.id} />
                          </Col>
                        </Row>
                      </div>
                    );
                  })}
                </div>
              </div>
            )
          })}
          <Row>
            {!this.state.complete && (
              <Col xs={12}>
                <label className="section__submission__notice" dangerouslySetInnerHTML={{__html: alertMessages.quizSubmissionNotice}} />
              </Col>
            )}
            <Col>
              <div className="section__button__wrapper">
                <Button
                  disabled={this.state.complete ? false : true}
                  className={this.state.complete ? 'section__submission__button' : 'section__submission__button inactive'}
                  onClick={() => {
                    this.saveConfirm();
                  }}>
                    <span className="section__submission__button__text">Submit</span>
                    <ArrowRight className="section__submission__arrow__icon" />
                </Button>
              </div>
            </Col>
          </Row>
          
        </div>
      </article>
    );
  }

  renderSubmittedView = () => {
    const post = this.state.item;
    const timestamp = moment.unix(post.created).format("Do MMM YYYYY");

    let category = 'Uncategorised';

    if (post.field_category_1) {
      category = post.field_category_1;
    }

    if (post.categoryLabel) {
      category = post.categoryLabel;
    }

    return (
      <article className="article">
        <header className="article__header">
          <PostTitle title={post.title} headingLevel={'h1'} />
          <div className="article__meta">
            <Row>
              <Col xs={"auto"}>
                <div className="timestamp align-items-center">
                  <Clock className="timestamp__icon" />
                  <span className="timestamp__label  label">{timestamp}</span> 
                </div>

              </Col>
              <Col xs={"auto"}>
                <div className="category align-items-center">
                  <Tag className="category__icon" />
                  <span className="category__label  label">{category}</span>
                </div>
              </Col>
            </Row>
          </div>
        </header>
        {this.renderPostImage(post)}
        <div className="article__body">
          <div dangerouslySetInnerHTML={{__html: post.body}} />
        </div>
        <div className="submitted__container alert alert-success" role="alert">
          <CheckCircle/>
          <span dangerouslySetInnerHTML={{__html: alertMessages.quizSubmissionSavedText}} />
        </div>
        <div className="section__container">
          {this.state.questions.map((_question, _index) => {
            const answers = this.state.answers[_index].rows;
            return (
              <div key={'section-' + _index} className="section__wrapper">
                <div className="section__question__wrapper">
                  <div className="section__question">
                    <h5 className="section__title" dangerouslySetInnerHTML={{__html: _question.field_question}} />
                  </div>

                  {answers.map((_answer, __index) => {
                    let answerStyle;
                    let iconStyle;

                    if (_answer.field_correct === 'Yes') {
                      answerStyle = 'selected__answer';
                      iconStyle = 'selected__icon';
                    } else {
                      answerStyle = '__answer';
                      iconStyle = '__icon';
                    }

                    return (
                      <div key={_answer.id + '-' + __index} className="section__answer">
                        <Row noGutters className="align-items-start">
                          <Col xs={'auto'} className="pr-2">
                            <Button disabled={true}>
                              {_answer.field_correct === 'Yes' ? (
                                <CheckSquare className={iconStyle} />
                              ) : (
                                <Square className={iconStyle} />
                              )}
                            </Button>
                          </Col>
                          <Col>
                            <label className={answerStyle} dangerouslySetInnerHTML={{__html: _answer.field_answer}} htmlFor={_answer.id} />
                          </Col>
                        </Row>
                      </div>
                    );
                  })}
                </div>
              </div>
            )
          })}
        </div>
      </article>
    );
  }

  renderPostImage = (post) => {
    let hasImage = post.field_featured_image.length > 0 ? true : false;

    if (hasImage) {
      return (
        <div className="article__image">
          <img src={post.field_featured_image} alt={'img-' + post.nid} />
        </div>
      );
    }
  };

  render() {
    if (this.state.isLoading) {
      return <SkeletonSingleScreen />;
    } else {
      if (this.state.isError) {
        return (
          <Error 
            status={this.state.errorStatus}
            message={this.state.errorMessage}
          />
        );
      } else {
        return (
          <main className="quiz single">
            <AlertModal 
              showAlert={this.state.modalVisible} 
              showAlertCallback={this.setModalVisible}
              alertType={this.state.alertType}
              alertMessageTitle={this.state.alertTitle}
              alertMessageBody={this.state.alertBody}
              cancelButton={this.state.alertCancelButton}
              confirmButton={this.state.alertConfirmButton}
              confirmButtonLabel={this.state.alertConfirmButtonLabel}
              onConfirm={this.state.alertOnConfirm}
            />
            {this.renderComponent()}
          </main>
        );
      }
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default withRouter(connect(mapStateToProps)(QuizsSingle));
