import React from 'react';

// Modules
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {encode} from "html-entities";

// App
import {serverUrl} from '../../config';
import {getOnlineNode} from '../../core/getNode';
import {postNode, getToken} from '../../core/postNode';
import {postFile} from '../../core/postFile';
import {alertMessages} from '../../partials/alertMessages';
import PageHeader from '../../partials/pageHeader';
import CategorySelector from '../../partials/categorySelector';
import EditGroup from './editGroup';
import CreateGroup from './createGroup';
import AlertModal from '../../partials/alertModal';
import NoticesEditSkeletonScreen from './skeleton-screens/noticesEditSkeletonScreen';

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { Edit2, File, Plus, X, ArrowRight} from 'react-feather';

class AddNotice extends React.Component {
  constructor() {
    super();
    this.state = {
      isComponentLoading: true,
      isLoading: false,
      isMembersLoading: false,
      title: '',
      body: '',
      token: null,
      featured_image: null,
      modalVisible: false,
      isCollapsed: true,

      editGroupModalVisible: false,
      addGroupModalVisible: false,
      postModalVisible: false,

      groups: [
        {
          label: 'Please choose an option',
          id: 0,
        },
      ],
      selectedGroup: 0,
      group_members: [],
      files: [],
      sentFileIDs: [],

    };
  }

  componentDidMount() {
    this.getGroups();
    this.getToken();
  }

  getToken = () => {
    getToken().then(_response => {
      this.setState({
        token: _response.data,
      });
    });
  };

  getGroups = id => {
    let path =
      'groups_list_manager/' +
      this.props.user.current_user.uid;

    getOnlineNode(path, this.props.user.access_token)
      .then(response => {
        let groups = [
          {
            label: 'Please choose an option',
            id: 0,
          },
        ];

        response.data.forEach(group => {
          groups.push(group);
        });

        this.setState(
          {
            isComponentLoading: false,
            groups: groups,
          },
          () => {
            this.setState(
              {
                selectedGroup: id ? id : 0,
              },
              () => {
                this.getGroupMembers();
              },
            );
          },
        );
      })
      .catch(_error => {
        this.setState({
          isComponentLoading: false,
        });
      });
  };

  getGroupMembers = () => {
    const {selectedGroup} = this.state;

    if (selectedGroup !== 0) {
      getOnlineNode(
        'group_members/' + this.state.selectedGroup,
        this.props.user.access_token,
      )
        .then(response => {
          this.setState({
            group_members: response.data,
            isMembersLoading: false,
          });
        })
        .catch(_error => {
          this.setState({
            group_members: [],
            isMembersLoading: false,
          });
        });
    } else {
      this.setState({
        group_members: [],
        isMembersLoading: false,
      });
    }
  };

  setTitle = _title => {
    this.setState({
      title: _title,
    });
  };

  setBody = _body => {
   let body = encode(_body);

    this.setState({
      body: body,
    });
  };

  uploadFiles = () => {
    const {title, body, files} = this.state;
    let fileUploadErrors = 0;

    if (title.length < 5) {
      this.setState({
        modalVisible: true,
        alertType: 'error',
        alertTitle: alertMessages.noticeTitle.title,
        alertBody: alertMessages.noticeTitle.message,
        alertConfirmButton: true,
      });

      this.setState({
        isLoading: false,
      });

      return false;
    }

    if (body.length < 5) {
      this.setState({
        modalVisible: true,
        alertType: 'error',
        alertTitle: alertMessages.noticeBody.title,
        alertBody: alertMessages.noticeBody.message,
        alertConfirmButton: true,
      });

      this.setState({
        isLoading: false,
      });

      return false;
    }

    this.setState({
      isLoading: true,
    });

    let filesSent = 0;

    if (files.length > 0) {
      files.forEach((file, index) => {
        postFile(
          file,
          serverUrl +
            '/file/upload/node/group_notices/field_notice_document?_format=hal_json',
          this.state.token,
          // this.props.user.access_token,
        )
          .then(response => response.json())
          .then(result => {
            const fid = result.fid[0].value;

            this.setState({
              sentFileIDs: this.state.sentFileIDs.concat([fid]),
            });

            filesSent++;

            if (filesSent === files.length) {
              this.filesSaved();
            }
          })
          .catch(error => {
            fileUploadErrors = fileUploadErrors + (index + 1);

            filesSent++;

            if (filesSent === files.length) {
              this.filesSaved();
            }
          });
      });
    } else {
      this.saveNode();
    }

    if (fileUploadErrors > 0) {
      this.setState({
        modalVisible: true,
        alertType: 'error',
        alertTitle: 'Error with files',
        alertBody: 'Failed to upload some of the documents. Please try uploading them again later.',
        alertConfirmButton: true,
        redirectBack: false,
      });
    }
  };

  filesSaved = () => {
    let files = [];

    this.state.sentFileIDs.forEach((fid, index) => {
      const file = {
        target_id: fid,
      };

      files.push(file);
    });

    this.saveNode(files);
  };

  saveNode = uploadedFiles => {
    const {title, body, selectedGroup} = this.state;

    this.setState({
      isLoading: true,
    });

    const data = {
      _links: {
        type: {
          href: serverUrl + '/rest/type/node/group_notices',
        },
      },
      type: {
        target_id: 'group_notices',
      },
      title: {
        value: title,
      },
      field_body: {
        value: body,
      },
      field_group: [
        {
          target_id: selectedGroup,
        },
      ],
      field_notice_document: uploadedFiles ? uploadedFiles : [],
    };

    postNode(
      'node',
      data,
      this.state.token,
      // this.props.user.access_token,
    )
      .then(_response => {
        if (_response.status === 201) {

          this.setState({
            isLoading: false,
            node: _response.data,
          });

          this.setState({
            postModalVisible: true,
            alertTitle: alertMessages.noticeSubmission.title,
            alertBody: alertMessages.noticeSubmission.message,
          });
        } else {
          this.setState({
            isLoading: false,
          });

          this.setState({
            modalVisible: true,
            alertType: 'error',
            alertTitle: alertMessages.postNodeFailed.title,
            alertBody: alertMessages.postNodeFailed.message,
            alertConfirmButton: true,
          });
        }
      })
      .catch(_error => {
        this.setState({
          isLoading: false,
        });

        this.setState({
          modalVisible: true,
          alertType: 'error',
          alertTitle: alertMessages.postNodeFailed.title,
          alertBody: alertMessages.postNodeFailed.message,
          alertConfirmButton: true,
          redirectBack: false,
        });
      });
  };

  notifyGroup = () => {
    const {node} = this.state;

    if (node && node.nid && node.nid[0] && node.nid[0].value) {
      const data = {
        nid: node.nid[0].value,
        type: 'new',
      };
  
      postNode(
        'notice',
        data,
        this.state.token,
        this.props.user.access_token,
      )
        .then(() => {
          console.log('notified');
        })
        .catch(error => {
          console.log('failed to nofity');
        });
    }
  };

  uploadMultipleFiles = e => {
    let {files} = this.state;

    for(let i = 0; i < e.target.files.length; i++) {
      files.push(e.target.files[i]);
    }

    this.setState({
      files: files,
    });
  }

  /**
   * @function handleCategorySelection
   * @description Modal Picker callback / Updates the header title and sets the state to the selected category
   * @param {object} event
   */
  handleGroupSelection = (event) => {
    this.setState(
      {
        selectedGroup: parseInt(event.value, 10),
        selectedGroupItem: event,
      },
      function () {
        this.getGroupMembers();
      },
    );
  };

  setModalVisible = _visible => {
    this.setState({
      modalVisible: _visible,
    });
  };

  getGroupTitle = () => {
    const {groups, selectedGroup} = this.state;

    if (groups.length > 0) {
      return groups.find(x => parseInt(x.id, 10) === selectedGroup).label;
    }
  };

  removeFile = _index => {
    const {files} = this.state;

    let newFiles = [];

    files.forEach((file, index) => {
      if (_index !== index) {
        newFiles.push(file);
      }
    });

    this.setState({
      files: newFiles,
    });
  };

  renderMember = item => {
    let name = '';

    if (item.field_first_name) {
      name = item.field_first_name;
    }

    if (item.field_last_name) {
      name = name + ' ' + item.field_last_name;
    }

    name = name + ' (' + item.name + ')';

    return (
      <Col>
        <div className="member">
          <span dangerouslySetInnerHTML={{__html: name}} />
        </div>
      </Col>
    );
  };

  renderSelectedFiles = () => {
    const {files} = this.state;

    if (files.length > 0) {
      return (
        <>
          {files.map((file, index) => {
            return (
              <div key={`doc-${index}`} className="document">
                <Row noGutters>
                  <Col className="col-auto">
                    <File />
                  </Col>
                  <Col className="d-flex">
                    <span className="file-name">{file.name}</span>
                    <Button variant="link" className="ml-auto" onClick={() => {
                      this.removeFile(index)
                    }}>
                      <X />
                      <span>Remove</span>
                    </Button>
                  </Col>
                </Row>
              </div>
            );
          })}
        </>
      );
    }
  };

  editGroup = group => {
    if (this.state.editGroupModalVisible) {
      const {selectedGroup, group_members} = this.state;
      return (
        <EditGroup
          modalVisible={this.state.editGroupModalVisible}
          setModalVisible={() => {
            this.setState({
              editGroupModalVisible: !this.state.editGroupModalVisible,
            });
          }}
          selectedGroup={selectedGroup.id}
          group_members={group_members}
          group_id={selectedGroup}
          group_label={this.getGroupTitle()}
          onEdit={id => {
            this.getGroups(id);
          }}
        />
      );
    }
  };

  createGroup = () => {
    if (this.state.addGroupModalVisible) {
      return (
        <CreateGroup
          modalVisible={this.state.addGroupModalVisible}
          setModalVisible={() => {
            this.setState({
              addGroupModalVisible: !this.state.addGroupModalVisible,
            });
          }}
          onCreated={id => {
            this.getGroups(id);
          }}
        />
      );
    }
  };

  errorAlerts = () => {
    return (
      <AlertModal 
        showAlert={this.state.modalVisible} 
        showAlertCallback={this.setModalVisible}
        alertType={this.state.alertType}
        alertMessageTitle={this.state.alertTitle}
        alertMessageBody={this.state.alertBody}
        cancelButton={this.state.alertCancelButton}
        confirmButton={this.state.alertConfirmButton}
        cancelButtonLabel={this.state.cancelButtonLabel}
        confirmButtonLabel={this.state.confirmButtonLabel}
      />
    )
  }

  saveAlerts = () => {
    return (
      <AlertModal 
        showAlert={this.state.postModalVisible} 
        showAlertCallback={() => {
          this.setState({
            postModalVisible: !this.state.postModalVisible,
          });
        }}
        alertType={'success'}
        alertMessageTitle={this.state.alertTitle}
        alertMessageBody={this.state.alertBody}
        cancelButton={true}
        confirmButton={true}
        cancelButtonLabel={'No'}
        confirmButtonLabel={'Yes'}
        onCancel={() => {
          this.props.history.push('/account/notices');
        }}
        onConfirm={() => {
          this.notifyGroup();
          this.props.history.push('/account/notices');
        }}
      />
    );
  }

  setAccordianVisibility = (collapsed) => {
    this.setState({
      isCollapsed: collapsed,
    });
  };

  render() {
    if (this.state.isComponentLoading) {
      return <NoticesEditSkeletonScreen />;
    } else {
      return (
        <main className="add-notice notices screen">
          <PageHeader 
            pageName="Add a Notice" 
            filters={false}
          />
          
          {this.errorAlerts()}
          {this.saveAlerts()}
  
          {this.editGroup()}
          {this.createGroup()}
          
          <div className="form-group">
            <Row className="mb-1">
              <Col>
                <label>Title</label>
              </Col>
            </Row>
  
            <Row>
              <Col>
                <input
                  type="text"
                  value={this.state.title}
                  placeholder="Add title"
                  className="form-control"
                  onInput={(event) => {
                    this.setTitle(event.target.value);
                  }}
                />
              </Col>
            </Row>
          </div>
  
          <div className="form-group">
            <Row className="mb-1">
              <Col>
                <label>Body</label>
              </Col>
            </Row>
  
            <Row>
              <Col>
                <CKEditor
                  editor={ClassicEditor}
                  config={{
                    toolbar: [
                      "bold",
                      "italic",
                      "link",
                      "bulletedList",
                      "undo",
                      "redo",
                    ],
                  }}
                  data=""
                  placeholder="Add body..."
                  onReady={(editor) => {}}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    this.setBody(data);
                  }}
                />
              </Col>
            </Row>
          </div>
  
          <div className="form-group">
            <Row className="mb-2">
              <Col>
                <label>Documents</label>
              </Col>
            </Row>
  
            <div className="documents-container  mb-2">
              {this.renderSelectedFiles()}
            </div>
  
            <Row>
              <Col>
                <input
                  ref={(input) => (this.fileInput = input)}
                  type="file"
                  accept="application/pdf"
                  onChange={this.uploadMultipleFiles}
                  multiple
                  style={{ display: "none" }}
                />
  
                <Button
                  variant="link"
                  onClick={() => {
                    this.fileInput.click();
                  }}
                >
                  <Plus />
                  <span>
                    {this.state.files.length > 0
                      ? "Add Documents"
                      : "Select Documents"}
                  </span>
                </Button>
              </Col>
            </Row>
          </div>
  
          <div className="form-group">
            <Row className="mb-1">
              <Col>
                <label>Select Group</label>
              </Col>
            </Row>

            <Row className="align-items-center">
              <Col md={8}>
                <CategorySelector
                  name="notices"
                  categories={this.state.groups}
                  selectedCategory={this.state.selectedGroupItem}
                  handleCategorySelection={this.handleGroupSelection}
                />
              </Col>

              <Col xs={'auto'} className="mt-2 mt-md-0">
                <Button
                  variant="link"
                  onClick={() => {
                    this.setState({
                      addGroupModalVisible: true,
                    });
                  }}
                >
                  <Plus />
                  <span>Create Group</span>
                </Button>
              </Col>
            </Row>

            <Row className="mt-2">
              <Col xs={'auto'}>
                {this.state.selectedGroup !== 0 && (
                  <Button
                    variant="link"
                    onClick={() => {
                      this.setState({
                        editGroupModalVisible: true,
                      });
                    }}
                  >
                    <Edit2 />
                    <span>Edit Group</span>
                  </Button>
                )}
              </Col>
            </Row>
          </div>
  
          {/* {this.state.selectedGroup !== 0 && (
            <div className="form-group">
              <Accordion>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="0"
                  onClick={() =>
                    this.setAccordianVisibility(!this.state.isCollapsed)
                  }
                >
                  <Row>
                    <Col className="d-flex  align-items-center mb-1">
                      <Users className="icon" />
                      <label>Group members</label>
                      {this.state.isCollapsed ? (
                        <ChevronDown className="chevron" />
                      ) : (
                        <ChevronUp className="chevron" />
                      )}
                    </Col>
                  </Row>
                </Accordion.Toggle>
  
                <Accordion.Collapse eventKey="0">
                  {this.state.isMembersLoading ? (
                    <Spinner animation="border" role="status" variant="primary">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  ) : (
                    <>
                      {this.state.group_members.length < 1 ? (
                        <div className="error-message">
                          <span>No members</span>
                        </div>
                      ): (
                        <div className="members-container">
                          {this.state.group_members.map((user, index) => {
                            return (
                              <Row key={`member-${index}`}>
                                {this.renderMember(user)}
                              </Row>
                            );
                          })}
                        </div>
                      )}
                    </>
                  )}
                </Accordion.Collapse>
              </Accordion>
            </div>
          )} */}
  
          <hr style={{margin: '3rem 0'}}/>
  
          <div className="form-group">
            <Row className="align-items-center justify-content-end">
              <Col xs={'auto'}>
                {this.state.isLoading ? (
                  <Spinner animation="border" role="status" variant="primary">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                ) : (
                  <Button
                    className="submit-btn"
                    onClick={() => {
                      this.uploadFiles();
                    }}
                  >
                    <span>Create Notice</span>
                    <ArrowRight />
                  </Button>
                )}
              </Col>
            </Row>
          </div>
  
        </main>
      );
    }

  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default withRouter(connect(mapStateToProps)(AddNotice));
