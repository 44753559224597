import React from "react";

// Modules
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import { Link } from "react-router-dom";
import TimeAgo from "react-timeago";
import moment from "moment";

// App
import {getOnlineNode} from '../../core/getNode';
import PostTitle from "../../partials/postTitle";
import UserImage from "../../partials/userImage";

// UI components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Bookmark, Users, Edit } from "react-feather";

class NoticesItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      isLoading: true,
      canEdit: false,

      favorite: 'false',

      author_image: null,
      author_username: null,
      author_first_name: null,
      author_last_name: null,
    };
  }

  componentDidMount() {
    this.checkState();
    this.checkAuthor();
    this.getPostAuthorData(this.props.item.uid);
  }

  checkState = () => {
    this.setState({
      favorite: this.props.item.favorite,
    });
  };

  checkAuthor = () => {
    if (
      this.props.item.uid ===
      this.props.user.current_user.name
    ) {
      this.setState({
        canEdit: true,
      });
    }
  };

  /**
   * Favourite a news item and update the db
   *
   * @param {The node id} _nid
   */
  favorite = (_nid) => {
    this.setState({
      favorite: "true",
    });

    this.props.favorite(_nid);
  };

  /**
   * Unfavourite a news item and update the db
   *
   * @param {*} _nid
   */
  unfavorite = (_nid) => {
    this.setState({
      favorite: "false",
    });

    this.props.unfavorite(_nid);
  };

  getPostAuthorData = _uid => {
    let path = 'custom_user?_format=json&name=' + _uid;

    getOnlineNode(path, this.props.user.access_token)
      .then(response => {
        if (response.data.rows.length > 0) {
          try {
            this.setState({
              isLoading: false,
              author_uid: response.data.rows[0].uid,
              author_image: response.data.rows[0].user_picture,
              author_username: response.data.rows[0].name,
              author_first_name: response.data.rows[0].field_first_name,
              author_last_name: response.data.rows[0].field_last_name,
            });
          } catch (error) {
            // console.log('getPostAuthorData: ', error);
          }
        }
      })
      .catch(_error => {
        this.setState({
          isLoading: false,
          author_image: null,
          author_username: null,
          author_first_name: null,
          author_last_name: null,
        });
      });
  };

  /**
   * Handle item hover state
   * @returns
   */
  handleItemHover = (hover) => {
    this.setState({ isHovered: hover });
  };

  renderAuthor = () => {
    let author;
    let picture;


    if (this.state.author_username) {
      author = this.state.author_username;
      picture = this.state.author_image;

      // first name
      if (this.state.author_first_name) {
        author = this.state.author_first_name;
      }

      // Concat first & last name
      if (
        this.state.author_first_name &&
        this.state.author_last_name
      ) {
        author =
          this.state.author_first_name +
          " " +
          this.state.author_last_name;
      }

      if (!author) {
        author = this.state.author_username;
      }
    }

    return (
      <Row noGutters className="align-items-center">
        <Col>
          <div className="author">
            <Link to={"/contacts/" + this.state.author_uid}>
              <UserImage className="author__image" image={picture} />
            </Link>
            <div className={"author__name"}>
              <span>
                posted by{" "}
                <Link to={"/contacts/" + this.state.author_uid}>
                  <strong>{author}</strong>
                </Link>
              </span>
            </div>
          </div>
        </Col>
        {this.state.canEdit && (
          <Col xs={'auto'} className="d-flex">
            <Link
              to={`/account-edit-notice/${this.props.item.nid}`}
              className="edit-notice-btn"
            >
              <Edit />
              <span>Edit</span>
            </Link>
          </Col>
        )}
        <Col xs={"auto"} className="d-flex  align-items-center">
          <div className="px-2">
            {this.state.favorite === "true" ? (
              <button
                className="favourite-btn filled"
                onClick={() => {
                  this.unfavorite(this.props.item.nid);
                }}
              >
                <Bookmark />
              </button>
            ) : (
              <button
                className="favourite-btn"
                onClick={() => {
                  this.favorite(this.props.item.nid);
                }}
              >
                <Bookmark />
              </button>
            )}
          </div>
        </Col>
      </Row>
    );
  };

  renderGroup = () => {
    let timestamp = this.props.item.created ? this.props.item.created : '';
    let timestampLabel;

    if (timestamp !== this.props.item.changed) {
      timestamp = this.props.item.changed ? this.props.item.changed : '';
      timestampLabel = 'Updated';
    }

    timestamp = moment.unix(timestamp);
  
    return (
      <>
        {this.props.item.groupLabel ? this.props.item.groupLabel : 'No group set'}
        <div className="timeago">
          <span className="timeago-label">{timestampLabel}</span>
          <TimeAgo date={timestamp} className="timeago-timestamp" />
        </div>
      </>
    );
  }

  /**
   * Render the notice title
   */
  renderNoticeTitle = () => {
    return (
      <>
        {this.props.item.title}
      </>
    );
  };

  renderNoticeItem = () => {
    const itemClass = "notice-item";
    const itemClassList = `${itemClass} item`;

    let itemStyle = itemClassList;
    let hoveredStyle = this.state.isHovered ? "hovered" : "";

    const noticeLink = `/account/notices/${this.props.item.nid}`;

    return (
      <Col
        key={"notice-" + this.props.item.nid}
        className="d-flex"
        xs={12}
        lg={6}
      >
        <div className={`${itemStyle} ${hoveredStyle}`}>
          <Row noGutters className="align-items-center mb-2">
            <Col xs={"auto"}>
              <div className={`${itemClass}__icon`}>
                <Users />
              </div>
            </Col>
            <Col>
              <div className={`${itemClass}__group-container`}>
                <h6 className="group">{this.renderGroup()}</h6>
              </div>
            </Col>
          </Row>
          <Row noGutters className="align-items-center">
            <Col>
              <Link
                to={noticeLink}
                className={`${itemClass}__content  item__content`}
                onMouseEnter={() => this.handleItemHover(true)}
                onMouseLeave={() => this.handleItemHover(false)}
              >
                <PostTitle
                  title={this.renderNoticeTitle()}
                  headingLevel={"h6"}
                />
                {/* {this.props.item.field_body && (
                  <PostExcerpt body={this.props.item.field_body} />
                )} */}
              </Link>
            </Col>
          </Row>
          <footer className={`${itemClass}__footer  item__footer`}>
            <Row>
              <Col>{this.renderAuthor()}</Col>
            </Row>
          </footer>
        </div>
      </Col>
    );
  };

  /**
   * @function render
   * @description Default render method
   */
   render() {
    if (this.state.isLoading) {
      return false;
    } else {
      return this.renderNoticeItem();
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default withRouter(connect(mapStateToProps)(NoticesItem));
