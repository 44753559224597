import React, { Component } from 'react';

// UI components
import Spinner from 'react-bootstrap/Spinner'

class LoadingSpinner extends Component {

  render() {
    let containerStyle = 'spinner-container';

    if (this.props.className && this.props.className !== undefined) {
      containerStyle = `${this.props.className} spinner-container`;
    }

    return (
      <div className={containerStyle}>
        <Spinner animation="border" role="status" variant="primary">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }

}

export default LoadingSpinner;