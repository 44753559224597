import React from "react";

// Modules
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// App
import PostTitle from "../../partials/postTitle";
import LoadingSpinner from "../../partials/loadingSpinner";

// Data
// import db from "../../core/db";

// UI components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Bookmark, Users, Tag } from "react-feather";

class CompanyDirectoryItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      favorite: "false",
    };
  }

  componentDidMount() {
    this.checkState();
  }

  checkState = () => {
    this.setState({
      favorite: this.props.item.favorite,
    });
  };

  /**
   * Favourite a form item and update the db
   *
   * @param {The node id} _nid
   */
  favorite = (_nid) => {
    this.setState({
      favorite: "true",
    });

    this.props.favorite(_nid);
  };

  /**
   * Unfavourite a form item and update the db
   *
   * @param {*} _nid
   */
  unfavorite = (_nid) => {
    this.setState({
      favorite: "false",
    });

    this.props.unfavorite(_nid);
  };

  /**
   * Handle item hover state
   * @returns
   */
  handleItemHover = (hover) => {
    this.setState({ isHovered: hover });
  };

  renderCompanyDirectoryItem = () => {
    const itemClass = "company-directory-item";
    const itemClassList = `${itemClass}  item`;
    const hasAbbreviation =
      this.props.item.abbreviation.length > 0 ? true : false;

    let category = "Uncategorised";
    if (this.props.item.categoryLabel) {
      category = this.props.item.categoryLabel;
    }

    let colour;
    if (this.props.item.abbreviationColour) {
      colour = this.props.item.abbreviationColour;
    }

    let itemStyle = itemClassList;
    let hoveredStyle = this.state.isHovered ? "hovered" : "";

    return (
      <Col
        key={"company-directory" + this.props.item.nid}
        className="d-flex gutter-lg"
        md={6}
      >
        <div className={`${itemStyle} ${hoveredStyle}`}>
          <Row noGutters className="h-100">
            <Col xs={"auto"} className="mb-2">
              <Link
                to={"/company-directory/" + this.props.item.nid}
                className={`${itemClass}__image  item__image`}
                style={{ backgroundColor: colour }}
                onMouseEnter={() => this.handleItemHover(true)}
                onMouseLeave={() => this.handleItemHover(false)}
              >
                {hasAbbreviation ? this.props.item.abbreviation : <Users />}
              </Link>
            </Col>
            <Col className="d-flex flex-column">
              <Link
                to={"/company-directory/" + this.props.item.nid}
                className={`${itemClass}__content  item__content`}
                onMouseEnter={() => this.handleItemHover(true)}
                onMouseLeave={() => this.handleItemHover(false)}
              >
                <PostTitle
                  title={this.props.item.title}
                  headingLevel={"h6"}
                />
              </Link>
              <footer className={`${itemClass}__footer  item__footer`}>
                <Row noGutters>
                  <Col>
                    <div className="category">
                      <Tag className="category__icon" />
                      <span className="category__text">{category}</span>
                    </div>
                  </Col>
                  <Col xs={"auto"} className="px-2">
                    {this.state.favorite === "true" ? (
                      <button
                        className="favourite-btn filled"
                        onClick={() => {
                          this.unfavorite(this.props.item.nid);
                        }}
                      >
                        <Bookmark />
                      </button>
                    ) : (
                      <button
                        className="favourite-btn"
                        onClick={() => {
                          this.favorite(this.props.item.nid);
                        }}
                      >
                        <Bookmark />
                      </button>
                    )}
                  </Col>
                </Row>
              </footer>
            </Col>
          </Row>
        </div>
      </Col>
    );
  };

  /**
   * @function render
   * @description Default render method
   */
  render() {
    if (this.state.isLoading) {
      return <LoadingSpinner />;
    } else {
      return this.renderCompanyDirectoryItem();
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default connect(mapStateToProps)(CompanyDirectoryItem);
