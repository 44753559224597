// Dependencies
import React from 'react';

// Modules
import {connect} from 'react-redux';
import TextareaAutosize from 'react-textarea-autosize';

// App
import {serverUrl} from '../config';
import {getOnlineNode} from '../core/getNode';
import {postNode} from '../core/postNode';
import {alertMessages} from '../partials/alertMessages';
import AlertModal from '../partials/alertModal';

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Send} from 'react-feather';

class CommentForm extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      isCommentPosted: false,
      subject: '',
      comment: '',
      modalVisible: false,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.getUserData();
  }

  getUserData = (_data) => {
    const user = this.props.user.current_user.name;
    let path = 'custom_user?_format=json&name=' + user;

    getOnlineNode(path, this.props.user.access_token)
      .then((response) => {
        if (response.data.rows.length > 0) {
          this.setState({
            user_username: response.data.rows[0].name,
            user_first_name: response.data.rows[0].field_first_name,
            user_last_name: response.data.rows[0].field_last_name,
          });
        }
      })
      .catch((_error) => {
        // error
      });

  };

  postComment = () => {
    if (this.state.comment.length < 1) {
      this.setState({
        modalVisible: true,
        alertType: 'error',
        alertTitle: alertMessages.commentEmpty.title,
        alertBody: alertMessages.commentEmpty.message,
        alertConfirmButton: true,
      });
      return false;
    }

    this.setState({
      isLoading: true,
      isCommentPosted: false,
    });

    const data = {
      _links: {
        type: {
          href: serverUrl + '/rest/type/comment/comment',
        },
      },
      entity_id: [
        {
          target_id: this.props.nid,
        },
      ],
      entity_type: [
        {
          value: 'node',
        },
      ],
      comment_type: [
        {
          target_id: 'comment',
        },
      ],
      field_name: [
        {
          value: 'field_comments',
        },
      ],
      subject: [
        {
          value: this.state.subject,
        },
      ],
      comment_body: [
        {
          value: this.state.comment,
        },
      ],
    };

    postNode(
      'comment',
      data,
      this.props.user.csrf_token,
      this.props.user.access_token,
    )
      .then((response) => {
        this.setState({
          isLoading: false,
          isCommentPosted: true,
          subject: '',
          comment: '',
          modalVisible: true,
          alertType: 'success',
          alertTitle: alertMessages.commentPosted.title,
          alertBody: alertMessages.commentPosted.message,
          alertConfirmButton: true,
        });

        if (this.props.onCommentPosted) {
          this.props.onCommentPosted();
        }
      })
      .catch((_error) => {
        this.setState({
          modalVisible: true,
          alertType: 'error',
          alertTitle: alertMessages.commentPostFailure.title,
          alertBody: alertMessages.commentPostFailure.message,
          alertConfirmButton: true,
          isLoading: false,
          isCommentPosted: false,
        });
      });
  };

  /**
   * Handle textarea input onChange
   * @param {*} event 
   */
  handleChange(event) {
    this.setState({comment: event.target.value});
  }

  /**
   * Set modal visibility
   * @param {*} visible 
   */
  setModalVisible = (visible) => {
    this.setState({
      modalVisible: visible,
    });
  };

  render() {
    // username
    let name = this.state.user_username;

    // first name
    if (this.state.user_first_name) {
      name = this.state.user_first_name;
    }

    // Concat first & last name
    if (this.state.user_first_name && this.state.user_last_name) {
      name = this.state.user_first_name + ' ' + this.state.user_last_name;
    }

    if (this.props.user.current_user) {
      return (
        <>
          <AlertModal 
            showAlert={this.state.modalVisible} 
            showAlertCallback={this.setModalVisible}
            alertType={this.state.alertType}
            alertMessageTitle={this.state.alertTitle}
            alertMessageBody={this.state.alertBody}
            cancelButton={this.state.alertCancelButton}
            confirmButton={this.state.alertConfirmButton}
          />
          <div className="comment-form">
            <TextareaAutosize 
              className="comment-form__input"
              onChange={this.handleChange}
              placeholder="Write a comment..."
              value={this.state.comment}
              minRows={4}
            />
            <div className="comment-form__footer">
              <Row className="align-items-center">
                <Col>
                  <p className="user mb-0">posting as <strong className="user__value">{name}</strong></p>
                </Col>
                <Col xs={'auto'}>
                  <button
                    className="submit"
                    type="submit"
                    onClick={this.postComment}>
                    <span className="mr-2">Submit</span>
                    <Send/>
                  </button>
                </Col>
              </Row>
            </div>
          </div>
        </>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default connect(mapStateToProps)(CommentForm);