import React from 'react';

// Modules
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import TimeAgo from 'react-timeago';
import moment from 'moment';

// App
import LoadingSpinner from '../../partials/loadingSpinner';
import PostTitle from "../../partials/postTitle";
import PostExcerpt from "../../partials/postExcerpt";
import UserImage from "../../partials/userImage";

// Data
// import db from '../../core/db';

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class MessageItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isHovered: false,
    };
  }

  /**
   * Handle item hover state
   * @returns
   */
  handleItemHover = (hover) => {
    this.setState({ isHovered: hover });
  };

  /**
   * Handle item click
   * @param {*} item 
   * @param {*} active 
   */
  handleClick = (item) => {
    this.props.onMessageOpen(item.user.uid);
  };

  /**
   * Conditional wrapper for MessageItem
   * If desktop, click event displays the thread alongside
   * If below desktop, add link to the thread on new page
   * @returns
   */
  renderMessageItemWrapper = () => {
    const itemClass = 'message-item';
    const itemClassList = `${itemClass}  item`;
    const itemUid = this.props.item.user.uid;
    let hoveredStyle = this.state.isHovered ? 'hovered' : '';

    if (this.props.isDesktopViewport) {
      return (
        <div
          key={"message-" + this.props.item.user.uid}
          className={
            this.props.activeItem === itemUid
              ? `${itemClassList} ${hoveredStyle} active`
              : `${itemClassList} ${hoveredStyle}`
          }
          onMouseEnter={() => this.handleItemHover(true)}
          onMouseLeave={() => this.handleItemHover(false)}
          onClick={() => this.handleClick(this.props.item)}
        >
          {this.renderMessageItemContent(itemClass)}
        </div>
      );
    } else {
      return (
        <Link
          key={"message-" + this.props.item.user.uid}
          to={'/account/messages/' + this.props.item.user.uid}
          className={`${itemClassList} ${hoveredStyle}`}
          onMouseEnter={() => this.handleItemHover(true)}
          onMouseLeave={() => this.handleItemHover(false)}
        >
          {this.renderMessageItemContent(itemClass)}
        </Link>
      );
    }
  };

  renderMessageItemContent = (itemClass) => {
    const timestamp = moment.unix(this.props.item.message.created);
    const userData = this.props.item.user;
    // username
    let user = userData.username;

    // first name
    if (userData.first_name) {
      user = userData.first_name;
    }

    // Concat first & last name
    if (userData.first_name && userData.last_name) {
      user = userData.first_name + " " + userData.last_name;
    }

    return (
      <Row noGutters className="">
        <Col xs={'auto'}>
          <div className={`${itemClass}__image-wrapper`}>
            <UserImage image={this.props.item.user.user_picture} />
            {parseInt(this.props.item.unread, 10) > 0 && (
              <span className={`${itemClass}__unread-counter`}>
                {this.props.item.unread}
              </span>
            )}
          </div>
        </Col>
        <Col className="ml-2">
          <div className={`${itemClass}__content  item__content`}>
            <Row noGutters className="align-items-center">
              <Col>
                <PostTitle
                  className={`${itemClass}__name`}
                  title={user}
                  headingLevel={"h6"}
                />
              </Col>
              <Col xs={'auto'}>
                <TimeAgo date={timestamp} className={`${itemClass}__timestamp`} />
              </Col>
            </Row>
            <PostExcerpt body={this.props.item.message.body} />
          </div>
        </Col>
      </Row>
    );
  };

  /**
   * @function render
   * @description Default render method
   */
  render() {
    if (this.state.isLoading) {
      return <LoadingSpinner />;
    } else {
      return this.renderMessageItemWrapper();
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default connect(mapStateToProps)(MessageItem);
