import React from 'react';

// Modules
import {connect} from 'react-redux';
import {withRouter} from 'react-router';

// App
import NoticesItem from './noticesItem';
import {getOnlineNode} from '../../core/getNode';
import {paginator} from '../../core/paginator';
import Pagination from '../../core/pagination';
import PageHeader from '../../partials/pageHeader';
import CategorySelector from '../../partials/categorySelector';
import {alertMessages} from '../../partials/alertMessages';
import Error from '../../partials/error';
import {_checkContent} from '../../core/checkContent';
import NoticesSkeletonScreen from './skeleton-screens/noticesSkeletonScreen';
import NoData from '../../partials/noData';

// Data
import db from './../../core/db';
import {getContentCount} from '../../core/getRecordCount';
import {updateContentCounterPreviousCount} from '../../core/updateData';
import {getFavorites, getFavouritesLength} from './../../core/getFavorites';

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Plus, Users } from "react-feather";

class Notices extends React.Component {
  constructor() {
    super();
    this.state = {
      isFirst: true,
      isLoading: true,
      isRefreshing: false,
      modalVisible: false,
      isError: false,
      errorStatus: '',
      errorMessage: '',

      pageSize: 20,
      index: 0,
      selectedGroup: 0,

      groups: '',
      data: [],
      favorites: [],
      archives: [],

      activeIndex: 0,
    };
  }

  /**
   * @function componentDidMount
   * @description Default react method. Fired when component is rendered
   */
  componentDidMount() {
    this.checkRole();
  }

  checkRole = () => {
    const {roles} = this.props.user.current_user;
    let isManager = false;

    if (roles) {
      roles.forEach(role => {
        if (role === 'manager' || role === 'administrator') {
          isManager = true;
        }
      });
    }

    this.setState(
      {
        isManager: isManager,
      },
      () => {
        this.getGroups();
      },
    );
  };

  /**
   * @function setData
   * @description Updates the state of the component upon data retrival
   * @param {object} _data
   * @param {object} _realm
   */
  setData = async (_data) => {
    this.setState(
      {
        isLoading: false,
        isRefreshing: false,
        isPaginating: false,
        data: _data.rows,
        totalItems: _data.pager.total_items,
      }
    );

    const serverCount = await getContentCount('group_notices')

    console.log('server count: ', serverCount);
    
    if (serverCount && serverCount[0]) {
      const count = serverCount[0] ? serverCount[0].serverCount : 0;
      updateContentCounterPreviousCount('group_notices', count)
    }

    _checkContent();
  };

  /**
   * @function setError
   * @description Updates the state of the component upon an error
   * @param {boolean} _isError
   * @param {int} _errorStatus
   * @param {string} _errorMessage
   */
  setError = (_isError, _errorStatus, _errorMessage) => {
    this.setState({
      isLoading: false,
      isRefreshing: false,
      isPaginating: false,
      isError: _isError,
      errorStatus: _errorStatus,
      errorMessage: _errorMessage,
    });
  };

  favorite = async (_nid) => {
    const {activeIndex} = this.state;

    let notices = await db.notices.get(_nid);
    notices.favorite = 'true';
    db.notices.put(notices, _nid);

    if (activeIndex === 0) {
      this.getContent();
    }

    if (activeIndex === 1) {
      this.getFavourites();
    }
  }

  unfavorite = async (_nid) => {
    const {activeIndex} = this.state;
  
    let notices = await db.notices.get(_nid);
    notices.favorite = 'false';
    db.notices.put(notices, _nid);

    if (activeIndex === 0) {
      this.getContent();
    }

    if (activeIndex === 1) {
      this.getFavourites();
    }

    if (activeIndex === 2) {
      this.getArchives();
    }
  }

  /**
   * @function dataHandler
   * @description Saves the retrived data to the realm object
   * @param {object} _data
   */
  dataHandler = async (_data) => {
    let noticesArray = [];

    for (const data of _data.rows) {
      let existingNotices = await db.notices.get(data.nid);
      let favorite = 'false';

      if (existingNotices) {
        favorite = existingNotices.favorite;
      }
      
      const notice = {
        nid: data.nid,
        title: data.title,
        field_body: data.field_body,
        field_notice_document: data.field_notice_document
          ? data.field_notice_document
          : null,
        groupID: data.groupID ? data.groupID : '0',
        groupLabel: data.groupLabel ? data.groupLabel : 'All Groups',
        created: data.created,
        uid: data.uid,
        favorite: favorite,
        sticky: data.sticky,
        field_feature: data.field_feature ? data.field_feature : 'false',
        field_allow_comments: data.field_allow_comments ? data.field_allow_comments : 'false',
        field_allow_reactions: data.field_allow_reactions ? data.field_allow_reactions : 'false',
        changed: data.changed,
      };

      db.notices.put(notice, data.nid);

      noticesArray.push(notice);
    }

    let data = _data;

    data.rows = noticesArray;

    this.setData(data);
  }

  /**
   * @function getContent
   * @description Retrieves the data from an API / Fallback to local realm object if there is no connection
   */
  getContent = () => {
    let path =
      'group_notices/all?status=1&promote=1&' +
      'items_per_page=' +
      this.state.pageSize +
      '&page=' +
      (this.state.currentPage - 1);

    const id = this.state.selectedGroup;

    if (id === 0) {
      let groupIDs = [];

      if (this.state.groups.length < 2) {
        let data = {
          rows: [],
          pager: {
            total_items: 0,
          }
        }
        this.dataHandler(data);

        return;
      }

      this.state.groups.forEach((group, index) => {
        if (group.id !== 0) {
          if (this.state.groups.length - 1 === index) {
            groupIDs += group.id;
          } else {
            groupIDs += group.id + '+';
          }
        }
      });

      path =
        'group_notices/' +
        groupIDs +
        '?status=1&promote=1&' +
        'items_per_page=' +
        this.state.pageSize +
        '&page=' +
        (this.state.currentPage - 1);
    } else {
      path =
        'group_notices/' +
        id +
        '?status=1&promote=1&' +
        'items_per_page=' +
        this.state.pageSize +
        '&page=' +
        (this.state.currentPage - 1);
    }

    getOnlineNode(path, this.props.user.access_token)
      .then(response => {
        this.dataHandler(response.data);
      })
      .catch(_error => {
        console.log('@getContent: ', _error);
        if (_error.response) {
          this.setError(
            true,
            _error.response.status,
            _error.response.statusText,
          );
        } else if (_error.request) {
          this.setError(true, 0, alertMessages.requestError.message);
        } else {
          this.setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };

  /**
   * @function getArchives
   * @description Retrives archives items
   */
  getArchives = () => {
    if (this.state.isManager) {
      let groupIDs = [];
      this.state.groups.forEach((group, index) => {
        if (group.id !== 0) {
          if (this.state.groups.length - 1 === index) {
            groupIDs += group.id;
          } else {
            groupIDs += group.id + '+';
          }
        }
      });

      const path =
        'group_notice_archives/' +
        groupIDs +
        '/' +
        this.props.user.current_user.uid +
        '?status=1&promote=1&' +
        'items_per_page=' +
        this.state.pageSize +
        '&page=' +
        (this.state.currentPage - 1);

      getOnlineNode(path, this.props.user.access_token)
        .then(response => {
          this.setData(response.data);
        })
        .catch(_error => {
          // error
          console.log('@getArchives: ', _error);
        });
    } else {
      if (this.state.isManager) {
        this.setState({
          isLoading: false,
          isRefreshing: false,
          isPaginating: false,
          data: [],
          totalItems: 0,
        });
      }
    }
  };

  /**
   * @function getFavourites
   * @description Retrives favorited items from the Realm object
   */
  getFavourites = async () => {
    const favorites = await getFavorites('notices', this.state.selectedGroup, this.state.index, this.state.pageSize);
    const favoritesLength = await getFavouritesLength('notices', this.state.selectedGroup);

    this.setState({
      data: favorites,
      totalItems: favoritesLength,
      isLoading: false,
      isRefreshing: false,
      isPaginating: false,
    });
  };

  /**
   * @function getGroups
   * @description Retrives the groups data from an API / Fallback to local realm object if there is no connection
   */
  getGroups = () => {
    let path = 'groups/list';

      getOnlineNode(path, this.props.user.access_token)
        .then(response => {
          this.renderGroups(response.data);
        })
        .catch(_error => {
          if (_error.response) {
            this.setError(
              true,
              _error.response.status,
              _error.response.statusText,
            );
          } else if (_error.request) {
            this.setError(true, 0, alertMessages.requestError.message);
          } else {
            this.setError(true, 0, alertMessages.unkownError.message);
          }
        });
  };

  /**
   * @function onPaginate
   * @description Pagination callback
   * @param {int} _index - Page number
   */
   onPaginate = (_index) => {
     const {activeIndex} = this.state;
    this.setState(
      {
        isLoading: true,
        isPaginating: true,
        currentPage: _index,
      },
      function() {
        if (activeIndex === 0) {
          this.getContent();
        }
    
        if (activeIndex === 1) {
          this.getFavourites();
        }
    
        if (activeIndex === 2) {
          this.getArchives();
        }
      },
    );
  };

  /**
   * @function renderGroups
   * @description Updates the state with the retrived groups
   * @param {object} _categories
   */
  renderGroups = (_data) => {
    let groups = [
      {
        id: 0,
        label: 'All Groups',
      },
    ];

    _data.forEach(item => {
      groups.push({
        id: item.id,
        label: item.label,
      });
    });

    this.setState(
      {
        groups: groups,
      },
      () => {
        this.getContent();
      },
    );
  };

  /**
   * @function handleGroupSelection
   * @description Modal Picker callback / Updates the header title and sets the state to the selected group
   * @param {object} event
   */
  handleGroupSelection = event => {
    const {activeIndex} = this.state;
    this.setState(
      {
        // experimental
        isLoading: true,
        isPaginating: true,
        isError: false,
        currentPage: 1,
        selectedGroup: parseInt(event.value, 10),
        selectedGroupItem: event,
      },
      function () {
        if (activeIndex === 0) {
          this.getContent();
        }

        if (activeIndex === 1) {
          this.getFavourites()
        }

        if (activeIndex === 2) {
          this.getArchives();
        }
      },
    );
  };

  // Pagination component
  renderPagination = () => {
    if (this.state.totalItems <= this.state.pageSize) {
      return null;
    } else {
      let pagination = paginator(
        this.state.totalItems,
        this.state.currentPage,
        this.state.pageSize,
        3,
      );

      return (
        <Pagination
          currentPage={pagination.currentPage}
          endIndex={pagination.endIndex}
          endPage={pagination.endPage}
          pageSize={pagination.pageSize}
          pages={pagination.pages}
          startIndex={pagination.startIndex}
          startPage={pagination.startPage}
          totalItems={pagination.totalItems}
          totalPages={pagination.totalPages}
          paginationCallback={this.onPaginate}
        />
      );
    }
  };

  // Data component
  dataComponent = () => {
    const {data} = this.state;

    if (typeof data !== 'undefined' && data.length > 0) {
      return (
        <Row>
          {data.map(item => {
            return (
              <NoticesItem
                key={'notice-' + item.nid}
                item={item}
                nid={item.nid}
                uid={item.uid}
                favorite={this.favorite}
                unfavorite={this.unfavorite}
              />
            );
          })}
        </Row>
      );
    } else {
      return (
        <NoData message1={alertMessages.noNotice} activeIndex={this.state.activeIndex} />
      );
    }
  };

  /**
   * @function render
   * @description Default render method
   */
   render() {
    if (this.state.isLoading) {
      return <NoticesSkeletonScreen />;
    } else {
      if (this.state.isError) {
        return (
          <Error 
            status={this.state.errorStatus}
            message={this.state.errorMessage}
          />
        );
      } else {
        return (
          <main className="notices screen">
            {this.state.isManager ? (
              <>
                <PageHeader 
                  pageName="Notices" 
                  filters={true}
                  categorySelector={
                    <CategorySelector 
                      name="notices"
                      categories={this.state.groups}
                      selectedCategory={this.state.selectedGroupItem}
                      handleCategorySelection={this.handleGroupSelection}
                    />
                  }
                  getContent={() => {
                    this.setState(
                      {
                        activeIndex: 0,
                        isLoading: true,
                      },
                      function () {
                        this.getContent();
                      },
                    );
                  }}
                  getFavourites={() => {
                    this.setState(
                      {
                        activeIndex: 1,
                        isLoading: true,
                      },
                      function () {
                        this.getFavourites();
                      },
                    );
                  }}
                  getArchives={() => {
                    this.setState(
                      {
                        activeIndex: 2,
                        isLoading: true,
                      },
                      function () {
                        this.getArchives();
                      },
                    );
                  }}
                  activeTabIndex={this.state.activeIndex}
                />
                <div className="notices-actions">
                  <Row className="justify-content-end">
                    <Col className="col-auto">
                      <Button onClick={() => {
                        this.props.history.push('/account/groups');
                      }}>
                        <Users />
                      </Button>
                    </Col>
                    <Col className="col-auto">
                      <Button onClick={() => {
                        this.props.history.push('/account/add-notice');
                      }}>
                        <Plus />
                      </Button>
                    </Col>
                  </Row>
                </div>
              </>
            ) : (
              <PageHeader 
                pageName="Notices" 
                filters={true}
                categorySelector={
                  <CategorySelector 
                    name="notices"
                    categories={this.state.groups}
                    selectedCategory={this.state.selectedGroupItem}
                    handleCategorySelection={this.handleGroupSelection}
                  />
                }
                getContent={() => {
                  this.setState(
                    {
                      activeIndex: 0,
                      isLoading: true,
                    },
                    function () {
                      this.getContent();
                    },
                  );
                }}
                getFavourites={() => {
                  this.setState(
                    {
                      activeIndex: 1,
                      isLoading: true,
                    },
                    function () {
                      this.getFavourites();
                    },
                  );
                }}
                activeTabIndex={this.state.activeIndex}
              />
            )}
            {this.dataComponent()}
            {this.renderPagination()}
          </main>
        );
      }
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default withRouter(connect(mapStateToProps)(Notices));