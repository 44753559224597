import React, { Component } from 'react';

// Modules
import ContentLoader from 'react-content-loader';

// App
import SkeletonPageHeader from '../../partials/skeleton-screens/skeletonPageHeader';

// UI components
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";

class CompanyDirectorySkeletonScreen extends Component {  
  constructor() {
    super();
    this.state = {
      backgroundColor: '#f3f3f3',
      foregroundColor: '#ecebeb',
    };
  }

  renderDefaultItems = (itemClassList) => {

    let items = [];
    let numberOfItems;

    if (this.props.numberOfItems) {
      numberOfItems = this.props.numberOfItems;
    } else {
      numberOfItems = 20;
    }

    for (let i = 0; i < numberOfItems; i++) {

      items.push(
        <Col xs={12} md={6} key={'item-' + i}>
          <div className={itemClassList} style={{paddingBottom: 0, border: 'none'}}>
            <ContentLoader 
              speed={2}
              width={'100%'}
              height={'100%'}
              viewBox="0 0 546 77"
              backgroundColor={this.state.backgroundColor}
              foregroundColor={this.state.foregroundColor}
            >
              <circle cx="525.1" cy="51.085" r="10" />
              <rect x="105.6" y="41.085" width="136.796" height="20" rx="10" ry="10" />
              <circle cx="85.568" cy="51.085" r="10" />
              <rect x="75.568" y="5.19" width="250.118" height="20" rx="10" ry="10" />
              <circle cx="30.292" cy="30.154" r="30.154" />
            </ContentLoader>
          </div>
        </Col>
      );

    }

    return items;
  };

  render() {
    const itemClass = "company-directory-item";
    const itemClassList = `${itemClass}  item`;

    return (
      <main className="company-directory  screen">
        <SkeletonPageHeader />

        <Row>
          {this.renderDefaultItems(itemClassList)}
        </Row>
      </main>
    );
  }

}

export default CompanyDirectorySkeletonScreen;