import React, { Component } from 'react';

// Modules
import ContentLoader from 'react-content-loader';

class MessagesSkeletonScreen extends Component {  
  constructor() {
    super();
    this.state = {
      backgroundColor: '#f3f3f3',
      foregroundColor: '#ecebeb',
    };
  }

  renderDefaultItems = (itemClassList) => {

    let items = [];
    let numberOfItems;

    if (this.props.numberOfItems) {
      numberOfItems = this.props.numberOfItems;
    } else {
      numberOfItems = 10;
    }

    for (let i = 0; i < numberOfItems; i++) {

      items.push(
        <div key={'item-' + i} className={itemClassList} style={{paddingBottom: 0, border: 'none'}}>
          <ContentLoader 
            speed={2}
            width={'100%'}
            height={'100%'}
            viewBox="0 0 1140 96"
            backgroundColor={this.state.backgroundColor}
            foregroundColor={this.state.foregroundColor}
          >
            <rect x="79.917" y="43.16" width="289.482" height="16" rx="10" ry="10" />
            <rect x="292.979" y="16.537" width="76.42" height="16" rx="10" ry="10" />
            <rect x="79.917" y="66.126" width="167.546" height="16" rx="10" ry="10" />
            <rect x="79.917" y="14.537" width="124.395" height="20" rx="10" ry="10" />
            <circle cx="39.965" cy="40.169" r="28.957" />
          </ContentLoader>
        </div>
      );

    }

    return items;
  };

  render() {
    const itemClass = "message-item ";
    const itemClassList = `${itemClass}  item`;

    return (
      <main className="messages  screen">

        {/* Title */}
        <div className="page-header">
          <ContentLoader 
            speed={2}
            width={'100%'}
            height={'100%'}
            viewBox="0 0 1140 50"
            backgroundColor={this.state.backgroundColor}
            foregroundColor={this.state.foregroundColor}
          >
            <rect x="0" y="0" width="236.444" height="50" rx="10" ry="10"/>
          </ContentLoader>
        </div>

        {/* Messages */}
        {this.renderDefaultItems(itemClassList)}
      </main>
    );
  }

}

export default MessagesSkeletonScreen;