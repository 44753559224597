import {api} from '../config';

export const postFile = async (
  _file,
  _uri,
  _xcsrf_token,
  _accessToken,
) => {
  let formData = new FormData();
	formData.append('File', _file);

  let headers = {
    headers: {
      Accept: 'application/hal+json',
      'Content-Type': 'application/octet-stream',
      'Content-Disposition': 'file; filename="' + _file.name + '"',
      'X-CSRF-Token': _xcsrf_token,
      Authorization: 'Bearer ' + _accessToken,
    },
  };

  if (!_accessToken) {
    headers = {
      Accept: 'application/hal+json',
      'Content-Type': 'application/octet-stream',
      'Content-Disposition': 'file; filename="' + _file.name + '"',
      'X-CSRF-Token': _xcsrf_token,
    };
  }

  return await fetch(_uri, {
    method: 'POST',
    headers: headers,
    body: formData,
    credentials: 'include'
  });
};

export const postBase64 = async (
  _data,
  _uri,
  _xcsrf_token,
  _accessToken,
) => {
  let headers = {
    headers: {
      Accept: 'application/hal+json',
      'Content-Type': 'application/hal+json',
      'X-CSRF-Token': _xcsrf_token,
      Authorization: 'Bearer ' + _accessToken,
    },
  };

  if (!_accessToken) {
    headers = {
      Accept: 'application/hal+json',
      'Content-Type': 'application/hal+json',
      'X-CSRF-Token': _xcsrf_token,
    };
  }

  return api({
    method: 'post',
    url: _uri,
    data: JSON.stringify(_data),
    headers: headers,
  });
};