import React from "react";

// Modules
import Select from "react-select";
import { decode } from "html-entities";

class CategorySelector extends React.Component {
  handleCategorySelection = (event) => {
    this.props.handleCategorySelection(event);
  };

  /**
   * Decode category label
   * @param {*} label
   * @returns
   */
  decodeLabel = (label) => {
    let item = label;

    try {
      item = decode(item);
    } catch (error) {
      console.log(error, "Cannot decode categories");
    }

    return item;
  };

  render() {
    // Decode categories
    let decodedCategories = [];
    if (
      this.props.categories !== "undefined" &&
      this.props.categories !== undefined
    ) {
      
      this.props.categories.map((item) => {
        let valueParam = item.value;
        let labelParam = item.label;

        if (this.props.name === 'notices') {
          valueParam = item.id;
        }

        if (this.props.name === 'addnoticeboard') {
          valueParam = item.tid;
          labelParam = item.title;
        }

        return decodedCategories.push({
          value: valueParam,
          label: this.decodeLabel(labelParam),
        });
      });
    }

    const colours = {
      primaryColour: "#685e9d",
      primaryColourAlpha: "rgba(104, 94, 157, 0.2)",
      lightFontColour: "#999",
      darkFontColour: "#333",
    };

    const customStyles = {
      control: (provided) => ({
        ...provided,
        cursor: "pointer",
      }),

      dropdownIndicator: (provided, state) => ({
        ...provided,
        color: state.isFocused
          ? colours.primaryColour
          : colours.lightFontColour,
        "&:hover": {
          color: colours.primaryColour,
        },
      }),

      menu: (provided) => ({
        ...provided,
        overflow: "hidden",
      }),

      menuList: (provided) => ({
        ...provided,
        padding: 0,
      }),

      option: (provided, state) => ({
        ...provided,
        cursor: "pointer",
        color:
          state.isFocused && !state.isSelected
            ? colours.primaryColour
            : state.isSelected
            ? "#fff"
            : colours.darkFontColour,
      }),

      // Label in control
      // singleValue: (provided, state) => ({
      //   ...provided,
      //   fontSize: state.selectProps.myFontSize,
      // }),

      // Styles for form select else defaults
      // valueContainer: (provided) => ({
      //   ...provided,
      //   padding: this.props.name === "notices" ? "0.375rem 0.75rem" : "2px 8px",
      // }),

      // // Styles for form select else defaults
      // input: (provided) => ({
      //   ...provided,
      //   padding: this.props.name === "notices" ? 0 : '2px 0',
      //   margin: this.props.name === "notices" ? 0 : '2px',
      // }),
    };

    return (
      <Select
        name={`${this.props.name}-category`}
        className="category-selector"
        defaultValue={decodedCategories[0]}
        options={decodedCategories}
        value={this.props.selectedCategory}
        onChange={(event) => {
          this.handleCategorySelection(event);
        }}
        styles={customStyles}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: colours.primaryColourAlpha,
            primary: colours.primaryColour,
          },
        })}
        maxMenuHeight={400}
        // menuIsOpen={true} // for testing
      />
    );
  }
}

export default CategorySelector;
