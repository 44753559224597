import React, { Component } from 'react';

// Modules
import ContentLoader from 'react-content-loader';

class ForumsSkeletonTopicCommentsScreen extends Component {  
  constructor() {
    super();
    this.state = {
      backgroundColor: '#f3f3f3',
      foregroundColor: '#ecebeb',
    };
  }

  renderDefaultItems = (itemClassList) => {

    let items = [];
    let numberOfItems;

    if (this.props.numberOfItems) {
      numberOfItems = this.props.numberOfItems;
    } else {
      numberOfItems = 10;
    }

    for (let i = 0; i < numberOfItems; i++) {

      items.push(
        <div key={'item-' + i} className={itemClassList} style={{paddingBottom: 0, border: 'none'}}>
          <ContentLoader 
            speed={2}
            width={'100%'}
            height={'100%'}
            viewBox="0 0 1140 260"
            backgroundColor={this.state.backgroundColor}
            foregroundColor={this.state.foregroundColor}
          >
            <circle cx="1111.88" cy="31.249" r="10" />
            <rect x="249.065" y="172.058" width="505.101" height="20" rx="10" ry="10" />
            <rect x="249.065" y="138.453" width="831.023" height="20" rx="10" ry="10" />
            <rect x="249.065" y="104.848" width="781.132" height="20" rx="10" ry="10" />
            <rect x="249.065" y="71.243" width="864.809" height="20" rx="10" ry="10" />
            <circle cx="1072.32" cy="31.249" r="10" />
            <rect x="58.568" y="217.728" width="110.336" height="20" rx="10" ry="10" />
            <rect x="249.065" y="21.249" width="119.368" height="20" rx="10" ry="10" />
            <rect x="249.065" y="217.728" width="44.437" height="20" rx="10" ry="10" />
            <rect x="58.568" y="109.558" width="110.336" height="20" rx="10" ry="10" />
            <circle cx="114.191" cy="61.249" r="40" />
          </ContentLoader>
        </div>
      );

    }

    return items;
  };

  render() {
    const itemClass = "topic-comment";
    const itemClassList = `${itemClass}  item`;

    return (
      <main className="forum-topic-comments  screen">

        {/* Board */}
        <div className="comments-header__board">
          <ContentLoader 
            speed={2}
            width={'100%'}
            height={'100%'}
            viewBox="0 0 1140 20"
            backgroundColor={this.state.backgroundColor}
            foregroundColor={this.state.foregroundColor}
          >
            <rect x="35.58" y="0" width="227.087" height="20" rx="10" ry="10" />
            <rect x="0" y="0" width="21.953" height="20" rx="5" ry="5" />
          </ContentLoader>
        </div>

        {/* Title */}
        <div className="page-header">
          <ContentLoader 
            speed={2}
            width={'100%'}
            height={'100%'}
            viewBox="0 0 1140 50"
            backgroundColor={this.state.backgroundColor}
            foregroundColor={this.state.foregroundColor}
          >
            <rect x="0" y="0" width="956.822" height="50" rx="10" ry="10" />
          </ContentLoader>
        </div>

        {/* Author */}
        <div className="mb-5">
          <ContentLoader 
            speed={2}
            width={'100%'}
            height={'100%'}
            viewBox="0 0 1140 30"
            backgroundColor={this.state.backgroundColor}
            foregroundColor={this.state.foregroundColor}
          >
            <rect x="40.681" y="5" width="86.118" height="20" rx="10" ry="10" />
            <rect x="159.792" y="5" width="255.981" height="20" rx="10" ry="10" />
            <circle cx="15" cy="15" r="15" />
          </ContentLoader>
        </div>

        {/* Comments */}
        {this.renderDefaultItems(itemClassList)}
      </main>
    );
  }

}

export default ForumsSkeletonTopicCommentsScreen;