import React from "react";

// Modules
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// App
import PostTitle from "../../partials/postTitle";
import LoadingSpinner from "../../partials/loadingSpinner";

// Data
// import db from "../../core/db";

// UI components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Bookmark, Play, Tag } from "react-feather";

class PodcastsItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      favorite: "false",
    };
  }

  componentDidMount() {
    this.checkState();
  }

  checkState = () => {
    this.setState({
      favorite: this.props.item.favorite,
    });
  };

  /**
   * Favourite a podcast item and update the db
   *
   * @param {The node id} _nid
   */
  favorite = (_nid) => {
    this.setState({
      favorite: "true",
    });

    this.props.favorite(_nid);
  };

  /**
   * Unfavourite a podcast item and update the db
   *
   * @param {*} _nid
   */
  unfavorite = (_nid) => {
    this.setState({
      favorite: "false",
    });

    this.props.unfavorite(_nid);
  };

  /**
   * Handle item hover state
   * @returns
   */
  handleItemHover = (hover) => {
    this.setState({ isHovered: hover });
  };

  renderPodcastsItem = () => {
    const itemClass = "podcasts-item";
    const itemClassList = `${itemClass}  item`;

    let category = "Uncategorised";
    if (this.props.item.categoryLabel) {
      category = this.props.item.categoryLabel;
    }

    let hasImage = this.props.item.field_featured_image.length > 0 ? true : false;
    hasImage = this.props.item.field_featured_image === "null" ? false : true;

    let itemStyle = hasImage ? itemClassList : `no-image ${itemClassList}`;
    let hoveredStyle = this.state.isHovered ? "hovered" : "";

    return (
      <Col
        key={"podcasts" + this.props.item.nid}
        className="d-flex"
        lg={4}
        xl={3}
      >
        <div className={`${itemStyle} ${hoveredStyle}`}>
          <Row noGutters>
            <Col xs={6} sm={6} lg={12}>
              <Link
                to={"/podcasts/" + this.props.item.nid}
                className={`${itemClass}__image  item__image`}
                onMouseEnter={() => this.handleItemHover(true)}
                onMouseLeave={() => this.handleItemHover(false)}
              >
                <img
                  src={this.props.item.field_featured_image}
                  alt={"img-" + this.props.item.nid}
                />
              </Link>
            </Col>

            <Col className="mt-lg-2">
              <Link
                to={"/podcasts/" + this.props.item.nid}
                className={`${itemClass}__content  item__content`}
                onMouseEnter={() => this.handleItemHover(true)}
                onMouseLeave={() => this.handleItemHover(false)}
              >
                <Row noGutters>
                  <Col xs={12} sm={"auto"} className="pr-sm-2 mb-2 mb-sm-0">
                    <div className="play-icon">
                      <Play />
                    </div>
                  </Col>
                  <Col>
                    <PostTitle
                      title={this.props.item.title}
                      headingLevel={"h6"}
                    />
                  </Col>
                </Row>
              </Link>
            </Col>
          </Row>
          <Row noGutters className="mt-auto">
            <Col>
              <footer className={`${itemClass}__footer  item__footer`}>
                <Row noGutters className="align-items-center">
                  <Col className="category">
                    <Tag className="category__icon" />
                    <span className="category__text">{category}</span>
                  </Col>
                  <Col xs={"auto"} className="ml-auto pr-2">
                    {this.state.favorite === "true" ? (
                      <button
                        className="favourite-btn filled"
                        onClick={() => {
                          this.unfavorite(this.props.item.nid);
                        }}
                      >
                        <Bookmark />
                      </button>
                    ) : (
                      <button
                        className="favourite-btn"
                        onClick={() => {
                          this.favorite(this.props.item.nid);
                        }}
                      >
                        <Bookmark />
                      </button>
                    )}
                  </Col>
                </Row>
              </footer>
            </Col>
          </Row>
        </div>
      </Col>
    );
  };

  /**
   * @function render
   * @description Default render method
   */
  render() {
    if (this.state.isLoading) {
      return <LoadingSpinner />;
    } else {
      return this.renderPodcastsItem();
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default connect(mapStateToProps)(PodcastsItem);
