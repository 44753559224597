import React from 'react';

// Modules
import {connect} from 'react-redux';

// App
import {serverUrl} from '../../config';
import {getOnlineNode} from '../../core/getNode';
import {alertMessages} from '../../partials/alertMessages';
import Error from '../../partials/error';
import {_checkContent} from '../../core/checkContent';
import SkeletonItemScreen from "../../partials/skeleton-screens/skeletonItemScreen";
import NoData from '../../partials/noData';
import PostTitle from '../../partials/postTitle';
import PostBody from '../../partials/postBody';

// Data
import db from './../../core/db';

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { ArrowRight } from 'react-feather';

class Submissions extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      isRefreshing: false,
      isError: false,

      errorStatus: '',
      errorMessage: '',

      currentPage: 1,
      pageSize: 20,
      index: 0,
      totalItems: 0,

      selectedCategory: 0,
      categories: [],
      token: '',

      data: [],
      favorites: [],

      activeIndex: 0,
    };
  }

  /**
   * @function componentDidMount
   * @description Default react method. Fired when component is rendered
   */
  componentDidMount() {
    this.checkSession();
  }
  
  checkSession = () => {
    if (Object.keys(this.props.user).length === 0) {
      this.props.history.push('/');
    } else {
      this.getContent();
    }
  }

  /**
   * @function setData
   * @description Updates the state of the component upon data retrival
   * @param {object} _data
   * @param {object} _realm
   */
  setData = async (_data) => {
    this.setState(
      {
        isLoading: false,
        isRefreshing: false,
        data: _data,
      }
    );
    _checkContent();
  };

  /**
   * @function setError
   * @description Updates the state of the component upon an error
   * @param {boolean} _isError
   * @param {int} _errorStatus
   * @param {string} _errorMessage
   */
  setError = (_isError, _errorStatus, _errorMessage) => {
    this.setState({
      isLoading: false,
      isRefreshing: false,
      isError: _isError,
      errorStatus: _errorStatus,
      errorMessage: _errorMessage,
    });
  };

  dataHandler = async (_data) => {
    let submissions = [];

    const submission = {
      nid: _data.nid,
      title: _data.title,
      body: _data.body,
      field_image: _data.field_image.length > 0 ? _data.field_image : 'null',
      webform: _data.webform,
    };

    db.submission.put(submission, _data.nid);

    submissions.push(submission);

    let data = _data;

    data.rows = submissions;

    this.setData(data);
  }

  /**
   * @function getContent
   * @description Retrieves the data from an API / Fallback to local realm object if there is no connection
   */
  getContent = () => {
    let path = 'submissions';

    getOnlineNode(path, this.props.user.access_token)
      .then((response) => {
        this.dataHandler(response.data.rows[0]);
      })
      .catch((_error) => {
        console.log('error: ', _error);
        if (_error.response) {
          //console.log('@rest response: ', _error.response);
          this.setError(
            true,
            _error.response.status,
            _error.response.statusText,
          );
        } else if (_error.request) {
          //console.log('@rest request: ', _error.request);
          this.setError(true, 0, alertMessages.requestError.message);
        } else {
          //console.log('@rest unknown: ', _error);
          this.setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };

  renderPostImage = (post) => {
    let hasImage = post.field_image.length > 0 ? true : false;

    if (hasImage) {
      return (
        <div className="submissions__image">
          <img src={post.field_image} alt={'img-' + post.nid} />
        </div>
      );
    }
  };

  // Data component
  dataComponent = () => {
    const {data} = this.state;

    if (typeof data !== 'undefined' && data) {

      let url = serverUrl + '/node/' + data.webform;

      if (this.props.user.access_token) {
        url =
          url + '?access_token=' + this.props.user.access_token;
      }

      return (
        <article
          className="article"
          style={{ maxWidth: "600px", margin: "0 auto", textAlign: "center" }}
        >
          {this.renderPostImage(data)}
          <PostTitle
            title={data.title}
            headingLevel={"h3"}
            className="submissions__title"
          />
          <div className="submissions__body">
            <PostBody body={data.body} />
          </div>
          <Row className="align-items-center justify-content-center mt-4">
            <Col xs={"auto"}>
              {this.state.isLoading ? (
                <Spinner animation="border" role="status" variant="primary">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              ) : (
                <Button
                  href={url}
                  className="submit-btn"
                  rel="noreferrer"
                  target="_blank"
                >
                  <span>Get started</span>
                  <ArrowRight />
                </Button>
              )}
            </Col>
          </Row>
        </article>
      );
    } else {
      return (
        <NoData activeIndex={this.state.activeIndex} />
      );
    }
  };

  /**
   * @function render
   * @description Default render method
   */
  render() {
    if (this.state.isLoading) {
      return <SkeletonItemScreen />
    } else {
      if (this.state.isError) {
        return (
          <Error 
            status={this.state.errorStatus}
            message={this.state.errorMessage}
          />
        );
      } else {
        return (
          <main className="submissions  screen">
            {this.dataComponent()}
          </main>
        );
      }
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default connect(mapStateToProps)(Submissions);
