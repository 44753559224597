// Dependencies
import React from 'react';

// Modules
import {withRouter} from 'react-router'
import {connect} from 'react-redux';
import moment from 'moment';

// App
import {getOnlineNode} from '../../core/getNode';
import PostTitle from '../../partials/postTitle';
import PostBody from '../../partials/postBody'
import CommentForm from '../../partials/commentForm';
import {alertMessages} from '../../partials/alertMessages';
import Error from '../../partials/error';
import Comment from '../../partials/comment';
import SkeletonSingleScreen from '../../partials/skeleton-screens/skeletonSingleScreen';

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import {MessageSquare, Clock, Users, File} from 'react-feather';
import { serverUrl } from '../../config';

class NoticesSingle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isPaginating: false,
      post: null,

      // Comments
      isCommentsLoading: true,
      comments: [],
      pageSize: 10,
      currentPage: 0,
      total_pages: 0,
    };
  }

  componentDidMount() {
    this.loadNode();
  }

  /**
   * @function setData
   * @description Updates the state of the component upon data retrival
   * @param {object} _data
   * @param {object} _realm
   */
  setData = (_data) => {
    this.setState(
      {
        isLoading: false,
        isRefreshing: false,
        isError: false,
        post: _data,
      },
      function () {
        this.getComments();
      },
    );
  };

  /**
   * @function setError
   * @description Updates the state of the component upon an error
   * @param {boolean} _isError
   * @param {int} _errorStatus
   * @param {string} _errorMessage
   */
  setError = (_isError, _errorStatus, _errorMessage) => {
    this.setState({
      isLoading: false,
      isRefreshing: false,
      isError: _isError,
      errorStatus: _errorStatus,
      errorMessage: _errorMessage,
    });
  };

  loadNode = () => {
    const nid = this.props.match.params.nid;

    if (nid) {
      this.getContent(nid);
    } else {
      this.setError(true, 0, alertMessages.contentError);
    }
  };

  /**
   * @function getContent
   * @description Retrieves the data from an API / Fallback to local realm object if there is no connection
   */
  getContent = (nid) => {
    let path = 'group_notices/all/' + nid + '?_format=json&status=1&promote=1';

    getOnlineNode(path, this.props.user.access_token)
      .then((response) => {
        if (response.data.rows.length > 0) {
          this.setData(response.data.rows[0]);
        } else {
          this.setError(true, 200, alertMessages.noData);
        }
      })
      .catch((_error) => {
        if (_error.response) {
          this.setError(
            true,
            _error.response.status,
            _error.response.statusText,
          );
        } else if (_error.request) {
          this.setError(true, 0, alertMessages.requestError.message);
        } else {
          this.setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };

  getComments = (_scrollToBottom) => {
    const {post, comments, currentPage, total_pages} = this.state;

    if (currentPage > total_pages) {
      return;
    }

    let path =
      'comments/' +
      post.nid +
      '?items_per_page=' +
      this.state.pageSize +
      '&page=' +
      this.state.currentPage;

    getOnlineNode(path, this.props.user.access_token)
      .then((response) => {
        this.setState(
          {
            isPaginating: false,
            comments:
              currentPage === 0
                ? response.data.rows
                : [...comments, ...response.data.rows],
            isCommentsLoading: false,
            total_pages: response.data.pager.total_pages - 1,
          },
          function () {
            // if (_scrollToBottom) {
            //   this.setState({
            //     scrollToBottom: true,
            //   });
            // }
          },
        );
      })
      .catch((_error) => {
        // failed to get comments
        this.setState({
          isCommentsLoading: false,
        });
      });
  };

  onCommentPosted = () => {
    this.getComments();
  }

  renderLoadMore = () => {
    if (this.state.currentPage < this.state.total_pages) {
      return (
        <div className="d-flex justify-content-center">
          <Button onClick={() => {
            if (!this.state.isPaginating) {
              this.loadMore();
            }
          }}>
            {'Load more'}
          </Button>
        </div>
      );
    } else {
      return null;
    }
  };

  renderComments = () => {
    const {post, comments} = this.state;

    if (post.field_allow_comments === 'false') {
      return false;
    }

    return (
      <section className="comments">
        {comments.length > 0 ? (
          <>
            <div className="comments__header  d-flex align-items-center">
              <MessageSquare size={35} />
              <h3 className="mb-0">Comments</h3>
            </div>
            {this.props.user.current_user && (
              <CommentForm nid={post.nid} onCommentPosted={this.onCommentPosted} />
            )}
            <div className="comments__content">
              <div className="comments__header  d-flex align-items-center">
                <Clock size={25} />
                <h5 className="mb-0">Most Recent</h5>
              </div>
              {comments.map((_comment, __index) => {
                return (
                  <Comment
                    comment={_comment}
                    key={'comment-' + __index}
                    getComments={this.getComments}
                  />
                )
              })}
            </div>

          </>
        ) : (
          <>
            <div className="comments__header  d-flex align-items-center">
              <MessageSquare/>
              <h3 className="mb-0">Comments</h3>
            </div>
            <div className="comments__header  d-flex align-items-center">
              <Clock/>
              <h5 className="mb-0">
                {this.props.user.current_user ? (
                  'Be the first to comment'
                ) : (
                  'Login to comment'
                )}
              </h5>
            </div>
            {this.props.user.current_user && (
              <CommentForm nid={post.nid} onCommentPosted={this.onCommentPosted} />
            )}
          </>
        )}
        {this.renderLoadMore()}
      </section>
    )
  }

  renderPDFLink = () => {
    const {post} = this.state;
    let hasDocument = post.field_notice_document
      ? post.field_notice_document
      : null;

    if (hasDocument !== null) {
      hasDocument = hasDocument.length > 0 ? true : false;
    }

    if (hasDocument) {
      const documents = post.field_notice_document.split(', ');

      return (
        <div className='documents'>
          {documents.map((document, index) => {

            const name = document.split('/').pop();
            const path = serverUrl + document;

            return (
              <a key={`doc-${index}`} href={path} target='_blank' rel='noreferrer' className="document  d-block">
                <Row noGutters>
                  <Col className="col-auto">
                    <File />
                  </Col>
                  <Col className="d-flex">
                    <span className="file-name">{decodeURIComponent(name)}</span>
                  </Col>
                </Row>
              </a>
            );
          })}
        </div>
      );
    }
  }

  render() {
    if (this.state.isLoading) {
      return <SkeletonSingleScreen />;
    } else {
      if (this.state.isError) {
        return (
          <Error 
            status={this.state.errorStatus}
            message={this.state.errorMessage}
          />
        );
      } else {
        const {post} = this.state;
        const timestamp = moment.unix(post.created).format("Do MMM YYYY");

        let group = post.groupLabel;

        return (
          <main className="notices single">
            <article className="article">
              <header className="article__header">
                <PostTitle title={post.title} headingLevel={'h1'} />
                <div className="article__meta">
                  <Row>
                    <Col xs={"auto"}>
                      <div className="timestamp align-items-center">
                        <Clock className="timestamp__icon" />
                        <span className="timestamp__label  label">{timestamp}</span> 
                      </div>

                    </Col>
                    <Col xs={"auto"}>
                      <div className="category align-items-center">
                        <Users className="category__icon" />
                        <span className="category__label  label">{group}</span>
                      </div>
                    </Col>
                  </Row>
                </div>
              </header>
              <div className="article__body">
                <PostBody body={post.field_body} type={'raw'} />
                <div className="form-group">
                  <div className="documents-container">
                    {this.renderPDFLink()}
                  </div>
                </div>
              </div>
              {this.renderComments()}
            </article>
          </main>
        );
      }
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default withRouter(connect(mapStateToProps)(NoticesSingle));
