import React, { Component } from 'react';

// Modules
import ContentLoader from 'react-content-loader';

// App
import SkeletonPageHeader from '../../partials/skeleton-screens/skeletonPageHeader';

// UI components
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";

class LinksSkeletonScreen extends Component {  
  constructor() {
    super();
    this.state = {
      backgroundColor: '#f3f3f3',
      foregroundColor: '#ecebeb',
    };
  }

  renderDefaultItems = (itemClassList) => {

    let items = [];
    let numberOfItems;

    if (this.props.numberOfItems) {
      numberOfItems = this.props.numberOfItems;
    } else {
      numberOfItems = 20;
    }

    for (let i = 0; i < numberOfItems; i++) {

      items.push(
        <Col sm={12} lg={6} key={'item-' + i}>
          <div className={itemClassList}>
            <ContentLoader 
              speed={2}
              width={'100%'}
              height={'100%'}
              viewBox="0 0 549 110"
              backgroundColor={this.state.backgroundColor}
              foregroundColor={this.state.foregroundColor}
            >
              <circle cx="528.5" cy="88.948" r="10" />
              <rect x="30.067" y="78.948" width="247.234" height="20" rx="10" ry="10" />
              <circle cx="10" cy="88.948" r="10" />
              <rect x="30.067" y="34.594" width="247.234" height="20" rx="10" ry="10" />
              <rect x="0" y="34.594" width="20" height="20" rx="5" ry="5" />
              <rect x="0" y="4" width="436.8" height="20" rx="10" ry="10" />
            </ContentLoader>
          </div>
        </Col>
      );

    }

    return items;
  };

  render() {
    const itemClass = "link-item";
    const itemClassList = `${itemClass}  item`;

    return (
      <main className="links  screen">
        <SkeletonPageHeader />

        <Row>
          {this.renderDefaultItems(itemClassList)}
        </Row>
      </main>
    );
  }

}

export default LinksSkeletonScreen;