import React from "react";

// Modules
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";

// App
import store from "../redux/store";
import { getOnlineNode } from "../core/getNode";
import Navigation from "./navbar";
import { auth_logout, get_logout_token, get_xcsrf_token } from "../core/auth";
import { alertMessages } from "../partials/alertMessages";
import AlertModal from "../partials/alertModal";

// UI components
import UserImage from "./userImage";
import {
  Search,
  ChevronDown,
  User,
  Menu,
  X,
  MessageCircle,
  LogOut,
  Send,
} from "react-feather";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logoReverse from "../assets/ca-logo-reverse-stroke.svg";
import { Container } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isAccountLoading: true,
      isAccountError: false,
      accountErrorStatus: "",
      accountErrorMessage: "",

      user: "",
      search: "",

      modalVisible: false,
      activeItem: 0,
    };

    // This binding is necessary to make `this` work in the callback
    this.submitSearchForm = this.submitSearchForm.bind(this);
  }

  /**
   * @function componentDidMount
   * @description Default react method. Fired when component is rendered
   */
  componentDidMount() {
    this.getAccount();
    this.props.headerCallback(this.state.showNavigation);

    this.checkViewport();
    window.addEventListener("resize", this.checkViewport);

    this.unsubscribe = store.subscribe(() => {
      this.getAccount();
    });
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.checkViewport);
    this.unsubscribe();
  };

  setAccount = (_data) => {
    this.setState({
      isAccountLoading: false,
      isRefreshing: false,
      user: _data,
    });
  };

  getAccount = () => {
    if (typeof this.props.user.current_user === "undefined") {
      //
      this.setState({
        isAccountLoading: false,
        isRefreshing: false,
      });
    } else {
      getOnlineNode(
        "custom_user/" + this.props.user.current_user.uid,
        this.props.user.access_token
      )
        .then((response) => {
          this.setAccount(response.data.rows[0]);
        })
        .catch((_error) => {});
    }
  };

  getTokens = () => {
    if (this.props.user.current_user) {
      get_logout_token().then((get_logout_token_response) => {
        get_xcsrf_token().then((get_xcsrf_token_response) => {
          this.setState(
            {
              logout_token: get_logout_token_response.data.logout_token,
              csrf_token: get_xcsrf_token_response.data,
            },
            function () {
              this.logout();
            }
          );
        })
        .catch((_error) => {
          if (_error.response) {
            if (_error.response.status === 403) {
              this.setState({
                user: '',
              });
              this.props.removeUserSession();
            } else {
              this.setState({
                modalVisible: true,
                alertType: "error",
                alertTitle: alertMessages.unkownError.title,
                alertBody: _error.response.data,
                alertConfirmButton: true,
              });
            }
          } else if (_error.request) {
            this.setState({
              modalVisible: true,
              alertType: "error",
              alertTitle: alertMessages.serverNoResponse.title,
              alertBody: alertMessages.serverNoResponse.message,
              alertConfirmButton: true,
            });
          } else {
            this.setState({
              modalVisible: true,
              alertType: "error",
              alertTitle: alertMessages.unkownError.title,
              alertBody: alertMessages.unkownError.message,
              alertConfirmButton: true,
            });
          }
        });
      })
      .catch((_error) => {
        if (_error.response) {
          if (_error.response.status === 403) {
            this.setState({
              user: '',
            });
            this.props.removeUserSession();
          } else {
            this.setState({
              modalVisible: true,
              alertType: "error",
              alertTitle: alertMessages.unkownError.title,
              alertBody: _error.response.data,
              alertConfirmButton: true,
            });
          }
        } else if (_error.request) {
          this.setState({
            modalVisible: true,
            alertType: "error",
            alertTitle: alertMessages.serverNoResponse.title,
            alertBody: alertMessages.serverNoResponse.message,
            alertConfirmButton: true,
          });
        } else {
          this.setState({
            modalVisible: true,
            alertType: "error",
            alertTitle: alertMessages.unkownError.title,
            alertBody: alertMessages.unkownError.message,
            alertConfirmButton: true,
          });
        }
      });
    }
  };

  logout = () => {
    const { logout_token, csrf_token } = this.state;

    if (!logout_token && !csrf_token) {
      this.setState({
        modalVisible: true,
        alertType: "error",
        alertTitle: alertMessages.noTokens.title,
        alertBody: alertMessages.noTokens.message,
        alertConfirmButton: true,
      });

      this.getTokens();

      return false;
    }

    auth_logout(logout_token, csrf_token)
      .then((_response) => {
        this.setState({
          user: '',
        });
        //if (_response.status === 204)
        this.props.removeUserSession();
        this.props.history.push("/");
      })
      .catch((_error) => {
        console.log("logout error: ", _error);
        if (_error.response) {
          if (_error.response.status === 403) {
            this.setState({
              user: '',
            });
            this.props.removeUserSession();
          } else {
            this.setState({
              modalVisible: true,
              alertType: "error",
              alertTitle: alertMessages.unkownError.title,
              alertBody: _error.response.data,
              alertConfirmButton: true,
            });
          }
        } else if (_error.request) {
          this.setState({
            modalVisible: true,
            alertType: "error",
            alertTitle: alertMessages.serverNoResponse.title,
            alertBody: alertMessages.serverNoResponse.message,
            alertConfirmButton: true,
          });
        } else {
          this.setState({
            modalVisible: true,
            alertType: "error",
            alertTitle: alertMessages.unkownError.title,
            alertBody: alertMessages.unkownError.message,
            alertConfirmButton: true,
          });
        }
      });
  };

  toggleActiveItem = (eventKey) => {
    this.setState({
      activeItem: eventKey,
    });

    // if (
    //   this.props.location.pathname === "/account" &&
    //   this.props.location.pathname !== prevProps.location.pathname
    // ) {
    //   this.setState({
    //     activeItem: eventKey,
    //   });
    // }
  };

  // componentDidUpdate = (prevProps) => {
  //   console.log("(1) @componentDidUpdate", this.props.location.pathname);
  //   console.log("(2) @componentDidUpdate activeItem", this.state.activeItem);

  //   this.toggleActiveItem(this.props.location.pathname, prevProps);
  // };

  renderUser = () => {
    if (this.props.user.current_user) {
      const firstName = this.state.user.field_first_name;
      const lastName = this.state.user.field_last_name;
      const userPicture = this.state.user.user_picture;
      let username;

      if (firstName && lastName) {
        username = firstName + " " + lastName;
      } else {
        username = this.state.user.name;
      }

      let content_counter = 0;
      if (this.props.content_counter.messages > 0) {
        content_counter = this.props.content_counter.messages;
      }

      if (this.props.content_counter.group_notices > 0) {
        content_counter += this.props.content_counter.group_notices;
      }

      if (this.props.user.current_user) {
        return (
          <Dropdown onToggle={() => {
            const location = this.props.location.pathname;

            switch(location) {
              case '/account':
                this.toggleActiveItem(1);
                break;
              case '/account/messages':
                this.toggleActiveItem(2);
                break;
              case '/account/notices':
                this.toggleActiveItem(3);
                break;
              default:
                this.toggleActiveItem(0);
            }

            if (location.indexOf('/account/messages') > -1) {
              this.toggleActiveItem(2);
            }
          }}>
            <Dropdown.Toggle id="dropdown-toggle" className="user">
              <UserImage className="user__image" image={userPicture} content_counter={content_counter} />
              <span className="user__name  d-none d-sm-block">{username}</span>
              <ChevronDown className="user__toggle  d-none d-sm-block" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                eventKey="1"
                className={this.state.activeItem === 1 ? "active" : ""}
                onClick={() => {
                  this.props.history.push('/account')
                }}
              >
                <div className="dropdown-item__icon">
                  <User />
                </div>
                <span className="dropdown-item__label">Account</span>
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="2"
                className={this.state.activeItem === 2 ? "active" : ""}
                onClick={() => {
                  this.props.history.push('/account/messages')
                }}
              >
                <div className="dropdown-item__icon">
                  <MessageCircle />
                </div>
                <span className="dropdown-item__label">Messages</span>
                {!!this.props.content_counter.messages && (
                  <span className="dropdown-item__indicator">
                    {this.props.content_counter.messages}
                  </span>
                )}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="2"
                className={this.state.activeItem === 3 ? "active" : ""}
                onClick={() => {
                  this.props.history.push('/account/notices')
                }}
              >
                <div className="dropdown-item__icon">
                  <Send />
                </div>
                <span className="dropdown-item__label">Notices</span>
                {!!this.props.content_counter.group_notices && (
                  <span className="dropdown-item__indicator">
                    {this.props.content_counter.group_notices}
                  </span>
                )}
              </Dropdown.Item>
              {/* <Dropdown.Item
                eventKey="3"
                className={this.state.activeItem === 4 ? "active" : ""}
              >
                <div className="dropdown-item__icon">
                  <Clock />
                </div>
                <span className="dropdown-item__label">Timesheets</span>
              </Dropdown.Item> */}
              <Dropdown.Divider />
              <Dropdown.Item eventKey="4" onClick={() => {
                this.getTokens();
              }}>
                <div className="dropdown-item__icon">
                  <LogOut />
                </div>
                <span className="dropdown-item__label">Log out</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );
      } else {
        return (
          <Link to={"/login"} className="login-button">
            <User size={20} />
            <span>Login</span>
          </Link>
        );
      }
    } else {
      return (
        <Link to={"/login"} className="login-button">
          <User size={20} />
          <span>Login</span>
        </Link>
      );
    }
  };

  setNavVisibility = (visible) => {
    this.setState(
      {
        showNavigation: visible,
      },
      () => {
        this.props.headerCallback(this.state.showNavigation);
      }
    );
  };

  checkViewport = () => {
    const desktopQuery = window.matchMedia("(min-width: 992px)");

    if (desktopQuery.matches) {
      // Issue: Will always show nav on resize (even if user collapsed nav)
      this.setNavVisibility(true);
      this.props.headerCallback(true);
    } else {
      if (this.state.showNavigation === true || this.state.showNavigation === 'true') {
        // Issue: Will alwats show nav on resize to mobile
        this.setNavVisibility(true);
        this.props.headerCallback(true);
      } else {
        this.setNavVisibility(false);
        this.props.headerCallback(false);
      }
      
    }
  };

  submitSearchForm = async (event) => {
    if (this.state.search.length < 1) {
      event.preventDefault();
    } else {
      event.preventDefault();
      this.props.history.push('/search?q=' + this.state.search); 
      // await this.props.saveSearchTerm(this.state.search)
      //this.props.history.push('/search'); 
    }
  };

  clearSearchForm = async () => {
    this.setState({search: ''});
    //await this.props.saveSearchTerm('')

    // Only redirect to search page when the form is cleared if on search page
    if (this.props.location === '/search') {
      this.props.history.push('/search'); 
    } else {
      return false;
    }
  };

  /**
   * Set modal visibility
   * @param {*} visible
   */
  setModalVisible = (visible) => {
    this.setState({
      modalVisible: visible,
    });
  };

  render() {
    return (
      <>
        <header className="header">
          <Container fluid className="py-2">
            <Row className="align-items-center  justify-content-between">
              <Col xs={"auto"}>
                <button
                  onClick={() => {
                    this.setNavVisibility(!this.state.showNavigation)
                  }}
                  className="navbar-btn"
                >
                  <Menu />
                </button>
              </Col>
              <Col xs={"auto"}>
                <Link to="/" className="branding">
                  <img src={logoReverse} alt={"companyapp logo"} />
                </Link>
              </Col>
              <Col xs={"auto"} className="flex-sm-grow-1">
                <Row className="justify-content-end  align-items-center">
                  <Col xs={"auto"} md={5} lg={4} className="d-none d-md-block">
                    {/* <div className="search">
                      <input type="text" placeholder="Search" onChange={(event) => {
                        this.setState({
                          search: event.target.value,
                        });
                      }} />
                      
                      <Link to={'/search?q=' + this.state.search} onClick={ (event) => {
                        if (this.state.search.length < 1) {
                          event.preventDefault();
                        }
                      }}>
                        <Search />
                      </Link>

                    </div> */}

                    <form className="search" onSubmit={this.submitSearchForm}>
                      <div className="flex-grow-1">
                        <input
                          type="text"
                          placeholder="Search"
                          value={this.state.search}
                          onChange={(event) => {
                            this.setState({
                              search: event.target.value,
                            });
                          }}
                        />
                      </div>
                      {this.state.search.length > 0 && (
                        <button
                          type="button"
                          onClick={() => this.clearSearchForm()}
                        >
                          <X />
                        </button>
                      )}
                      <button type="submit">
                        <Search />
                      </button>
                    </form>
                  </Col>
                  <Col xs={"auto"}>{this.renderUser()}</Col>
                </Row>
              </Col>
            </Row>
          </Container>
          <Navigation
            showNavigation={this.state.showNavigation}
            currentUser={this.props.user.current_user}
            handleNavCallback={() =>
              this.setNavVisibility(!this.state.showNavigation)
            }
          />
        </header>

        {/* Alert error modal */}
        <AlertModal
          showAlert={this.state.modalVisible}
          showAlertCallback={this.setModalVisible}
          alertType={this.state.alertType}
          alertMessageTitle={this.state.alertTitle}
          alertMessageBody={this.state.alertBody}
          confirmButton={this.state.alertConfirmButton}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
  content_counter: state.authReducer.content_counter,
  search: state.searchReducer.search,
});

const mapDispatchToProps = (dispatch) => ({
  removeUserSession: () => dispatch({type: 'REMOVE_SESSION'}),
  saveSearchTerm: (data) => dispatch({type: 'SAVE_TERM', payload: data}),
  removeSearchTerm: () => dispatch({type: 'REMOVE_TERM'})
});

// export default connect(mapStateToProps, mapDispatchToProps)(Header);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
