import React from 'react';

// Modules
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import { decode } from "html-entities";

// App
import {serverUrl} from '../../config';
import {getOnlineNode} from '../../core/getNode';
import LoadingSpinner from '../../partials/loadingSpinner';
import Pagination from '../../core/pagination';
import {paginator} from '../../core/paginator';
import {alertMessages} from '../../partials/alertMessages';
import Error from '../../partials/error';
import CategorySelector from '../../partials/categorySelector';

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AlertCircle, BookOpen, Calendar, Camera, ChevronRight, Edit2, File, FileText, Info, Link2, Mic, PieChart, UserCheck, Users, Video } from 'react-feather';

class Search extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      isSearching: false,
      title: 'Search',
      data: null,
      isConnected: true,
      isError: false,
      errorStatus: '',
      errorMessage: '',
      pageSize: 10,
      currentPage: 1,
      totalItems: 0,
      searchQuery: '',
      selectedContentType: {value: 'all', label: 'All'},
      selectedContentTypeId: 'all',
      contentTypes: [
        {
          value: 'all',
          label: 'All',
        },
        {
          value: 'company_directory',
          label: 'Company Directory',
        },
        {
          value: 'course',
          label: 'Training Courses',
        },
        {
          value: 'documents',
          label: 'Document Library',
        },
        {
          value: 'events',
          label: 'Upcoming Events',
        },
        {
          value: 'external_resource',
          label: 'Useful Links',
        },
        {
          value: 'group_messages',
          label: 'Group Messages',
        },
        {
          value: 'image_gallery',
          label: 'Image Galleries',
        },
        {
          value: 'news',
          label: 'Latest News',
        },
        {
          value: 'noticeboard',
          label: 'Noticeboard',
        },
        {
          value: 'quiz',
          label: 'Tests & Quizzes',
        },
        {
          value: 'podcasts',
          label: 'Podcasts',
        },
        {
          value: 'poll',
          label: 'Opinion Polls',
        },
        {
          value: 'videos',
          label: 'Video Library',
        },
        {
          value: 'webform',
          label: 'Surveys & Forms'
        }
      ],
    };
  }

  /**
   * @function componentDidMount
   * @description Default react method. Fired when component is rendered
   */
  componentDidMount() {
    this.getQuery();
  }

  componentDidUpdate(prevProps) {
    // will be true
    const termChanged =
      this.props.location.search !== prevProps.location.search;

    if (termChanged) {
      this.getQuery();
    }
  }

  getQuery = () => {
    const search = this.props.location.search;
    const query = search.split('q=');

    if (query && query[1]) {
      this.search(query[1], 1);
    } else {
      this.setState({
        data: null,
      })
    }
  }

  /**
   * @function setData
   * @description Updates the state of the component upon data retrival
   * @param {object} _data
   * @param {object} _realm
   */
  setData = (_data) => {
    // let title = 'Search';

    // if (this.state.searchQuery.length > 0) {
    //   title = 'Search: ' + this.state.searchQuery;
    // }

    this.setState({
      // title: title,
      isLoading: false,
      isError: false,
      data: _data,
      totalItems: _data.pager.total_items,
      isSearching: false,
      isPaginating: false,
    });
  };

  /**
   * @function setError
   * @description Updates the state of the component upon an error
   * @param {boolean} _isError
   * @param {int} _errorStatus
   * @param {string} _errorMessage
   */
  setError = (_isError, _errorStatus, _errorMessage) => {
    this.setState({
      isLoading: false,
      isSearching: false,
      isError: _isError,
      isPaginating: false,
      errorStatus: _errorStatus,
      errorMessage: _errorMessage,
    });
  };

  /**
   * @function handleContentTypeSelection
   * @description Modal Picker callback / Updates the header title and sets the state to the selected category
   * @param {string} _id - Category ID
   */
   handleContentTypeSelection = (event) => {
    this.setState(
      {
        isLoading: true,
        currentPage: 1,
        selectedContentType: event,
        selectedContentTypeId: event.value,
      },
      function () {
        this.getQuery();
      },
    );
  };

  search = (_searchQuery, _currentPage) => {
    let isSearching = _searchQuery.length > 0 ? true : false;

    this.setState(
      {
        currentPage: _currentPage,
        searchQuery: _searchQuery,
        isSearching: isSearching,
        data: {
          rows: [],
          pager: {
            total_items: 0,
          },
        },
      },
      function () {
        this.searchOnline();
      },
    );
  };

  searchOnline = () => {
    const {searchQuery, selectedContentTypeId} = this.state;

    let path =
      'search?_format=json&title=' +
      searchQuery +
      '&items_per_page=' +
      this.state.pageSize +
      '&page=' +
      (this.state.currentPage - 1);

    if (selectedContentTypeId !== 'all' && selectedContentTypeId !== 'poll') {
      path =
        'search/' +
        selectedContentTypeId +
        '?_format=json&title=' +
        searchQuery +
        '&items_per_page=' +
        this.state.pageSize +
        '&page=' +
        (this.state.currentPage - 1);
    }

    if (selectedContentTypeId === 'poll') {
      this.searchPolls();
    } else {
      getOnlineNode(path, this.props.user.access_token)
        .then((response) => {
          if (response.data.rows.length > 0) {
            let data = response.data;
            let new_data = [];

            data.rows.forEach(item => {
              let new_item = item;

              if (new_item.type === 'company_directory') {
                new_item.content_type = 'Company Directory';
                new_item.icon = <Users />;
              }

              if (new_item.type === 'course') {
                new_item.content_type = 'Training Courses';
                new_item.icon = <UserCheck />;
              }

              if (new_item.type === 'documents') {
                new_item.content_type = 'Document Library';
                new_item.icon = <File />;
              }

              if (new_item.type === 'events') {
                new_item.content_type = 'Upcoming Events';
                new_item.icon = <Calendar />;
              }

              if (new_item.type === 'external_resource') {
                new_item.content_type = 'Useful Links';
                new_item.icon = <Link2 />;
              }

              if (new_item.type === 'image_gallery') {
                new_item.content_type = 'Image Galleries';
                new_item.icon = <Camera />;
              }

              if (new_item.type === 'news') {
                new_item.content_type = 'Latest News';
                new_item.icon = <FileText />;
              }

              if (new_item.type === 'noticeboard') {
                new_item.content_type = 'Noticeboard';
                new_item.icon = <Info />;
              }

              if (new_item.type === 'podcasts') {
                new_item.content_type = 'Podcasts';
                new_item.icon = <Mic />;
              }

              if (new_item.type === 'quiz') {
                new_item.content_type = 'Tests & Quizzes';
                new_item.icon = <BookOpen />;
              }

              if (new_item.type === 'videos') {
                new_item.content_type = 'Video Library';
                new_item.icon = <Video />;
              }

              if (new_item.type === 'webform') {
                new_item.content_type = 'Surveys & Forms';
                new_item.icon = <Edit2 />;
              }

              if (new_item.type === 'poll') {
                new_item.content_type = 'Opinion Polls';
                new_item.icon = <PieChart />;
              }

              new_data.push(new_item);
            });

            data.rows = new_data;

            this.setState({
              data: response.data,
            });
          }

          this.searchPolls();
        })
        .catch((_error) => {
          console.log('error: ', _error);
          if (_error.response) {
            //console.log('@rest response: ', _error.response);
            this.setError(
              true,
              _error.response.status,
              _error.response.statusText,
            );
          } else if (_error.request) {
            //console.log('@rest request: ', _error.request);
            this.setError(true, 0, alertMessages.requestError.message);
          } else {
            //console.log('@rest unknown: ', _error);
            this.setError(true, 0, alertMessages.unkownError.message);
          }
        });
    }

    
  };

  searchPolls = () => {
    const {searchQuery, selectedContentTypeId} = this.state;
    let olddata = this.state.data;

    let path = 'polls?_format=json&title=' + searchQuery;

    if (selectedContentTypeId !== 'all' && selectedContentTypeId !== 'poll') {
      this.finaliseSearch(olddata);
    } else {
      getOnlineNode(path, this.props.user.access_token)
        .then((response) => {
          if (response.data.rows.length > 0) {
            olddata.rows = olddata.rows.concat(response.data.rows);

            if (olddata.rows.length > 0) {
              let new_data = [];

              olddata.rows.forEach(item => {
                let new_item = item;

                if (new_item.type === 'poll') {
                  new_item.content_type = 'Opinion Polls';
                  new_item.icon = <PieChart />;
                }

                new_data.push(new_item);
              });

              this.finaliseSearch(olddata);
            } else {
              this.finaliseSearch(olddata);
            }
          } else {
            this.finaliseSearch(olddata);
          }
        })
        .catch((_error) => {
          this.finaliseSearch(olddata);
        });
    }
  };

  finaliseSearch = (_data) => {
    if (_data.rows.length > 0) {
      this.setData(_data);
    } else {
      const data = {
        rows: [],
        pager: {
          total_items: 0,
        },
      };
      this.setData(data);
    }
  };

  /**
   * @function onPaginate
   * @description Pagination callback
   * @param {int} _index - Page number
   */
  onPaginate = (_currentPage) => {
    this.setState(
      {
        isPaginating: true,
        currentPage: _currentPage,
      },
      function () {
        this.search(this.state.searchQuery, _currentPage);
      },
    );
  };

  linkGenerator = (_type, _nid) => {
    if (!_nid) {
      return;
    }

    let path= '';

    switch (_type) {
      case 'company_directory':
        path += '/company-directory/' + _nid;
        break;

      case 'course':
        path += '/courses/' + _nid;
        break;

      case 'documents':
        path += '/documents/' + _nid;
        break;

      case 'events':
        path += '/events/' + _nid;
        break;

      case 'external_resource':
        path += '/links';
        break;

      case 'image_gallery':
        path += '/galleries/' + _nid;
        break;

      case 'news':
        path += '/news/' + _nid;
        break;

      case 'noticeboard':
        path += '/noticeboard/' + _nid;
        break;

      case 'podcasts':
        path += '/podcasts/' + _nid;
        break;

      case 'quiz':
        path += '/quizzes/' + _nid;
        break;

      case 'videos':
        path += '/videos/' + _nid;
        break;

      case 'webform':
        path += '/forms';
        break;

      case 'poll':
        path += '/polls';
        break;

      default:
        path = '/';
    }

    return path;
  };

  // Pagination component
  renderPagination = () => {
    if (this.state.totalItems <= this.state.pageSize) {
      return null;
    } else {
      let pagination = paginator(
        this.state.totalItems,
        this.state.currentPage,
        this.state.pageSize,
        3,
      );

      return (
        <Pagination
          currentPage={pagination.currentPage}
          endIndex={pagination.endIndex}
          endPage={pagination.endPage}
          pageSize={pagination.pageSize}
          pages={pagination.pages}
          startIndex={pagination.startIndex}
          startPage={pagination.startPage}
          totalItems={pagination.totalItems}
          totalPages={pagination.totalPages}
          paginationCallback={this.onPaginate}
        />
      );
    }
  };

  dataComponent = () => {
    if (
      this.state.data &&
      this.state.data.rows &&
      this.state.searchQuery.length > 0
    ) {
      return (
        <div className='search-content'>
          {/* {this.state.data && this.state.data.rows && this.state.searchQuery.length > 0 ? ( */}
          {this.state.data && this.state.data.rows.length > 0 && this.state.searchQuery.length > 0 ? (
            <>
              {/* <Row>
                <Col>
                  <h6 className='search-content__title'>Results</h6>
                </Col>
              </Row> */}
              {this.state.data.rows.map((item, index) => {
                let link;

                if (item.type === 'external_resource') {
                  link = item.field_url;
                } else if (item.type === 'webform') {
                  link = serverUrl + '/node/' + item.nid;

                  if (this.props.user.access_token) {
                    link = link + '?access_token=' + this.props.user.access_token;
                  }
                } else if (item.type === 'poll') {
                  link = serverUrl + '/poll/' + item.nid;

                  if (this.props.user.access_token) {
                    link = link + '?access_token=' + this.props.user.access_token;
                  }
                } else {
                  link = this.linkGenerator(item.type, item.nid);
                }

                let title = item.title;

                try {
                  title = decode(title);
                } catch (error) {
                  console.log('cannot decode:', title);
                }

                return (
                  <Row key={'res-' + index}>
                    <Col>
                      {item.type === 'webform' || item.type === 'external_resource' || item.type === 'poll' || item.type === 'documents' ? (
                        <a href={link} rel={'noreferrer'} target={'_blank'} className='result-item'>
                          <Row noGutters className='result-item__content-type  align-items-center'>
                            <Col xs={'auto'}>
                              {item.icon}
                            </Col>
                            <Col>
                              <p>{item.content_type}</p>
                            </Col>
                          </Row>
                          <Row className='result-item__title  align-items-center'>
                            <Col>
                              <p>{title}</p> 
                            </Col>
                            <Col xs={'auto'}>
                              <ChevronRight />
                            </Col>
                          </Row>
                        </a>
                      ) : (
                        <Link to={link} className='result-item'>
                          <Row noGutters className='result-item__content-type  align-items-center'>
                            <Col xs={'auto'}>
                              {item.icon}
                            </Col>
                            <Col>
                              <p>{item.content_type}</p>
                            </Col>
                          </Row>
                          <Row className='result-item__title  align-items-center'>
                            <Col>
                              <p>{title}</p> 
                            </Col>
                            <Col xs={'auto'}>
                              <ChevronRight />
                            </Col>
                          </Row>
                        </Link>
                      )}
                    </Col>
                  </Row>
                );
              })}
              {this.renderPagination()}
            </>
          ) : (
            <>
              {this.state.isSearching ? (
                <>
                </>
              ) : (
                <Row noGutters>
                  <Col>
                    <div className='alert alert-secondary  d-flex align-items-center'>
                      <AlertCircle />
                      <p className='ml-2 mb-0'>Nothing found matching your search...</p>
                    </div>
                  </Col>
                </Row>
              )}
            </>
          )}
        </div>
      );
    } else {
      if (this.state.isError) {
        return (
          <Error 
            status={this.state.accountErrorStatus}
            message={this.state.accountErrorMessage}
          />
        );
      }
    }
  }

  /**
   * @function render
   * @description Default render method
   */
   render() {
    if (this.state.isLoading) {
      return <LoadingSpinner />;
    } else {
      if (this.state.isError) {
        return (
          <Error 
            status={this.state.errorStatus}
            message={this.state.errorMessage}
          />
        );
      } else {

        const hasSearchQuery =
          this.state.data &&
          this.state.data.rows &&
          this.state.searchQuery.length > 0
            ? true
            : false;

        return (
          <main ref={this.containerRef} className="search screen">
            <div className="page-header">
              <Row className="align-items-center">
                <Col>
                  <h1 className="mb-0">
                    {hasSearchQuery ? (
                      <>
                        <span>Search: </span>
                        {this.state.searchQuery}
                      </>
                    ): (
                      <span>Search</span>
                    )}
                  </h1>
                </Col>
                <Col md={4} xl={3} >
                  <div className="search-category-selector  mt-3 mt-md-0">
                    <CategorySelector 
                      name='search'
                      categories={this.state.contentTypes}
                      selectedCategory={this.state.selectedContentType}
                      handleCategorySelection={this.handleContentTypeSelection}
                    />
                  </div>
                </Col>
              </Row>
            </div>

            {this.dataComponent()}
          </main>
        )
      }
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
  search: state.searchReducer.search,
});

export default connect(mapStateToProps)(Search);