import React, { Component } from 'react';

// Modules
import ContentLoader from 'react-content-loader';

// UI components
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";

class NoticesGroupsSkeletonScreen extends Component {  
  constructor() {
    super();
    this.state = {
      backgroundColor: '#f3f3f3',
      foregroundColor: '#ecebeb',
    };
  }

  renderDefaultItems = (itemClassList) => {

    let items = [];
    let numberOfItems;

    if (this.props.numberOfItems) {
      numberOfItems = this.props.numberOfItems;
    } else {
      numberOfItems = 20;
    }

    for (let i = 0; i < numberOfItems; i++) {

      items.push(
        <Col sm={12} lg={6} key={'item-' + i}>
          <div className={itemClassList} style={{paddingBottom: 0, border: 'none'}}>
            <ContentLoader 
              speed={2}
              width={'100%'}
              height={'100%'}
              viewBox="0 0 546 37"
              backgroundColor={this.state.backgroundColor}
              foregroundColor={this.state.foregroundColor}
            >
              <rect x="421.682" y="3" width="42.88" height="20" rx="10" ry="10"/>
              <circle cx="405.721" cy="12" r="10" />
              <rect x="508.805" y="3" width="25.961" height="20" rx="10" ry="10"/>
              <circle cx="492.844" cy="12" r="10" />
              <rect x="16.748" y="3" width="251.535" height="20" rx="10" ry="10"/>
            </ContentLoader>
          </div>
        </Col>
      );

    }

    return items;
  };

  render() {
    const itemClass = "group-item";
    const itemClassList = `${itemClass}  item`;

    return (
      <main className="groups screen">
        <div className="page-header">
          <ContentLoader 
            speed={2}
            width={'100%'}
            height={'100%'}
            viewBox="0 0 1140 60"
            backgroundColor={this.state.backgroundColor}
            foregroundColor={this.state.foregroundColor}
          >
            <rect x="0" y="0" width="250.539" height="60" rx="10" ry="10"/>
          </ContentLoader>
        </div>

        <Row>
          {this.renderDefaultItems(itemClassList)}
        </Row>
      </main>
    );
  }

}

export default NoticesGroupsSkeletonScreen;