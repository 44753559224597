import {api} from '../config';

export const postNode = (_endpoint, _node, _xcsrf_token, _accessToken) => {
  let postUri = 'node?_format=hal_json';
  let headers = {
    Accept: 'application/hal+json',
    'Content-Type': 'application/hal+json',
    'X-CSRF-Token': _xcsrf_token,
    Authorization: 'Bearer ' + _accessToken,
  };

  if (_endpoint) {
    postUri = _endpoint;
  }

  if (!_accessToken) {
    headers = {
      Accept: 'application/hal+json',
      'Content-Type': 'application/hal+json',
      'X-CSRF-Token': _xcsrf_token,
    };
  }

  return api({
    method: 'post',
    url: postUri + '?_format=hal_json',
    data: JSON.stringify(_node),
    headers: headers,
  });
};

export const customPost = (_endpoint, _data, _headers) => {
  return api({
    method: 'post',
    url: _endpoint,
    data: JSON.stringify(_data),
    headers: _headers,
  });
};

export const patchNode = (_endpoint, _node, _xcsrf_token, _accessToken) => {
  let headers = {
    Accept: 'application/hal+json',
    'Content-Type': 'application/hal+json',
    'X-CSRF-Token': _xcsrf_token,
    Authorization: 'Bearer ' + _accessToken,
  };

  if (!_accessToken) {
    headers = {
      Accept: 'application/hal+json',
      'Content-Type': 'application/hal+json',
      'X-CSRF-Token': _xcsrf_token,
    };
  }

  return api({
    method: 'patch',
    url: _endpoint + '?_format=hal_json',
    data: JSON.stringify(_node),
    headers: headers,
  });
};

export const getToken = () => {
  return api({
    method: 'post',
    url: 'session/token',
  });
};

