// Modules
import axios from 'axios';

// App
import {serverUrl} from '../config'
import authStore from '../redux/store'
import {getOnlineNode} from './getNode';

// Data
import {getContentCount} from './getRecordCount'
import {updateContentCounterTable} from './updateData'

axios.defaults.withCredentials = false;

export const _checkContent = async () => {
  let local_content_counter = {
    blog: await getContentCount('blog'),
    company_directory: await getContentCount('company_directory'),
    contacts: await getContentCount('users'),
    course: await getContentCount('course'),
    documents: await getContentCount('documents'),
    event: await getContentCount('event'),
    external_resource: await getContentCount('external_resource'),
    group_notices: await getContentCount('group_notices'),
    image_gallery: await getContentCount('image_gallery'),
    messages: await getContentCount('messages'),
    news: await getContentCount('news'),
    noticeboard: await getContentCount('noticeboard'),
    podcasts: await getContentCount('podcasts'),
    polls: await getContentCount('polls'),
    quiz: await getContentCount('quiz'),
    videos: await getContentCount('videos'),
    webform: await getContentCount('webform'),
  };

  let content_counter = {};

  getOnlineNode(serverUrl + '/content_counter')
    .then(response => {
      response.data.forEach(item => {
        updateContentCounterTable(item.content_type, parseInt(item.count, 10))
        
        // COMPANY DIRECTORY
        if (item.content_type === 'company_directory') {
          const local_company_directory =
            local_content_counter.company_directory.length > 0
              ? local_content_counter.company_directory[0]
                  .previousCount
              : 0;

          content_counter.company_directory =
            parseInt(item.count, 10) - local_company_directory;
        }

        // COURSE
        if (item.content_type === 'course') {
          const localCourses =
            local_content_counter.course.length > 0
              ? local_content_counter.course[0].previousCount
              : 0;

          content_counter.course = parseInt(item.count, 10) - parseInt(localCourses, 10);
        }

        // DOCUMENTS
        if (item.content_type === 'documents') {
          const localDocuments =
            local_content_counter.documents.length > 0
              ? local_content_counter.documents[0].previousCount
              : 0;

          content_counter.documents = parseInt(item.count, 10) - parseInt(localDocuments, 10);
        }

        // EVENTS
        if (item.content_type === 'event') {
          const localEvents =
            local_content_counter.event.length > 0
              ? local_content_counter.event[0].previousCount
              : 0;

          content_counter.event = parseInt(item.count, 10) - parseInt(localEvents, 10);
        }

        // EXTERNAL RESOURCE
        if (item.content_type === 'external_resource') {
          const localExternalResources =
            local_content_counter.external_resource.length > 0
              ? local_content_counter.external_resource[0]
                  .previousCount
              : 0;

          content_counter.external_resource =
            parseInt(item.count, 10) - parseInt(localExternalResources, 10);
        }

        // EXTERNAL RESOURCE
        if (item.content_type === 'group_notices') {
          const local_group_notices =
            local_content_counter.group_notices.length > 0
              ? local_content_counter.group_notices[0].previousCount
              : 0;

          content_counter.group_notices =
            parseInt(item.count, 10) - parseInt(local_group_notices, 10);
        }

        // IMAGE GALLERY
        if (item.content_type === 'image_gallery') {
          const local_image_gallery =
            local_content_counter.image_gallery.length > 0
              ? local_content_counter.image_gallery[0].previousCount
              : 0;

          content_counter.image_gallery =
            parseInt(item.count, 10) - parseInt(local_image_gallery, 10);
        }

        // NEWS
        if (item.content_type === 'news') {
          const localNews =
            local_content_counter.news.length > 0
              ? local_content_counter.news[0].previousCount
              : 0;

          content_counter.news = parseInt(item.count, 10) - parseInt(localNews, 10);
        }

        // NOTICEBOARD
        if (item.content_type === 'noticeboard') {
          const local_noticeboard =
            local_content_counter.noticeboard.length > 0
              ? local_content_counter.noticeboard[0].previousCount
              : 0;

          content_counter.noticeboard = parseInt(item.count, 10) - parseInt(local_noticeboard, 10);
        }

        if (item.content_type === 'podcasts') {
          const localPodcast =
            local_content_counter.podcasts.length > 0
              ? local_content_counter.podcasts[0].previousCount
              : 0;

          content_counter.podcasts = parseInt(item.count, 10) - parseInt(localPodcast, 10);
        }

        // QUIZ
        if (item.content_type === 'quiz') {
          const localQuiz =
            local_content_counter.quiz.length > 0
              ? local_content_counter.quiz[0].previousCount
              : 0;

          content_counter.quiz = parseInt(item.count, 10) - parseInt(localQuiz, 10);
        }

        // VIDEOS
        if (item.content_type === 'videos') {
          const localVideos =
            local_content_counter.videos.length > 0
              ? local_content_counter.videos[0].previousCount
              : 0;

          content_counter.videos = parseInt(item.count, 10) - parseInt(localVideos, 10);
        }

        // WEBFORM
        if (item.content_type === 'webform') {
          const localWebforms =
            local_content_counter.webform.length > 0
              ? local_content_counter.webform[0].previousCount
              : 0;

          content_counter.webform = parseInt(item.count, 10) - parseInt(localWebforms, 10);
        }

        // BLOGS
        if (item.content_type === 'blog') {
          const local_blogs =
            local_content_counter.polls.length > 0
              ? local_content_counter.blog[0].previousCount
              : 0;

          content_counter.blog =
            parseInt(item.count, 10) - parseInt(local_blogs, 10);
        }

        // CONTACTS
        if (item.content_type === 'users') {
          const local_contacts =
            local_content_counter.contacts.length > 0
              ? local_content_counter.contacts[0].previousCount
              : 0;

          content_counter.contacts =
            parseInt(item.count, 10) - parseInt(local_contacts, 10);
        }

        // MESSAGES
        if (item.content_type === 'messages') {
          updateContentCounterTable('messages', parseInt(item.count, 10))

          const localMessages =
            local_content_counter.messages.length > 0
              ? local_content_counter.messages[0].previousCount
              : 0;

          content_counter.messages =
            parseInt(item.count, 10) - parseInt(localMessages, 10);
        }

        // POLLS
        if (item.content_type === 'polls') {
          const localPolls =
            local_content_counter.polls.length > 0
              ? local_content_counter.polls[0].previousCount
              : 0;

          content_counter.polls = parseInt(item.count, 10) - parseInt(localPolls, 10);
        }
      });

      if (content_counter.blogs < 0) {
        content_counter.blogs = 0;
      }

      if (content_counter.company_directory < 0) {
        content_counter.company_directory = 0;
      }

      if (content_counter.contacts < 0) {
        content_counter.contacts = 0;
      }

      if (content_counter.course < 0) {
        content_counter.course = 0;
      }

      if (content_counter.documents < 0) {
        content_counter.documents = 0;
      }

      console.log('content_counter.events: ', content_counter);

      if (content_counter.event < 0) {
        content_counter.event = 0;
      }

      if (content_counter.external_resource < 0) {
        content_counter.external_resource = 0;
      }

      if (content_counter.group_notices < 0) {
        content_counter.group_notices = 0;
      }

      if (content_counter.image_gallery < 0) {
        content_counter.image_gallery = 0;
      }

      if (content_counter.messages < 0) {
        content_counter.messages = 0;
      }

      if (content_counter.news < 0) {
        content_counter.news = 0;
      }

      if (content_counter.noticeboard < 0) {
        content_counter.noticeboard = 0;
      }

      if (content_counter.podcasts < 0) {
        content_counter.podcasts = 0;
      }

      if (content_counter.polls < 0) {
        content_counter.polls = 0;
      }

      if (content_counter.quiz < 0) {
        content_counter.quiz = 0;
      }

      if (content_counter.videos < 0) {
        content_counter.videos = 0;
      }

      if (content_counter.webform < 0) {
        content_counter.webform = 0;
      }

      authStore.dispatch({
        type: 'UPDATE_CONTENT_COUNTER',
        payload: content_counter,
      });
    })
    .catch(error => {
      // error
      console.log('error: ', error);
    })
}