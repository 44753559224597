import React from "react";

// Modules
import { connect } from "react-redux";
import { decode } from "html-entities";
import moment from 'moment';
import { Link } from "react-router-dom";

// App
import {getOnlineNode} from '../../core/getNode';
import {paginator} from '../../core/paginator';
import Pagination from '../../core/pagination';
import PageHeader from '../../partials/pageHeader';
import {alertMessages} from "../../partials/alertMessages";
import Error from "../../partials/error";
import ForumsSkeletonTopicsScreen from "./skeleton-screens/forumsSkeletonTopicsScreen";

// UI components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ArrowRight, Plus } from "react-feather";

class ForumTopics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isError: false,

      tid: '',

      currentPage: 1,
      pageSize: 20,
      totalItems: 0,
    };

    this.onPaginate = this.onPaginate.bind(this);
  }

  componentDidMount() {
    this.checkSession();
  }
  
  checkSession = () => {
    if (Object.keys(this.props.user).length === 0) {
      this.props.history.push('/');
    } else {
      this.loadNode();
    }
  }

  /**
   * @function setData
   * @description Updates the state of the component upon data retrival
   * @param {object} _data
   * @param {object} _realm
   */
  setData = (_data) => {
    this.setState(
      {
        // isLoading: false,
        isRefreshing: false,
        isError: false,
        forumTopics: _data.rows,
        totalItems: _data.pager.total_items,
      }, () => {
        this.getLastPostAuthorData();
      }
    );
  };

  /**
   * @function setError
   * @description Updates the state of the component upon an error
   * @param {boolean} _isError
   * @param {int} _errorStatus
   * @param {string} _errorMessage
   */
  setError = (_isError, _errorStatus, _errorMessage) => {
    this.setState({
      isLoading: false,
      isRefreshing: false,
      isError: _isError,
      errorStatus: _errorStatus,
      errorMessage: _errorMessage,
    });
  };

  loadNode = () => {
    const tid = this.props.match.params.tid;

    if (tid) {
      this.getForumBoard(tid);
      this.setState({
        tid: tid,
      });
    } else {
      this.setError(true, 0, alertMessages.contentError);
    }
  };

  /**
   * @function getForumTopics
   * @description Retrieves the data from an API
   */
  getForumTopics = (tid) => {
    let path =
      "forum_topic/" +
      tid +
      "/all?_format=json&status=1&promote=1&" +
      "items_per_page=" +
      this.state.pageSize +
      "&page=" +
      (this.state.currentPage - 1);

    getOnlineNode(path, this.props.user.access_token)
      .then((response) => {
        if (response.data.rows.length > 0) {
          this.setData(response.data);
        } else {
          this.setError(true, 200, alertMessages.noData);
        }
      })
      .catch((_error) => {
        console.log("error: ", _error);
        if (_error.response) {
          this.setError(
            true,
            _error.response.status,
            _error.response.statusText
          );
        } else if (_error.request) {
          //console.log('@rest request: ', _error.request);
          this.setError(true, 0, alertMessages.requestError.message);
        } else {
          //console.log('@rest unknown: ', _error);
          this.setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };

  /**
   * @function getForumBoard
   * @description Retrieves the data from an API
   */
  getForumBoard = (tid) => {
    let path = 'all_categories/forums/' + tid;

    getOnlineNode(path, this.props.user.access_token)
      .then((response) => {
        this.setState({
          forumBoard: response.data[0],
        }, () => {
          this.getForumTopics(tid);
        });
      })
      .catch((_error) => {
        if (_error.response) {
          //console.log('@rest response: ', _error.response);
          this.setError(
            true,
            _error.response.status,
            _error.response.statusText,
          );
        } else if (_error.request) {
          //console.log('@rest request: ', _error.request);
          this.setError(true, 0, alertMessages.requestError.message);
        } else {
          //console.log('@rest unknown: ', _error);
          this.setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };

  /**
   * @function getLastPostAuthorData
   * @description Retrieves the data from an API
   */
  getLastPostAuthorData = () => {
    let data;
    let lastPostAuthorData = [];

    this.state.forumTopics.forEach(topic => {
      let path = 'custom_user?_format=json&name=' + topic.last_comment_name;

      getOnlineNode(path, this.props.user.access_token)
        .then((response) => {
          data = response.data.rows[0];
          lastPostAuthorData.push({
            uid: data.uid,
            username: data.name,
            field_first_name: data.field_first_name,
            field_last_name: data.field_last_name,
          });
          this.setState(
            {
              lastPostAuthorData: lastPostAuthorData
            },
            () => {
              this.setState({isLoading: false});
            },
          );
        })
        .catch((_error) => {
          // error
        });
    });
  };

  /**
   * @function onPaginate
   * @description Pagination callback
   * @param {int} _index - Page number
   */
  onPaginate = (_index) => {
    this.setState(
      {
        isLoading: true,
        isPaginating: true,
        currentPage: _index,
      },
      function() {
        // Call getForumTopics() as it's quicker than loadNode() = 2x api calls
        this.getForumTopics(this.props.match.params.tid);
      },
    );
  };

  // Pagination component
  renderPagination = () => {

    if (this.state.totalItems <= this.state.pageSize) {
      return null;
    } else {
      let pagination = paginator(
        this.state.totalItems,
        this.state.currentPage,
        this.state.pageSize,
        3,
      );

      return (
        <Pagination
          currentPage={pagination.currentPage}
          endIndex={pagination.endIndex}
          endPage={pagination.endPage}
          pageSize={pagination.pageSize}
          pages={pagination.pages}
          startIndex={pagination.startIndex}
          startPage={pagination.startPage}
          totalItems={pagination.totalItems}
          totalPages={pagination.totalPages}
          paginationCallback={this.onPaginate}
        />
      );
    }
  };

  /**
   * @function renderAuthor returns the concatenated name and fallsback to username
   * @param {*} userData 
   * @returns 
   */
  renderAuthor = (userData) => {
    // username
    let author = userData.name;

    // first name
    if (userData.field_first_name) {
      author = userData.field_first_name;
    }

    // Concat first & last name
    if (
      userData.field_first_name &&
      userData.field_last_name
    ) {
      author =
        userData.field_first_name +
        " " +
        userData.field_last_name;
    }

    if (!author) {
      author = userData.name;
    }

    return author;
  };

  /**
   * Render each topic within the forum
   * @param {*} itemClass 
   */
  renderForumTopics = () => {
    const itemClass = "forum-topic";

    return (
      this.state.forumTopics.map((topic) => {
        let lastPostAuthorObject;

        let filteredLastPostAuthor = this.state.lastPostAuthorData.filter(item => item.uid === topic.last_comment_uid);

        if (filteredLastPostAuthor) {
          lastPostAuthorObject = filteredLastPostAuthor[0];
        }

        let forumTopicLink = `/forums/${this.state.forumBoard.tid}/${topic.nid}`;

        let title = topic.title;

        try {
          title = decode(title);
        } catch (error) {
          // console.log('cannot decode:', title);
        }

        const lastCommentTimestamp = moment.unix(topic.last_comment_timestamp).format("DD.MM.YY (HH:mm)");

        return (
          <div key={"topic-" + topic.nid} className={`${itemClass}`}>
            <Row>
              <Col xs={6}>
                <Link
                  to={forumTopicLink}
                  className={`${itemClass}__link  link`}
                >
                  <div className={`${itemClass}__icon`}>
                    <ArrowRight />
                  </div>
                  <span className={`${itemClass}__title`}>{title}</span>
                </Link>
              </Col>
              <Col xs={2}>
                <Link
                  to={"/contacts/" + topic.author_uid}
                  className={`${itemClass}__author  link`}
                >
                  {this.renderAuthor(topic)}
                </Link>
              </Col>
              <Col xs={1}>
                <span className={`${itemClass}__posts`}>
                  {topic.comment_count}
                </span>
              </Col>
              <Col xs={3}>
                <div className={`${itemClass}__last-post`}>
                  <span className={`${itemClass}__last-timestamp`}>
                    {lastCommentTimestamp}
                  </span>
                  <div className={`${itemClass}__last-contributor`}>
                    <span className="mr-1">By</span>
                    <Link
                      to={"/contacts/" + topic.last_comment_uid}
                      className="link"
                    >
                      {lastPostAuthorObject && (
                        this.renderAuthor(lastPostAuthorObject)
                      )}
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        );
      })
    );
  };

  /**
   * @function render
   * @description Default render method
   */
  render() {
    if (this.state.isLoading) {
      return <ForumsSkeletonTopicsScreen />;
    } else {
      if (this.state.isError) {
        return (
          <Error 
            status={this.state.errorStatus}
            message={this.state.errorMessage}
          />
        );
      } else {
        return (
          <main className="forums-topics  screen">
            <PageHeader
              pageName={this.state.forumBoard.title}
              filters={false}
            />
            {this.state.forumBoard.description__value && (
              <div
                className="forums-board-description"
                dangerouslySetInnerHTML={{
                  __html: this.state.forumBoard.description__value,
                }}
              />
            )}
            <section className="forums-topics__table">
              <div className="forum-topics__header">
                <Row>
                  <Col xs={6}>
                    <h6 className="heading">Topic</h6>
                  </Col>
                  <Col xs={2}>
                    <h6 className="heading">Author</h6>
                  </Col>
                  <Col xs={1}>
                    <h6 className="heading">Posts</h6>
                  </Col>
                  <Col xs={3}>
                    <h6 className="heading">Last Post</h6>
                  </Col>
                </Row>
              </div>
              {this.renderForumTopics()}
              <Row>
                <Col xs={'auto'} className="d-flex ml-auto">
                  <Link to={`/forums/${this.state.tid}/create`}>
                    <button type="button" className="add-topic">
                      <div className="add-topic__icon"><Plus /></div>
                      <span>New topic</span>
                    </button>
                  </Link>
                </Col>
              </Row>
            </section>
            {this.renderPagination()}
          </main>
        );
      }
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default connect(mapStateToProps)(ForumTopics);
