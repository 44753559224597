// Dependencies
import React from 'react';

// Modules
import {connect} from 'react-redux';
import TextareaAutosize from 'react-textarea-autosize';
import { Link } from 'react-router-dom';

// App
import {serverUrl} from '../config';
import {getOnlineNode} from '../core/getNode';
import {postNode} from '../core/postNode';
import {alertMessages} from '../partials/alertMessages';
import AlertModal from '../partials/alertModal';
import PostExcerpt from '../partials/postExcerpt';
import {get_xcsrf_token} from '../core/auth';
import UserImage from './userImage';

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import {Send, X} from 'react-feather';

class ForumComment extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      isCommentPosted: false,
      subject: '',
      comment: '',
      modalVisible: false,
      userData: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.commentTextbox = null;
  }

  componentDidMount() {
    this.getToken();
    this.getUserData();
  }

  getToken = () => {
    get_xcsrf_token().then(_response => {
      this.setState({
        token: _response.data,
      });
    });
  };

  getUserData = (_data) => {
    const user = this.props.user.current_user.name;
    let path = 'custom_user?_format=json&name=' + user;

    getOnlineNode(path, this.props.user.access_token)
      .then((response) => {
        if (response.data.rows.length > 0) {
          this.setState({
            userData: response.data.rows[0],
          });
        }
      })
      .catch((_error) => {
        // error
      });
  };

  postComment = () => {
    if (this.state.comment.length < 1) {
      this.setState({
        modalVisible: true,
        alertType: 'error',
        alertTitle: alertMessages.commentEmpty.title,
        alertBody: alertMessages.commentEmpty.message,
        alertConfirmButton: true,
      });
      return false;
    }

    this.setState({
      isLoading: true,
      isCommentPosted: false,
    });

    const data = {
      _links: {
        type: {
          href: serverUrl + '/rest/type/comment/comment_forum',
        },
      },
      entity_id: [
        {
          target_id: this.props.nid,
        },
      ],
      entity_type: [
        {
          value: 'node',
        },
      ],
      comment_type: [
        {
          target_id: 'comment_forum',
        },
      ],
      pid: [
        {
          target_id: this.props.comment ? this.props.comment.cid : null,
        }
      ],
      field_name: [
        {
          value: 'comment_forum',
        },
      ],
      subject: [
        {
          value: this.state.subject,
        },
      ],
      comment_body: [
        {
          value: this.state.comment,
        },
      ],
    };

    postNode(
      'comment',
      data,
      this.state.token,
      this.props.user.access_token,
    )
      .then((response) => {
        this.setState({
          isLoading: false,
          isCommentPosted: true,
          subject: '',
          comment: '',
          modalVisible: true,
          alertType: 'success',
          alertTitle: alertMessages.commentPosted.title,
          alertBody: alertMessages.commentPosted.message,
          alertConfirmButton: true,
        });

        this.props.onCommentPosted();
      })
      .catch((_error) => {
        this.setState({
          modalVisible: true,
          alertType: 'error',
          alertTitle: alertMessages.commentPostFailure.title,
          alertBody: alertMessages.commentPostFailure.message,
          alertConfirmButton: true,
          isLoading: false,
          isCommentPosted: false,
        });
      });
  };

  /**
   * Handle textarea input onChange
   * @param {*} event 
   */
  handleChange(event) {
    this.setState({comment: event.target.value});
  }

  /**
   * Set modal visibility
   * @param {*} visible 
   */
  setModalVisible = (visible) => {
    this.setState({
      modalVisible: visible,
    });
  };

  shouldFocus = () => {
    if (this.commentTextbox) {
      this.commentTextbox.focus();
    }
  }

  /**
   * @function renderAuthor returns the concatenated name and fallsback to username
   * @param {*} userData 
   * @returns 
   */
  renderAuthor = (userData) => {

    // username
    let author = userData.name;

    // first name
    if (userData.field_first_name) {
      author = userData.field_first_name;
    }

    // Concat first & last name
    if (
      userData.field_first_name &&
      userData.field_last_name
    ) {
      author =
        userData.field_first_name +
        " " +
        userData.field_last_name;
    }

    if (!author) {
      author = userData.name;
    }

    return author;
  };

  render() {
    if (this.props.user.current_user) {
      if (this.props.focus === true) {
        this.shouldFocus();
      }

      return (
        <>
          <AlertModal 
            showAlert={this.state.modalVisible} 
            showAlertCallback={this.setModalVisible}
            alertType={this.state.alertType}
            alertMessageTitle={this.state.alertTitle}
            alertMessageBody={this.state.alertBody}
            cancelButton={this.state.alertCancelButton}
            confirmButton={this.state.alertConfirmButton}
          />
          <div className="forum-comment-form-container">
            <Row>
              <Col xs={12} md={'auto'} className="col-author">
                <div className="forum-comment-form__author">
                  <Link to={"/contacts/" + this.state.userData.uid} className="mr-2  mr-md-0  mb-md-2">
                    <UserImage image={this.state.userData.user_picture} />
                  </Link>
                  <p className="username">
                    <span>Posting as:</span>              
                    <Link to={"/contacts/" + this.state.userData.uid}>
                      {this.renderAuthor(this.state.userData)}
                    </Link>
                  </p>
                </div>
              </Col>
              <Col>
                <div className="comment-form">
                  {this.props.comment.cid && (
                    <div className="replied-comment">
                      <p className="d-flex  mb-1">
                        <span className="username">
                          {this.props.comment.username}
                        </span>
                        <span className="ml-1">said:</span>
                        <Button
                          className='reset-btn'
                          onClick={() => {
                            this.props.clearReply();
                          }}>
                          <X />
                        </Button>
                      </p>
                      <PostExcerpt
                        body={this.props.comment.comment_body}
                        maxLength={150}
                      />
                    </div>
                  )}
                  <TextareaAutosize 
                    className="comment-form__input"
                    onChange={this.handleChange}
                    placeholder="Write a comment..."
                    value={this.state.comment}
                    minRows={4}
                    ref={(textarea) => { this.commentTextbox = textarea; }} 
                  />
                  <div className="comment-form__footer">
                    <Row className="align-items-center">
                      <Col xs={'auto'} className="ml-auto">
                        <button
                          className="submit"
                          type="submit"
                          onClick={this.postComment}>
                          <span className="mr-2">Submit</span>
                          <Send/>
                        </button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default connect(mapStateToProps)(ForumComment);