import React from "react";

// Modules
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import TimeAgo from "react-timeago";
import moment from "moment";

// App
import PostTitle from "../../partials/postTitle";
import PostExcerpt from "../../partials/postExcerpt";
import LoadingSpinner from "../../partials/loadingSpinner";

// Data
// import db from "../../core/db";

// UI components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Bookmark, Clock } from "react-feather";

class BlogItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      favorite: "false",
    };
  }

  componentDidMount() {
    this.checkState();
  }

  checkState = () => {
    this.setState({
      favorite: this.props.item.favorite,
    });
  };

  /**
   * Favourite a blog item and update the db
   *
   * @param {The node id} _nid
   */
  favorite = (_nid) => {
    this.setState({
      favorite: "true",
    });

    this.props.favorite(_nid);
  };

  /**
   * Unfavourite a blog item and update the db
   *
   * @param {*} _nid
   */
  unfavorite = (_nid) => {
    this.setState({
      favorite: "false",
    });

    this.props.unfavorite(_nid);
  };

  /**
   * Handle item hover state
   * @returns
   */
  handleItemHover = (hover) => {
    this.setState({ isHovered: hover });
  };

  renderBlogItem = () => {
    const itemClass = "blog-item";
    const itemClassList = `${itemClass}  item`;
    const timestamp = moment.unix(moment(this.props.item.date).format("X"));

    let itemStyle = itemClassList;
    let hoveredStyle = this.state.isHovered ? 'hovered' : '';

    return (
      <Col key={"blog" + this.props.item.id} className="d-flex" xs={12} lg={6}>
        <div className={`${itemStyle} ${hoveredStyle}`}>
          <Row noGutters>
            <Col xs={6}>
              <Link
                to={"/blog/" + this.props.item.id}
                className={`${itemClass}__image  item__image`}
                onMouseEnter={() => this.handleItemHover(true)}
                onMouseLeave={() => this.handleItemHover(false)}
              >
                <img
                  src={this.props.item.uri}
                  alt={"img-" + this.props.item.id}
                />
              </Link>
            </Col>
            <Col>
              <Link
                to={"/blog/" + this.props.item.id}
                className={`${itemClass}__content  item__content`}
                onMouseEnter={() => this.handleItemHover(true)}
                onMouseLeave={() => this.handleItemHover(false)}
              >
                <PostTitle
                  title={this.props.item.title.rendered}
                  headingLevel={"h6"}
                />
                <PostExcerpt body={this.props.item.content.rendered} />
              </Link>
            </Col>
          </Row>
          <Row noGutters className="mt-auto">
            <Col>
              <footer className={`${itemClass}__footer  item__footer`}>
                <Row noGutters>
                  <Col className="timestamp">
                    <Clock className="timestamp__icon" />
                    <TimeAgo date={timestamp} className="timestamp__text" />
                  </Col>
                  <Col xs={"auto"} className="px-2">
                    {this.state.favorite === "true" ? (
                      <button
                        className="favourite-btn filled"
                        onClick={() => {
                          this.unfavorite(this.props.item.id);
                        }}
                      >
                        <Bookmark />
                      </button>
                    ) : (
                      <button
                        className="favourite-btn"
                        onClick={() => {
                          this.favorite(this.props.item.id);
                        }}
                      >
                        <Bookmark />
                      </button>
                    )}
                  </Col>
                </Row>
              </footer>
            </Col>
          </Row>
        </div>
      </Col>
    );
  };

  /**
   * @function render
   * @description Default render method
   */
  render() {
    if (this.state.isLoading) {
      return <LoadingSpinner />;
    } else {
      return this.renderBlogItem();
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default connect(mapStateToProps)(BlogItem);
