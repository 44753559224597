// Dependencies
import React from 'react';

// Modules
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import TimeAgo from 'react-timeago'
import moment from 'moment';

// App
import {getExternal} from '../../core/getNode';
import PostTitle from '../../partials/postTitle';
import PostBody from '../../partials/postBody'
import { alertMessages } from "../../partials/alertMessages";
import Error from "../../partials/error";
import SkeletonSingleScreen from '../../partials/skeleton-screens/skeletonSingleScreen';

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Clock, Tag } from 'react-feather';

class BlogSingle extends React.Component {
  constructor(props) {
    super(props);
    this.scrollViewRef = React.createRef();
    this.state = {
      isLoading: true,
      isPaginating: false,
      post: null,
      pageSize: 10,
      currentPage: 0,
      total_pages: 0,
    };
  }

  componentDidMount() {
    this.checkSession();
  }

  checkSession = () => {
    this.loadNode();
    // if (Object.keys(this.props.user).length === 0) {
    //   this.props.history.push('/');
    // } else {
    //   this.loadNode();
    // }
  }

  /**
   * @function setData
   * @description Updates the state of the component upon data retrival
   * @param {object} _data
   * @param {object} _realm
   */
  setData = (_data) => {
    this.setState(
      {
        isLoading: false,
        isRefreshing: false,
        isError: false,
        post: _data,
      },
    );
  };

  /**
   * @function setError
   * @description Updates the state of the component upon an error
   * @param {boolean} _isError
   * @param {int} _errorStatus
   * @param {string} _errorMessage
   */
  setError = (_isError, _errorStatus, _errorMessage) => {
    this.setState({
      isLoading: false,
      isRefreshing: false,
      isError: _isError,
      errorStatus: _errorStatus,
      errorMessage: _errorMessage,
    });
  };

  loadNode = () => {
    const id = this.props.match.params.id;

    if (id) {
      this.getContent(id);
    } else {
      this.setError(true, 0, alertMessages.contentError);
    }
  };

  /**
   * @function getContent
   * @description Retrieves the data from an API / Fallback to local realm object if there is no connection
   */
  getContent = (id) => {
    let path = 'wp-json/wp/v2/posts?include[]=' + id;

    getExternal(path + '&_embed=1')
      .then(response => {
        this.setData(response.data[0]);
      })
      .catch(_error => {
        console.log('error: ', _error);
        if (_error.response) {
          //console.log('@rest response: ', _error.response);
          this.setError(
            true,
            _error.response.status,
            _error.response.statusText,
          );
        } else if (_error.request) {
          //console.log('@rest request: ', _error.request);
          this.setError(true, 0, alertMessages.requestError.message);
        } else {
          //console.log('@rest unknown: ', _error);
          this.setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };

  renderPostImage = (post) => {
    return (
      <div className="article__image">
        <img src={post._embedded['wp:featuredmedia'][0].source_url} alt={'img-' + post.id} />
      </div>
    );
  };

  renderCategory = (post) => {
    const categories = post._embedded['wp:term'];

    let category = '';
    if (categories && Array.isArray(categories)) {
      categories.forEach(item => {
        item.forEach(term => {
          if (term.taxonomy === 'category') {
            category += term.name + ', ';
          }
        });
      });
      category = category.slice(0, -2);
    } else {
      category = 'Uncategorised';
    }

    return category;
  };

  render() {
    if (this.state.isLoading) {
      return <SkeletonSingleScreen />;
    } else {
      if (this.state.isError) {
        return (
          <Error 
            status={this.state.errorStatus}
            message={this.state.errorMessage}
          />
        );
      } else {
        const {post} = this.state;
        const timestamp = moment.unix(moment(post.date).format('X'));

        return (
          <main ref={this.containerRef} className="blog  single">
            <article className="article">
              <header className="article__header">
                <PostTitle title={post.title.rendered} headingLevel={'h1'} />
                <div className="article__meta">
                  <Row>
                    <Col xs={"auto"}>
                      <div className="timestamp align-items-center">
                        <Clock className="timestamp__icon" />
                        <TimeAgo date={timestamp} className="timestamp__label label" />
                      </div>

                    </Col>
                    <Col xs={"auto"}>
                      <div className="category align-items-center">
                        <Tag className="category__icon" />
                        <span className="category__label  label">{this.renderCategory(post)}</span>
                      </div>
                    </Col>
                  </Row>
                </div>
              </header>
              {this.renderPostImage(post)}
              <div className="article__body">
                <div>
                  <PostBody body={post.content.rendered} />
                </div>
              </div>
            </article>
          </main>
        );
      }
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default withRouter(connect(mapStateToProps)(BlogSingle));
