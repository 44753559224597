// Dependencies
import React from 'react';

// Modules
import {withRouter} from 'react-router'
import {connect} from 'react-redux';

// App
import {getOnlineNode} from '../../core/getNode';
import LoadingSpinner from '../../partials/loadingSpinner';
import {alertMessages} from '../../partials/alertMessages';
import Error from '../../partials/error';

class DocumentSingle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    this.loadNode();
  }

  /**
   * @function setError
   * @description Updates the state of the component upon an error
   * @param {boolean} _isError
   * @param {int} _errorStatus
   * @param {string} _errorMessage
   */
  setError = (_isError, _errorStatus, _errorMessage) => {
    this.setState({
      isLoading: false,
      isRefreshing: false,
      isError: _isError,
      errorStatus: _errorStatus,
      errorMessage: _errorMessage,
    });
  };

  loadNode = () => {
    const nid = this.props.match.params.nid;

    if (nid) {
      this.getContent(nid);
    } else {
      this.setError(true, 0, alertMessages.contentError);
    }
  };

  /**
   * @function getContent
   * @description Retrieves the data from an API / Fallback to local realm object if there is no connection
   */
  getContent = (nid) => {
    let path = 'documents/all/' + nid + '?_format=json&status=1&promote=1';

    getOnlineNode(path, this.props.user.access_token)
      .then((response) => {
        if (response.data.rows.length > 0) {

          if (response.data.rows[0].uri) {
            window.location = response.data.rows[0].uri;

            this.props.history.goBack();            
          }

        } else {
          this.setError(true, 200, alertMessages.noData);
        }
      })
      .catch((_error) => {
        if (_error.response) {
          this.setError(
            true,
            _error.response.status,
            _error.response.statusText,
          );
        } else if (_error.request) {
          this.setError(true, 0, alertMessages.requestError.message);
        } else {
          this.setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };

  render() {
    if (this.state.isLoading) {
      return <LoadingSpinner />;
    } else {
      if (this.state.isError) {
        return (
          <Error 
            status={this.state.errorStatus}
            message={this.state.errorMessage}
          />
        );
      } else {
        return null;
      }
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default withRouter(connect(mapStateToProps)(DocumentSingle));
