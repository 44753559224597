// Dependencies
import React from 'react';

// Modules
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import {decode} from 'html-entities';

// App
import {getOnlineNode} from '../../core/getNode';
import PostTitle from '../../partials/postTitle';
import {alertMessages} from "../../partials/alertMessages";
import Error from "../../partials/error";
import CompanyDirectorySkeletonSingleScreen from './companyDirectorySkeletonSingleScreen';

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import {Clock, Mail, MapPin, Phone, Users } from 'react-feather';

class CompanyDirectorySingle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isPaginating: false,
      post: null,
      isCommentsLoading: true,
      comments: [],
      pageSize: 10,
      currentPage: 0,
      total_pages: 0,
      contactGroups: [],
    };
  }

  componentDidMount() {
    this.checkSession();
  }

  checkSession = () => {
    if (Object.keys(this.props.user).length === 0) {
      this.props.history.push('/');
    } else {
      this.loadNode();
    }
  }

  /**
   * @function setData
   * @description Updates the state of the component upon data retrival
   * @param {object} _data
   * @param {object} _realm
   */
  setData = (_data) => {
    this.setState(
      {
        // isLoading: false,
        isRefreshing: false,
        isError: false,
        post: _data,
      },
      function () {
        this.getContactGroups();
      },
    );
  };

  /**
   * @function setError
   * @description Updates the state of the component upon an error
   * @param {boolean} _isError
   * @param {int} _errorStatus
   * @param {string} _errorMessage
   */
  setError = (_isError, _errorStatus, _errorMessage) => {
    this.setState({
      isLoading: false,
      isRefreshing: false,
      isError: _isError,
      errorStatus: _errorStatus,
      errorMessage: _errorMessage,
    });
  };

  loadNode = () => {
    const nid = this.props.match.params.nid;

    if (nid) {
      this.getContent(nid);
    } else {
      this.setError(true, 0, alertMessages.contentError);
    }
  };

  /**
   * @function getContent
   * @description Retrieves the data from an API / Fallback to local realm object if there is no connection
   */
  getContent = (nid) => {
    let path = 'company_directory/all/' + nid + '?_format=json&status=1&promote=1';

    getOnlineNode(path, this.props.user.access_token)
      .then((response) => {
        if (response.data.rows.length > 0) {
          this.setData(response.data.rows[0]);
        } else {
          this.setError(true, 200, alertMessages.noData);
        }
      })
      .catch((_error) => {
        console.log('error: ', _error);
        if (_error.response) {
          this.setError(
            true,
            _error.response.status,
            _error.response.statusText,
          );
        } else if (_error.request) {
          //console.log('@rest request: ', _error.request);
          this.setError(true, 0, alertMessages.requestError.message);
        } else {
          //console.log('@rest unknown: ', _error);
          this.setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };

  /**
   * @function getContactGroups
   * @description Retrieves the data from an API / Fallback to local realm object if there is no connection
   */
  getContactGroups = () => {
    let path = 'company_directory_contact_group/' + this.state.post.nid + '?_format=json';

    getOnlineNode(path, this.props.user.access_token)
      .then((response) => {
        if (response.data.rows.length > 0) {
          let contact_groups = [];
          response.data.rows.map((contactGroup) => {
            return contact_groups.push(contactGroup);
          });
  
          this.setState({
            contactGroups: contact_groups,
          }, () => {
            this.setState({isLoading: false});
          });
        } else {
          this.setError(true, 200, alertMessages.noData);
        }
      })
      .catch((_error) => {
        console.log('error: ', _error);
        if (_error.response) {
          this.setError(
            true,
            _error.response.status,
            _error.response.statusText,
          );
        } else if (_error.request) {
          //console.log('@rest request: ', _error.request);
          this.setError(true, 0, alertMessages.requestError.message);
        } else {
          //console.log('@rest unknown: ', _error);
          this.setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };


  officeHoursRenderer = (post) => {
    let startTime = post.field_start_time;
    let endTime = post.field_end_time;
    let time = startTime;

    if (endTime !== startTime) {
      time += ' - ' + endTime;
    }

    if (startTime) {
      return (
        <Row noGutters className="details-item">
          <Col xs={4} md={3}>
            <p className="details-item__label"><strong>Office hours:</strong></p>
          </Col>

          <Col className="d-flex pl-2 align-items-center">
            <Clock />
            <p className="details-item__value">{time}</p>
          </Col>
        </Row>
      );
    }
  };

  locationRenderer = (post) => {
    const address = post.field_address;
    const decodedAddress = decode(address);

    if (address) {
      return (
        <Row noGutters className="details-item">
          <Col xs={4} md={3}>
            <p className="details-item__label"><strong>Location:</strong></p>
          </Col>
          <Col className="d-flex pl-2 align-items-start">
            <MapPin />
            <p className="details-item__value">{decodedAddress}</p>
          </Col>
        </Row>
      );
    } else {
      return null;
    }
  };

  contactGroupsRenderer = (post) => {
    if (this.state.contactGroups.length > 0) {
      return (
        this.state.contactGroups.map((_contactGroup, _index) => {
          let email = _contactGroup.field_email;
          let phoneNumber = _contactGroup.field_phone_number;

          return (
            <div
              className="contact-group-item"
              key={_contactGroup.id + '-' + _index}>
              <h4>{decode(_contactGroup.field_section_title)}</h4>

              {!!phoneNumber && phoneNumber && (
                <div className="phone  ml-sm-4  mb-2">
                  <Row className="align-items-center">
                    <Col xs={'auto'}>
                      <Button href={'tel:' + phoneNumber} className="contact-group-item__btn">
                        <Phone />
                      </Button>
                    </Col>
                    <Col>
                      <a href={'tel:' + phoneNumber} className="contact-group-item__value  text-break">
                      {decode(phoneNumber)}
                      </a>
                    </Col>
                  </Row>
                </div>  
              )}

              {!!email && email && (
                <div className="email  ml-sm-4">
                  <Row className="align-items-center">
                    <Col xs={'auto'}>
                      <Button href={'mailto:' + email} className="contact-group-item__btn" variant="secondary">
                        <Mail />
                      </Button>
                    </Col>
                    <Col>
                      <a href={'mailto:' + email} className="contact-group-item__value  text-break">
                      {decode(email)}
                      </a>
                    </Col>
                  </Row>
                </div>  
              )}
            </div>
          );
        })
      );
    } else {
      return null;
    }
  };

  render() {
    if (this.state.isLoading) {
      return <CompanyDirectorySkeletonSingleScreen />;
    } else {
      if (this.state.isError) {
        return (
          <Error 
            status={this.state.errorStatus}
            message={this.state.errorMessage}
          />
        );
      } else {
        const {post} = this.state;

        let category = 'Uncategorised';

        if (post.field_category_1) {
          category = post.field_category_1;
        }

        if (post.categoryLabel) {
          category = post.categoryLabel;
        }

        // Abbreviation colour
        let colour;
        if (post.field_abbreviation_colour) {
          colour = post.field_abbreviation_colour;
        }

        return (
          <main className="company-directory  single">
            <article className="article">
              <header className="company-directory__header  article__header">
                <Row className="align-items-center">
                  <Col xs={'auto'}>
                    <div className="abbreviation" style={{backgroundColor: colour}}>
                      {post.field_abbreviation ? (
                        <span>{decode(post.field_abbreviation)}</span>
                      ) : (
                        <Users />
                      )}
                    </div>
                  </Col>
                  <Col>
                    <PostTitle title={post.title} headingLevel={'h1'} />
                    <h3 className="category">{category}</h3>
                  </Col>
                </Row>
              </header>
              <div className="article__body">
                <div dangerouslySetInnerHTML={{__html: post.body}} />
              </div>
              <div className="company-directory__details">
                {this.officeHoursRenderer(post)}
                {this.locationRenderer(post)}
              </div>
              {this.contactGroupsRenderer(post)}
            </article>
          </main>
        );
      }
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default withRouter(connect(mapStateToProps)(CompanyDirectorySingle));
