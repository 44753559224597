import React from 'react';

// Modules
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
// App
import {serverUrl} from '../../config';
import {getOnlineNode} from '../../core/getNode';
import {postNode, getToken} from '../../core/postNode';
import {postBase64} from '../../core/postFile';
import {alertMessages} from '../../partials/alertMessages';
import PageHeader from '../../partials/pageHeader';
import CategorySelector from '../../partials/categorySelector';
import AlertModal from '../../partials/alertModal';
import Crop from '../../partials/cropper/cropper';
import NoticesEditSkeletonScreen from '../notices/skeleton-screens/noticesEditSkeletonScreen';

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner'
import {Plus, ArrowRight} from 'react-feather';

class AddNoticeboard extends React.Component {
  constructor() {
    super();
    this.state = {
      isComponentLoading: true,
      isLoading: false,
      title: '',
      body: '',
      token: null,
      featured_image: null,
      categories: [{tid: 0, title: 'Uncategorised'}],
      selectedCategory: 0,

      modalVisible: false,
      postModalVisible: false,
    };
  }

  componentDidMount() {
    this.getCategories();
    this.getToken();
  }

  getToken = () => {
    getToken().then(_response => {
      this.setState({
        token: _response.data,
      });
    });
  };

  getCategories = () => {
    getOnlineNode(
      'all_categories/noticeboard_categories',
      this.props.user.access_token,
    )
      .then(response => {
        let categories = [{tid: 0, title: 'Uncategorised'}];

        response.data.forEach(item => {
          categories.push(item);
        });

        this.setState({
          isComponentLoading: false,
          categories: categories,
        });
      })
      .catch(_error => {
        console.log('error: ', _error);

        this.setState({
          isComponentLoading: false,
        });
      });
  };

  setTitle = _title => {
    this.setState({
      title: _title,
    });
  };

  setBody = _body => {
    this.setState({
      body: _body,
    });
  };

  saveNode = () => {
    const {title, body, selectedCategory, featured_image_cropped} = this.state;

    this.setState({
      isLoading: true,
    });

    let category;
    if (selectedCategory && selectedCategory !== 0) {
      category = selectedCategory;
    }

    if (this.state.featured_image_cropped) {
      const imageData = {
        _links: {
          type: {
            href: `${serverUrl}/rest/type/file/image`
          }
        },
        filename: [
          {
            value: `noticeboard_cropped-${Date.now()}.jpg`
          }
        ],
        filemime: {
          value: 'image/jpg'
        },
        uri: [
          {
            value: `public://noticeboard_cropped-${Date.now()}.jpg`
          }
        ],
        type: {
          target_id: 'image'
        },
        data: [
          {
            value: featured_image_cropped
          }
        ],
        uid: [
          {
            target_id: this.props.user.current_user.uid ? this.props.user.current_user.uid : 1
          },
        ]
      };

      postBase64(
        imageData,
        serverUrl +
          '/entity/file?_format=hal_json',
        this.state.token,
        // this.props.user.access_token,
      )
        .then(response => {
          const fid = response.data.fid[0].value;

          const data = {
            _links: {
              type: {
                href: serverUrl + '/rest/type/node/noticeboard',
              },
            },
            type: {
              target_id: 'noticeboard',
            },
            title: {
              value: title,
            },
            body: {
              value: body,
            },
            field_featured_image: [
              {
                target_id: fid,
              },
            ],
            field_category: [
              {
                target_id: category,
              },
            ],
          };

          postNode(
            'node',
            data,
            this.state.token,
            this.props.user.access_token,
          )
            .then(_response => {
              console.log('response: ', _response.status);

              if (_response.status === 201) {
                this.setState({
                  isLoading: false,
                  postModalVisible: true,
                  alertTitle: alertMessages.noticeboadSubmission.title,
                  alertBody: alertMessages.noticeboadSubmission.message,
                });
              } else {
                this.setState({
                  isLoading: false,
                  modalVisible: true,
                  alertType: 'error',
                  alertTitle: alertMessages.postNodeFailed.title,
                  alertBody: alertMessages.postNodeFailed.message,
                  alertConfirmButton: true,
                });
              }
            })
            .catch(_error => {
              console.log('_error: ', _error);
              this.setState({
                isLoading: false,
                modalVisible: true,
                alertType: 'error',
                alertTitle: alertMessages.postNodeFailed.title,
                alertBody: alertMessages.postNodeFailed.message,
                alertConfirmButton: true,
                redirectBack: false,
              });
            });
        })
        .catch(error => {
          console.log('error: ', error);
          this.setState({
            isLoading: false,
            modalVisible: true,
            alertType: 'error',
            alertTitle: alertMessages.postNodeFailed.title,
            alertBody: alertMessages.postNodeFailed.message,
            alertConfirmButton: true,
            redirectBack: false,
          });
        });
    } else {
      const data = {
        _links: {
          type: {
            href: serverUrl + '/rest/type/node/noticeboard',
          },
        },
        type: {
          target_id: 'noticeboard',
        },
        title: {
          value: title,
        },
        body: {
          value: body,
        },
        field_category: [
          {
            target_id: category,
          },
        ],
      };

      postNode(
        'node',
        data,
        this.state.token,
        this.props.user.access_token,
      )
        .then(_response => {
          if (_response.status === 201) {
            this.setState({
              isLoading: false,
              postModalVisible: true,
              alertTitle: alertMessages.noticeboadSubmission.title,
              alertBody: alertMessages.noticeboadSubmission.message,
            });
          } else {
            this.setState({
              isLoading: false,
              modalVisible: true,
              alertType: 'error',
              alertTitle: alertMessages.postNodeFailed.title,
              alertBody: alertMessages.postNodeFailed.message,
              alertConfirmButton: true,
              redirectBack: false,
            });
          }
        })
        .catch(_error => {
          this.setState({
            isLoading: false,
            modalVisible: true,
            alertType: 'error',
            alertTitle: alertMessages.postNodeFailed.title,
            alertBody: alertMessages.postNodeFailed.message,
            alertConfirmButton: true,
            redirectBack: false,
          });
        });
    }
  };

  /**
   * @function handleCategorySelection
   * @description Modal Picker callback / Updates the header title and sets the state to the selected category
   * @param {object} event
   */
   handleCategorySelection = (event) => {
    this.setState({
      selectedCategory: parseInt(event.value, 10),
      selectedCategoryItem: event,
    });
  };

  setModalVisible = _visible => {
    this.setState({
      modalVisible: _visible,
    });
  };

  getCategoryTitle = () => {
    return this.state.categories.find(
      x => x.tid === this.state.selectedCategory,
    ).title;
  };

  errorAlerts = () => {
    return (
      <AlertModal 
        showAlert={this.state.modalVisible} 
        showAlertCallback={this.setModalVisible}
        alertType={this.state.alertType}
        alertMessageTitle={this.state.alertTitle}
        alertMessageBody={this.state.alertBody}
        cancelButton={this.state.alertCancelButton}
        confirmButton={this.state.alertConfirmButton}
        cancelButtonLabel={this.state.cancelButtonLabel}
        confirmButtonLabel={this.state.confirmButtonLabel}
      />
    )
  }

  saveAlerts = () => {
    return (
      <AlertModal 
        showAlert={this.state.postModalVisible} 
        showAlertCallback={() => {
          this.setState({
            postModalVisible: !this.state.postModalVisible,
          });
        }}
        alertType={'success'}
        alertMessageTitle={this.state.alertTitle}
        alertMessageBody={this.state.alertBody}
        cancelButton={false}
        confirmButton={true}
        confirmButtonLabel={'OK'}
        onConfirm={() => {
          this.props.history.push('/noticeboard');
        }}
      />
    );
  };

  render() {
    if (this.state.isComponentLoading) {
      return <NoticesEditSkeletonScreen />;
    } else {
      return (
        <main className="add-noticeboard notices screen">
          <PageHeader 
            pageName="Add a Noticeboard Item" 
            filters={false}
          />
          
          {this.errorAlerts()}
          {this.saveAlerts()}
  
          <div className="form-group">
            <Row className="mb-1">
              <Col>
                <label>Title</label>
              </Col>
            </Row>
  
            <Row>
              <Col>
                <input
                  type="text"
                  value={this.state.title}
                  placeholder="Add title"
                  className="form-control"
                  onInput={(event) => {
                    this.setTitle(event.target.value);
                  }}
                />
              </Col>
            </Row>
          </div>
  
          <div className="form-group">
            <Row className="mb-1">
              <Col>
                <label>Body</label>
              </Col>
            </Row>
  
            <Row>
              <Col>
                <textarea className="form-control" onChange={(event) => {
                  this.setBody(event.target.value);
                }} />
              </Col>
            </Row>
          </div>   
  
          <div className="form-group">
            <Row className="mb-1">
              <Col>
                <label>Select Category</label>
              </Col>
            </Row>
  
            <Row>
              <Col>
                <CategorySelector 
                  name="addnoticeboard"
                  categories={this.state.categories}
                  selectedCategory={this.state.selectedCategoryItem}
                  handleCategorySelection={this.handleCategorySelection}
                />
              </Col>
            </Row>
          </div>  
  
          <div className="form-group">
            <Row className="mb-2">
              <Col>
                <label>Featured Image</label>
              </Col>
            </Row>
  
            <Row>
              <Col xs={'auto'}>
                <input
                  ref={input => this.featuredInput = input}
                  type="file"
                  accept="image/*"
                  onChange={(event) => {
                    this.setState({
                      featured_image: event.target.files[0],
                    });

                    this.featuredInput.value = "";
                  }}
                  style={{display: 'none'}}
                />

                {!this.state.featured_image && (
                  <Button variant="link" onClick={() => {
                    this.featuredInput.click()
                  }}>
                    <Plus />
                    <span>Add image</span>
                  </Button>
                )}
              </Col>
            </Row>
          </div>  
          
          {this.state.featured_image && (
            <div className="form-group cropper-wrapper">
              <Row>
                <Col>
                  <Crop
                    file={this.state.featured_image}
                    ratio={16 / 9}
                    minWidth={960}
                    maxWidth={960}
                    minHeight={540}
                    maxHeight={540}
                    onCrop={(data) => {
                      this.setState({
                        featured_image_cropped: data
                      });
                    }}
                    onRemoveImage={() => {
                      this.setState({
                        featured_image: null,
                      });
    
                      this.featuredInput.value = "";
                    }}
                  />
                </Col>
              </Row>
            </div>
          )}
              
          <hr style={{margin: '3rem 0'}}/>
  
          <div className="form-group">
            <Row className="align-items-center justify-content-end">
              <Col xs={'auto'}>
                {this.state.isLoading ? (
                  <Spinner animation="border" role="status" variant="primary">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                ) : (
                  <Button
                    className="submit-btn"
                    onClick={() => {
                      this.saveNode();
                    }}
                  >
                    <span>Create Noticeboard Item</span>
                    <ArrowRight />
                  </Button>
                )}
              </Col>
            </Row>
          </div>
  
        </main>
      );
    }

  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default withRouter(connect(mapStateToProps)(AddNoticeboard));
