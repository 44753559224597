import React from "react";

// Modules
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// App
import PostTitle from "../../partials/postTitle";
import PostExcerpt from "../../partials/postExcerpt";
import LoadingSpinner from "../../partials/loadingSpinner";

// Data
// import db from "../../core/db";

// UI components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Bookmark, Tag } from "react-feather";

class NoticeboardsItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      favorite: "false",
    };
  }

  componentDidMount() {
    this.checkState();
  }

  checkState = () => {
    this.setState({
      favorite: this.props.item.favorite,
    });
  };

  /**
   * Favourite a noticeboard item and update the db
   *
   * @param {The node id} _nid
   */
  favorite = (_nid) => {
    this.setState({
      favorite: "true",
    });

    this.props.favorite(_nid);
  };

  /**
   * Unfavourite a noticeboard item and update the db
   *
   * @param {*} _nid
   */
  unfavorite = (_nid) => {
    this.setState({
      favorite: "false",
    });

    this.props.unfavorite(_nid);
  };

  /**
   * Handle item hover state
   * @returns
   */
  handleItemHover = (hover) => {
    this.setState({ isHovered: hover });
  };

  renderNoticeboardItem = () => {
    const itemClass = "noticeboard-item";
    const itemClassList = `${itemClass}  default-layout  item`;

    let category = "Uncategorised";
    if (this.props.item.categoryLabel) {
      category = this.props.item.categoryLabel;
    }

    let hasImage = this.props.item.field_featured_image.length > 0 ? true : false;
    hasImage = this.props.item.field_featured_image === "null" ? false : true;

    let itemStyle = hasImage ? itemClassList : `no-image ${itemClassList}`;
    let hoveredStyle = this.state.isHovered ? "hovered" : "";

    return (
      <Col
        key={"noticeboard" + this.props.item.nid}
        className="d-flex"
        xs={12}
        lg={6}
      >
        <div className={`${itemStyle} ${hoveredStyle}`}>
          <Row noGutters>
            {hasImage && (
              <Col xs={6}>
                <Link
                  to={"/noticeboard/" + this.props.item.nid}
                  className={`${itemClass}__image  item__image`}
                  onMouseEnter={() => this.handleItemHover(true)}
                  onMouseLeave={() => this.handleItemHover(false)}
                >
                  <img
                    src={this.props.item.field_featured_image}
                    alt={"img-" + this.props.item.nid}
                  />
                </Link>
              </Col>
            )}
            <Col>
              <Link
                to={"/noticeboard/" + this.props.item.nid}
                className={`${itemClass}__content  item__content`}
                onMouseEnter={() => this.handleItemHover(true)}
                onMouseLeave={() => this.handleItemHover(false)}
              >
                <PostTitle title={this.props.item.title} headingLevel={"h6"} />
                <PostExcerpt body={this.props.item.body} />
              </Link>
            </Col>
          </Row>
          <Row noGutters className="mt-auto">
            <Col>
            <footer className={`${itemClass}__footer  item__footer`}>
                <Row noGutters>
                  <Col className="category">
                    <Tag className="category__icon" />
                    <span className="category__text">{category}</span>
                  </Col>
                  <Col xs={"auto"} className="px-2">
                    {this.state.favorite === "true" ? (
                      <button
                        className="favourite-btn filled"
                        onClick={() => {
                          this.unfavorite(this.props.item.nid);
                        }}
                      >
                        <Bookmark />
                      </button>
                    ) : (
                      <button
                        className="favourite-btn"
                        onClick={() => {
                          this.favorite(this.props.item.nid);
                        }}
                      >
                        <Bookmark />
                      </button>
                    )}
                  </Col>
                </Row>
              </footer>
            </Col>
          </Row>
        </div>
      </Col>
    );
  };

  /**
   * @function render
   * @description Default render method
   */
  render() {
    if (this.state.isLoading) {
      return <LoadingSpinner />;
    } else {
      return this.renderNoticeboardItem();
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default connect(mapStateToProps)(NoticeboardsItem);
