import React, {useState} from 'react';
import AlertModal from './alertModal';

export default function ServiceWorkerUpdateNotification() {
  const [show, setShow] = useState(true);

  function updateApp() {
    window.location.reload();
  };

  return (
    <AlertModal 
      showAlert={show} 
      showAlertCallback={() => {
        setShow(!show);
      }}
      alertType={'success'}
      alertMessageTitle={'Update available'}
      alertMessageBody={'A new version of the app is available. Would you like to update it now?'}
      cancelButton={true}
      confirmButton={true}
      cancelButtonLabel={'No'}
      confirmButtonLabel={'Yes'}
      onConfirm={() => {
        updateApp();
      }}
    />
  );
}