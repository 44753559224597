import React from 'react';

// Modules
// import {connect} from 'react-redux';
// import {Redirect} from 'react-router'

// App
import {
  get_xcsrf_token
} from '../../core/auth';

import {customPost} from '../../core/postNode';
import PageHeader from '../../partials/pageHeader';
import {alertMessages} from '../../partials/alertMessages';
import AlertModal from '../../partials/alertModal';

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { User, Lock } from 'react-feather';
import { serverUrl } from '../../config';

class ResetPassword extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isLoading: true,
      isError: false,
      errorStatus: '',
      errorMessage: '',
      modalVisible: false,

      mail: '',
      temp_password: '',
      new_password: '',

      passChanged: false,
    };
  }

  componentDidMount() {
    this._getXCSRFToken();
  }

  _getXCSRFToken = () => {
    get_xcsrf_token()
      .then(response => {
        this.setState({
          isLoading: false,
          token: response.data,
        });
      })
      .catch(error => {
        this.setState({
          isLoading: false,
          modalVisible: true,
          alertType: 'error',
          alertTitle: error.response ? error.response.statusText : 'Error',
          alertBody: error.response ? error.response.data.message : 'Unknown error.',
          alertConfirmButton: true,
        });
      });
  };

  getTempPassword = () => {
    if (this.state.mail.length < 2) {
      this.setState({
        isLoading: false,
        modalVisible: true,
        alertType: 'error',
        alertTitle: 'Error',
        alertBody: 'Please make sure you entered a valid email address.',
        alertConfirmButton: true,
      });

      return false;
    }

    const data = {
      mail: this.state.mail,
    };

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    customPost(serverUrl + '/user/lost-password?_format=json', data, headers)
      .then(_response => {
        this.setState({
          isLoading: false,
          modalVisible: true,
          alertType: 'success',
          alertTitle: alertMessages.passwordEmailSent.title,
          alertBody: alertMessages.passwordEmailSent.message,
          alertConfirmButton: true,
        });

        this.setState({
          temp_password_sent: true,
        });
      })
      .catch(_error => {
        if (_error.response.status === 400) {
          this.setState({
            isLoading: false,
            modalVisible: true,
            alertType: 'error',
            alertTitle: 'Error',
            alertBody: 'User not found. Please make sure you entered the correct email address.',
            alertConfirmButton: true,
          });
        } else {
          this.setState({
            isLoading: false,
            modalVisible: true,
            alertType: 'error',
            alertTitle: 'Error',
            alertBody: 'Unknown error. Please try again.',
            alertConfirmButton: true,
          });
        }

        this.setState({
          temp_password_sent: false,
        });
      });
  };

  resetPassword = () => {
    const data = {
      name: this.state.mail,
      temp_pass: this.state.temp_password,
      new_pass: this.state.new_password,
    };

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    customPost(
      serverUrl + '/user/lost-password-reset?_format=json',
      data,
      headers,
    )
      .then(_response => {
        this.setState({
          isLoading: false,
          modalVisible: true,
          alertType: 'success',
          alertTitle: alertMessages.passwordChangedSuccess.title,
          alertBody: alertMessages.passwordChangedSuccess.message,
          alertConfirmButton: true,
          passChanged: true,
        });
      })
      .catch(_error => {
        this.setState({
          isLoading: false,
          modalVisible: true,
          alertType: 'error',
          alertTitle: 'Error',
          alertBody: 'Unknown error. Please try again.',
          alertConfirmButton: true,
        });
      });
  };

  render() {
    return (
      <main ref={this.containerRef} className="login  screen">
        <Row noGutters>
          <PageHeader pageName="Reset Password" filters={false} />
        </Row>
        <Row>
          <Col xs={12} lg={4}>
            <form onSubmit={this._doLogin}>
              <div className="form-group mb-0">
                <p>
                  {
                    "Insert your email and click the 'Request Temporary Password' button. If your email is associated with an account in the system - you will recieve an email with a temporary password."
                  }
                </p>
              </div>
              <div className="form-group mb-0">
                <Row>
                  <Col className="d-flex  align-items-center mb-1">
                    <User className={"icon"} />
                    <label>{"Email"}</label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <input
                      type={"text"}
                      id={"email"}
                      placeholder={"Enter your email"}
                      value={this.state.username}
                      onInput={(e) => {
                        this.setState({
                          mail: e.target.value,
                        });
                      }}
                      className="form-control mb-3"
                    />
                  </Col>
                </Row>
              </div>
              <div className="form-group mb-4">
                <Row>
                  <Col className="d-flex  align-items-center mb-1">
                    <Button
                      disabled={this.state.temp_password_sent ? true : false}
                      onClick={this.getTempPassword}>
                        {alertMessages.request + ' ' + alertMessages.temp_password}
                    </Button>
                  </Col>
                </Row>
              </div>
              <div className="form-group mb-4">
                <Row>
                  <Col className="d-flex  align-items-center mb-1">
                    <Lock className={"icon"} />
                    <label>{"Temporary Password"}</label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <input
                      type={"text"}
                      id={"temp_pass"}
                      placeholder={"Enter your temporary password"}
                      value={this.state.password}
                      onInput={(e) => {
                        this.setState({
                          temp_password: e.target.value,
                        });
                      }}
                      className="form-control"
                    />
                  </Col>
                </Row>
              </div>

              {this.state.temp_password.length > 0 && (
                <>
                  <div className="form-group  mb-4">
                    <Row>
                      <Col className="d-flex  align-items-center mb-1">
                        <Lock className={"icon"} />
                        <label>{"New Password"}</label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <input
                          type={"password"}
                          id={"newpassword"}
                          placeholder={"Enter your new password"}
                          value={this.state.password}
                          onInput={(e) => {
                            this.setState({
                              new_password: e.target.value,
                            });
                          }}
                          className="form-control"
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group mb-4">
                    <Row>
                      <Col className="d-flex  align-items-center mb-1">
                        <Button
                          onClick={this.resetPassword}>
                            {alertMessages.change_password}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </>
              )}

            </form>
          </Col>
        </Row>

        {/* Alert error modal */}
        <AlertModal 
          showAlert={this.state.modalVisible} 
          showAlertCallback={this.setModalVisible}
          alertType={this.state.alertType}
          alertMessageTitle={this.state.alertTitle}
          alertMessageBody={this.state.alertBody}
          cancelButton={this.state.alertCancelButton}
          confirmButton={this.state.alertConfirmButton}
          onCancel={() => {
            this.setState({
              modalVisible: false,
            })
          }}
          onConfirm={() => {
            this.setState({
              modalVisible: false,
            })

            if (this.state.passChanged) {
              this.props.history.push('/login');
            }
          }}
        />
      </main>
    );
  }
}


export default ResetPassword;
