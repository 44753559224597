import React from "react";

// Modules
import parse from 'html-react-parser';

const regex = /(<([^>]+)>)/gi;

export default class PostExcerpt extends React.Component {
  render() {
    let defaultStyle = 'excerpt text-break';
    let style = this.props.className ? `${this.props.className} ${defaultStyle}` : defaultStyle;

    let EXCERPT_MAX_LENGTH;
    if (this.props.maxLength) {
      EXCERPT_MAX_LENGTH = this.props.maxLength;
    }

    if (!this.props.body) {
      return null;
    }

    let excerpt = this.props.body;
    excerpt = excerpt.replace(regex, "").trimEnd();

    if (excerpt.length > EXCERPT_MAX_LENGTH) {
      excerpt = excerpt.substring(0, EXCERPT_MAX_LENGTH);
      excerpt = excerpt.substring(0, excerpt.lastIndexOf(" "));
      excerpt += "...";
    }

    return (
      <div
        dangerouslySetInnerHTML={{ __html: parse(excerpt) }}
        className={style}
      />
    );
  }
}
