// Dependencies
import React from 'react';

// Modules
import {withRouter} from 'react-router'
import {connect} from 'react-redux';
import TimeAgo from 'react-timeago'
import moment from 'moment';

// App
import {serverUrl} from '../config';
import {getOnlineNode} from '../core/getNode';
import {postNode} from '../core/postNode';
import {deleteRequest} from '../core/delete';
import {alertMessages} from './alertMessages'
import UserImage from './userImage';
import AlertModal from './alertModal'


// UI components
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner'
import {Clock} from 'react-feather';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faThumbsUp as faThumbsUpSolid}  from '@fortawesome/free-solid-svg-icons';
import {faThumbsUp as faThumbsUpRegular} from '@fortawesome/free-regular-svg-icons';

class Comment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isReactionsLoading: true,
      likes: 0,
      hasReacted: false,
      reactionId: 'none',
      modalVisible: false,
    };
  }

  componentDidMount() {
    this.getReactions();
  }

  /**
   * Set modal visibility
   * @param {*} visible 
   */
   setModalVisible = (visible) => {
    this.setState({
      modalVisible: visible,
    });
  };
  
  getReactions = () => {
    const comment = this.props.comment;
    let hasReacted = false;
    let reactionId = 'none';

    if (comment) {
      const path = 'comment_likes/' + comment.cid;

      getOnlineNode(path, this.props.user.access_token)
        .then(response => {
          let likes = 0;

          if (response.data.rows.length > 0) {
            response.data.rows.forEach(like => {
              likes = likes + 1;

              if (
                like.user_id ===
                this.props.user.current_user.uid
              ) {
                hasReacted = true;
                reactionId = like.id;
              }

              this.setState({
                hasReacted: hasReacted,
                reactionId: reactionId,
                likes: likes,
                isReactionsLoading: false,
              });
            });
          } else {
            this.setState({
              likes: 0,
              hasReacted: false,
              reactionId: 'none',
              isReactionsLoading: false,
            });
          }
        })
        .catch(_error => {
          console.log('Failed to get likes: ', _error);
          this.setState({
            hasReacted: hasReacted,
            reactionId: reactionId,
            isReactionsLoading: false,
          });
        });
    }
  };

  addReaction = () => {
    const comment = this.props.comment;

    if (comment.uid === this.props.user.current_user.uid) {
      this.setState({
        modalVisible: true,
        alertType: 'error',
        alertTitle: alertMessages.cannotReactOwnComment.title,
        alertBody: alertMessages.cannotReactOwnComment.message,
        alertConfirmButton: true,
      });

      return false;
    }

    const data = {
      _links: {
        type: {
          href: serverUrl + '/rest/type/vote/comment_likes',
        },
      },
      entity_id: [
        {
          target_id: this.props.comment.cid,
        },
      ],
      entity_type: [
        {
          value: 'comment',
        },
      ],
    };

    postNode(
      'entity/vote',
      data,
      this.props.user.csrf_token,
      this.props.user.access_token,
    )
      .then(response => {
        this.getReactions();
      })
      .catch(_error => {
        console.log('@addReaction [comment]: ', _error);
        this.setState({
          isLoading: false,
          modalVisible: true,
          alertType: 'error',
          alertTitle: alertMessages.cannotReact.title,
          alertBody: alertMessages.cannotReact.message,
          alertConfirmButton: true,
        });
      });
  };

  removeReaction = eid => {
    deleteRequest(
      'entity/vote/' + eid,
      this.props.user.csrf_token,
      this.props.user.access_token,
    )
      .then(response => {
        this.getReactions();
      })
      .catch(_error => {
        console.log('@_error: ', _error);
      });
  };

  renderReactions = () => {
    const {hasReacted, reactionId, likes} = this.state;

    /**
     * Reaction counter style logic
     */
    let reactionCounterLikeStyles = 'comment_reactions_counter comment_reactions_like_counter'

    if (hasReacted === true) {
      reactionCounterLikeStyles += ' reacted';
    }

    return (
      <div className='comment_reactions'>
        {this.state.isReactionsLoading ? (
          <Spinner animation="border" role="status" variant="primary">
            <span className="sr-only">Loading...</span>
          </Spinner>
        ) : (
          <Button
            className={hasReacted ? 'like reacted' : 'like'} 
            onClick={() => {
              if (hasReacted) {
                this.removeReaction(reactionId);
              } else {
                this.addReaction();
              }
            }}
          >
            <span className='comment_reactions_icon_container'>
              {hasReacted ? (
                <FontAwesomeIcon size='sm' icon={faThumbsUpSolid} />
              ) : (
                <FontAwesomeIcon size='sm' icon={faThumbsUpRegular} />      
              )}
            </span>
            <span className={reactionCounterLikeStyles}>{likes}</span>
          </Button>
        )}
      </div>
    );
  };

  render() {
    if (this.state.isLoading) {
      return (
        <Spinner animation="border" role="status" variant="primary">
          <span className="sr-only">Loading...</span>
        </Spinner>
      )
    } else {
      const comment = this.props.comment;
      const commentTimestamp = moment.unix(comment.created);
    
      // username
      let user = comment.name;

      // first name
      if (comment.field_first_name) {
        user = comment.field_first_name;
      }

      // Concat first & last name
      if (
        comment.field_first_name &&
        comment.field_last_name
      ) {
        user =
        comment.field_first_name +
          ' ' +
          comment.field_last_name;
      }

      return (
        <div key={'comment-' + this.props.index} className="comment">
          <div className="comment__header  d-flex align-items-center">
            <UserImage className="comment__image" image={comment.user_picture} />
            <h6 className="comment__user  mb-0">{user}</h6>
            <div className="timestamp  ml-auto  align-items-center">
              <Clock className="timestamp__icon" />
              <TimeAgo date={commentTimestamp} className="timestamp__text" />
            </div>
          </div>
          <div dangerouslySetInnerHTML={{__html: comment.comment_body}} className="comment__body"/>
          {this.renderReactions()}

          <AlertModal 
            showAlert={this.state.modalVisible} 
            showAlertCallback={this.setModalVisible}
            alertType={this.state.alertType}
            alertMessageTitle={this.state.alertTitle}
            alertMessageBody={this.state.alertBody}
            cancelButton={this.state.alertCancelButton}
            confirmButton={this.state.alertConfirmButton}
          />
        </div>
      );
    }
  }

}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default withRouter(connect(mapStateToProps)(Comment));
