import Dexie from "dexie";

const db = new Dexie("Companyapp");

db.version(1).stores({
  news: "++nid, title, body, created, categoryID, categoryLabel, field_featured_image, sticky, favorite, feature",
  event:
    "++nid, title, body, created, categoryID, categoryLabel, field_featured_image, sticky, favorite, eventAddress, eventPhone, eventEmail, eventStartDate, eventStartTime, eventEndDate, eventEndTime, eventWebsite",
  blog: "++id, title, content, date, categoryID, categoryLabel, uri, sticky, favorite",
  course:
    "++nid, title, body, created, categoryID, categoryLabel, field_featured_image, sticky, favorite",
  courseProgress: "++nid, opened, complete",
  quiz: "++nid, title, body, created, categoryID, categoryLabel, field_featured_image, sticky, favorite",
  quizAnswers: "++id, parent_id, rows",
  quizQuestions: "++id, parent_id, rows",
  quizSubmissions: "++id",
  poll: "++nid, title, body, created, categoryID, categoryLabel, field_image, sticky, favorite",
  form: "++nid, title, body, created, categoryID, categoryLabel, field_featured_image, sticky, favorite, viewNode",
  document:
    "++nid, title, body, created, categoryID, categoryLabel, uri, sticky, favorite",
  video:
    "++nid, title, body, created, categoryID, categoryLabel, field_featured_image, uri, sticky, favorite",
  gallery:
    "++nid, title, body, created, categoryID, categoryLabel, field_featured_image, sticky, favorite, name",
  company_directory:
    "++nid, title, body, created, categoryID, categoryLabel, sticky, favorite, abbreviation, abbreviationColour, address, startTime, endTime",
  contact:
    "++uid, name, firstName, lastName, email, phoneNumber, userPicture, jobTitle, department, location, role, emailNotifications, pushNotifications, showInfo, favorite",
  link: "++nid, title, body, created, categoryID, categoryLabel, sticky, favorite, url",
  noticeboard:
    "++nid, title, body, created, categoryID, categoryLabel, field_featured_image, sticky, favorite",
  contentCounter: "++content_type, serverCount, previousCount",
  messages:
    "++nid, body, created, field_read, from_first_name, from_last_name, from_name, from_uid, recipient_uid, recipient_first_name, recipient_last_name, recipient_name",
  conversations:
    "++nid, field_first_name, field_first_name_1, field_last_name, field_last_name_1, field_user_1, field_user_2, name, name_1, user_picture, user_picture_1, from_uid, body, created, unread_count",
  forum:
    "++tid, title, description, favorite",
  podcast:
    "++nid, title, body, created, categoryID, categoryLabel, field_featured_image, uri, sticky, favorite",
  notices:
    "++nid, title, field_body, field_notice_document, groupID, groupLabel, created, uid, favorite, sticky, field_feature, field_allow_comments, field_allow_reactions, changed",
  submission: "++nid, title, body, field_image, webform",
});

export default db;
