// Dependencies
import React from 'react';

// Modules
import {withRouter} from 'react-router';
import {connect} from 'react-redux';

// App
import {getOnlineNode} from '../../core/getNode';
import PostTitle from '../../partials/postTitle';
import UserImage from '../../partials/userImage';
import { alertMessages } from '../../partials/alertMessages';
import Error from '../../partials/error';
import MessageForm from '../../partials/messageForm';
import ContactsSkeletonSingleScreen from './contactsSkeletonSingleScreen';

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import {Mail, MessageSquare, Phone} from 'react-feather';

class ContactsSingle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isPaginating: false,
      post: null,
      pageSize: 10,
      currentPage: 0,
      total_pages: 0,
    };
  }

  componentDidMount() {
    this.checkSession();
  }
  
  checkSession = () => {
    if (Object.keys(this.props.user).length === 0) {
      this.props.history.push('/');
    } else {
      this.loadNode();
    }
  }


  /**
   * @function setData
   * @description Updates the state of the component upon data retrival
   * @param {object} _data
   * @param {object} _realm
   */
  setData = (_data) => {
    this.setState(
      {
        isLoading: false,
        isRefreshing: false,
        isError: false,
        post: _data,
      },
    );
  };

  /**
   * @function setError
   * @description Updates the state of the component upon an error
   * @param {boolean} _isError
   * @param {int} _errorStatus
   * @param {string} _errorMessage
   */
  setError = (_isError, _errorStatus, _errorMessage) => {
    this.setState({
      isLoading: false,
      isRefreshing: false,
      isError: _isError,
      errorStatus: _errorStatus,
      errorMessage: _errorMessage,
    });
  };


  loadNode = () => {
    const uid = this.props.match.params.uid;

    if (uid) {
      this.getContent(uid);
    } else {
      this.setError(true, 0, alertMessages.contentError);
    }
  };

  /**
   * @function getContent
   * @description Retrieves the data from an API / Fallback to local realm object if there is no connection
   */
  getContent = (uid) => {
    let path = 'custom_user/' + uid + '?_format=json&status=1&promote=1';
    // let path = 'custom_user/?name=' + uid + '&_format=json&status=1&promote=1';

    getOnlineNode(path, this.props.user.access_token)
      .then((response) => {
        if (response.data.rows.length > 0) {
          this.setData(response.data.rows[0]);
        } else {
          this.setError(true, 200, alertMessages.noData);
        }
      })
      .catch((_error) => {
        console.log('error: ', _error);
        if (_error.response) {
          this.setError(
            true,
            _error.response.status,
            _error.response.statusText,
          );
        } else if (_error.request) {
          //console.log('@rest request: ', _error.request);
          this.setError(true, 0, alertMessages.requestError.message);
        } else {
          //console.log('@rest unknown: ', _error);
          this.setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };

  loadMore = () => {
    this.setState(
      {
        isPaginating: true,
        currentPage: this.state.currentPage + 1,
      },
    );
  };

  renderPostImage = (post) => {
    let hasImage = post.user_picture.length > 0 ? true : false;

    if (hasImage && !this.state.isLandscape) {
      return (
        <div className='article__image'>
          <img src={post.user_picture} alt={'img-' + post.uid} />
        </div>
      );
    }
  };

  renderContactName = (post) => {
    // username
    let contactName = post.name;

    // first name
    if (post.field_first_name) {
      contactName = post.field_first_name;
    }

    // Concat first & last name
    if (post.field_first_name && post.field_last_name) {
      contactName =
      post.field_first_name + ' ' + post.field_last_name;
    }

    return (
      <PostTitle title={contactName} headingLevel={'h1'} />
    );
  }

  render() {
    if (this.state.isLoading) {
      return <ContactsSkeletonSingleScreen />;
    } else {
      if (this.state.isError) {
        return (
          <Error 
            status={this.state.errorStatus}
            message={this.state.errorMessage}
          />
        );
      } else {
        const {post} = this.state;

        return (
          <main className='contact  single'>
            <article className='article'>
              <header className='contact__header  article__header'>
                <Row className='align-items-center'>
                  <Col xs={12} lg={'auto'}>
                    <UserImage image={post.user_picture} />
                  </Col>
                  <Col className='text-center text-lg-left'>
                    {this.renderContactName(post)}
                    <h3>{post.field_job_title}</h3>
                  </Col>
                </Row>
              </header>
              <section className='contact__body'>
                <Row>
                  <Col xs={12} lg={6} className="mb-4 mb-lg-0">
                    <div className='contact__group--email  contact__group'>
                      <h6>Email</h6>
                      <p className='text-break'>{post.mail}</p>
                      <Button href={`mailto: ${post.mail}`} variant='primary'>
                        <Mail/>
                      </Button>
                    </div>
                  </Col>

                  {post.phone_number && (
                    <Col xs={12} lg={6}>
                      <div className='contact__group--phone contact__group'>
                        <h6>Phone</h6>
                        <p className='text-break'>{post.phone_number}</p>
                        <Row>
                          <Col xs={'auto'}>
                            <Button href={`tel: ${post.phone_number}`} variant='secondary'>
                              <Phone />
                            </Button>
                          </Col>
                          <Col xs={'auto'}>
                            <Button href={`sms: ${post.phone_number}`} variant='gradient-pink'>
                              <MessageSquare />
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  )}
                </Row>

                {this.props.user.current_user.uid !== post.uid && (
                  <div className='contact__message'>
                    <MessageForm
                      uid={this.props.user.current_user.uid}
                      sendto={this.props.match.params.uid}
                      onMessageSent={this.onMessageSent}
                    />
                  </div>
                )}

              </section>
            </article>
          </main>
        );
      }
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default withRouter(connect(mapStateToProps)(ContactsSingle));
