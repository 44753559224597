import {api} from '../config';
import axios from 'axios';

const blogApi = axios.create({
  baseURL: 'https://companyapp.co.uk/',
  headers: {
    'Content-Type': 'application/json',
  },
});

export const getOnlineNode = (_endpoint, _accessToken) => {
  if (_accessToken) {
    return api({
      method: 'get',
      url: _endpoint,
      headers: {
        Authorization: 'Bearer ' + _accessToken,
        'Cache-Control': 'no-cache',
      },
    });
  } else {
    return api({
      method: 'get',
      url: _endpoint,
      headers: {
        'Cache-Control': 'no-cache',
      },
    });
  }
};

export const getExternal = (_endpoint, _method) => {
  const method = _method ? _method : 'get';
  return blogApi({
    method: method,
    url: _endpoint,
    withCredentials: false,
  });
};

export const getFile = (_endpoint, _accessToken) => {
  if (_accessToken) {
    return api({
      method: 'get',
      url: _endpoint,
      headers: {
        Authorization: 'Bearer ' + _accessToken,
        'Cache-Control': 'no-cache',
      },
      // responseType: 'blob'
    });
  } else {
    return api({
      method: 'get',
      url: _endpoint,
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }
};