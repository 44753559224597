import React from 'react';

// Modules
import {connect} from 'react-redux';
import moment from 'moment';

// App
import EventsItem from './eventsItem';
import { getOnlineNode } from '../../core/getNode';
import { paginator } from '../../core/paginator';
import Pagination from '../../core/pagination';
import PageHeader from '../../partials/pageHeader';
import CategorySelector from '../../partials/categorySelector';
import {alertMessages} from '../../partials/alertMessages';
import Error from '../../partials/error';
import {_checkContent} from '../../core/checkContent';
import EventsSkeletonScreen from './eventsSkeletonScreen';
import NoData from '../../partials/noData';


// Data
import db from './../../core/db';
import {getContentCount} from '../../core/getRecordCount';
import {updateContentCounterPreviousCount} from '../../core/updateData';
import {getFavorites, getFavouritesLength} from './../../core/getFavorites';

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Calendar, Sun } from 'react-feather';
import { Button } from 'react-bootstrap';

class Events extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      isRefreshing: false,
      isError: false,

      errorStatus: '',
      errorMessage: '',

      // currentPage: 1,
      // pageSize: 20,
      // index: 0,
      // totalItems: 0,

      // Settings for 'load more' events
      pageSize: 10,
      currentPage: 0,
      index: 0,
      total_pages: 0,

      selectedCategory: 0,
      categories: [],
      token: '',

      data: [],
      favorites: [],

      activeIndex: 0,
    };

    this.onPaginate = this.onPaginate.bind(this);
  }

  /**
   * @function componentDidMount
   * @description Default react method. Fired when component is rendered
   */
   componentDidMount() {
    this.checkSession();
  }
  
  checkSession = () => {
    if (Object.keys(this.props.user).length === 0) {
      this.props.history.push('/');
    } else {
      this.getCategories();
      this.getContent();
    }
  }

  /**
   * @function setData
   * @description Updates the state of the component upon data retrival
   * @param {object} _data
   * @param {object} _realm
   */
  setData = async (_data) => {
    this.setState({
      isLoading: false,
      isRefreshing: false,
      isPaginating: false,
      // data: _data.rows,
      // totalItems: _data.pager.total_items,
      data:
        this.state.currentPage === 0
          ? _data.rows
          : [...this.state.data, ..._data.rows],
      total_pages: _data.pager.total_pages - 1,
    });

    const serverCount = await getContentCount('event')
    
    if (serverCount && serverCount[0]) {
      const count = serverCount[0] ? serverCount[0].serverCount : 0;
      updateContentCounterPreviousCount('event', count)
    }

    _checkContent();
  };

  /**
   * @function setError
   * @description Updates the state of the component upon an error
   * @param {boolean} _isError
   * @param {int} _errorStatus
   * @param {string} _errorMessage
   */
  setError = (_isError, _errorStatus, _errorMessage) => {
    this.setState({
      isLoading: false,
      isRefreshing: false,
      isPaginating: false,
      isError: _isError,
      errorStatus: _errorStatus,
      errorMessage: _errorMessage,
    });
  };

  favorite = async (_nid) => {
    const {activeIndex} = this.state;

    let event = await db.event.get(_nid);
    event.favorite = 'true';
    db.event.put(event, _nid);

    if (activeIndex === 0) {
      this.getContent();
    }

    if (activeIndex === 1) {
      this.getFavourites();
    }
  }

  unfavorite = async (_nid) => {
    const {activeIndex} = this.state;
  
    let event = await db.event.get(_nid);
    event.favorite = 'false';
    db.event.put(event, _nid);

    if (activeIndex === 0) {
      this.getContent();
    }

    if (activeIndex === 1) {
      this.getFavourites();
    }
  }

  dataHandler = async (_data) => {
    let events = [];

    for (const data of _data.rows) {
      let existingEvent = await db.event.get(data.nid);
      let favorite = 'false';

      if (existingEvent) {
        favorite = existingEvent.favorite;
      }
      
      const event = {
        nid: data.nid,
        title: data.title,
        body: data.body,
        created: data.created,
        categoryID: data.field_category,
        categoryLabel: data.field_category_1,
        sticky: 'false',
        favorite: favorite,
        field_featured_image: data.field_featured_image.length > 0 ? data.field_featured_image : 'null',
        eventStartDate: data.field_start_date.replace(/\n|\r/g, ""),
        eventStartTime: data.field_start_time,
        eventEndDate: data.field_end_date.replace(/\n|\r/g, ""),
        eventEndTime: data.field_end_time,
        eventAddress: data.field_address,
        eventEmail: data.field_email,
        eventPhone: data.field_contact_number,
        eventWebsite: data.field_website,
      };

      db.event.put(event, data.nid);

      events.push(event);
    }

    let data = _data;

    data.rows = events;

    this.setData(data);
  } 

  /**
   * @function getContent
   * @description Retrieves the data from an API / Fallback to local realm object if there is no connection
   */
  getContent = () => {
    if (this.state.currentPage > this.state.total_pages) {
      return;
    }

    let path =
      'events/all/all?status=1&promote=1&' +
      'items_per_page=' +
      this.state.pageSize +
      '&page=' +
      // (this.state.currentPage - 1);
      (this.state.currentPage);

    const tid = this.state.selectedCategory;

    if (tid !== 0) {
      path =
        'events/' +
        tid +
        '/all' +
        '?status=1&promote=1&' +
        'items_per_page=' +
        this.state.pageSize +
        '&page=' +
        // (this.state.currentPage - 1);
        (this.state.currentPage);
    }

    getOnlineNode(path, this.props.user.access_token)
      .then((response) => {
        this.dataHandler(response.data);
      })
      .catch((_error) => {
        console.log('error: ', _error);
        if (_error.response) {
          //console.log('@rest response: ', _error.response);
          this.setError(
            true,
            _error.response.status,
            _error.response.statusText,
          );
        } else if (_error.request) {
          //console.log('@rest request: ', _error.request);
          this.setError(true, 0, alertMessages.requestError.message);
        } else {
          //console.log('@rest unknown: ', _error);
          this.setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };

  /**
   * @function getFavourites
   * @description Retrieves the favourited data from the database
   */
   getFavourites = async () => {
    const favorites = await getFavorites('event', this.state.selectedCategory, this.state.index, this.state.pageSize);
    const favoritesLength = await getFavouritesLength('event', this.state.selectedCategory);

    this.setState({
      data: favorites,
      totalItems: favoritesLength,
      isLoading: false,
      isRefreshing: false,
      isPaginating: false,
    });
  }

  /**
   * @function getCategories
   * @description Retrives the category data from an API / Fallback to local realm object if there is no connection
   */
  getCategories = () => {
    getOnlineNode('all_categories/event_c', this.props.user.access_token)
      .then((response) => {
        this.renderCategories(response.data);
      })
      .catch((_error) => {
        console.log("error: ", _error);
        if (_error.response) {
          this.setError(
            true,
            _error.response.status,
            _error.response.statusText,
          );
        } else if (_error.request) {
          //console.log('@rest request: ', _error.request);
          this.setError(true, 0, alertMessages.requestError.message);
        } else {
          //console.log('@rest unknown: ', _error);
          this.setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };

  /**
   * @function onPaginate
   * @description Pagination callback
   * @param {int} _index - Page number
   */
  onPaginate = (_index) => {
    this.setState(
      {
        isLoading: true,
        isPaginating: true,
        currentPage: _index,
      },
      function() {
        this.getContent();
      },
    );
  };

  /**
   * @function renderCategories
   * @description Updates the state with the retrived categories
   * @param {object} _categories
   */
  renderCategories = (_data) => {
    let categories = [
      {
        value: 0,
        label: 'All Events',
      },
    ];

    _data.forEach((item) => {
      categories.push({
        value: item.tid,
        label: item.title,
      });
    });

    this.setState({
      categories: categories,
    });
  };

  /**
   * @function handleCategorySelection
   * @description Modal Picker callback / Updates the header title and sets the state to the selected category
   * @param {string} _id - Category ID
   */
  handleCategorySelection = (event) => {
    const {activeIndex} = this.state;
    this.setState(
      {
        // experimental
        isLoading: true,
        isPaginating: true,
        isError: false,
        currentPage: 1,
        selectedCategory: parseInt(event.value, 10),
        selectedCategoryItem: event,
      },
      function () {
        if (activeIndex === 0) {
          this.getContent();
        }

        if (activeIndex === 1) {
          this.getFavourites()
        }
      },
    );
  };

  // Pagination component
  renderPagination = () => {
    if (this.state.totalItems <= this.state.pageSize) {
      return null;
    } else {
      let pagination = paginator(
        this.state.totalItems,
        this.state.currentPage,
        this.state.pageSize,
        3
      );

      return (
        <Pagination
          currentPage={pagination.currentPage}
          endIndex={pagination.endIndex}
          endPage={pagination.endPage}
          pageSize={pagination.pageSize}
          pages={pagination.pages}
          startIndex={pagination.startIndex}
          startPage={pagination.startPage}
          totalItems={pagination.totalItems}
          totalPages={pagination.totalPages}
          paginationCallback={this.onPaginate}
        />
      );
    }
  };

  loadMore = () => {
    this.setState(
      {
        isLoading: true,
        isPaginating: true,
        currentPage: this.state.currentPage + 1,
      },
      function () {
        this.getContent();
      },
    );
  };

  renderLoadMore = () => {
    if (this.state.currentPage < this.state.total_pages) {
      return (
        <div className="d-flex justify-content-center">
          <Button onClick={() => {
            if (!this.state.isPaginating) {
              this.loadMore();
            }
          }}>
            {'Load more'}
          </Button>
        </div>
      );
    } else {
      return null;
    }
  };

  /**
   * Filter event data into year and month blocks
   * @returns 
   */
  filterEventsByYear = () => {
    const months = moment.months();

    // (1) Get the year of each event
    const allEventYears = [];
    this.state.data.map((item) => {
      const year = moment.unix(item.eventStartDate).format('YYYY');
      return allEventYears.push(year);
    });

    // (2) Remove duplicate years from array
    const removeDuplicateYears = allEventYears.filter((item, index) => allEventYears.indexOf(item) === index);

    // (3) Add months to each year
    const eventYears = [];
    removeDuplicateYears.map((item) => {
      return eventYears.push({
        year: item,
        months: months.map((monthItem) => {
          return {
            month: monthItem,
            events: [],
          }
        })
      });
    });

    // (4) Assign event data to month & year
    this.state.data.map((item) => {

      const eventItemMonth = moment.unix(item.eventStartDate).format('MMMM');
      const eventItemYear = moment.unix(item.eventStartDate).format('YYYY');

      // Filter calendar year array by event item's month 
      return eventYears.map((yearItem) => {

        if (yearItem.year === eventItemYear) {
          // Filter calendar year array by event item's month 
          return yearItem.months.map((monthItem) => {
            if (monthItem.month === eventItemMonth) {
              // Then add item to the events array of the month in eventYears
              return monthItem.events.push(item);

              // const sortDate = monthItem.events.sort(
              //   (a, b) =>
              //     moment.unix(a.eventStartDate, moment.defaultFormat).toDate() -
              //     moment.unix(b.eventStartDate, moment.defaultFormat).toDate()
              // );
              // console.log('@events monthItem.events sorted:', sortDate);
              
            } else {
              return false;
            }
          });
        } else {
          return false;
        }
      });

    });

    // (5) Render event data
    return this.renderEventsByYear(eventYears);

  };

  /**
   * Render data as month blocks within year blocks
   * @param {*} eventYears 
   * @returns 
   */
  renderEventsByYear = (eventYears) => {

    return eventYears.map((item, index) => {

      const hasEvents = item.months.map((month) => month.events.length > 0);

      let currentYear = moment().year();
      currentYear = currentYear.toString();
      const isCurrentYear = item.year === currentYear ? true : false;

      if (hasEvents) {
        return (
          <section key={index} className="year">
            {!isCurrentYear && (
              <header className="year__header">
                <h4>{item.year}</h4>
              </header>
            )}
            {item.months.map((monthItem, index) => {
              if (monthItem.events.length > 0) {
                return ( 
                  <div key={index} className="month">
                    <header className="month__header">
                      <Row noGutters className="align-items-center">
                        <Col xs={"auto"} className="d-flex">
                          <Calendar />
                        </Col>
                        <Col>
                          <h4>{monthItem.month + ' ' + item.year}</h4>
                        </Col>
                      </Row>
                    </header>
                    <Row className="ml-lg-3">
                      {monthItem.events.map((eventItem) => {
                        return (
                          <EventsItem
                            key={'event' + eventItem.nid}
                            item={eventItem}
                            nid={eventItem.nid}
                            favorite={this.favorite}
                            unfavorite={this.unfavorite}
                          />
                        );
                      })}
                    </Row>
                  </div>
                );
              } else {
                return false;
              }
            })}
          </section>
        );
      } else {
        return false;
      }
    });

  };

  // Data component
  dataComponent = () => {
    const {data} = this.state;
  
    if (typeof data !== 'undefined' && data.length > 0) {
      const timestamp = moment().format('dddd Do MMM YYYY');

      return (
        <>
          <Row>
            <Col>
              <div className="events__today">
                <Sun />
                <h5>
                  Today is <strong>{timestamp}</strong>
                </h5>
              </div>
            </Col>
          </Row>
          {this.filterEventsByYear()}
        </>
      );
    } else {
      return (
        <NoData activeIndex={this.state.activeIndex} />
      );
    }
  };

  /**
   * @function render
   * @description Default render method
   */
  render() {
    if (this.state.isLoading) {
      return <EventsSkeletonScreen />;
    } else {
      if (this.state.isError) {
        return (
          <Error 
            status={this.state.errorStatus}
            message={this.state.errorMessage}
          />
        );
      } else {
        return (
          <main className="events  screen">
            <PageHeader
              pageName="Upcoming Events"
              filters={true}
              categorySelector={
                <CategorySelector 
                  name="events"
                  categories={this.state.categories}
                  selectedCategory={this.state.selectedCategoryItem}
                  handleCategorySelection={this.handleCategorySelection}
                />
              }
              getContent={() => {
                this.setState(
                  {
                    activeIndex: 0,
                    isLoading: true,
                  },
                  function () {
                    this.getContent();
                  },
                );
              }}
              getFavourites={() => {
                this.setState(
                  {
                    activeIndex: 1,
                    isLoading: true,
                  },
                  function () {
                    this.getFavourites();
                  },
                );
              }}
              activeTabIndex={this.state.activeIndex}
            />
            {this.dataComponent()}
            {/* {this.renderPagination()} */}
            {this.renderLoadMore()}
          </main>
        );
      }
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default connect(mapStateToProps)(Events);
