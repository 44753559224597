// Modules
import Resizer from 'react-image-file-resizer';

export const resizeFile = (
  file,
  minWidth,
  maxWidth,
  minHeight,
  maxHeight,
  format,
  quality,
  rotation,
  outputFormat,
) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      format ? format : 'JPEG',
      quality ? quality : 76,
      rotation ? rotation : 0,
      (resizedFile) => {
        resolve(resizedFile);
      },
      outputFormat ? outputFormat : 'file',
      minWidth,
      minHeight
    );
  });


