import React from "react";

// Modules
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// App
import {getOnlineNode} from '../../core/getNode';
import PostTitle from "../../partials/postTitle";
import PostExcerpt from "../../partials/postExcerpt";
import LoadingSpinner from "../../partials/loadingSpinner";
import {alertMessages} from '../../partials/alertMessages';

// UI components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Bookmark, Folder, List } from "react-feather";

class ForumsBoard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      favorite: "false",
      forumTopics: [],
    };
  }

  /**
   * @function componentDidMount
   * @description Default react method. Fired when component is rendered
   */
  componentDidMount() {
    this.checkSession();
  }

  checkSession = () => {
    if (Object.keys(this.props.user).length === 0) {
      this.props.history.push('/');
    } else {
      this.checkState();
      // this.getForumTopics();
    }
  }

  checkState = () => {
    this.setState({
      favorite: this.props.item.favorite,
    });
  };

  /**
   * @function setData
   * @description Updates the state of the component upon data retrival
   * @param {object} _data
   * @param {object} _realm
   */
  setData = (_data) => {
    this.setState({
      isLoading: false,
      isRefreshing: false,
      isError: false,
      forumTopics: _data.rows,
    });
  };

  /**
   * @function setError
   * @description Updates the state of the component upon an error
   * @param {boolean} _isError
   * @param {int} _errorStatus
   * @param {string} _errorMessage
   */
  setError = (_isError, _errorStatus, _errorMessage) => {
    this.setState({
      isLoading: false,
      isRefreshing: false,
      isError: _isError,
      errorStatus: _errorStatus,
      errorMessage: _errorMessage,
    });
  };

  /**
   * @function getForumTopics
   * @description Retrieves the data from an API
   */
  getForumTopics = () => {
    let tid = this.props.tid;
    let path = "forum_topic/" + tid + "/all?_format=json&status=1&promote=1";

    getOnlineNode(path, this.props.user.access_token)
      .then((response) => {
        if (response.data.rows.length > 0) {
          this.setData(response.data);
        } else {
          this.setError(true, 200, alertMessages.noData);
        }
      })
      .catch((_error) => {
        console.log("error: ", _error);
        if (_error.response) {
          this.setError(
            true,
            _error.response.status,
            _error.response.statusText
          );
        } else if (_error.request) {
          //console.log('@rest request: ', _error.request);
          this.setError(true, 0, alertMessages.requestError.message);
        } else {
          //console.log('@rest unknown: ', _error);
          this.setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };

  /**
   * Favourite a forum item and update the db
   *
   * @param {The taxonomy id} _tid
   */
  favorite = (_tid) => {
    this.setState({
      favorite: "true",
    });

    this.props.favorite(_tid);
  };

  /**
   * Unfavourite a forum item and update the db
   *
   * @param {*} _tid
   */
  unfavorite = (_tid) => {
    this.setState({
      favorite: "false",
    });

    this.props.unfavorite(_tid);
  };

  /**
   * Handle item hover state
   * @returns
   */
  handleItemHover = (hover) => {
    this.setState({ isHovered: hover });
  };

  /**
   * Render the forum title
   */
  renderForumTitle = () => {

    // console.log('@forumsBoard.js:', this.props.topics);

    let numberOfTopics;
    if (
      typeof this.props.topics !== "undefined" &&
      this.props.topics.length > 0
    ) {
      numberOfTopics = this.props.topics.length;
    } else {
      numberOfTopics = 0;
    }

    return (
      <>
        {this.props.item.title}
        <div className="title__topics">
          <div className="icon">
            <List/>
          </div>
          {/* Topics: <strong>{this.state.forumTopics.length}</strong> */}
          Topics: <strong>{numberOfTopics}</strong>
        </div>
      </>
    );
  };

  renderForumBoard = () => {
    const itemClass = "forum-item";
    const itemClassList = `${itemClass} item`;

    let itemStyle = itemClassList;
    let hoveredStyle = this.state.isHovered ? "hovered" : "";

    const forumBoardLink = `/forums/${this.props.item.tid}`;

    return (
      <Col
        key={"forum-" + this.props.item.tid}
        className="d-flex"
        xs={12}
      >
        <div className={`${itemStyle} ${hoveredStyle}`}>
          <Row noGutters className="align-items-center mb-1">
            <Col xs={"auto"}>
              <Link
                to={forumBoardLink}
                className={`${itemClass}__icon`}
                onMouseEnter={() => this.handleItemHover(true)}
                onMouseLeave={() => this.handleItemHover(false)}
              >
                <Folder />
              </Link>
            </Col>
            <Col>
              <Link
                to={forumBoardLink}
                className={`${itemClass}__title-container`}
                onMouseEnter={() => this.handleItemHover(true)}
                onMouseLeave={() => this.handleItemHover(false)}
              >
                <PostTitle
                  title={this.renderForumTitle()}
                  headingLevel={"h6"}
                />
              </Link>
            </Col>
            <Col xs={"auto"} className="d-flex  align-items-center">
              <div className="px-2">
                {this.state.favorite === "true" ? (
                  <button
                    className="favourite-btn filled"
                    onClick={() => {
                      this.unfavorite(this.props.item.tid);
                    }}
                  >
                    <Bookmark />
                  </button>
                ) : (
                  <button
                    className="favourite-btn"
                    onClick={() => {
                      this.favorite(this.props.item.tid);
                    }}
                  >
                    <Bookmark />
                  </button>
                )}
              </div>
            </Col>
          </Row>
          <Row noGutters>
            <Col xs={12} md={6}>
              {this.props.item.description && (
                <PostExcerpt body={this.props.item.description} />
              )}
            </Col>
          </Row>
        </div>
      </Col>
    );
  };

  /**
   * @function render
   * @description Default render method
   */
  render() {
    if (this.state.isLoading) {
      return <LoadingSpinner />;
    } else {
      return this.renderForumBoard();
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default connect(mapStateToProps)(ForumsBoard);
