import React from 'react';

// Modules
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import TimeAgo from 'react-timeago';
import moment from 'moment';
import _ from 'lodash';

// App
import {serverUrl} from '../../config';
import {getOnlineNode} from '../../core/getNode';
import PageHeader from '../../partials/pageHeader';
import LoadingSpinner from '../../partials/loadingSpinner';
import {alertMessages} from '../../partials/alertMessages';
import Error from '../../partials/error';
import {patchNode} from '../../core/postNode';
import {get_xcsrf_token} from '../../core/auth';
import MessageForm from '../../partials/messageForm';
import UserImage from "../../partials/userImage";
import PostExcerpt from "../../partials/postExcerpt";

// UI components
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { Col } from 'react-bootstrap';

class Conversation extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      isRefreshing: false,
      isPaginating: false,

      title: '',

      messages: [],
      pageSize: 10,
      currentPage: 0,
      total_pages: 0,
    };

    this.messagesStart = null;
    this.messagesEnd = null;
    this.conversationBlock = React.createRef();
  }

  /**
   * @function componentDidMount
   * @description Default react method. Fired when component is rendered
   */
  componentDidMount() {
    this.checkSession();
    window.addEventListener('resize', this.checkDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkDimensions);
  }

  checkSession = () => {
    if (Object.keys(this.props.user).length === 0) {
      this.props.history.push('/');
    } else {
      this.getToken();
      // this.getContent();
      this.getUid();
    }
  }

  checkDimensions = () => {
    const {innerWidth} = window;
    const {uid} = this.state;
  
    const breakpoint = 992;

    /** breakpoint 992 */
    if (innerWidth > breakpoint && uid !== null) {
      this.props.history.push({ 
        pathname: '/account/messages',
        state: {
          uid: uid,
        }
       });
    }
  }

  getToken = () => {
    get_xcsrf_token().then(_response => {
      this.setState({
        token: _response.data,
      });
    });
  };

  /**
   * @function setData
   * @description Updates the state of the component upon data retrival
   * @param {object} _data
   * @param {object} _realm
   */
  setData = async (_data, _scrollToBottom, _scrollToTop) => {
    const {originalMessages, currentPage, total_pages} = this.state;

    let title;
    
    if (
      _data.rows[0].from_uid !==
      this.props.user.current_user.uid
    ) {
      title =
        _data.rows[0].from_first_name + ' ' + _data.rows[0].from_last_name;
      if (title.length < 3) {
        title = _data.rows[0].from_name;
      }
    } else {
      title =
        _data.rows[0].recipient_first_name +
        ' ' +
        _data.rows[0].recipient_last_name;
      if (title.length < 3) {
        title = _data.rows[0].recipient_name;
      }
    }

    if (currentPage > total_pages) {
      return;
    }

    let newMessages = [];

    if (currentPage > 0) {
      newMessages = _data.rows.reverse();
      newMessages = [...newMessages, ...originalMessages];
    } else {
      newMessages = _data.rows.reverse();
    }

    newMessages = _(newMessages)
      .sortBy(message => message.created)
      .groupBy(message => moment.unix(message.created).format('DD MMM YYYY'))
      .value();

    this.setState(
      {
        isLoading: false,
        isRefreshing: false,
        isPaginating: false,
        isError: false,
        messages: newMessages,
        originalMessages:
          currentPage === 0 ? _data.rows : [...originalMessages, ..._data.rows],
        total_pages: _data.pager.total_pages - 1,
        title: title,
      },
      function () {
        if (_scrollToBottom) {
          this.messagesEnd.scrollIntoView({ behavior: 'smooth' });
        }

        if (_scrollToTop) {
          this.messagesStart.scrollIntoView({ behavior: 'smooth' });
        }
      },
    );
  };

  /**
   * @function setError
   * @description Updates the state of the component upon an error
   * @param {boolean} _isError
   * @param {int} _errorStatus
   * @param {string} _errorMessage
   */
  setError = (_isError, _errorStatus, _errorMessage) => {
    this.setState({
      isLoading: false,
      isRefreshing: false,
      isPaginating: false,
      isError: _isError,
      errorStatus: _errorStatus,
      errorMessage: _errorMessage,
    });
  };

  /**
   * Get the UID from url or as props from parent
   */
  getUid = () => {
    if (this.props.match.params.uid) {
      this.setState({
        uid: this.props.match.params.uid
      },
      () => {
        this.getContent();
      });
    }

    if (this.props.uid) {
      this.setState({
        uid: this.props.uid,
        messages: [],
      },
      () => {
        this.getContent();
      });
    }
  };

  /**
   * @function getContent
   * @description Retrieves the data from an API / Fallback to local realm object if there is no connection
   */
  getContent = () => {
    let path =
        'conversations/' +
        this.state.uid +
        '+' +
        this.props.user.current_user.uid +
        '/' +
        this.state.uid +
        '+' +
        this.props.user.current_user.uid +
        '?items_per_page=' +
        this.state.pageSize +
        '&page=' +
        this.state.currentPage;

    getOnlineNode(path, this.props.user.access_token)
      .then((response) => {
        if (response.data.rows.length > 0) {
          if (this.state.currentPage > 0) {
            this.setData(response.data, false, true);
          } else {
            this.setData(response.data, true, false);
          }
        } else {
          this.setError(true, 200, 'No data found for this uid.');
        }
      })
      .catch((_error) => {
        if (_error.response) {
          this.setError(
            true,
            _error.response.status,
            _error.response.statusText,
          );
        } else if (_error.request) {
          this.setError(true, 0, alertMessages.requestError.message);
        } else {
          this.setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };

  onMessageSent = () => {
    this.setState(
      {
        isLoading: true,
        currentPage: 0,
        messages: [],
      },
      function () {
        this.getContent();
      },
    );
  };

  updateMessageState = _nid => {
      const data = {
        _links: {
          type: {
            href: serverUrl + '/rest/type/node/message',
          },
        },
        type: {
          target_id: 'message',
        },
        field_read: {
          value: 1,
        },
      };

    patchNode(
      'node/' + _nid,
      data,
      this.state.token,
      this.props.user.access_token,
    )
      .then(_response => {
        if (typeof this.props.onMessageReadStatusUpdate !== 'undefined') {
          this.props.onMessageReadStatusUpdate();
        }
      })
      .catch(_error => {
        console.log('@patchNode error: ', _error);
      });
  };

  loadMore = () => {
    this.setState(
      {
        isPaginating: true,
        currentPage: this.state.currentPage + 1,
      },
      function () {
        this.getContent();
      },
    );
  };

  renderMessageBlock = (key, messages) => {
    return (
      <div key={key} className="message-block">
        <div className="message-block__timestamp">{key}</div>
        {messages.map((message) => {
          return (
            <div key={message.nid} className="message-block__message">
              {this.renderMessage(message)}
            </div>
          );
        })}
      </div>
    );
  };

  renderMessage = message => {
    if (
      message.from_uid !== this.props.user.current_user.uid
    ) {
      if (message.field_read !== 'true') {
        this.updateMessageState(message.nid);
      }
      return this.renderReceived(message);
    } else {
      return this.renderSent(message);
    }
  };

  renderReceived = message => {
    let created = moment.unix(message.created).format('HH:mm');
    let imageURI = message.from_picture;

    return (
      <div key={message.nid} className="message--received  message">
        <Row noGutters>
          <Col xs={'auto'}>
            <UserImage image={imageURI} />
          </Col>
          <Col className="ml-2 d-flex flex-column">
            <PostExcerpt className="message__body" body={message.body}/>
            <div className="message__timestamp">{created}</div>
          </Col>
        </Row>
      </div>
    );
  };

  renderSent = message => {
    let created = moment.unix(message.created).format('HH:mm');
    let hasRead = ' ✓';

    if (message.field_read === 'false') {
      hasRead = '';
    }

    return (
      <div key={message.nid} className="message--sent  message">
        <Row noGutters>
          <Col className="d-flex flex-column">
            <PostExcerpt className="message__body" body={message.body}/>
            <div className="message__timestamp">{created + hasRead}</div>
          </Col>
        </Row>
      </div>
    );
  };

  renderLoadMore = () => {
    if (this.state.currentPage < this.state.total_pages) {
      return (
        <div className="form-group d-flex justify-content-center">
          {this.state.isPaginating ? (
            <LoadingSpinner />
          ) : (
            <Button onClick={() => this.loadMore()}>Load Previous</Button>
          )}
        </div>
      )
    } else {
      return (
        <div className="load-more-end-text">No more messages</div>
      )
    }
  };

  renderLastMessageCreated = () => {
    let timestamp;
    Object.entries(this.state.messages).map(([key, value]) => {
      return value.map((message) => {
        return timestamp = moment.unix(message.created);
      })
    });

    return (
      <div className="last-message">
        <span>Last message:</span>
        <TimeAgo date={timestamp} className={`timestamp`} />
      </div>
    );
  };

  /**
   * onBlur
   */
  onBlur = () => {
    /**
     * PROBLEM: 
     * After iOS safari keyboard disappears the space remains and .conversation-block 
     * does not 're-grow' to fill this space
     * SOLUTION: Triggering the overflow to hidden then back to auto forces a re-render 
     */
    this.conversationBlock.current.style.overflow = 'hidden';
    setTimeout(() => {
      this.conversationBlock.current.style.overflow = 'auto';
    }, 100);
  };

  /**
   * @function render
   * @description Default render method
   */
  render() {
    if (this.state.isLoading) {
      return <LoadingSpinner className="spinner-container--conversation" />;
    } else {
      if (this.state.isError) {
        return (
          <Error 
            status={this.state.errorStatus}
            message={this.state.errorMessage}
          />
        );
      } else {
        return (
          <main className="conversation screen">
            <div ref={(el) => { this.messagesStart = el; }} />
            <header className="conversation__header">
              <Row className="align-items-center">
                <Col xs={'auto'}>
                  <PageHeader
                    pageName={this.state.title} 
                    filters={false}
                    getContent={() => {
                      this.getContent();
                    }}
                  />
                </Col>
                <Col>
                  {this.renderLastMessageCreated()}
                </Col>
              </Row>
            </header>
            <section ref={this.conversationBlock} className="conversation-block">
              {this.renderLoadMore()}
              {Object.entries(this.state.messages).map(([key, value]) => {
                return this.renderMessageBlock(key, value);
              })}
            </section>
            <div ref={(el) => { this.messagesEnd = el; }} />
            <MessageForm
              uid={this.props.user.current_user.uid}
              sendto={this.state.uid}
              onMessageSent={this.onMessageSent}
              onMessageBlur={this.onBlur}
            />
          </main>
        );
      }
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default withRouter(connect(mapStateToProps)(Conversation));
