import React from "react";

// Modules
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import TimeAgo from "react-timeago";
import moment from "moment";

// App
import PostTitle from "../../partials/postTitle";
import PostExcerpt from "../../partials/postExcerpt";
import LoadingSpinner from "../../partials/loadingSpinner";

// Data
// import db from "../../core/db";

// UI components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Bookmark, Clock } from "react-feather";

class NewsItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      favorite: 'false',
    };
  }

  componentDidMount() {
    this.checkState();
  }

  checkState = () => {
    this.setState({
      favorite: this.props.item.favorite,
    });
  };

  /**
   * Favourite a news item and update the db
   *
   * @param {The node id} _nid
   */
  favorite = (_nid) => {
    this.setState({
      favorite: "true",
    });

    this.props.favorite(_nid);
  };

  /**
   * Unfavourite a news item and update the db
   *
   * @param {*} _nid
   */
  unfavorite = (_nid) => {
    this.setState({
      favorite: "false",
    });

    this.props.unfavorite(_nid);
  };

  /**
   * Handle item hover state
   * @returns
   */
  handleItemHover = (hover) => {
    this.setState({ isHovered: hover });
  };

  renderNewsItem = () => {
    const itemClass = "news-item";
    const itemClassList = `${itemClass}  item`;
    const timestamp = moment.unix(this.props.item.created);
    const isSticky = this.props.item.sticky === true || this.props.item.sticky === "true" ? true : false;
    const isFeatured = this.props.item.feature === true || this.props.item.feature === "true" ? true : false;

    let hasImage = this.props.item.field_featured_image.length > 0 ? true : false;
    hasImage = this.props.item.field_featured_image === "null" ? false : true;

    let itemStyle = hasImage ? itemClassList : `no-image ${itemClassList}`;
    let hoveredStyle = this.state.isHovered ? 'hovered' : '';

    // STICKY
    if (isSticky && hasImage) {

      itemStyle = `sticky ${itemClassList}`;
      
      return (
        <Col key={"news" + this.props.item.nid} className="d-flex" xs={12}>
          <div className={`${itemStyle} ${hoveredStyle}`}>
            <Row>
              <Col xs={12} sm={6}>
                <Link
                  to={"/news/" + this.props.item.nid}
                  className={`${itemClass}__image  item__image`}
                  onMouseEnter={() => this.handleItemHover(true)}
                  onMouseLeave={() => this.handleItemHover(false)}
                >
                  <img
                    src={this.props.item.field_featured_image}
                    alt={"img-" + this.props.item.nid}
                  />
                </Link>
              </Col>
              <Col className="d-flex  flex-column">
                <Link
                  to={"/news/" + this.props.item.nid}
                  className={`${itemClass}__content  item__content`}
                  onMouseEnter={() => this.handleItemHover(true)}
                  onMouseLeave={() => this.handleItemHover(false)}
                >
                  <PostTitle
                    title={this.props.item.title}
                    headingLevel={"h6"}
                  />
                  <PostExcerpt
                    body={this.props.item.body}
                  />
                </Link>
                <Row noGutters className="mt-auto">
                  <Col>
                    <footer className={`${itemClass}__footer  item__footer`}>
                      <Row noGutters>
                        <Col className="timestamp">
                          <Clock className="timestamp__icon" />
                          <TimeAgo date={timestamp} className="timestamp__text" />
                        </Col>
                        <Col xs={"auto"} className="px-2">
                          {this.state.favorite === "true" ? (
                            <button
                              className="favourite-btn filled"
                              onClick={() => {
                                this.unfavorite(this.props.item.nid);
                              }}
                            >
                              <Bookmark />
                            </button>
                          ) : (
                            <button
                              className="favourite-btn"
                              onClick={() => {
                                this.favorite(this.props.item.nid);
                              }}
                            >
                              <Bookmark />
                            </button>
                          )}
                        </Col>
                      </Row>
                    </footer>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      );
    }

    // FEATURED
    if (isFeatured && hasImage) {
      itemStyle = `featured ${itemClassList}`;
      return (
        <Col
          key={"news" + this.props.item.nid}
          className="d-flex"
          xs={12}
          lg={6}
        >
          <div className={`${itemStyle} ${hoveredStyle}`}>
            <Link
              to={"/news/" + this.props.item.nid}
              className={`${itemClass}__image  item__image`}
              onMouseEnter={() => this.handleItemHover(true)}
              onMouseLeave={() => this.handleItemHover(false)}
            >
              <img
                src={this.props.item.field_featured_image}
                alt={"img-" + this.props.item.nid}
              />
            </Link>

            <div className={`${itemClass}__content  item__content`}>
              <Link 
                to={"/news/" + this.props.item.nid}
                onMouseEnter={() => this.handleItemHover(true)}
                onMouseLeave={() => this.handleItemHover(false)}
              >
                <PostTitle
                  title={this.props.item.title}
                  headingLevel={"h6"}
                />
                <PostExcerpt
                  body={this.props.item.body}
                />
              </Link>
              <footer className={`${itemClass}__footer  item__footer`}>
                <Row noGutters>
                  <Col className="timestamp">
                    <Clock className="timestamp__icon" />
                    <TimeAgo date={timestamp} className="timestamp__text" />
                  </Col>
                  <Col xs={"auto"}>
                    {this.state.favorite === "true" ? (
                      <button
                        className="favourite-btn filled"
                        onClick={() => {
                          this.unfavorite(this.props.item.nid);
                        }}
                      >
                        <Bookmark />
                      </button>
                    ) : (
                      <button
                        className="favourite-btn"
                        onClick={() => {
                          this.favorite(this.props.item.nid);
                        }}
                      >
                        <Bookmark />
                      </button>
                    )}
                  </Col>
                </Row>
              </footer>
            </div>
          </div>
        </Col>
      );
    } else {
      return (
        <Col
          key={"news" + this.props.item.nid}
          className="d-flex"
          sm={6}
          lg={3}
        > 
          <div className={`${itemStyle} ${hoveredStyle}`}>
            <Row noGutters>
              {hasImage && (
                <Col xs={4} sm={12} lg={12}>
                  <Link
                    to={"/news/" + this.props.item.nid}
                    className={`${itemClass}__image  item__image`}
                    onMouseEnter={() => this.handleItemHover(true)}
                    onMouseLeave={() => this.handleItemHover(false)}
                  >
                    <img
                      src={this.props.item.field_featured_image}
                      alt={"img-" + this.props.item.nid}
                    />
                  </Link>
                </Col>
              )}
              <Col>
                <Link
                  to={"/news/" + this.props.item.nid}
                  className={`${itemClass}__content  item__content`}
                  onMouseEnter={() => this.handleItemHover(true)}
                  onMouseLeave={() => this.handleItemHover(false)}
                >
                  <PostTitle
                    title={this.props.item.title}
                    headingLevel={"h6"}
                  />
                  <PostExcerpt
                    body={this.props.item.body}
                  />
                </Link>
              </Col>
            </Row>
            <Row noGutters className="mt-auto">
              <Col>
                <footer className={`${itemClass}__footer  item__footer`}>
                  <Row noGutters>
                    <Col className="timestamp">
                      <Clock className="timestamp__icon" />
                      <TimeAgo date={timestamp} className="timestamp__text" />
                    </Col>
                    <Col xs={"auto"} className="px-2">
                      {this.state.favorite === "true" ? (
                        <button
                          className="favourite-btn filled"
                          onClick={() => {
                            this.unfavorite(this.props.item.nid);
                          }}
                        >
                          <Bookmark />
                        </button>
                      ) : (
                        <button
                          className="favourite-btn"
                          onClick={() => {
                            this.favorite(this.props.item.nid);
                          }}
                        >
                          <Bookmark />
                        </button>
                      )}
                    </Col>
                  </Row>
                </footer>
              </Col>
            </Row>
          </div>
        </Col>
      );
    }
  };

  /**
   * @function render
   * @description Default render method
   */
  render() {
    if (this.state.isLoading) {
      return <LoadingSpinner />;
    } else {
      return this.renderNewsItem();
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default connect(mapStateToProps)(NewsItem);
