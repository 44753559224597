import React from "react";

// Modules
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";

// App
import PostTitle from "../../partials/postTitle";
import LoadingSpinner from "../../partials/loadingSpinner";

// Data
// import db from "../../core/db";

// UI components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Bookmark, Tag } from "react-feather";
import placeholderImage from "../../assets/events-placeholder.svg";

class EventsItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      favorite: "false",
    };
  }

  componentDidMount() {
    this.checkState();
  }

  checkState = () => {
    this.setState({
      favorite: this.props.item.favorite,
    });
  };

  /**
   * Favourite a form item and update the db
   *
   * @param {The node id} _nid
   */
  favorite = (_nid) => {
    this.setState({
      favorite: "true",
    });

    this.props.favorite(_nid);
  };

  /**
   * Unfavourite a form item and update the db
   *
   * @param {*} _nid
   */
  unfavorite = (_nid) => {
    this.setState({
      favorite: "false",
    });

    this.props.unfavorite(_nid);
  };

  /**
   * Handle item hover state
   * @returns
   */
  handleItemHover = (hover) => {
    this.setState({ isHovered: hover });
  };

  renderEventItem = () => {
    const itemClass = "event-item";
    const itemClassList = `${itemClass}  item`;
    const startDay = moment.unix(this.props.item.eventStartDate).format("ddd");
    const endDay = moment.unix(this.props.item.eventEndDate).format("ddd");
    const startDate = moment.unix(this.props.item.eventStartDate).format("DD");
    const endDate = moment.unix(this.props.item.eventEndDate).format("DD");
    const startMonth = moment.unix(this.props.item.eventStartDate).format('MMM');
    const endMonth = moment.unix(this.props.item.eventEndDate).format('MMM');
    const isMultiDay = startDate === endDate ? false : true;

    let category = "Uncategorised";
    if (this.props.item.categoryLabel) {
      category = this.props.item.categoryLabel;
    }

    let hasImage = this.props.item.field_featured_image.length > 0 ? true : false;
    hasImage = this.props.item.field_featured_image === "null" ? false : true;

    let imageSrc = placeholderImage;
    if (hasImage) {
      imageSrc = this.props.item.field_featured_image;
    }

    let itemStyle = hasImage ? itemClassList : `no-image ${itemClassList}`;
    let hoveredStyle = this.state.isHovered ? "hovered" : "";

    return (
      <Col
        key={"event" + this.props.item.nid}
        className="d-flex gutter-lg"
        xs={12}
        lg={6}
      >
        <div className={`${itemStyle} ${hoveredStyle}`}>
          <Row noGutters>
            <Col xs={"auto"} className="d-flex">
              <Link
                to={"/events/" + this.props.item.nid}
                className="date  item__link"
                onMouseEnter={() => this.handleItemHover(true)}
                onMouseLeave={() => this.handleItemHover(false)}
              >
                {isMultiDay ? (
                  <>
                    <div className="multi-day  date__month">
                      <h3>{startDay}</h3>
                      <span>-</span>
                      <h3>{endDay}</h3>
                    </div>
                    
                    <div className="multi-day  date__day">
                      <h3>{startDate} {startMonth}</h3>
                      <h3>{endDate} {endMonth}</h3>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="date__month">
                      <h3>{startDay}</h3>
                    </div>
                    <div className="date__day">
                      <h3>{startDate}</h3>
                    </div>
                  </>
                )}
              </Link>
              <Link
                to={"/events/" + this.props.item.nid}
                className={`${itemClass}__image item__image item__link`}
                onMouseEnter={() => this.handleItemHover(true)}
                onMouseLeave={() => this.handleItemHover(false)}
              >
                <img src={imageSrc} alt={"img-" + this.props.item.nid} />
              </Link>
            </Col>

            <Col className="d-flex  flex-column">
              <Link
                to={"/events/" + this.props.item.nid}
                className={`${itemClass}__content item__content item__link`}
                onMouseEnter={() => this.handleItemHover(true)}
                onMouseLeave={() => this.handleItemHover(false)}
              >
                <PostTitle title={this.props.item.title} headingLevel={"h6"} />
              </Link>
              <footer className={`${itemClass}__footer  item__footer`}>
                <Row noGutters className="align-items-center">
                  <Col className="category">
                    <Tag className="category__icon" />
                    <span className="category__text">{category}</span>
                  </Col>
                  <Col xs={"auto"} className="d-flex px-2">
                    {this.state.favorite === "true" ? (
                      <button
                        className="favourite-btn filled"
                        onClick={() => {
                          this.unfavorite(this.props.item.nid);
                        }}
                      >
                        <Bookmark />
                      </button>
                    ) : (
                      <button
                        className="favourite-btn"
                        onClick={() => {
                          this.favorite(this.props.item.nid);
                        }}
                      >
                        <Bookmark />
                      </button>
                    )}
                  </Col>
                </Row>
              </footer>
            </Col>
          </Row>
        </div>
      </Col>
    );
  };

  /**
   * @function render
   * @description Default render method
   */
  render() {
    if (this.state.isLoading) {
      return <LoadingSpinner />;
    } else {
      return this.renderEventItem();
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default connect(mapStateToProps)(EventsItem);
