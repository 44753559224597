import React, { Component } from 'react';

// Modules
import ContentLoader from 'react-content-loader';

class SkeletonSingleScreen extends Component {  
  constructor() {
    super();
    this.state = {
      backgroundColor: '#f3f3f3',
      foregroundColor: '#ecebeb',
    };
  }

  render() {
    return (
      <main className="single">
        <article className="article">
          
          <div className="article__header">
            <ContentLoader 
              speed={2}
              width={'100%'}
              height={'100%'}
              viewBox="0 0 920 110"
              backgroundColor={this.state.backgroundColor}
              foregroundColor={this.state.foregroundColor}
              style={{marginBottom: '0.75em', fontSize: '2.5rem'}}
            >
              <rect x="0" y="0" width="920" height="50" rx="10" ry="10" />
              <rect x="0" y="59.997" width="699.707" height="50" rx="10" ry="10" />
            </ContentLoader>

            <div className="article__meta">
              <ContentLoader 
                speed={2}
                width={279}
                height={20}
                viewBox="0 0 279 20"
                backgroundColor={this.state.backgroundColor}
                foregroundColor={this.state.foregroundColor}
                style={{width: 279, height: 20}}
              >
                <rect x="185.594" y="0" width="93.013" height="20" />
                <circle cx="165.557" cy="10" r="10" />
                <rect x="30.037" y="0" width="93.013" height="20" />
                <circle cx="10" cy="10" r="10" />
              </ContentLoader>
            </div>
          </div>

          <div className="article__image">
            <ContentLoader 
              speed={2}
              width={'100%'}
              height={'100%'}
              viewBox="0 0 920 518"
              backgroundColor={this.state.backgroundColor}
              foregroundColor={this.state.foregroundColor}
            >
              <rect x="0" y="0" width="920" height="517.5" />
            </ContentLoader>
          </div>

          <div className="article__body">
            <ContentLoader 
              speed={2}
              width={'100%'}
              height={'100%'}
              viewBox="0 0 920 283"
              backgroundColor={this.state.backgroundColor}
              foregroundColor={this.state.foregroundColor}
            >
              <rect x="0" y="262.908" width="553.742" height="20" rx="10" ry="10"/>
              <rect x="0" y="225.503" width="828.794" height="20" rx="10" ry="10"/>
              <rect x="0" y="188.098" width="878.418" height="20" rx="10" ry="10"/>
              <rect x="0" y="150.692" width="842.991" height="20" rx="10" ry="10"/>
              <rect x="0" y="112.216" width="817.823" height="20" rx="10" ry="10"/>
              <rect x="0" y="74.811" width="857.93" height="20" rx="10" ry="10"/>
              <rect x="0" y="37.405" width="781.132" height="20" rx="10" ry="10"/>
              <rect x="0" y="0" width="842.991" height="20" rx="10" ry="10"/>
            </ContentLoader>
          </div>
        </article>
      </main>
    );
  }

}

export default SkeletonSingleScreen;