import React, { Component } from 'react';

// Modules
import ContentLoader from 'react-content-loader';

// UI components
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";

class NoticesSkeletonScreen extends Component {  
  constructor() {
    super();
    this.state = {
      backgroundColor: '#f3f3f3',
      foregroundColor: '#ecebeb',
    };
  }

  renderDefaultItems = (itemClassList) => {

    let items = [];
    let numberOfItems;

    if (this.props.numberOfItems) {
      numberOfItems = this.props.numberOfItems;
    } else {
      numberOfItems = 20;
    }

    for (let i = 0; i < numberOfItems; i++) {

      items.push(
        <Col sm={12} lg={6} key={'item-' + i}>
          <div className={itemClassList} style={{paddingBottom: 0, border: 'none'}}>
            <ContentLoader 
              speed={2}
              width={'100%'}
              height={'100%'}
              viewBox="0 0 555 136"
              backgroundColor={this.state.backgroundColor}
              foregroundColor={this.state.foregroundColor}
            >
              <circle cx="534.7" cy="105.767" r="10" />
              <rect x="37.801" y="95.767" width="184.848" height="20" rx="10" ry="10"/>
              <circle cx="15" cy="105.767" r="15" />
              <circle cx="12.5" cy="12.5" r="12.5" />
              <rect x="0" y="37.866" width="365.368" height="20" rx="10" ry="10"/>
              <rect x="408.594" y="2.5" width="146.091" height="20" rx="10" ry="10"/>
              <rect x="40.577" y="2.5" width="146.091" height="20" rx="10" ry="10"/>
            </ContentLoader>
          </div>
        </Col>
      );

    }

    return items;
  };

  render() {
    const itemClass = "notice-item";
    const itemClassList = `${itemClass}  item`;

    return (
      <main className="notices screen">
        <div className="page-header">
          <ContentLoader 
            speed={2}
            width={'100%'}
            height={'100%'}
            viewBox="0 0 1140 60"
            backgroundColor={this.state.backgroundColor}
            foregroundColor={this.state.foregroundColor}
          >
            <rect x="0" y="0" width="386.701" height="60" rx="10" ry="10"/>
            <rect x="1041.7" y="11" width="98.3" height="38" rx="5" ry="5"/>
            <rect x="906.703" y="11" width="111.975" height="38" rx="5" ry="5"/>
            <rect x="806.615" y="11" width="77.079" height="38" rx="5" ry="5"/>
            <rect x="496.681" y="11" width="282.373" height="38" rx="5" ry="5"/>
          </ContentLoader>
        </div>

        <Row>
          {this.renderDefaultItems(itemClassList)}
        </Row>
      </main>
    );
  }

}

export default NoticesSkeletonScreen;